import { useState } from "react";
import { currentApiList, currentUsername, getCurrentApiList } from "../../data/config";
import axios from 'axios';

export const useGetMyPremintedPools = () => {
    
    const [myPools, setMyPools] = useState([]);
    const [myPoolsAreLoading, setMyPoolsAreLoading] = useState(true);

    const getMyPremintedPools = () => {
        
        axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
            table:"premintpools",
            scope:"waxdaomarket",
            code:"waxdaomarket",
            key_type: 'name',
            index_position: 2,
            limit:1000,
            lower_bound: currentUsername,
            upper_bound: currentUsername,
            json:true
            }).then((res) => {
                setMyPools(res.data.rows)
            })
            .catch((error) => console.log(error))
            .finally(() => setMyPoolsAreLoading(false))

    }

    return [myPools, getMyPremintedPools, myPoolsAreLoading]
}