import React, { useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { Helmet } from "react-helmet";
import {
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  MainContainer,
  LoadSpinner,
  LoadSpinnerRed,
  LoadSpinnerBlue,
  LoadSpinnerGreen,
  CreateFarmIPFS,
} from "../../components/LeftDiv";
import DatePicker from "react-datepicker";
import ".././datepicker.css";
import {
  MainButton,
  NewListingBody,
  NewListingCont,
  SmallButton,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import { notifyWaxDAO } from "../../data/functions/wallet_functions";
import { handleCalendarChange } from "../../data/functions/pack_functions";
import { createDrop } from "../../data/functions/DropFunctions";
import { getTokenHelp } from "../pools/create_pool_functions";
import { mainnetWebsiteURL } from "../../data/config";

const CreateDrop = () => {
  const {
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
    wharfSession
  } = useStateContext();

  const [collection, setCollection] = useState("");
  const [dropPrice, setDropPrice] = useState("");
  const [dropSymbol, setDropSymbol] = useState("");
  const [dropPrecision, setDropPrecision] = useState("");
  const [rewardContract, setRewardContract] = useState("");
  const [schema, setSchema] = useState("");
  const [templateID, setTemplateID] = useState("");
  const [totalAvailable, setTotalAvailable] = useState("");
  const [limitPerUser, setLimitPerUser] = useState("");
  const [cooldownSeconds, setCooldownSeconds] = useState("");
  const [whitelistType, setWhitelistType] = useState("");
  const [loading, setLoading] = useState(true);
  const [usernames, setUsernames] = useState("");
  const [farmName, setFarmName] = useState("");
  const [minimumNftsStaked, setMinimumNftsStaked] = useState("");

  const [timestamp, setTimestamp] = useState(Math.round(Date.now() / 1000));
  const [endTime, setEndTime] = useState(Math.round(Date.now() / 1000));

  const [dropDescription, setDropDescription] = useState("");
  const [logoHash, setLogoHash] = useState("");
  const [receivingAccount, setReceivingAccount] = useState("");
  const [poolName, setPoolName] = useState("");
  const [percentToPool, setPercentToPool] = useState("0");
  const [revenueOption, setRevenueOption] = useState("no");

  return (
    <div id="seo">
      <Helmet>
        <title>Create NFT Drop - WaxDAO</title>
        <meta
          name="description"
          content="Create an NFT drop on WaxDAO, and accept any WAX token for payment."
        />
        <link rel="canonical" href={`${mainnetWebsiteURL}/create-drop`} />
      </Helmet>

        <MainContainer>
          <Modal className={enterModalDisplay}>
            <ModalContent>{enterModalText}</ModalContent>

            <Spinner style={{ display: loadingDisplay }}>
              <SpinnerRed />
               <SpinnerBlue /> 
              <SpinnerGreen />
            </Spinner>

            <br />
            <GameButton onClick={() => setEnterModalDisplay("hidden")}>
              Close Window
            </GameButton>
          </Modal>

          <NewListingCont>
            <NewListingBody>
              <TallFiltersCont>
                <h2>Create An NFT Drop</h2>
                <br />
                <TallFiltersInnerCont>
                  You can accept payment in any token you like.
                  <br />
                  This includes your token that you created on WaxDAO... right?
                </TallFiltersInnerCont>
                <br />
                <TallFiltersInnerCont>
                  <h3>IMPORTANT!!</h3>
                  waxdaomarket needs to be a "Notify Account" on your collection
                  if you want to receive your RAM back when NFTs from
                  mint-on-demand drops are burned.
                  <br />
                  <br />
                  <h3>NFT Collection</h3>
                  <input
                    type="text"
                    id="collection"
                    maxLength={12}
                    value={collection}
                    placeholder="e.g. hoodpunknfts"
                    onChange={(e) => {
                      setCollection(e.target.value.toLowerCase());
                    }}
                  />
                  <br />
                  <br />
                  <MainButton
                    onClick={() => {
                      notifyWaxDAO(
                        collection,
                        setEnterModalText,
                        setLoadingDisplay,
                        setEnterModalDisplay,
                        wharfSession
                      );
                    }}
                  >
                    Add waxdaomarket Now
                  </MainButton>
                </TallFiltersInnerCont>
              </TallFiltersCont>

              <TallFiltersCont>
                <h2>Drop Details</h2>
                <br />
                <TallFiltersInnerCont>
                  <h3>Price Per NFT</h3>
                  <input
                    type="text"
                    maxLength={20}
                    placeholder="Put 0 if it's a free drop"
                    value={dropPrice}
                    onChange={(e) => {
                      setDropPrice(e.target.value);
                    }}
                  />
                  <br />
                  <span className="italic text-sm">
                    *Note: WaxDAO takes a 2% service fee on completed sales
                  </span>
                  <br />
                  <br />

                  <h3>Token Symbol &nbsp;&nbsp;<SmallButton onClick={() => getTokenHelp(setEnterModalDisplay, setEnterModalText)}>Help!</SmallButton></h3>
                  <input
                    type="text"
                    maxLength={7}
                    placeholder="Put FREE for free drops"
                    value={dropSymbol}
                    onChange={(e) => {
                      setDropSymbol(e.target.value.toUpperCase());
                    }}
                  />
                  <br />
                  <br />

                  <h3>Token Precision  &nbsp;&nbsp;<SmallButton onClick={() => getTokenHelp(setEnterModalDisplay, setEnterModalText)}>Help!</SmallButton></h3>
                  <input
                    type="text"
                    placeholder="Put 0 for a free drop"
                    maxLength={2}
                    value={dropPrecision}
                    onChange={(e) => {
                      setDropPrecision(e.target.value);
                    }}
                  />
                  <br />
                  <br />

                  <h3>Token Contract &nbsp;&nbsp;<SmallButton onClick={() => getTokenHelp(setEnterModalDisplay, setEnterModalText)}>Help!</SmallButton></h3>
                  <input
                    type="text"
                    placeholder="Put free for free drops"
                    maxLength={12}
                    value={rewardContract}
                    onChange={(e) => {
                      setRewardContract(e.target.value.toLowerCase());
                    }}
                  />
                  <br />
                  <br />

                  <h3>NFT Collection</h3>
                  <input
                    type="text"
                    maxLength={12}
                    value={collection}
                    placeholder="e.g. hoodpunknfts"
                    onChange={(e) => {
                      setCollection(e.target.value.toLowerCase());
                    }}
                  />
                  <br />
                  <br />

                  <h3>NFT Schema</h3>
                  <input
                    type="text"
                    maxLength={12}
                    value={schema.toLowerCase()}
                    placeholder="e.g. wojakpacks"
                    onChange={(e) => {
                      setSchema(e.target.value);
                    }}
                  />
                  <br />
                  <br />

                  <h3>Template ID</h3>
                  <input
                    type="text"
                    placeholder="e.g. 169420"
                    maxLength={8}
                    value={templateID}
                    onChange={(e) => {
                      setTemplateID(e.target.value);
                    }}
                  />
                  <br />
                  <br />

                  <h3>Total Available</h3>
                  <input
                    placeholder="Put 0 if unlimited"
                    type="text"
                    maxLength={20}
                    value={totalAvailable}
                    onChange={(e) => {
                      setTotalAvailable(e.target.value);
                    }}
                  />
                  <br />
                  <br />

                  <h3>Limit Per User</h3>
                  <input
                    placeholder="Put 0 if unlimited"
                    type="text"
                    maxLength={20}
                    value={limitPerUser}
                    onChange={(e) => {
                      setLimitPerUser(e.target.value);
                    }}
                  />
                  <br />
                  <br />

                  <h3>Cooldown Timer (in seconds)</h3>
                  <input
                    placeholder="Put 0 if no reset"
                    type="text"
                    maxLength={8}
                    value={cooldownSeconds}
                    onChange={(e) => {
                      setCooldownSeconds(e.target.value);
                    }}
                  />
                  <br />
                  <br />

                  <h3>Whitelist Type</h3>
                  <select
                    onChange={(e) => {
                      setWhitelistType(e.target.value);
                    }}
                  >
                    <option value="" hidden>
                      Choose
                    </option>
                    <option value="none">Public Drop</option>
                    <option value="farm">WaxDAO NFT Farm</option>
                    <option value="usernames">List Of Usernames</option>
                  </select>
                  <br />
                  <br />

                  <span className={whitelistType != "usernames" && "hidden"}>
                    <h3>WAX Addresses To Whitelist</h3>
                    <textarea
                      placeholder="e.g. john,bob,alice"
                      value={usernames}
                      type="text"
                      rows={4}
                      onChange={(e) => {
                        setUsernames(e.target.value.replace(/ /g, ""));
                      }}
                    />
                    <br />
                    <br />
                  </span>

                  <span className={whitelistType != "farm" && "hidden"}>
                    <h3>Farm Name</h3>
                    <input
                      type="text"
                      placeholder="e.g. waxdao.1week"
                      maxLength={12}
                      value={farmName}
                      onChange={(e) => {
                        setFarmName(e.target.value.toLowerCase());
                      }}
                    />
                    <br />
                    <br />

                    <h3>Minimum NFTs Staked</h3>
                    <input
                      placeholder="e.g. 3"
                      type="text"
                      maxLength={12}
                      value={minimumNftsStaked}
                      onChange={(e) => {
                        setMinimumNftsStaked(e.target.value);
                      }}
                    />
                    <br />
                    <br />
                  </span>

                  <h3>Start Time</h3>
                  <DatePicker
                    showTimeSelect
                    timeIntervals={15}
                    selected={new Date(timestamp * 1000)}
                    onChange={(e) => {
                      handleCalendarChange(e, setTimestamp);
                    }}
                  />
                  <br />
                  <br />

                  <h3>End Time</h3>
                  <DatePicker
                    showTimeSelect
                    timeIntervals={15}
                    selected={new Date(endTime * 1000)}
                    onChange={(e) => {
                      handleCalendarChange(e, setEndTime);
                    }}
                  />
                  <br />
                  <br />

                  <h3>Drop Description</h3>
                  <textarea
                    placeholder="Tell people some info about your drop"
                    value={dropDescription}
                    type="text"
                    rows={4}
                    onChange={(e) => {
                      setDropDescription(e.target.value);
                    }}
                  />
                  <br />
                  <br />

                  <h3>Cover Image (300px x 300px)</h3>
                  <input
                    type="text"
                    maxLength={70}
                    placeholder="IPFS hash only"
                    value={logoHash}
                    onChange={(e) => {
                      setLogoHash(e.target.value);
                    }}
                  />
                  <br />
                  <br />

                  {logoHash != null &&
                    logoHash != "" &&
                    logoHash.length > 10 && (
                      <span>
                        <span className={loading == false && "hidden"}>
                          <LoadSpinner>
                            <LoadSpinnerRed />
                            <LoadSpinnerBlue />
                            <LoadSpinnerGreen />
                          </LoadSpinner>
                          <br />
                          Loading Image...
                          <br />
                        </span>
                        <span className={loading == true && "hidden"}>
                          <CreateFarmIPFS
                            src={`https://ipfs.io/ipfs/${logoHash}`}
                            onLoad={() => {
                              setLoading(false);
                            }}
                            onError={() => {
                              setLoading(false);
                            }}
                          />
                          <br />
                          <br />
                        </span>
                      </span>
                    )}

                  <h3>Receiving Account</h3>
                  <input
                    type="text"
                    maxLength={12}
                    placeholder="e.g. mikedcrypto5"
                    value={receivingAccount}
                    onChange={(e) => {
                      setReceivingAccount(e.target.value.toLowerCase());
                    }}
                  />
                  <br />
                  <br />

                  <TallFiltersCont>
                    <div className="pl-3 pr-3">
                      You can automatically send a portion of drop revenue to
                      any staking pool or NFT farm on WaxDAO.
                      <br />
                      <br />
                      To do this, select one of the options below. Otherwise,
                      select "Don't split revenue".
                    </div>
                  </TallFiltersCont>
                  <br />
                  <br />

                  <h3>Revenue Split</h3>
                  <select
                    onChange={(e) => {
                      setRevenueOption(e.target.value);
                    }}
                  >
                    <option value="" hidden>
                      Choose
                    </option>
                    <option value="no">Don't split revenue</option>
                    <option value="farm">WaxDAO NFT Farm</option>
                    <option value="pool">WaxDAO Staking Pool</option>
                  </select>
                  <br />
                  <br />

                  <span
                    className={
                      revenueOption != "farm" &&
                      revenueOption != "pool" &&
                      "hidden"
                    }
                  >
                    <h3>Name of {revenueOption}</h3>
                    <input
                      type="text"
                      maxLength={12}
                      placeholder={`Which ${revenueOption}?`}
                      value={poolName}
                      onChange={(e) => {
                        setPoolName(e.target.value.toLowerCase());
                      }}
                    />
                    <br />
                    <br />

                    <h3>Percent for {revenueOption}</h3>
                    <input
                      type="text"
                      maxLength={2}
                      placeholder="1 to 98"
                      value={percentToPool}
                      onChange={(e) => {
                        setPercentToPool(e.target.value);
                      }}
                    />
                    <br />
                    <br />
                  </span>

                  <MainButton
                    onClick={() => {
                      createDrop(
                        dropPrice,
                        dropSymbol,
                        dropPrecision,
                        rewardContract,
                        collection,
                        schema,
                        templateID,
                        totalAvailable,
                        limitPerUser,
                        cooldownSeconds,
                        whitelistType,
                        usernames,
                        farmName,
                        minimumNftsStaked,
                        timestamp,
                        endTime,
                        dropDescription,
                        logoHash,
                        receivingAccount,
                        revenueOption,
                        poolName,
                        percentToPool,
                        setEnterModalText,
                        setLoadingDisplay,
                        setEnterButtonsDisplay,
                        setEnterModalDisplay,
                        wharfSession
                      );
                    }}
                  >
                    Create Drop Now
                  </MainButton>
                </TallFiltersInnerCont>
              </TallFiltersCont>
            </NewListingBody>
          </NewListingCont>
        </MainContainer>
      <br />
      <br />
      <br />
    </div>
  );
};

export default CreateDrop;
