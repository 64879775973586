import styled from 'styled-components';
import { theme2024 } from '../../Styles2024';

export const TokenCreatorLeftTop2024 = styled.div`
    width: 100%;
    height: 40%;
    //border: 1px solid magenta;
    font-size: 72px;
    font-weight: 700;
    display: flex;
    align-items: flex-end; 
    color: ${theme2024.textMain};
    padding-left: 0.5em;
    padding-bottom: 0px;

    @media (max-width: 940px) {
        justify-content: center;
        padding-left: 0px;
      }     
      
      @media (max-width: 560px) {
        font-size: 60px;
    }     
    
    @media (max-width: 480px) {
        font-size: 40px;
      }    
`

export const CreatorLeftMiddle2024 = styled.div`
    width: 100%;
    height: 20%;
    //border: 1px solid magenta;
    font-size: 72px;
    font-weight: 700;
    color: ${theme2024.primary};
    padding-left: 0.5em;
    overflow: hidden; /* Hide the text when it's outside the box */
    white-space: nowrap; /* Prevent text from wrapping */   
    
    @media (max-width: 940px) {
        padding-left: 0px;
        text-align: center;
      }      

    @media (max-width: 560px) {
        font-size: 60px;
    }    
    
    @media (max-width: 480px) {
        font-size: 40px;
      }    

`;

export const Creator2024Button = styled.button`
    width: 190px;
    padding: 1em;
    border: 1px solid ${theme2024.textSecondary};
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme2024.textMain};
    background-color: ${props => props.primary ? theme2024.primary : 'transparent'};
    height: 50px;

    :hover{
        border: 1px solid ${theme2024.textMain};
        padding: 1.1em;
        transform: scale(1.05);
        transition: transform 0.2s;
    }

    @media (max-width: 480px) {
        font-size: 12px;
        width: 150px;
      }     
      
    @media (max-width: 430px) {
      margin-top: 20px;
    }   
    
    @media (max-width: 400px) {
      font-size: 10px;
    }    

`

export const SpotlightButton = styled.button`
    width: 140px;
    padding: 1em;
    border: 1px solid ${theme2024.textSecondary};
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme2024.textMain};
    background-color: ${props => props.primary ? theme2024.primary : 'transparent'};
    height: 50px;

    :hover{
        border: 1px solid ${theme2024.textMain};
        padding: 1.1em;
        transform: scale(1.05);
        transition: transform 0.2s;
    }

    @media (max-width: 480px) {
        font-size: 12px;
        width: 120px;
      }     
      
      @media (max-width: 430px) {
        margin-top: 20px;
  }        

`

export const FiltersCont2024 = styled.div`
    width: 900px;
    max-width: 95%;
    background-color: ${theme2024.secondaryDark};
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    text-align: center;
    border-radius: 15px;
    padding-bottom: 25px;
    box-shadow: 1px 1px 3px 0px ${theme2024.secondaryLight};

    h2{
        font-size: 24px;
        color: ${theme2024.textMain};
        font-weight: 600;
    }

    @media (max-width: 560px) {
      width: 95%;
  }     
`