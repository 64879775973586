import { ModalErrorCont, ModalSuccessCont } from "../../Styles2024";
import { currentUsername, currentWebsiteURL, defaultSpinnerDuration, defaultTxSettings } from "../../data/config";
import { PROCESSING_TX_TEXT, error_svg, success_svg } from "../../data/constants";
import { BackedTokensWrapper } from "./WalletStyles2024";

export const burnNft = async (
    asset_id,
    isBacked,
    setEnterModalDisplay,
    setEnterModalText,
    setLoadingDisplay,
    wharfSession
  ) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");
  
      if(localStorage.getItem("wharf--session") == null){
          setEnterModalText(
              "You are not logged in. Click the wallet icon in the top menu"
              );
              return;        
      }
  
      const session = wharfSession  
      
      const action = [
        {
          account: "atomicassets",
          name: "burnasset",
          authorization: [session.permissionLevel],
          data: {
            asset_owner: session.actor,
            asset_id: asset_id
          },
        },
      ];
      
      try {
        const result = await session.transact({ actions: action }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);
        const timer = setTimeout(() => {
            setEnterModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              {isBacked ? 'Your tokens have been unlocked!' : 'Your NFT has been burned!'}
            </span>)
            
          setLoadingDisplay("none");
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
        setEnterModalDisplay("");
      }   
  };

  export const bulkBurnNfts = async (
    assetVector,
    setAssetVector,
    setAllAreSelected,
    setCurrentBulkAction,
    setEnterModalDisplay,
    setEnterModalText,
    setLoadingDisplay,
    wharfSession
  ) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");
  
      if(localStorage.getItem("wharf--session") == null){
          setEnterModalText(
              "You are not logged in. Click the wallet icon in the top menu"
              );
              return;        
      }
  
      const session = wharfSession     
      
      let actions = [];

      for(const nft of assetVector){
        actions.push({
          account: "atomicassets",
          name: "burnasset",
          authorization: [session.permissionLevel],
          data: {
            asset_owner: session.actor,
            asset_id: nft
          },
        })
      }
      
      try {
        const result = await session.transact({ actions: actions }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);        
        const timer = setTimeout(() => {
            setEnterModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              Your NFTs have been burned!
            </span>)
            
          setLoadingDisplay("none");
          setAssetVector([]);
          setAllAreSelected(false);
          setCurrentBulkAction('');
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
        setEnterModalDisplay("");
      }   
  };

  export const bulkBackNfts = async (
    owner,
    assetVector,
    amountPerAsset,
    selectedToken,
    setAssetVector,
    setAllAreSelected,
    setCurrentBulkAction,
    setEnterModalDisplay,
    setEnterModalText,
    setLoadingDisplay,
    wharfSession
  ) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");
  
      if(localStorage.getItem("wharf--session") == null){
          setEnterModalText(
              "You are not logged in. Click the wallet icon in the top menu"
              );
              return;        
      }
  
      const session = wharfSession     
      
      let actions = [];

      let formattedTotalAmount = `${(Number(amountPerAsset) * assetVector.length).toFixed(selectedToken.decimals)} ${selectedToken.currency}`
      let formattedAmountPerAsset = `${Number(amountPerAsset).toFixed(selectedToken.decimals)} ${selectedToken.currency}`

      let contract = "waxdaobacker";
      let announceData = {
        user: session.actor,
        tokens: [{quantity: formattedTotalAmount, token_contract: selectedToken.contract}]
      }

      let back_action_name = "backnft";
      
      let back_action_data = (nft) => {{
          return {
            user: session.actor,
            asset_owner: owner,
            asset_id: nft,
            tokens_to_back: [{quantity: formattedAmountPerAsset, token_contract: selectedToken.contract}]
          }
        }
      }

      actions.push({
        account: contract,
        name: "announcedepo",
        authorization: [session.permissionLevel],
        data: announceData,
      });   
      
      actions.push({
        account: selectedToken.contract,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: contract,
          quantity: formattedTotalAmount,
          memo: 'deposit'
        },
      });         

      for(const nft of assetVector){
        actions.push({
          account: contract,
          name: back_action_name,
          authorization: [session.permissionLevel],
          data: back_action_data(nft),
        })
      }
      
      try {
        const result = await session.transact({ actions: actions }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);        
        const timer = setTimeout(() => {
            setEnterModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              You've successfully backed {assetVector.length} NFTs!
              <br/><br/>
              <BackedTokensWrapper>
              Note: WaxDAO only shows data for irreversible data for backed NFTs.{" "}
              Allow 3-4 minutes for the backed tokens to be displayed on our website.
              </BackedTokensWrapper>
            </span>)
            
          setLoadingDisplay("none");
          setAssetVector([]);
          setAllAreSelected(false);
          setCurrentBulkAction('');
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
        setEnterModalDisplay("");
      }   
  };

  export const bulkTransferNfts = async (
    assetVector,
    setAssetVector,
    receiver,
    memo,
    setAllAreSelected,
    setCurrentBulkAction,
    setEnterModalDisplay,
    setEnterModalText,
    setLoadingDisplay,
    wharfSession
  ) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");
  
      if(localStorage.getItem("wharf--session") == null){
          setEnterModalText(
              "You are not logged in. Click the wallet icon in the top menu"
              );
              return;        
      }
  
      const session = wharfSession     
      
      const action = [
        {
          account: "atomicassets",
          name: "transfer",
          authorization: [session.permissionLevel],
          data: {
            from: session.actor,
            to: receiver,
            asset_ids: assetVector,
            memo: memo,
          },
        },
      ];
      
      try {
        const result = await session.transact({ actions: action }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);        
        const timer = setTimeout(() => {
            setEnterModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              Your NFTs have been sent to {receiver}
            </span>)
            
          setLoadingDisplay("none");
          setAssetVector([]);
          setAllAreSelected(false);
          setCurrentBulkAction('');
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
        setEnterModalDisplay("");
      }   
  };


  export const claimBackedTokens = async (
    claimable,
    ignoreList,
    setEnterModalDisplay,
    setEnterModalText,
    setLoadingDisplay,
    wharfSession
  ) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");
  
      if(localStorage.getItem("wharf--session") == null){
          setEnterModalText(
              "You are not logged in. Click the wallet icon in the top menu"
              );
              return;        
      }
  
      const session = wharfSession     
      
      const action = [
        {
          account: "waxdaobacker",
          name: "withdraw",
          authorization: [session.permissionLevel],
          data: {
            user: session.actor,
            contract_ignore_list: ignoreList,
          },
        },
      ];
      
      try {
        const result = await session.transact({ actions: action }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);        
        const timer = setTimeout(() => {
            setEnterModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              Your tokens have been claimed!
            </span>)
            
          setLoadingDisplay("none");
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
        setEnterModalDisplay("");
      }   
  };  

  export const claimAllBackedTokens = async (
    claimable,
    ignoreList,
    setEnterModalDisplay,
    setEnterModalText,
    setLoadingDisplay,
    wharfSession
  ) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");
  
      if(localStorage.getItem("wharf--session") == null){
          setEnterModalText(
              "You are not logged in. Click the wallet icon in the top menu"
              );
              return;        
      }
  
      const session = wharfSession   
      
      let action = [{
        account: "waxdaobacker",
        name: "withdraw",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          contract_ignore_list: ignoreList
        },
      }] 
      
      try {
        const result = await session.transact({ actions: action }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);        
        const timer = setTimeout(() => {
            setEnterModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              Your tokens have been claimed!
            </span>)
            
          setLoadingDisplay("none");
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
        setEnterModalDisplay("");
      }   
  };  

  /** @fixMissingBalances */
  /* should probably have a list of "bad contracts" like MARTIA that don't have an open action
  */

  export const fixMissingBalances = async (
    missingBalances,
    setEnterModalDisplay,
    setEnterModalText,
    setLoadingDisplay,
    wharfSession
  ) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");
  
      if(localStorage.getItem("wharf--session") == null){
          setEnterModalText(
              "You are not logged in. Click the wallet icon in the top menu"
              );
              return;        
      }
  
      const session = wharfSession     
      
      let actions = [];

      for(const m of missingBalances){
        if(!m.decimals || !m.contract || !m.currency){
          setEnterModalText(<span>
            <ModalErrorCont>
            {error_svg}
            </ModalErrorCont>
            {m.currency ? `${m.currency} is breaking this transaction, please contact us so we can fix it` :
            "Can't find the token information needed to create this transaction!"
            }
                  
          </span>
            );
          setEnterModalDisplay("");
          return;          
        }
        actions.push(        {
          account: m.contract,
          name: 'open',
          authorization: [session.permissionLevel],
          data: {
            owner: session.actor,
            symbol: `${m.decimals},${m.currency}`,
            ram_payer: session.actor
          },
        },)
      }
      
      try {
        const result = await session.transact({ actions: actions }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);        
        const timer = setTimeout(() => {
            setEnterModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              You can now claim your tokens!
            </span>)
            
          setLoadingDisplay("none");
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
        setEnterModalDisplay("");
      }   
  };  

  export const setGallery = async (
    asset_ids,
    setEnterModalDisplay,
    setEnterModalText,
    setLoadingDisplay,
    wharfSession
  ) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");
  
      if(localStorage.getItem("wharf--session") == null){
          setEnterModalText(
              "You are not logged in. Click the wallet icon in the top menu"
              );
              return;        
      }
  
      const session = wharfSession   

      
      let action = [{
        account: "waxdaomarket",
        name: "setgallery",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          asset_ids: asset_ids,
        },
      }] 
      
      try {
        const result = await session.transact({ actions: action }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);        
        const timer = setTimeout(() => {
            setEnterModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              Your gallery has been set! View it on <a href={`${currentWebsiteURL}/u/${currentUsername}`}>your profile page</a>.
            </span>)
            
          setLoadingDisplay("none");
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
        setEnterModalDisplay("");
      }   
  };    

  export const getWhitelisted = async (
    asset_id,
    product,
    setEnterModalDisplay,
    setEnterModalText,
    setLoadingDisplay,
    wharfSession
  ) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");
  
      if(localStorage.getItem("wharf--session") == null){
          setEnterModalText(
              "You are not logged in. Click the wallet icon in the top menu"
              );
              return;        
      }
  
      const session = wharfSession 
      
      let responseData = {
        memo: "",
        url: ""
      }

      if(product == "TOKEN CREATOR"){
        responseData.memo = "createatoken";
        responseData.url = `${currentWebsiteURL}/token-creator`;
      } else if(product == "ROYALTY CONTRACT"){
        responseData.memo = "royalty";
        responseData.url = `${currentWebsiteURL}/royalty-contract`;        
      } else {
        setEnterModalText(
          "You have not chosen a valid product"
          );
          return;           
      }
      
      const action = [
        {
          account: "atomicassets",
          name: "transfer",
          authorization: [session.permissionLevel],
          data: {
            from: session.actor,
            to: 'waxywojaknft',
            asset_ids: [asset_id],
            memo: responseData.memo
          },
        },
      ];
      
      try {
        const result = await session.transact({ actions: action }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);
        const timer = setTimeout(() => {
            setEnterModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              You are now whitelisted! 
              <br/>
              <a href={responseData.url}>Deploy contract now</a>
            </span>)
            
          setLoadingDisplay("none");
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
        setEnterModalDisplay("");
      }   
  };

  export const makeAction = (account, actionName, data, wharfSession) => {
    return {account: account, name: actionName,
      authorization: [wharfSession.permissionLevel],
      data: data
    }
  }

  export const waxTransaction = async (
    actions,
    successMessage,
    setEnterModalDisplay,
    setEnterModalText,
    setLoadingDisplay,
    wharfSession
  ) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");
  
      if(localStorage.getItem("wharf--session") == null){
          setEnterModalText(
              "You are not logged in. Click the wallet icon in the top menu"
              );
              return;        
      }
  
      const session = wharfSession     
      
      try {
        const result = await session.transact({ actions: actions }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);        
        const timer = setTimeout(() => {
            setEnterModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              {successMessage}
            </span>)
            
          setLoadingDisplay("none");
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
        setEnterModalDisplay("");
      }   
  };  