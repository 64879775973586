import React, { useEffect } from 'react'
import { useGetUserItem } from './CustomHooks/useGetUserItem'
import LoadingDiv from './LoadingDiv';
import DropCard2024 from './DropCard2024';
import FarmCard2024 from './FarmCard2024';
import { FarmsContainer2024 } from '../pages/farmsv2/FarmStyles2024';
import TokenFarmCard2024 from './TokenFarmCard2024';
import BlendCard2024 from './BlendCard2024';
import UnboxCard2024 from './UnboxCard2024';
import DaoCard2024 from './DaoCard2024';

const UserItems = (props) => {
    const item = props.item;
    const user = props.user;

   const [itemData, getItem, itemIsLoading] = useGetUserItem();

   useEffect(() => {
     let isMounted = true;
     if(isMounted){
        getItem(user, item)
     }

     return () => {
       isMounted = false;
     }
   }, [item])
   

  return (
    <div className={`w-100 flex flex-wrap justify-center gap-2 mt-4`}>
    {itemIsLoading && <LoadingDiv />}
    {!itemIsLoading && itemData?.length == 0 && "No items"}

    {!itemIsLoading && itemData?.length > 0 && itemData.map((i, index) => (
        <span key={index}>
            {item == "Drops" && <DropCard2024 item={i} nsfwCollections={[]} nsfwIsLoading={false} />}
            {item == "Blends" && <BlendCard2024 item={i} nsfwCollections={[]} nsfwIsLoading={false} />}
            {item == "Unbox" && <UnboxCard2024 item={i} nsfwCollections={[]} nsfwIsLoading={false} />}
            {item == "DAOs" && <DaoCard2024 item={i} />}
            {item == "NFT Farms" && 
                <FarmsContainer2024>
            <FarmCard2024 farm={i} />
            </FarmsContainer2024>}
            {item == "Token Farms" && 
                <FarmsContainer2024>
            <TokenFarmCard2024 farm={i} isTokenFarm={true} />
            </FarmsContainer2024>}            
        </span>
    ))}

    </div>
  )
}

export default UserItems