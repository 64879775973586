import styled from "styled-components";
import { theme2024 } from "../../Styles2024";

export const CollectionsDropDownWrapper = styled.div`
    //position: absolute;
    top: 72px;
    left: 15px;
    font-size: 12px;
    width: 150px;  
    height: auto;
    max-height: 200px;
    overflow-y: scroll;  
    opacity: ${props => props.open ? 1 : 0};
    //opacity: 100%;
    visibility: ${props => props.open ? 'visible' : 'hidden'};
    transition: opacity 0.3s, visibility 0.3s;
    //background-color: ${theme2024.secondaryDark};
    background-color: black;
    border: 1px solid ${theme2024.secondaryLight};
    z-index: 1002;

    -ms-overflow-style: scrollbar;  /* IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: ${theme2024.primary} black; 

    ::-webkit-scrollbar{
        height: 4px;
    }

    ::-webkit-scrollbar-thumb{

        background: ${theme2024.primary};
    }

    ::-webkit-scrollbar-track{

        background: #dfdcdc40;
    }    

    button{
        transition: color 0.5s;
        :hover{
            color: ${theme2024.primary};
        }
    }

    @media (max-width: 420px) {
        width: 130px;
        font-size: 10px;              
    }    
`

export const SortDropDownWrapper = styled.div`
    //position: absolute;
    top: 72px;
    left: 675px;
    font-size: 12px;
    width: 150px;  
    height: auto;
    max-height: 200px;
    overflow-y: scroll;  
    opacity: ${props => props.open ? 1 : 0};
    visibility: ${props => props.open ? 'visible' : 'hidden'};
    transition: opacity 0.3s, visibility 0.3s;
    background-color: ${theme2024.secondaryDark};
    border: 1px solid ${theme2024.secondaryLight};
    //z-index: 1001;

    -ms-overflow-style: scrollbar;  /* IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: ${theme2024.primary} black; 

    ::-webkit-scrollbar{
        height: 4px;
    }

    ::-webkit-scrollbar-thumb{

        background: ${theme2024.primary};
    }

    ::-webkit-scrollbar-track{

        background: #dfdcdc40;
    }    

    button{
        transition: color 0.5s;
        :hover{
            color: ${theme2024.primary};
        }
    }

    @media (max-width: 420px) {
        width: 130px;
        font-size: 10px;              
    }    
`

export const SchemasDropDownWrapper = styled.div`
    //position: absolute;
    top: 142px;
    left: 15px;
    font-size: 12px;
    width: 150px;  
    height: auto;
    max-height: 200px;
    overflow-y: scroll;  
    opacity: ${props => props.open ? 1 : 0};
    visibility: ${props => props.open ? 'visible' : 'hidden'};
    transition: opacity 0.3s, visibility 0.3s;
    background-color: ${theme2024.secondaryDark};
    border: 1px solid ${theme2024.secondaryLight};
   //z-index: 1001;

    -ms-overflow-style: scrollbar;  /* IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: ${theme2024.primary} black; 

    ::-webkit-scrollbar{
        height: 4px;
    }

    ::-webkit-scrollbar-thumb{

        background: ${theme2024.primary};
    }

    ::-webkit-scrollbar-track{

        background: #dfdcdc40;
    }    

    button{
        transition: color 0.5s;
        :hover{
            color: ${theme2024.primary};
        }
    }

    @media (max-width: 420px) {
        width: 130px;
        font-size: 10px;              
    }    
`

export const WalletFiltersWrapper = styled.div`
    width: 920px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    //position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    //height: ${props => props.height};
    max-width: 95%;
    border: 1px solid ${theme2024.primary};
    border-radius: 10px;

    h3{
        font-size: 12px;
        font-weight: 600;
    }
           
`

export const WalletFiltersQuadrant = styled.div`
    width: 50%;
    max-height: ${props => props.height};
    //border: 1px solid grey;
    z-index: 1001;
`

export const WalletFiltersVerticalHalf = styled.div`
    width: 100%;
    height: 75px;
    //border: 1px solid grey;
    padding: 10px;
    padding-left: 20px;
    box-sizing: border-box;
    //z-index: 1001;
`

export const WalletActionsWrapper = styled.div`
    position: ${(props) => (props.isSticky ? 'fixed' : 'relative')};
    top: ${(props) => (props.isSticky ? '110px' : 'auto')};
    z-index: 1000;
    width: ${(props) => (props.isSticky ? '100%' : '920px')};
    margin-left: ${(props) => (props.isSticky ? '0px' : 'auto')};
    margin-right: ${(props) => (props.isSticky ? 'none' : 'auto')};
    margin-top: ${(props) => (props.isSticky ? 'none' : '30px')};
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    align-items: center;
    height: 80px;
    max-width: ${(props) => (props.isSticky ? '100%' : '95%')};
    border-radius: ${(props) => !props.isSticky && '10px'};
    background-color: ${theme2024.secondaryDarker};

    h3{
        font-size: 12px;
        font-weight: 600;
    }
           
`

export const SelectAllWrapper = styled.div`
    width: 150px;
    display: flex;
    height: 50%;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.7px;
    justify-content: right;

    button{
        margin-right: 10px;
        margin-left: 10px;
        width: 14px;
        height: 14px
        box-sizing: border-box;
        border: ${props => !props.selected ? `1px solid ${theme2024.textSecondaryDarker}` : `1px solid transparent`};
        border-radius: 3px;
    }

    svg{
        fill: ${theme2024.primary};
        width: 14px;
        height: 14px;
    }
`

export const AmountSelectedWrapper = styled.div`
    margin-left: 20px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: left;
    flex-grow: 1;
    color: ${theme2024.textSecondaryDarker};

    @media(max-width: 480px){
        width: 100%;
        padding-left: 15px;
    }
`

export const BulkActionsWrapper = styled.div`
    display: flex;
    //flex-grow: 1;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
    font-size: 12px;
    font-weight: 600;

    button{
        padding: 7px 20px 7px 20px;
        border: 1px solid ${theme2024.secondaryLight}
        //border-bottom: ${props => props.open && 'none'};
        //border-radius: ${props => props.open ? '17px 17px 0px 0px' : '17px'};
        //border-radius: 17px;
        
        letter-spacing: 0.7px;
        color: ${theme2024.textMain};
        text-align: center;
        transition: background-color 0.3s, border 0.3s;
        position: relative;
        box-sizing: border-box;

        :hover{
            background-color: ${theme2024.secondaryDark};
        }
    }
`

export const BulkActionsDropDown = styled.div`
    position: absolute;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    top: 33px;
    left: 0px
    width: 100%;
    overflow: hidden;
    visibility: ${props => !props.visible && 'hidden'};
    height: ${props => !props.visible ? '0px' : '150px'};
    background-color: ${theme2024.secondaryDark};
    border: 1px solid ${theme2024.primary};
    border-radius: 0px 0px 7px 7px;
    border-top: none;
    transition: height 0.3s, visibility 0.3s;
    z-index: 14000;

    button{
        width: 100%;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        transition: background-color 0.3s;
        border-radius: 0px;
        color: ${theme2024.textSecondaryDarker};

        :hover{
            background-color: ${theme2024.secondaryMedium};
        }
    }
`

export const WalletCollectionsHeader = styled.h3`
    //position: absolute;
    top: 15px;
    left: 15px;
`

export const WalletSchemasHeader = styled.h3`
    //position: absolute;
    visibility: ${props => !props.visible && 'hidden'};
    //top: 85px;
    //left: 15px;
`

export const WalletSortHeader = styled.h3`
    //position: absolute;
    //top: 15px;
    //left: 675px;
`

export const SortOpener = styled.div`
    font-size: 12px;
    width: 150px;
    background-color: ${theme2024.secondaryDark};
    outline: none;
    border: ${props => props.focused ? `2px solid ${theme2024.primary}` : `1px solid ${theme2024.secondaryLight}`};
    border-radius: ${props => props.focused ? `7px 7px 0px 0px` : `7px`};
    padding-left: 10px;
    padding-top: 4px;
    padding-bottom: 4px;

    :hover{
        cursor: pointer;
    }    

    :focus{
        //border: 2px solid ${theme2024.primary};
        //border-radius: 7px 7px 0px 0px;
        transition: border-radius 0.3s;
    }

    @media (max-width: 420px) {
        width: 130px;
        font-size: 10px;              
    }        
`

export const CollectionsOpener = styled.div`
    //position: absolute;
    //top: 40px;
    //left: 15px;
    font-size: 12px;
    width: 150px;
    background-color: ${theme2024.secondaryDark};
    outline: none;
    border: ${props => props.focused ? `2px solid ${theme2024.primary}` : `1px solid ${theme2024.secondaryLight}`};
    border-radius: ${props => props.focused ? `7px 7px 0px 0px` : `7px`};
   
    input{
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: transparent;
        width: 100%;
        height: 100%;

        :focus{
            outline: none;
        }
    }  

    :hover{
        cursor: pointer;
    }    

    :focus{
        //border: 2px solid ${theme2024.primary};
        //border-radius: 7px 7px 0px 0px;
        transition: border-radius 0.3s;
    }

    @media (max-width: 420px) {
        width: 130px;
        font-size: 10px;              
    }    
`

export const SchemasOpener = styled.div`
    //position: absolute;
    //top: 110px;
    //left: 15px;
    font-size: 12px;
    width: 150px;
    background-color: ${theme2024.secondaryDark};
    outline: none;
    border: ${props => props.focused ? `2px solid ${theme2024.primary}` : `1px solid ${theme2024.secondaryLight}`};
    border-radius: ${props => props.focused ? `7px 7px 0px 0px` : `7px`};

    input{
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: transparent;
        width: 100%;
        height: 100%;

        :focus{
            outline: none;
        }
    }    

    :hover{
        cursor: pointer;
    }    

    :focus{
        //border: 2px solid ${theme2024.primary};
        //border-radius: 7px 7px 0px 0px;
        transition: border-radius 0.3s;
    }

    @media (max-width: 420px) {
        width: 130px;
        font-size: 10px;              
    }    
`

export const GoToTopWrapper = styled.div`
    position: fixed;
    z-index: 11000;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    display: ${({ show }) => (show ? 'block' : 'none')};

    svg{
        width: 40px;
        height: 40px;
        fill: ${theme2024.primary};
    }
`


export const AttributeModalWrapper2024 = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;    
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 15011;
    width: 500px;
    max-width: 96vw;
    height: 500px;
    max-height: 90vh;
    background-color: ${theme2024.secondaryDark};
    border-radius: 10px;
    overflow: hidden;
`
export const AttributeModalTopSection = styled.div`
    width: 100%;
    display: flex;
    min-height: 130px;
    //border: 1px solid grey;
    justify-content: left;
    margin-top: 10px;
`

export const AttributeModalTopLeft = styled.div`
    width: 100px;
    height: 120px;
    margin-left: 10px
    //border: 1px solid yellow;
    display: flex;
    align-items: center;
    justify-content: center;

    img, video{
        width: auto;
        height: auto;
        max-width: 95%;
        max-height: 95%;
    }

    @media (max-width: 420px) {
        width: 80px;
        height: 96px;                
    }     
`

export const AttributeModalTopMiddle = styled.div`
    min-width: 300px;
    flex-grow: 1;
    margin-right: 25px;
    height: 120px;
    //border: 1px solid magenta;
    display: flex;
    flex-direction: column;
    //padding-top: 10px;
    padding-left: 10px;      

    svg{
        fill: ${theme2024.primary};
        width: 25px;
        height: 25px;
        display: inline-block;
        margin-left: 7px;
    }    

    h2{
        color: ${theme2024.primary};
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
    }

    h3{
        color: ${theme2024.textMain};
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.7px;
    }
    
    h4{
        color: ${theme2024.textMain};
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.7px;
        padding-top: 3px;
    }    

    @media (max-width: 515px) {
        min-width: 200px;
        svg{
            width: 17px;
            height: 17px;
        }           
    }     
`

export const BackedTokensWrapper = styled.div`
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    background-color: ${theme2024.secondaryDarker};
    border-radius: 10px;
    padding: 10px;
    font-size: 12px;

    a, button{
        color: ${theme2024.primary};
        font-weight: 600;
        letter-spacing: 0.5px;
    }
`
export const AttributesWrapper2024 = styled.div`
    width: 95%;
    max-height: 100%;
    margin-bottom: 15px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    background-color: ${theme2024.secondaryDarker};
    border-radius: 10px;
    padding: 10px;
    font-size: 12px;
    overflow-y: scroll; 
    //flex: 1;

    div{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: ${props => props.location && props.location == 'asset_id_page' && `1px solid ${theme2024.darkGrey}`};
    }

    h2{
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.7px;
        padding: 7px;
        height: 100%;
        min-height: 100%;
        width: ${props => props.location && props.location == 'asset_id_page' && '220px'};
        min-width: ${props => props.location && props.location == 'asset_id_page' && '220px'};
    }

    h3{
        display: flex;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.7px;
        color: ${theme2024.textSecondary};
        padding: 7px;
        justify-content: right;
        text-align: right;
        word-break: break-word;
        border-left: ${props => props.location && props.location == 'asset_id_page' && `1px solid ${theme2024.darkGrey}`};
        flex-grow: 1;
   
    }    

    h5{
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        color: ${theme2024.primary};
        letter-spacing: 0.7px;
        text-align: center;
        margin-bottom: 20px;
    }

    -ms-overflow-style: scrollbar;  /* IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: ${theme2024.primary}; 

    ::-webkit-scrollbar{
        width: 4px;
    }

    ::-webkit-scrollbar-thumb{

        background: ${theme2024.primary};
    }

    ::-webkit-scrollbar-track{

        //background: #dfdcdc40;
    }  
`

export const ChooseProductButton = styled.button`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid ${theme2024.secondaryLight};
    margin-top: 15px;
    height: 45px;
    font-weight: 600;
    position: relative;
    background-color: ${theme2024.secondaryDark};

    :hover{
        border: 1px solid ${theme2024.textMain};
        transform: scale(1.05);
        transition: transform 0.2s;
    }

    input{
        width: 100%;
        height: 100%;
        background-color: transparent;
        outline: none;
        text-align: center;
        color: ${theme2024.textSecondary};

        :focus{
            border: 1px solid ${theme2024.primary};
        }
    }
`
export const ChooseProductPopup = styled.div`
    width: 100%;
    position: absolute;
    bottom: 45px;
    background-color: ${theme2024.secondaryDarker};
    height: ${props => !props.visible ? '0px' : '100px'};
    visibility: ${props => !props.visible && 'hidden'};
    color: ${props => !props.visible ? 'transparent' : theme2024.textSecondaryDarker};
    border-radius: 7px 7px 0px 0px;
    border: 1px solid ${theme2024.primary};
    border-bottom: none;
    overflow: hidden;
    overflow-y: scroll;
    transition: height 0.3s, visibility 0.3s, color 0.3s;

    -ms-overflow-style: scrollbar;  /* IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: ${theme2024.primary} black; 

    ::-webkit-scrollbar{
        width: 4px;
    }

    ::-webkit-scrollbar-thumb{

        background: ${theme2024.primary};
    }

    ::-webkit-scrollbar-track{

        background: #dfdcdc40;
    }      


`

export const ProductButton = styled.button`
    width: 100%;
    display: ${props => props.hide ? "none" : "flex"};
    font-size: 14px;
    padding: 7px;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: ${theme2024.textSecondaryDarker};
    justify-content: center;
    align-items: center;
    transition: color 0.3s, background-color 0.3s;

    :hover{
        color: ${theme2024.primary};
        background-color: ${theme2024.secondaryMedium};
    }

`