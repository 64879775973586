import axios from "axios";
import { StakeLeftRow, StakeRightRow, StakersRow } from "../../components/LeftDiv";
import { MainButton, SmallButton } from "../../Styles";
import { currentUsername, currentWebsiteURL, currentAtomicHubLink, currentHyperionApiList, defaultTxSettings, defaultSpinnerDuration, mainnetLightApiList } from "../config";
import { BoldWhiteSpan } from "../css/FarmPageStyles";
import { PROCESSING_TX_TEXT } from "../constants";
import { isLoggedIn } from "./wallet_functions";

export const depositTokens = async (farmName, amountToAdd, precision, contract, rewardtoken, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

    const action = [{
        account: contract,
        name: 'transfer',
        authorization: [session.permissionLevel],
      data: {
          to: 'waxdaofarmer',
          from: session.actor,
          quantity: Number(amountToAdd).toFixed(Number(precision)) + ' ' + rewardtoken,
          memo: '|farm_deposit|' + farmName + '|',
      }
    }]
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your rewards were added to the farm")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }   
}



export const removeTemplate = async (FarmName, tempToRemove, rewardType, farmType, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession     

    let action_name;
    if( rewardType == "dynamic" && farmType == "template"){ action_name = "rmvdynamict"; }
    else if( rewardType == "dynamic" && farmType == "nc.v2"){ action_name = "removenctemp"; }
    else{ action_name = "removetempl"; }
  

      const action = [{
            account: "waxdaofarmer",
            name: action_name,
            authorization: [session.permissionLevel],
          data: {
              user: session.actor,
              farmname: FarmName,
              temp_id: tempToRemove,
          }
        }]
        
        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText("Template has been removed")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }      
  
  } //end removeTemplate



export const addDynamicTemp = async (farmName, farm_type, templateID, collection, stakingValue, precision, rewardtoken, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession  
  
    let action_name;

    if(farm_type == "nc.v2"){
      action_name = {
        account: "waxdaofarmer",
        name: 'addnctemp',
        authorization: [session.permissionLevel],
      data: {
          user: session.actor,
          farmname: farmName,
          template_info: {collection_name: collection, template_id: templateID, hourly_reward: Number(stakingValue).toFixed(Number(precision)) + ' ' + rewardtoken, multiplier: 1}
      }
    }      
    } else {
      action_name = {
        account: "waxdaofarmer",
        name: 'setdynamict',
        authorization: [session.permissionLevel],
      data: {
          user: session.actor,
          farmname: farmName,
          temp_id: templateID,
          hourly_reward: Number(stakingValue).toFixed(Number(precision)) + ' ' + rewardtoken,
          collection_name: collection,
      }
    }
    }

      const action = [action_name]
      
      try {
        const result = await session.transact({ actions: action }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);
        const timer = setTimeout(() => {
          setEnterModalText("Template has been added")
          setLoadingDisplay("none");
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(e.message);
        setEnterModalDisplay("");
      }          
  }



export const addCollection = async (farmName, collection, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession   

      const action = [{
          account: "waxdaofarmer",
          name: 'addnewcol',
          authorization: [session.permissionLevel],
        data: {
            user: session.actor,
            farmname: farmName,
            collection: collection,
        }
      }]
      
      try {
        const result = await session.transact({ actions: action }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);
        const timer = setTimeout(() => {
          setEnterModalText("Collection has been added")
          setLoadingDisplay("none");
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(e.message);
        setEnterModalDisplay("");
      }         
  
  }

  
  export const removeCollection = async (farmName, collection, reward_type, farmtype, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession  

      const action = [{
          account: "waxdaofarmer",
          name: 'removecol',
          authorization: [session.permissionLevel],
        data: {
            user: session.actor,
            farmname: farmName,
            collection: collection,
        }
      }]
      
      try {
        const result = await session.transact({ actions: action }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);
        const timer = setTimeout(() => {
          setEnterModalText("Collection has been removed")
          setLoadingDisplay("none");
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(e.message);
        setEnterModalDisplay("");
      }  
  }


export const newProfile = async (FarmName, description, telegram, discord, twitter, website, nftDrop, image1, image2, image3, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession 

      const action = [{
            account: "waxdaofarmer",
            name: 'newprofile',
            authorization: [session.permissionLevel],
          data: {
              user: session.actor,
              farmname: FarmName,
              description: description,
              telegram: telegram,
              discord: discord,
              twitter: twitter,
              website: website,
              latest_drop: nftDrop,
              image1: image1,
              image2: image2,
              image3: image3,
          }
        }]

        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText("Your profile has been updated")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }           
    
  } //end newProfile



export const addTemplates = async (FarmName, templates, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession     
      
      const action = [{
            account: "waxdaofarmer",
            name: 'addtempl',
            authorization: [session.permissionLevel],
          data: {
              user: session.actor,
              farmname: FarmName,
              temp_ids: templates.split(','),
          }
        }]
        
        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText("Templates have been added")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }      
    
  } //end addTemplates


export const extendFarmNew = async (FarmName, newEndDate, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
  
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession 
      
      const action = [{
        account: 'waxdaofarmer',
        name: 'extendfarm',
        authorization: [session.permissionLevel],
      data: {
          farmname: FarmName,
          endtime: newEndDate,

      }
    }]
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your farm has been extended")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }        
    
  } //end extendFarmNew

  export const banUser = async (FarmName, user, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession     

      const action = [{
        account: 'waxdaofarmer',
        name: 'banuser',
        authorization: [session.permissionLevel],
      data: {
          farmname: FarmName,
          farm_owner: session.actor,
          user_to_ban: user,

      }
    }]

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText(user + " has been banned")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }        
    
  } //end banUser


  export const unbanUser = async (FarmName, user, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession     

      const action = [{
        account: 'waxdaofarmer',
        name: 'unbanuser',
        authorization: [session.permissionLevel],
      data: {
          farmname: FarmName,
          user_to_unban: user,
      }
    }]
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText(user + " has been unbanned")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }       

  } //end unbanUser


  export const kickUser = async (FarmName, user, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession   

      const action = [{
        account: 'waxdaofarmer',
        name: 'kickuser',
        authorization: [session.permissionLevel],
      data: {
          farmname: FarmName,
          user_to_kick: user,
      }
    }]
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText(user + " has been kicked")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }       
    
  } //end kickUser


export const editStakeableAssets = (farmData, dynamicTemps, currentViewStakeableTab, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {
    if(farmData.farmtype == 'template' && farmData.reward_type != 'dynamic'){
        return (
            <span>
                These templates from collection <BoldWhiteSpan><a href={`${currentWebsiteURL}/collection/${farmData.collection}`}>{farmData.collection}</a></BoldWhiteSpan>
                <br/><br/>
                <div className="flex gap-4 flex-wrap">
                {farmData.templates.map((item, index) => (
                  <StakersRow key={index}>
                  <StakeLeftRow>
                  <a href={`${currentAtomicHubLink}/explorer/template/${farmData.collection}/${item}`} target="none" className="font-semibold">{item}</a>
                  </StakeLeftRow>
                  <StakeRightRow>
                   <SmallButton
                    onClick={() => removeTemplate(farmData.farmname, item, farmData.reward_type, farmData.farmtype, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession)}
                   >
                     Remove
                   </SmallButton>
                  </StakeRightRow>                    
                </StakersRow>
                ))}
                </div>
            </span>)
    }
    //v2 farm
    else if((farmData.farmtype == 'template' && farmData.reward_type == 'dynamic')
      ||
      (farmData.farmtype == 'nc.v2' && farmData.reward_type == 'dynamic')
    ){
      if(currentViewStakeableTab == "Collections"){
        return (
            <span>
                <div className="flex gap-4 flex-wrap mt-3 mb-3">
                {farmData.collections.map((item, index) => (
                  <StakersRow key={index}>
                  <StakeLeftRow>
                  <a href={`${currentWebsiteURL}/collection/${item.collection_name}`} className="font-semibold">{item.collection_name}</a>
                  </StakeLeftRow>
                  <StakeRightRow>
                   <SmallButton
                    onClick={() => removeCollection(farmData.farmname, item.collection_name, farmData.reward_type, farmData.farmtype, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession)}
                   >
                     Remove
                   </SmallButton>
                  </StakeRightRow>                    
                </StakersRow>
                ))}
                </div>
            </span>)
          }
          else if(currentViewStakeableTab == "Templates"){
            return(
              <span>
              <div className="mt-3">
              {dynamicTemps.map((item, index) => (
                  <StakersRow key={index}>
                    <StakeLeftRow>
                    <a href={`${currentAtomicHubLink}/explorer/template/${item.collection}/${item.template_id}`} target="none" className="font-semibold">{item.template_id}</a> ({item.reward_amount})
                    </StakeLeftRow>
                    <StakeRightRow>
                     <SmallButton
                      onClick={() => removeTemplate(farmData.farmname, item.template_id, farmData.reward_type, farmData.farmtype, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession)}
                     >
                       Remove
                     </SmallButton>
                    </StakeRightRow>                    
                  </StakersRow>
              ))}
              </div>
          </span>              
            )
          }
    }
    else if(farmData.farmtype == 'collection'){
        return (<span>Any asset from <BoldWhiteSpan><a href={`${currentWebsiteURL}/collection/${farmData.collection}`}>{farmData.collection}</a></BoldWhiteSpan> collection</span>)
    }
    else if(farmData.farmtype == 'schema'){
        return (<span>Any asset from <BoldWhiteSpan><a href={`${currentWebsiteURL}/collection/${farmData.collection}`}>{farmData.collection}</a></BoldWhiteSpan> collection, in the <BoldWhiteSpan>{farmData.schema}</BoldWhiteSpan> schema</span>)
    }
    else if(farmData.farmtype == 's.exclude'){
        return (
        <span>
            Any asset from <BoldWhiteSpan><a href={`${currentWebsiteURL}/collection/${farmData.collection}`}>{farmData.collection}</a></BoldWhiteSpan> collection, in the <BoldWhiteSpan>{farmData.schema}</BoldWhiteSpan> schema, EXCEPT for the following templates:
            <br/>
            <div className="flex gap-4 flex-wrap">
            {farmData.templates.map((item, index) => (
                <MainButton key={index}>
                    {item}
                </MainButton>
            ))}
            </div>
        </span>)
    }
}

export const assetsAreAddable = (farmData) => {
  if(farmData.farmtype == "collection" || farmData.farmtype == "schema"){
    return false
  }
  else{
    return true
  }
}

export const farmIsUpgradeable = (farmData) => {
  if(farmData.farmtype == "collection" || farmData.farmtype == "schema" || farmData.farmtype == "nc.c.shared" || (farmData.farmtype == 'template' && farmData.reward_type == 'dynamic')){
    return false
  }
  else{
    return true
  }
}

export const getRewardTokenBalance = async (contract, symbol, setRewardTokenBalance) => {
  if(!isLoggedIn()) { return; }

  for (const api of mainnetLightApiList) {

      try {
        console.log(`${api}/api/tokenbalance/wax/${currentUsername}/${contract}/${symbol.toUpperCase()}`)
          const res = await axios.get(`${api}/api/tokenbalance/wax/${currentUsername}/${contract}/${symbol.toUpperCase()}`);

          if (res.data && res.data) {
              console.log(res.data)
          
              setRewardTokenBalance(res.data)
              break;
          }

      } catch (error) {
          console.log(`An error occurred with ${api}. Trying next API...`);
          console.error(error);
      }
    }
};