import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  StakeManyDiv,
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  WalletSearchBarCont,
  WalletSearchBar,
  CloseWalletSearchBar,
  WalletSearchBarCollectionList,
  WalletSearchBarSingleCollectionButton,
} from "../components/LeftDiv";
import { Name } from "eos-common";
import {
  currentUsername,
  getCurrentApiList,
  mainnetWebsiteURL,
} from "../data/config";
import {
  unstakeAll,
  stakeMany,
  claimRewards,
  unstakeMany,
  getFarmSocials,
  stakeNonCustodial,
  unstakeNonCustodial,
} from "../data/functions/farm_functions";
import { useGetDynamicTemplates } from "../components/CustomHooks/useGetDynamicTemplates";
import { StakeableAssetsContainer } from "../data/css/FarmPageStyles";
import { useGetStakedAssets } from "../components/CustomHooks/useGetStakedAssets";
import { useGetUnstakedForV1Farm } from "../components/CustomHooks/useGetUnstakedForV1Farm";
import {
  CollectionProfileAvatarCont,
  CollectionProfileBGContainer,
  CollectionProfileContainer,
} from "../data/css/CollectionPageStyles";
import collection_profile_stock_bg from "../data/default_cover.png";
import waxapes_cover from "../data/waxapes_cover.png";
import {
  ExploreFiltersCont,
  ExploreFiltersInnerCont,
  ExploreFiltersScrollCont,
  FarmDescriptionContainer,
  FoldersContainer,
  FoldersRow,
  MainButton,
  MilestoneDescription,
  NewListingBody,
  SingleFolderFourth,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../Styles";
import { FARM_STATUSES, STANDARD_NFT_IMAGE_WIDTH, waxapesExplainer } from "../data/constants";
import {
  PoolLineBreak,
  SinglePoolDetail,
  SinglePoolDetailBold,
} from "../data/css/PoolPageStyles";
import {
  getHourlyReward,
  getPoolSize,
  getPrecisionFromAsset,
  roundToDecimals
} from "../data/functions/pool_functions";
import LoadingPage from "../components/LoadingPage";
import {
  deselectAllStaked,
  deselectAllUnstaked,
  getStakeableAssets,
  selectAllStaked,
  selectAllUnstaked,
  showUnstakedAssets,
} from "./farmpage/showUnstaked";
import FarmNFTCard from "../components/FarmNFTCard";
import { getAssetSymbol } from "../data/functions/wallet_functions";
import { depositTokens } from "../data/functions/edit_farm_functions";
import LoadingDiv from "../components/LoadingDiv";
import Error from "../components/Error";
import { buildIpfsUrl } from "../data/functions/global_functions";
import { useGetNsfwCollections } from "../components/CustomHooks/useGetNsfwCollections";

var list = [];

const FarmPage = ({ location }) => {
  const {
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
    wharfSession
  } = useStateContext();

  const [payoutInterval, setPayoutInterval] = useState(1);

  const { FarmName } = useParams();

  const [farmData, setFarmData] = useState([]);
  const [farmDataIsLoaded, setFarmDataIsLoaded] = useState(false);
  const [currentSection, setCurrentSection] = useState("Claim");

  const [dynamicTemps, getDynamicTemps] = useGetDynamicTemplates();
  const [stakedAssets, getStakedAssets, userCanUnlock, validAssets, claimable] =
    useGetStakedAssets();
  const [unstaked, getUnstakedAssets, loading] = useGetUnstakedForV1Farm();
  const [removalVector, setRemovalVector] = useState([]);
  const [assetVector, setAssetVector] = useState([]);

  const [farmCollections, setFarmCollections] = useState([]);
  const [profile, setProfile] = useState([]);
  const [showSearchCollections, setShowSearchCollections] = useState(false);
  const [enteredText, setEnteredText] = useState("");
  const [stakeMultipleDisplay, setStakeMultipleDisplay] = useState("hidden");
  const [addRewardsAmount, setAddRewardsAmount] = useState(0);

  const [nsfwCollections, getNsfwCollections, nsfwIsLoading] = useGetNsfwCollections();

  useEffect(() => {
    getNsfwCollections();
  }, [])
  

  useEffect(() => {
    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "farms",
        scope: "waxdaofarmer",
        code: "waxdaofarmer",
        key_type: "name",
        indexName: "farmname",
        limit: 1,
        lower_bound: FarmName,
        upper_bound: FarmName,
        json: true,
      })
      .then((response) => {
        console.log(response);
        setFarmData(response.data.rows[0]);
        setFarmDataIsLoaded(true);
        setFarmCollections(response.data.rows[0].collections)

        if (response.data.rows[0].reward_type != "dynamic") {
          if (
            Number(response.data.rows[0].totalstaked) >= 5000 &&
            Number(response.data.rows[0].totalstaked) < 10000
          ) {
            setPayoutInterval(2);
          } else if (
            Number(response.data.rows[0].totalstaked) >= 10000 &&
            Number(response.data.rows[0].totalstaked) <= 15000
          ) {
            setPayoutInterval(3);
          }
        } else {
          if (
            Number(response.data.rows[0].totalstaked) >= 1000 &&
            Number(response.data.rows[0].totalstaked) < 2000
          ) {
            setPayoutInterval(2);
          } else if (
            Number(response.data.rows[0].totalstaked) >= 20000 &&
            Number(response.data.rows[0].totalstaked) <= 3000
          ) {
            setPayoutInterval(3);
          }
        }

        const thisCollection = response.data.rows[0].collection;
        const thisSchema = response.data.rows[0].schema;
        const thisFarmType = response.data.rows[0].farmtype;
        const thisRewardType = response.data.rows[0].reward_type;

        if (
          ((response.data.rows[0].farmtype == "template" &&
          response.data.rows[0].reward_type == "dynamic") || (response.data.rows[0].farmtype == "nc.v2" &&
          response.data.rows[0].reward_type == "dynamic")
        )) {
          getDynamicTemps(FarmName, response.data.rows[0].farmtype);
        } //end if farmtype == template

        if (currentUsername) {
          getUnstakedAssets(
            currentUsername,
            FarmName,
            thisFarmType,
            thisRewardType,
            thisCollection,
            thisSchema
          );
          getStakedAssets(currentUsername, FarmName, thisRewardType);
        }
      })
      .catch((error) => console.log(error));
  }, []);


  const [dropTimer, setDropTimer] = useState("");
  const [timerWasUpdated, setTimerWasUpdated] = useState(true);

  useEffect(() => {
      const timer = setTimeout(() => {
        if (userCanUnlock != null) {
          let current_time = Date.now() / 1000;
          if (current_time < userCanUnlock) {
            let total_minutes_left = Math.round(
              Number(userCanUnlock - current_time) / 60
            );
            let days_left = Math.floor(total_minutes_left / 1440);
            let hours_left =  Math.floor( (total_minutes_left - (days_left * 1440) ) / 60 );
            let minutes_left = total_minutes_left - (days_left * 1440) - (hours_left * 60);
            setDropTimer(days_left + "d " + hours_left + "h " + minutes_left + "m");
          } else {
            setDropTimer("");
          }
        }
        setTimerWasUpdated((prev) => !prev);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }, [dropTimer, timerWasUpdated]);

  useEffect(() => {
    const nameValue = new Name(FarmName).raw().toString();

    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "farmprofiles",
        scope: "waxdaofarmer",
        code: "waxdaofarmer",
        limit: 1,
        lower_bound: nameValue,
        upper_bound: nameValue,
        json: true,
      })
      .then((profileResponse) => {
        setProfile(profileResponse.data.rows[0]);
      })
      .catch((error) => console.log(error));
  }, []);

  if (farmData != null && farmDataIsLoaded) {
    return (
      <div id="seo">
        <Helmet>
          <title>{FarmName} Details</title>
          <meta
            name="description"
            content={`Stake NFTs in the ${FarmName} pool on WaxDao`}
          />
          <link rel="canonical" href={`${mainnetWebsiteURL}/farm/${FarmName}`} />
        </Helmet>

          <Modal className={enterModalDisplay}>
            <ModalContent>{enterModalText}</ModalContent>

            <Spinner style={{ display: loadingDisplay }}>
              <SpinnerRed />
              <SpinnerBlue />
              <SpinnerGreen />
            </Spinner>

            <br />
            <GameButton onClick={() => setEnterModalDisplay("hidden")}>
              Close Window
            </GameButton>
          </Modal>

          <CollectionProfileContainer>
            <CollectionProfileBGContainer>
              {profile?.image1 != null && profile?.image1?.length > 0 ? (
                <img
                  src={buildIpfsUrl(profile.image1, 900)}
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={FarmName != "waxapes" ? collection_profile_stock_bg : waxapes_cover}
                  style={{
                    width: "auto",
                    height: "auto",
                    minHeight: "100%",
                    minWidth: "100%",
                  }}
                />
              )}
            </CollectionProfileBGContainer>

            <CollectionProfileAvatarCont>
              <img
                src={
                  farmData.logo != null &&
                  buildIpfsUrl(farmData.logo, STANDARD_NFT_IMAGE_WIDTH)
                }
                style={{
                  width: "auto",
                  height: "auto",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              />
            </CollectionProfileAvatarCont>
          </CollectionProfileContainer>

          <div className="p-5 mt-4 text-white">
            <br />
            <span className="text-2xl font-semibold">{FarmName}</span>
            <br />
            <span className="text-md">
              {farmData.creator != null && <span>By {farmData.creator}</span>}
            </span>

            {profile?.description != null &&
              profile?.description?.length > 3 && (
                <FarmDescriptionContainer>
                  <MilestoneDescription>
                    {profile.description}
                  </MilestoneDescription>
                </FarmDescriptionContainer>
              )}

            {FarmName == "waxapes" && (
                <FarmDescriptionContainer>
                  <MilestoneDescription>
                    {waxapesExplainer}
                  </MilestoneDescription>
                </FarmDescriptionContainer>
              )}              

              {getFarmSocials(profile)}
          </div>

          <NewListingBody>
            <span className={(currentSection != "Stake" || farmData.farmtype == "nc.c.shared" || farmData.farmtype == "nc.v2") && "hidden"}>
              <StakeManyDiv
                onClick={() => {
                  stakeMany(
                    FarmName,
                    assetVector,
                    setEnterModalText,
                    setLoadingDisplay,
                    setEnterButtonsDisplay,
                    setEnterModalDisplay,
                    wharfSession
                  );
                }}
              >
                Stake {assetVector.length} NFTs <br />
              </StakeManyDiv>
            </span>

            <span className={(currentSection != "Unstake" || farmData.farmtype == "nc.c.shared" || farmData.farmtype == "nc.v2        ") && "hidden"}>
              <StakeManyDiv
                onClick={() => {
                  unstakeMany(
                    FarmName,
                    removalVector,
                    setEnterModalText,
                    setLoadingDisplay,
                    setEnterButtonsDisplay,
                    setEnterModalDisplay,
                    wharfSession
                  );
                }}
              >
                Unstake {removalVector.length} NFTs <br />
              </StakeManyDiv>
            </span>

            <span className={(currentSection != "Stake" || (farmData.farmtype != "nc.c.shared" && farmData.farmtype != "nc.v2")) && "hidden"}>
              <StakeManyDiv
                onClick={() => {
                  stakeNonCustodial(
                    FarmName,
                    assetVector,
                    setEnterModalText,
                    setLoadingDisplay,
                    setEnterButtonsDisplay,
                    setEnterModalDisplay,
                    wharfSession
                  );
                }}
              >
                Stake {assetVector.length} NFTs <br />
              </StakeManyDiv>
            </span>

            <span className={(currentSection != "Unstake" || (farmData.farmtype != "nc.c.shared" && farmData.farmtype != "nc.v2")) && "hidden"}>
              <StakeManyDiv
                onClick={() => {
                  unstakeNonCustodial(
                    FarmName,
                    removalVector,
                    setEnterModalText,
                    setLoadingDisplay,
                    setEnterButtonsDisplay,
                    setEnterModalDisplay,
                    wharfSession
                  );
                }}
              >
                Unstake {removalVector.length} NFTs <br />
              </StakeManyDiv>
            </span>



            <ExploreFiltersCont>
              <h2>Farm Details</h2>

              <br />
              <ExploreFiltersInnerCont>
                <SinglePoolDetail>
                  Farm Status{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {
                      FARM_STATUSES[
                        FARM_STATUSES.findIndex(
                          (attributeIndex) =>
                            attributeIndex.value === farmData.allow_new_members
                        )
                      ].display_text
                    }
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Total Staked{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {farmData?.totalstaked}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  {FarmName != "waxapes" ? "Hourly Pool" : "Last Payout"}{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {getHourlyReward(farmData)}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Total Pool{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {getPoolSize(farmData)}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Lock Period{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {farmData.mintime / 86400} Days
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Expires{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {FarmName == "waxapes" ? "N/A" :
                    new Date(farmData.enddate * 1000).toLocaleDateString()}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
              </ExploreFiltersInnerCont>
            </ExploreFiltersCont>

            <ExploreFiltersCont>
              <h2>Stakeable Assets</h2>

              <br />
              <ExploreFiltersInnerCont>
                <ExploreFiltersScrollCont>
                  <StakeableAssetsContainer>
                    {FarmName != "waxapes" ? getStakeableAssets(farmData, dynamicTemps) : "All 10k #warc apes are stakeable! You don't need to do anything to stake them, it's already been done for you :)"}
                  </StakeableAssetsContainer>
                </ExploreFiltersScrollCont>
              </ExploreFiltersInnerCont>
            </ExploreFiltersCont>

            {currentUsername && (
              <span>

                
                <ExploreFiltersCont>
                  <h2>My Info</h2>

                  <br />
                  <ExploreFiltersInnerCont>
                    <SinglePoolDetail>
                      Staked NFTs{" "}
                      <PoolLineBreak>
                        <br />
                      </PoolLineBreak>
                      <SinglePoolDetailBold>
                        {validAssets != null ? validAssets.length : "0"}
                      </SinglePoolDetailBold>
                    </SinglePoolDetail>
                    <SinglePoolDetail>
                      Unclaimed Rewards{" "}
                      <PoolLineBreak>
                        <br />
                      </PoolLineBreak>
                      <SinglePoolDetailBold>
                        {claimable != null
                          ? roundToDecimals(claimable, 8)
                          : "0"}
                      </SinglePoolDetailBold>
                    </SinglePoolDetail>

                    <span className={(FarmName == "waxapes" || FarmName == "succubank2") && "hidden"}>
                    <SinglePoolDetail>
                      Unlock Time{" "}
                      <PoolLineBreak>
                        <br />
                      </PoolLineBreak>
                      <SinglePoolDetailBold>
                          {dropTimer != "" && dropTimer}
                          {validAssets?.length == 0 && "N/A"}
                          {validAssets?.length > 0 && dropTimer == "" && "Unlocked"}
                      </SinglePoolDetailBold>
                    </SinglePoolDetail>
                    </span>
                  </ExploreFiltersInnerCont>
                </ExploreFiltersCont>
          

                <TallFiltersCont>
                  <h2>Actions</h2>

                  <br />
                  <FoldersContainer>
                    <FoldersRow>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentSection("Claim");
                        }}
                        selected={currentSection == "Claim" && true}
                      >
                        Claim
                      </SingleFolderFourth>

                      <span className={(FarmName == "waxapes" || FarmName == "succubank2") && "hidden"}>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentSection("Stake");
                        }}
                        selected={currentSection == "Stake" && true}
                      >
                        Stake
                      </SingleFolderFourth>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentSection("Unstake");
                        }}
                        selected={currentSection == "Unstake" && true}
                      >
                        Unstake
                      </SingleFolderFourth>
                      </span>

                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentSection("Add Rewards");
                        }}
                        selected={currentSection == "Add Rewards" && true}
                      >
                        Add Rewards
                      </SingleFolderFourth>
                    </FoldersRow>
                  </FoldersContainer>
                  <br />
                  <TallFiltersInnerCont>
                    {currentSection == "Claim" && (
                      <MainButton
                        onClick={() =>
                          claimRewards(
                            FarmName,
                            getPrecisionFromAsset(farmData.poolsize) + "," + getAssetSymbol(farmData.poolsize),
                            farmData.contract,
                            setEnterModalText,
                            setLoadingDisplay,
                            setEnterButtonsDisplay,
                            setEnterModalDisplay,
                            wharfSession
                          )
                        }
                      >
                        Claim{" "}
                        {claimable != null && roundToDecimals(claimable, 8)}
                      </MainButton>
                    )}

                    {currentSection == "Stake" && (
                      <span>
                        <WalletSearchBarCont
                          className={
                            (farmData?.reward_type == "dynamic" || farmData.farmtype == "nc.c.shared") ? "flex mb-3" : "hidden"
                          }
                        >
                          <WalletSearchBar
                            placeholder={`All Collections`}
                            maxLength={12}
                            onChange={(e) => {
                              setEnteredText(e.target.value.toLowerCase());
                            }}
                            onFocus={() => {
                              setShowSearchCollections(true);
                            }}
                          />
                          <CloseWalletSearchBar
                            onClick={() => {
                              setShowSearchCollections(false);
                            }}
                            className={
                              !showSearchCollections ? "hidden" : "inline-block"
                            }
                          >
                            X
                          </CloseWalletSearchBar>
                          <br />
                          <WalletSearchBarCollectionList
                            className={
                              showSearchCollections == false && "hidden"
                            }
                          >
                            {farmCollections.length > 0
                              ? farmCollections.map((item, index) => (
                                  <span
                                    key={index}
                                    className={
                                      enteredText.length >= 1 &&
                                      item.collection_name
                                        .substring(0, enteredText.length)
                                        .indexOf(enteredText) == -1 &&
                                      "hidden"
                                    }
                                  >
                                    {item.collection_name != null && (
                                      <WalletSearchBarSingleCollectionButton
                                        key={index}
                                        onClick={() => {
                                          getUnstakedAssets(
                                            currentUsername,
                                            FarmName,
                                            farmData.farmtype,
                                            farmData?.reward_type,
                                            item.collection_name,
                                            "na"
                                          );
                                          setShowSearchCollections(false);
                                        }}
                                      >
                                        {item.collection_name}
                                      </WalletSearchBarSingleCollectionButton>
                                    )}
                                  </span>
                                ))
                              : "No collections"}
                          </WalletSearchBarCollectionList>
                        </WalletSearchBarCont>
                        <MainButton
                          onClick={() =>
                            selectAllUnstaked(
                              showUnstakedAssets(
                                unstaked,
                                farmData?.reward_type,
                                dynamicTemps,
                                farmData.farmtype,
                                farmData.templates,
                                assetVector,
                                farmData.collections,
                                stakedAssets
                              ),
                              assetVector,
                              setAssetVector
                            )
                          }
                        >
                          Select All
                        </MainButton>
                        &nbsp;
                        <MainButton
                          onClick={() => deselectAllUnstaked(setAssetVector)}
                        >
                          De-select All
                        </MainButton>
                      </span>
                    )}

                    {currentSection == "Unstake" && (
                      <span>
                        <MainButton
                          onClick={() =>
                            selectAllStaked(
                              stakedAssets,
                              removalVector,
                              setRemovalVector
                            )
                          }
                        >
                          Select All
                        </MainButton>
                        &nbsp;
                        <MainButton
                          onClick={() => deselectAllStaked(setRemovalVector)}
                        >
                          De-select All
                        </MainButton>
                        &nbsp;
                        <MainButton
                          onClick={() =>
                            unstakeAll(
                              FarmName,
                              setEnterModalText,
                              setLoadingDisplay,
                              setEnterButtonsDisplay,
                              setEnterModalDisplay,
                              wharfSession
                            )
                          }
                        >
                          Unstake All
                        </MainButton>
                        &nbsp;
                      </span>
                    )}

            {currentSection == "Add Rewards" && (
                <span className="text-white">
                  Like what this farm owner is doing and want to support their collection?<br/><br/>
                  You can now show your support by adding tokens to their reward pool!<br/><br/>

                  <h3>Amount</h3>
                  <input value={addRewardsAmount} onChange={(e) => setAddRewardsAmount(e.target.value)} />
                  <br/><br/>
                  <MainButton onClick={() =>
                        depositTokens(
                          FarmName,
                          addRewardsAmount,
                          getPrecisionFromAsset(farmData.poolsize),
                          farmData.contract,
                          farmData.rewardtoken,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterButtonsDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        )     
                  }             
                  >
                    Deposit {addRewardsAmount} {farmData.rewardtoken}
                  </MainButton>
                </span>
              )}


                  </TallFiltersInnerCont>
                </TallFiltersCont>
              </span>
            )}

            <div
              className={
                currentSection != "Stake"
                  ? "hidden"
                  : "flex flex-wrap gap-5 mt-4 justify-center"
              }
            >
              {currentSection == "Stake" && !loading &&
                showUnstakedAssets(
                  unstaked,
                  farmData?.reward_type,
                  dynamicTemps,
                  farmData.farmtype,
                  farmData.templates,
                  assetVector,
                  farmData.collections,
                  stakedAssets
                ).map((item, index) => (
                  <FarmNFTCard
                    item={item}
                    assetVector={assetVector}
                    setAssetVector={setAssetVector}
                    stakeMultipleDisplay={stakeMultipleDisplay}
                    editMode={0}
                    removalVector={removalVector}
                    setRemovalVector={setRemovalVector}
                    rewardType={farmData?.reward_type}
                    dynamicTemps={dynamicTemps}
                    nsfwCollections={nsfwCollections}
                    nsfwIsLoading={nsfwIsLoading}
                  />
                ))}
              {currentSection == "Stake" && !loading &&
                showUnstakedAssets(
                  unstaked,
                  farmData?.reward_type,
                  dynamicTemps,
                  farmData.farmtype,
                  farmData.templates,
                  assetVector,
                  farmData.collections,
                  stakedAssets
                ).length == 0 && (
                  <span className="text-white">No unstaked assets to display</span>
                )}

                {currentSection == "Stake" && loading && (
                  <span className="text-white">
                  <LoadingDiv />
                  <br/>
                  If you have a lot of assets, this make take a minute or 2. Please be patient.
                  </span>
                )}
            </div>

            <div
              className={
                currentSection != "Unstake"
                  ? "hidden"
                  : "flex flex-wrap gap-5 mt-4 justify-center"
              }
            >
              {currentSection == "Unstake" &&
                stakedAssets.map((item, index) => (
                  <FarmNFTCard
                    item={item}
                    assetVector={assetVector}
                    setAssetVector={setAssetVector}
                    stakeMultipleDisplay={stakeMultipleDisplay}
                    editMode={1}
                    removalVector={removalVector}
                    setRemovalVector={setRemovalVector}
                    rewardType={farmData?.reward_type}
                    dynamicTemps={dynamicTemps}
                    nsfwCollections={nsfwCollections}
                    nsfwIsLoading={nsfwIsLoading}
                  />
                ))}
              {currentSection == "Unstake" && stakedAssets.length == 0 && (
                <span className="text-white">No assets to display</span>
              )}
            </div>

          </NewListingBody>
        <br />
        <br />
        <br />
      </div>
    );
  } 
  else if(farmDataIsLoaded && farmData == null) {
    return <Error error="This farm does not exist"/>
  }
  
  else {
    return <LoadingPage />;
  }
};

export default FarmPage;
