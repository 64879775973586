import React, { useState } from "react";
import {
  DropCardBottomHalf,
  DropCardTitle,
  DropCardTopHalf,
  PremintPoolCardViewPool,
} from "../../data/css/DropStyles";
import {
  WalletCardIcons,
  WalletCardMint,
  WalletCardName,
  WalletCardSingleIconWrapper,
  WalletCardWrapper,
} from "../../data/css/WalletStyles";
import {
  unbox,
} from "../../data/functions/DropFunctions";
import {
  isAWojakPack,
  isStakeable,
  isValidForWhitelisting,
} from "../../data/functions/wallet_functions";
import { ModalOverlay } from "../LeftDiv";
import { RiCoinsFill } from "react-icons/ri";
import { BsFillUnlockFill } from "react-icons/bs";
import { NFTCardCheckbox, ShowNFTInfo } from "../../data/css/PremiintStyles";
import { ImInfo } from "react-icons/im";
import { AiFillGift } from "react-icons/ai";
import AttributeModal from "../AttributeModal";
import { currentWebsiteURL } from "../../data/config";
import StakeableFarmsModal from "../StakeableFarmsModal";
import { MainButton, ViewPoolButton } from "../../Styles";
import { handleAddAsset, handleRemoveAsset } from "../../data/functions/premint_functions";
import { buildNftImage, collectionIsNsfw, showNsfwOverlay } from '../../data/functions/global_functions';
import { useStateContext } from "../../contexts/ContextProvider";
import { hasNsfwHidden } from "../../pages/Settings";
import LoadingDiv from "../LoadingDiv";

const WalletCard = (props) => {
  const item = props.item;
  const farmList = props.farmList;
  const unstaked = props.unstaked;
  const stakeableOnly = props.stakeableOnly;
  const setEnterModalText = props.setEnterModalText;
  const setEnterModalDisplay = props.setEnterModalDisplay;
  const setWhitelistMeDisplay = props.setWhitelistMeDisplay;
  const setAssetToBurn = props.setAssetToBurn;
  const showIcons = props.showIcons;
  const unboxPage = props.unboxPage;
  const setLoadingDisplay = props.setLoadingDisplay;
  const setEnterButtonsDisplay = props.setEnterButtonsDisplay;
  const DropID = props.DropID;
  const assetVector = props.assetVector;
  const setAssetVector = props.setAssetVector;
  const nsfwCollections= props.nsfwCollections;
  const nsfwIsLoading = props.nsfwIsLoading;

  const { wharfSession } = useStateContext();

  const [showFarms, setShowFarms] = useState(false);
  const [showAttributeModal, setShowAttributeModal] = useState(false);

  if ((stakeableOnly && isStakeable(farmList, item)[0]) || !stakeableOnly) {
    return (
      <WalletCardWrapper>
        <DropCardTopHalf>

          {nsfwIsLoading && <LoadingDiv />}

        {!nsfwIsLoading && (
          <>
        {collectionIsNsfw(item.collection.collection_name, nsfwCollections) && hasNsfwHidden() ? 
        showNsfwOverlay() : buildNftImage(item.data)}
          </>
        )} 
        

        {/* {buildNftImage(item.data)} */}
        </DropCardTopHalf>

        {assetVector != null && (
          <NFTCardCheckbox selected={assetVector.indexOf(item.asset_id) > -1 ? "yes" : "no"} onClick={() => {assetVector.indexOf(item.asset_id) > -1 ? handleRemoveAsset(item.asset_id, assetVector, setAssetVector) : handleAddAsset(item.asset_id, assetVector, setAssetVector)}}>
              {assetVector.indexOf(item.asset_id) > -1 && (<span>&#x2713;</span>)}
          </NFTCardCheckbox>
        )}



        <ShowNFTInfo onClick={() => setShowAttributeModal(true)}>
          <ImInfo className="ml-auto mr-auto" />
        </ShowNFTInfo>

        {showAttributeModal && (
          <span>
            <ModalOverlay />
            <AttributeModal
              showAttributeModal={showAttributeModal}
              setShowAttributeModal={setShowAttributeModal}
              item={item}
            />
          </span>
        )}

        {showFarms && (
          <span>
            <ModalOverlay />
            <StakeableFarmsModal
              showFarmsModal={showFarms}
              setShowFarmsModal={setShowFarms}
              farms={isStakeable(farmList, item)[1]}
              possibleFarms={isStakeable(farmList, item)[3]}
            />
          </span>
        )}

        {item.template_mint != null && (
          <WalletCardMint>#{item.template_mint}</WalletCardMint>
        )}

        {item.collection != null && (
          <a
            href={`${currentWebsiteURL}/collection/${item.collection.collection_name}`}
          >
            <DropCardTitle>{item.collection.collection_name}</DropCardTitle>
          </a>
        )}

        <WalletCardName>{item.name != null && item.name}
        </WalletCardName>
        
        {farmList != null &&
          unstaked == true &&
          showIcons != null &&
          showIcons && (
            <WalletCardIcons>
              {( isStakeable(farmList, item)[0] || isStakeable(farmList, item)[2] ) && (
                <WalletCardSingleIconWrapper
                  onClick={() => {
                    setShowFarms(true);
                  }}
                >
                  <RiCoinsFill className="inline-block" />
                </WalletCardSingleIconWrapper>
              )}

              {isValidForWhitelisting(item) && (
                <WalletCardSingleIconWrapper
                  onClick={() => {
                    setEnterModalText(
                      "You can burn this NFT and get whitelisted for the Token Creator"
                    );
                    setEnterModalDisplay("");
                    setWhitelistMeDisplay("");
                    setAssetToBurn(item.asset_id);
                  }}
                >
                  <BsFillUnlockFill className="inline-block" />
                </WalletCardSingleIconWrapper>
              )}
              {isAWojakPack(item) && (
                <WalletCardSingleIconWrapper
                  onClick={() => {
                    setEnterModalText(
                      <span>
                        You can unbox this pack at the following page
                        <br />
                        <br />
                        <a href={`${currentWebsiteURL}/unbox/246`}>
                          <MainButton>Unbox Link</MainButton>
                        </a>
                      </span>
                    );
                    setEnterModalDisplay("");
                  }}
                >
                  <AiFillGift className="inline-block" />
                </WalletCardSingleIconWrapper>
              )}
            </WalletCardIcons>
          )}

        <DropCardBottomHalf />

        {unboxPage != null && unboxPage && (
          <PremintPoolCardViewPool>
            &nbsp;
            <ViewPoolButton
              onClick={() => unbox(
                DropID,
                item.asset_id,
                setEnterModalText,
                setLoadingDisplay,
                setEnterButtonsDisplay,
                setEnterModalDisplay,
                wharfSession
              )}
            >
              Unbox
            </ViewPoolButton>
          </PremintPoolCardViewPool>
        )}
      </WalletCardWrapper>
    );
  } else {
    return null;
  }
};

export default WalletCard;
