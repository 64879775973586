import React from 'react';
import { ModalOverlay, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton } from '../components/LeftDiv';
import { Modal2024, ModalContent2024, ModalOverlay2024, theme2024 } from '../Styles2024';


const DeployModal = (props) => {
    const setDeployModalDisplay = props.setDeployModalDisplay;
  
    return (
      <span>
      <ModalOverlay2024 className={props.deployModalDisplay}/>
      <Modal2024 className={props.deployModalDisplay}>
      <div style={{width: "100%", textAlign: "right", paddingRight: "5px", fontSize: "22px", fontWeight: "400", color: theme2024.textMain}}><button
            onClick={() => {
                setDeployModalDisplay("hidden");
              }}            
            >X</button></div>        
        <ModalContent2024>
          {props.deployModalText}
        </ModalContent2024>
  
          <Spinner style={{display:props.loadingDisplay}}>
          <SpinnerRed  />
          <SpinnerBlue  />
          <SpinnerGreen />
          </Spinner>
  
        </Modal2024>
        </span>
    )
  }
  
  export default DeployModal