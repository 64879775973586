import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { Helmet } from "react-helmet";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  DisabledButton,
  MissingInfoMsg,
} from "../../components/LeftDiv";
import ".././datepicker.css";
import {
  getCurrentApiList,
  currentUsername,
  mainnetWebsiteURL,
} from "../../data/config";
import {
  MainButton,
  NewListingBody,
  NewListingCont,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import {
  createPack,
  filterPoolsByAvailable,
  handleCalendarChange,

} from "../../data/functions/pack_functions";
import PremintPoolCard from "../../components/PremintPoolCard";
import { isLoggedIn } from "../../data/functions/wallet_functions";
import Error from "../../components/Error";

const CreatePack = () => {
  const {

    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
    templateID,
    setTemplateID,
    wharfSession
  } = useStateContext();

  const { PoolID } = useParams();

  const [poolData, setPoolData] = useState([]);
  const [assetVector, setAssetVector] = useState([]);
  const [poolStatus, setPoolStatus] = useState("");
  const [poolIDs, setPoolIDs] = useState([]);
  const [startTime, setStartTime] = useState(Number(Date.now() / 1000).toFixed(0));
  const [dropDescription, setDropDescription] = useState("");
  const [logoHash, setLogoHash] = useState("");
  const [totalAvailable, setTotalAvailable] = useState("");
  const [collection, setCollection] = useState("");

  useEffect(() => {

    if(!isLoggedIn()) return
    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "premintpools",
        scope: "waxdaomarket",
        code: "waxdaomarket",
        key_type: "name",
        index_position: 2,
        limit: 1000,
        lower_bound: currentUsername,
        upper_bound: currentUsername,
        json: true,
      })
      .then((response) => {
        if (response.data.rows.length > 0) {
          let filteredPools = filterPoolsByAvailable(response.data.rows, PoolID, setPoolData, setTotalAvailable, setPoolStatus, setCollection, setAssetVector);
          
          
          setPoolIDs(filteredPools[0]);
          console.log(response.data.rows);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  if(isLoggedIn()){

  return (
    <div id="seo">
      <Helmet>
        <title>Create Unboxing Page - WaxDAO</title>
        <meta name="description" content="Create pre-minted packs on WaxDAO" />
        <link rel="canonical" href={`${mainnetWebsiteURL}/create-pack`} />
      </Helmet>


        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <GameButton onClick={() => setEnterModalDisplay("hidden")}>
            Close Window
          </GameButton>
        </Modal>

        <NewListingCont>
          <NewListingBody>
            <TallFiltersCont>
              <h2>Prerequisites</h2>
              <br />
              <TallFiltersInnerCont>
                • You created a preminted pool on WaxDAO.
                <br />
                <br />• You have created an NFT template to use as your "pack
                template".
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <br />
            <TallFiltersCont>
              <h2>Pack Details</h2>
              <br />
              <TallFiltersInnerCont>
                <h3>Pack Template</h3>
                <input
                  type="text"
                  maxLength={8}
                  placeholder="e.g. 123456"
                  id="template"
                  value={templateID}
                  onChange={(e) => {
                    setTemplateID(e.target.value);
                  }}
                />
                <br />
                <br />
                <h3>Total Available</h3>

                <input
                  type="text"
                  maxLength={20}
                  value={totalAvailable}
                  id="template"
                  onChange={(e) => {
                    setTotalAvailable(e.target.value);
                  }}
                />

                <br />
                <br />

                <h3>Start Time</h3>
                <DatePicker
                  selected={new Date(startTime * 1000)}
                  onChange={(e) => {
                    handleCalendarChange(e, setStartTime);
                  }}
                />
                <br />
                <br />

                <h3>Description</h3>
                <textarea
                  placeholder="Tell people some info about your pack"
                  value={dropDescription}
                  type="text"
                  rows={4}
                  id="template"
                  onChange={(e) => {
                    setDropDescription(e.target.value);
                  }}
                />
                <br />
                <br />
                <h3>Cover Image (300 x 300)</h3>

                <input
                  type="text"
                  maxLength={70}
                  placeholder="e.g. Qmfkjs..."
                  value={logoHash}
                  id="template"
                  onChange={(e) => {
                    setLogoHash(e.target.value);
                  }}
                />
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Pack Slots</h2>
              <br/>
              <TallFiltersInnerCont>
              Note: All preminted pools must have the exact same amount of NFTs
              if you want to include them in the same pack.
              </TallFiltersInnerCont>
            </TallFiltersCont>
                  <br/><br/>

            {poolIDs?.length > 0 ? (
              <div className="w-100 justify-left gap-5 flex flex-wrap mb-4">
              {poolIDs.map((item, index) => (
                <PremintPoolCard item={item} assetVector={assetVector} setAssetVector={setAssetVector} />
                
              ))}
              </div>
              
            ) : (
              <div className="w-100 text-center font-semibold mt-4 mb-4">
                <br/>
                You don't have any pools to include in your pack.
                <br/><br/>
              </div>
            )}



<div className="text-center w-100">
            {poolStatus != "available" ? (
              <span>
                <MissingInfoMsg>This pool is currently locked.</MissingInfoMsg>

                <DisabledButton>Create Pack Now</DisabledButton>
              </span>
            ) : (
              <MainButton
              onClick={() => {
                createPack(
                  assetVector,
                  collection,
                  templateID,
                  totalAvailable,
                  startTime,
                  dropDescription,
                  logoHash,
                  setEnterModalText,
                  setLoadingDisplay,
                  setEnterButtonsDisplay,
                  setEnterModalDisplay,
                  wharfSession
                );
              }}
            >
              Create Pack Now
            </MainButton>
            )}



</div>
            <br />
            <br />
          </NewListingBody>
        </NewListingCont>

      <br />
      <br />
      <br />
    </div>
  )}
  else{
    return <Error error="You are not logged in" />
  }
};

export default CreatePack;
