import React from 'react';
import {Helmet} from "react-helmet";
import { NavPageCont, DaoTitle, NavPageItem, NavPageInnerCont
} from '../.././components/LeftDiv';
import { currentWebsiteURL, mainnetWebsiteURL } from '../../data/config';


const WojakNav = () => {

  return (
    <div id="seo">
    <Helmet>
    <title>Wojak NFTs Homepage - WaxDAO</title>
    <meta name="description" content="Waxy Wojak NFTs on WaxDAO" />
    <link rel="canonical" href={`${mainnetWebsiteURL}/wojak-nfts`} />
    </Helmet>
    
    <NavPageCont>
        <DaoTitle>
        Wojak NFTs
        </DaoTitle>   
        <NavPageInnerCont>
            <NavPageItem href={`${currentWebsiteURL}/wojak/swap`}>
                Swap Tokens
            </NavPageItem>
            <NavPageItem href={`${currentWebsiteURL}/drops/247`}>
                NFT Drop
            </NavPageItem>
            <NavPageItem href={`${currentWebsiteURL}/unbox/246`}>
                Unpack
            </NavPageItem>
            <NavPageItem href={`${currentWebsiteURL}/farm/waxdao.1week`}>
                My NFTs
            </NavPageItem>
        </NavPageInnerCont>
    </NavPageCont>   
    </div>
  )
}

export default WojakNav