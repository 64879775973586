import React, { useState } from 'react'
import { DropCardBottomHalf, DropCardName, DropCardTitle, DropCardTopHalf, DropCardWrapper } from '../data/css/DropStyles'
import { NFTCardCheckbox, ShowNFTInfo } from '../data/css/PremiintStyles';
import { WalletCardMint } from '../data/css/WalletStyles';
import { ModalOverlay } from './LeftDiv';
import { ImInfo } from 'react-icons/im'
import AttributeModal from './AttributeModal';
import { currentWebsiteURL } from '../data/config';
import { buildNftImage } from '../data/functions/global_functions';

const WojakCard = (props) => {
    const item = props.item;
    const selectedAsset = props.selectedAsset;
    const setSelectedAsset = props.setSelectedAsset;

    const [showAttributeModal, setShowAttributeModal] = useState(false);

  return (
    <DropCardWrapper>
        <DropCardTopHalf>
        {buildNftImage(item.data)}
        </DropCardTopHalf>
            
        <NFTCardCheckbox selected={selectedAsset == item.asset_id ? "yes" : "no"} onClick={() => {selectedAsset == item.asset_id ? setSelectedAsset("") : setSelectedAsset(item.asset_id)}}>
            {selectedAsset == item.asset_id && (<span>&#x2713;</span>)}
        </NFTCardCheckbox>

        <ShowNFTInfo onClick={() => setShowAttributeModal(true)}>
            <ImInfo className='ml-auto mr-auto' />
        </ShowNFTInfo>

        {showAttributeModal && (
            <span>
                <ModalOverlay />
                <AttributeModal showAttributeModal={showAttributeModal} setShowAttributeModal={setShowAttributeModal} item={item} />
            </span>
        )}

        {item.template_mint != null && (
            <WalletCardMint>
            #{item.template_mint}
            </WalletCardMint>
        )}

      {item.collection != null && (
          <a href={`${currentWebsiteURL}/collection/${item.collection.collection_name}`}>
        <DropCardTitle>
        {item.collection.collection_name}
        </DropCardTitle>
        </a>
      )}

        {item.data.name != null && (
            <DropCardName>
                {item.data.name}
            </DropCardName>
        )}

        <DropCardBottomHalf />

    </DropCardWrapper>
  )
}

export default WojakCard