import styled from 'styled-components'
import { theme } from '../../Styles'


<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>

export const RecentFarmsContainer = styled.div`
    width: 95%;
    display: ${props => props.closed == false ? "flex" : "none" };
    gap: 20px;
    padding-bottom: 10px;
    height: 420px;
    margin-bottom: 15px;

    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: scrollbar;  /* IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: ${theme.secondary} black; 

    ::-webkit-scrollbar{
        height: 4px;
    }

    ::-webkit-scrollbar-thumb{

        background: ${theme.secondary};
    }

    ::-webkit-scrollbar-track{

        background: #dfdcdc40;
    }
`

export const FarmCardUnofficial = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 10px;
    font-weight: 500;
    top: 20px;
    right: 20px;
    padding:3px 10px 3px 10px;
    border-radius: 10px;
    color: ${theme.textMain};
    background-color: ${props => props.official == false ? "#bd2525e6" : "#1f9771e6" };
`