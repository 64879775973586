import { useState } from "react";
import { currentApiList, getCurrentApiList } from "../../data/config";
import axios from 'axios';

export const useGetDAOsByCreator = () => {
    
    const [daosByCreator, setDAOsByCreator] = useState([]);
    const [creatorDAOsAreLoading, setCreatorDAOsAreLoading] = useState(true);

    const getDAOsByCreator = (UserName) => {
        
        axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
            table: "daos",
            scope: "buildawaxdao",
            code: "buildawaxdao",
            key_type: "name",
            index_position: 3,
            limit: 100,
            lower_bound: UserName,
            upper_bound: UserName,
            json: true,
            }).then((res) => {
                setDAOsByCreator(res.data.rows);

                console.log(res.data.rows);
            })
            .catch((error) => console.log(error))
            .finally(() => setCreatorDAOsAreLoading(false))

    }

    return [daosByCreator, getDAOsByCreator, creatorDAOsAreLoading]
}