import React from 'react'
import { Spinner, SpinnerBlue, SpinnerGreen, SpinnerRed } from './LeftDiv'

const LoadingSpinnerImageFallback = (props) => {
  let top = '90px';
  if(props.top){
    top = props.top;
  }

  return (
    <div style={{textAlign:"center", paddingTop: top, width:"100%", position: 'absolute', top: '0px'}}>
    <Spinner>
    <SpinnerRed  />
    <SpinnerBlue  />
    <SpinnerGreen />
    </Spinner>
    </div>
  )
}

export default LoadingSpinnerImageFallback