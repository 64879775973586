import { useEffect, useState } from "react";
import { go_to_top_svg } from "../../data/svgs";
import { GoToTopWrapper } from "../../pages/utilities/WalletStyles2024";

const GoToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);
  
    const toggleVisibility = () => {
      if (window.scrollY > 1000) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', toggleVisibility);
  
      return () => {
        window.removeEventListener('scroll', toggleVisibility);
      };
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
  
    return (
      <GoToTopWrapper onClick={scrollToTop} show={isVisible}>
        <button>
        {go_to_top_svg}
        </button>
      </GoToTopWrapper>
    );
  };
  
  export default GoToTopButton;