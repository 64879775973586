import { currentWebsiteURL, waxExplorerLink } from "../../data/config"

const getAmmLink = (contract) => {
    if(contract == "swap.taco"){
        return "https://swap.tacocrypto.io"
    } else if(contract == "swap.box"){
        return "https://wax.defibox.io"
    }
}

const getAmmName = (contract) => {
    if(contract == "swap.taco"){
        return "TacoSwap"
    } else if(contract == "swap.box"){
        return "Defibox"
    }
}

export const showLpTokenInfo = (data) => {
    
    if(data.contract == "swap.box" || data.contract == "swap.taco"){
        return (<div style={{
            width: "90%",
            clear: "both",
            border: "1px solid rgba(133, 100, 4, 0.7)", 
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "15px",
            marginBottom: "20px",
            backgroundColor: "rgba(133, 100, 4, 0.4)",
            borderRadius: "5px",
            color: "#eda702",
            padding: "10px",
            fontSize: "14px",
          }}>
              This token represents ownership of liquidity on <a style={{color:"#4bcc00"}} href={getAmmLink(data.contract)} target="none">{getAmmName(data.contract)}</a>. 
              The underlying tokens are <a style={{color:"#4bcc00"}} href={`${currentWebsiteURL}/tokens/${JSON.parse(data?.lp_ingredients)?.token1}`}>{JSON.parse(data?.lp_ingredients)?.token1}</a> and{" "} 
              <a style={{color:"#4bcc00"}} href={`${currentWebsiteURL}/tokens/${JSON.parse(data?.lp_ingredients)?.token2}`}>{JSON.parse(data?.lp_ingredients)?.token2}</a>
          </div> )  
    } else if(data.contract == "alcorammswap"){
        return (<div style={{
            width: "90%",
            clear: "both",
            border: "1px solid rgba(133, 100, 4, 0.7)", 
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "15px",
            marginBottom: "20px",
            backgroundColor: "rgba(133, 100, 4, 0.4)",
            borderRadius: "5px",
            color: "#eda702",
            padding: "10px",
            fontSize: "14px",
          }}>
              This token is obsolete. It used to represent ownership of liquidity on{" "}
              <a style={{color:"#4bcc00"}} target="none" href={`https://wax.alcor.exchange`}>Alcor Exchange</a>. 
              If you were holding this token, your position has automatically been converted to concentrated liquidity 
              on <a style={{color:"#4bcc00"}} target="none" href={`${waxExplorerLink}/account/swap.alcor`}>Alcor's new contract</a>. 
          </div> )          
    }
}

export const showAlertOne = (data) => {


    if(data?.alerts?.one != null && data?.alerts.one != ""){
        return(<div style={{
            width: "90%",
            clear: "both",
            border: "1px solid rgba(133, 100, 4, 0.7)", 
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "15px",
            marginBottom: "20px",
            backgroundColor: "rgba(133, 100, 4, 0.4)",
            borderRadius: "5px",
            color: "#eda702",
            padding: "10px",
            fontSize: "14px",
          }}>
              {data.alerts.one}
          </div>)
    } 
}

export const showAlertTwo = (data) => {


    if(data?.alerts?.two != null && data?.alerts.two != ""){
        return(<div style={{
            width: "90%",
            clear: "both",
            border: "1px solid rgba(133, 100, 4, 0.7)", 
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "15px",
            marginBottom: "20px",
            backgroundColor: "rgba(133, 100, 4, 0.4)",
            borderRadius: "5px",
            color: "#eda702",
            padding: "10px",
            fontSize: "14px",
          }}>
              {data.alerts.two}
          </div>)
    } 
}


export const showCautionMessage = (message) => {

        return(<div style={{
            width: "90%",
            clear: "both",
            border: "1px solid rgba(133, 100, 4, 0.7)", 
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "15px",
            marginBottom: "20px",
            backgroundColor: "rgba(133, 100, 4, 0.4)",
            borderRadius: "5px",
            color: "#eda702",
            padding: "10px",
            fontSize: "14px",
          }}>
              {message}
          </div>)
}