import styled from 'styled-components'
import { theme2024 } from '../../Styles2024'

export const LargeNavWrapper2024 = styled.div`
    width: 100%;
    max-width: 100%;
    position: fixed;
    z-index: 15000;
    top: 0px;    
    background-color: ${theme2024.backgroundDark};
    border-bottom: 1px solid ${theme2024.darkGrey};
    height: 110px; 
    bottom: 0px;
    //display: flex;
    color: white;
    text-align: center;
    font-size: 12px;    

    @media (max-width: 1100px) {
        display: none;
    }    
`

export const LargeNav2024TopWrapper = styled.div`
    width: 100%;
    height: 45px;
    border-bottom: 1px solid ${theme2024.darkGrey};
    display: flex;
    align-items: center;
    padding-left: 10px;
    justify-content: space-between;
    box-sizing: border-box;
`

export const LargeNavTopLeftWrapper = styled.div`
    width: auto;
    display: flex;
    //max-width: 70%;
    flex-grow: 1;
    height: 100%;
    //border: 1px solid yellow;
`

export const LargeNavTopRightWrapper = styled.div`
    width: auto;
    display: flex;
    max-width: 20%;
    justify-content: right;
    height: 100%;
    //border: 1px solid green;
    padding-right: 10px;
    align-items: center;
`

export const LargeNav2024TopItem = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    color: ${theme2024.textSecondaryDarker};
    //border: 1px solid green;
    box-sizing: border-box;
    padding-top: 5px;
    height: 100%;
    display: flex;
    align-items: center;
`

export const LargeNavbar2024LoginButton = styled.button`
    width: 150px;
    border: 1px solid ${theme2024.textSecondaryDarker};
    border-radius: 5px;
    height: 30px;
    color: ${theme2024.textSecondaryDarker};
    text-align: center;
    transition: color 1s, border-color 1s;

    :hover{
        //background-color: ${theme2024.hoverColor};
        //transition: background-color 0.3s;
        color: ${theme2024.textMain};
        //transition: color 1.5s, border-color 1.5s;
        border-color: ${theme2024.textMain};
        //transition: border-color 0.5s;        
    }
`

export const LargeNav2024BottomWrapper = styled.div`
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    justify-content: left;
    box-sizing: border-box;

    img{
        width: 150px;
        height: auto;
    }
`

export const LargeNav2024LinksWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: left;
    box-sizing: border-box;
    //border: 1px solid green;
    width: 750px;
    padding-left: 10px;
`

export const LargeNav2024LinkButton = styled.button`
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    color: ${theme2024.textSecondaryDarker};
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 14px;
    display: flex;
    transition: color 0.7s;
    position: relative;

    svg{
        fill: ${theme2024.primary};
        width: 20px;
        margin-right: 5px;
        height: auto;
    }

    :hover{
        color: ${theme2024.textMain};
        //transition: color 0.5s;
    }
`

export const LargeNavLinkDropDown = styled.div`
    position: absolute;
    visibility: ${props => props.open ? 'visible' : 'hidden'};
    opacity: ${props => props.open ? '100%' : '0%'};
    transition: visibility 0s, opacity 0.5s;
    top: 45px;
    left: 50%;
    transform: translate(-50%, 0%);    
    min-width: 180px;
    width: auto;
    height: auto;
    max-height: 400px;
    white-space: nowrap;
    border-radius: 15px;
    border: 1px solid ${theme2024.textSecondaryDarker};
    background-color: ${theme2024.backgroundDark};
    padding-top: 10px;
    padding-bottom: 15px;   
`

export const LargeNavDropDownInnerWrapper = styled.div`

    overflow-y: scroll;
    white-space: nowrap;
    height: auto;
    max-height: 380px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;

    &::-webkit-scrollbar-track {
        border-radius: 15px; 
        background-color: transparent; 
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: ${theme2024.primary};
    }

    &::-webkit-scrollbar {
        width: 4px;
    }    
    
    //Firefox
    scrollbar-color: ${theme2024.primary} transparent;
    scrollbar-width: thin;    

`

export const LargeNavLinkDropDownRow = styled.div`
    width: 100%;
    color: ${theme2024.textSecondaryDarker};
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 400;
    transition: color 0.5s;
    text-align: left;
    padding: 10px;
    padding-bottom: 5px;

    :hover{
        color: ${theme2024.textMain};
    }

    p{
        text-align: center;
        display: inline-block;
        width: 50px;
        color: ${theme2024.textMain};
        background-color: ${theme2024.danger};
        border-radius: 5px;
        font-weight:600;
        padding: 3px;
        margin-left: 7px;
    }
`

export const LargeNav2024SearchBarWrapper = styled.div`
    display: flex;
    align-items: center;
    mix-width: 200px;
    flex-grow: 1;
    box-sizing: border-box;
    height: 100%;
    //border: 1px solid green;
    justify-content: right;
    padding-right: 15px;

    input{
        width: 200px;
        outline: none;
        background-color: ${theme2024.darkGrey};
        border-radius: 7px;
        padding: 10px;
        padding-left: 15px;
        font-size: 12px;
        font-weight: 600;
    }
`

export const UserDropDown = styled.div`
    z-index: 15011;
    position: fixed;
    right: 10px;
    top: 50px;
    bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    visibility: ${props => props.open ? 'visible' : 'hidden'};
    opacity: ${props => props.open ? '100%' : '0%'};
    transition: visibility 0s, opacity 0.5s;    
    width: 350px;
    //height: 500px;
    background-color: ${theme2024.backgroundDark};
    border: 1px solid ${theme2024.textSecondaryDarker};
    border-radius: 15px;
`
export const UserDrowDownInnerScrollWrapper = styled.div`
    overflow-y: scroll;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar-track {
        border-radius: 15px; 
        background-color: transparent; 
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: ${theme2024.primary};
    }

    &::-webkit-scrollbar {
        width: 4px;
    }    
    
    //Firefox
    scrollbar-color: ${theme2024.primary} transparent;
    scrollbar-width: thin;      
`

export const UserDropDownTopSection = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: left;
    box-sizing: border-box;
    //border: 1px solid green;
`

export const UserDropDownTopLeft = styled.div`
    display: flex;
    flex-grow: 1;
    height: 100%;
    //border: 1px solid magenta;
    align-items: center;
    padding-left: 5px;
`

export const UserDropDownAvatarCont = styled.div`
    width: 75px;
    height: 75px;
    text-align: center;
    background-color: ${theme2024.darkGrey};
    border-radius: 50%;
`
export const UserDropDownUserNameCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    color: ${theme2024.textMain};
    padding-left: 10px;
    //border: 1px solid green;

    h3{
        
    }

    p{
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        color: ${theme2024.textSecondaryDarker};
    }
`
export const UserDropDownBalancesCont = styled.div`
    width: 400px;
    max-width: 90%;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    background-color: ${theme2024.secondaryMedium};
    border-radius: 10px;
    height: 135px;

    @media (max-width: 1100px) {
        margin-left: 10px;
        margin-right: unset;

        a{
            margin-left: 10px;
        }
    }     

    h3{
        width: 100%;
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        padding: 10px;
        padding-bottom: 5px;
        color: ${theme2024.textSecondaryDarker};
    }

    h4{
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        padding-left: 10px;
    }    

`

export const UserDropDownBuyWaxDaoButton = styled.button`
    width: 90%;
    border: 1px solid ${theme2024.textSecondaryDarker};
    border-radius: 5px;
    height: 30px;
    color: ${theme2024.textSecondaryDarker};
    text-align: center;
    transition: color 1s, border-color 1s;
    margin-top: 10px;


    :hover{
        //background-color: ${theme2024.hoverColor};
        //transition: background-color 0.3s;
        color: ${theme2024.textMain};
        //transition: color 1.5s, border-color 1.5s;
        border-color: ${theme2024.textMain};
        //transition: border-color 0.5s;        
    }
`

export const UserDropDownLinkButton = styled.div`
    width: 95%;
    height: 60px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;  
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    transition: background-color 0.5s;   

    h3{
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.7px; 
        color: ${theme2024.textMain};
    }

    p{
        color: ${theme2024.textSecondaryDarker};
        @media (max-width: 400px) {
            font-size: 12px;
        }         
    }

    :hover{
        cursor: pointer;
        background-color: ${theme2024.hoverColor};
    }
`

export const UserDropDownWalletsSeparator = styled.div`
    width: 95%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto; 
    height: 5px;
    border-bottom: 1px solid ${theme2024.darkGrey};    
`

export const UserDropDownWalletsTitleCont = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-bottom: 0px;

    h3{
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        color: ${theme2024.textSecondaryDarker};  
    }

    button{
        margin-left: 15px;
        font-size: 30px;
        font-weight: 600;
        text-align: right;
        color: ${theme2024.primary};  

        svg{
            fill: ${theme2024.textMain}; 
            width: 20px;
            height: 20px;
            stroke: ${theme2024.textMain};
        }
    }    
`


export const SmallNavWrapper2024 = styled.div`
    width: 100%;
    max-width: 100%;
    position: fixed;
    z-index: 15000;
    top: 0px;    
    background-color: ${theme2024.backgroundDark};
    border-bottom: 1px solid ${theme2024.darkGrey};
    height: 110px; 
    bottom: 0px;
    //display: flex;
    color: white;
    text-align: center;
    font-size: 12px;    

    @media (min-width: 1101px) {
        display: none;
    }    
`

export const SmallNav2024SearchBarWrapper = styled.div`
    display: flex;
    align-items: center;
    mix-width: 100%;;
    flex-grow: 1;
    box-sizing: border-box;
    height: 100%;
    //border: 1px solid green;
    justify-content: center;
    padding-right: 15px;
    padding-right: 15px;

    input{
        width: 90%;
        outline: none;
        background-color: ${theme2024.darkGrey};
        border-radius: 7px;
        padding: 10px;
        padding-left: 15px;
        font-size: 12px;
        font-weight: 600;
    }
`

export const SmallNavTopLeftWrapper = styled.div`
    width: auto;
    display: flex;
    max-width: 70%;
    height: 100%;
    padding-top: 10px;
    padding-left: 15px;
    
    img{
        height: 25px;
        width: auto;
    }
`

export const SmallNavTopRightWrapper = styled.div`
    width: auto;
    display: flex;
    max-width: 20%;
    justify-content: right;
    height: 100%;
    //border: 1px solid green;
    padding-right: 10px;
    align-items: center;

    svg{
        transition: fill 0.5s, stroke 0.5s;
        width: 25px;
        height: 25px;
        fill: white;
        margin-right: 10px;
        stroke: ${props => props.open ? theme2024.textMain : "none"};

        :hover{
            fill: ${theme2024.primary};
            cursor: pointer;
            stroke: ${props => props.open ? theme2024.primary : "none"};
        }
    }
`

export const SmallNavDropDown = styled.div`
    position: fixed;
    width: 400px;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    bottom: 0px;
    z-index: 15011;
    background-color: ${theme2024.backgroundDark};
    padding-top: 10px;
    padding-bottom: 10px;
    visibility: ${props => props.open ? 'visible' : 'hidden'};
    opacity: ${props => props.open ? '100%' : '0%'};
    transition: visibility 0s, opacity 0.5s;       

    @media (min-width: 1101px) {
        display: none;
    }     
`

export const SmallNavWelcomeWrapper = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: left;
    box-sizing: border-box;
    //border: 1px solid magenta;
`

export const SmallNavWelcomeInnerCont = styled.div`
    width: 100%;
    height: 75px;
    display: flex;
    //border: 1px solid yellow;
    box-sizing: border-box;
`

export const SmallNavWelcomeSvgWrapper = styled.div`
    width: 75px;
    height: 75px;
    //border: 1px solid green;
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        width: 50px;
        height: 50px;
        fill: ${theme2024.primary};
    }
`

export const SmallNavWelcomeRightCont = styled.div`
    height: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    flex-wrap: wrap;
    
    h3{
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.7px;
        color: ${theme2024.textSecondaryDarker};
        //border: 1px solid red;
        margin: 0px;
    }

    h4{
        margin: 0px;
        width: 100%;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 1px;
        color: ${theme2024.textMain};      
    }

`

export const SmallNavLoginButtonWrapper = styled.div`
    width: 100%;
    padding-left: 25px;
    display: flex;
    justify-content: left;
    box-sizing: border-box;
`

export const SmallNavbar2024LoginButton = styled.button`
    width: 200px;
    border: 1px solid ${theme2024.textSecondaryDarker};
    border-radius: 5px;
    height: 30px;
    color: ${theme2024.textSecondaryDarker};
    text-align: center;
    transition: color 1s, border-color 1s;
    margin-bottom: 15px;

    :hover{
        color: ${theme2024.textMain};
        border-color: ${theme2024.textMain};      
    }
`

export const SmallScreenWalletsWrapper = styled.div`
    width: 100%;
    height: ${props => !props.open && '0px'};
    overflow-y: ${props => !props.open && 'hidden'};
    visibility: ${props => props.open ? 'visible' : 'hidden'};
    opacity: ${props => props.open ? '100%' : '0%'};
    transition: visibility 0s, opacity 0.5s;   
    margin-bottom: 15px;    
`

export const SmallNavLinkWrapper = styled.div`
    width: 100%;
    border-top: 1px solid ${theme2024.primary};
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    justify-content: left;

    h3{
        padding-left: 10px;
        font-size: 14px;
        font-weight: 600;
        color: ${theme2024.textMain};
        letter-spacing: 0.7px;
    }

`

export const SmallNavLinkSvgWrapper = styled.div`
    width: 30px;
    height: 30px;

    svg{
        height: 25px;
        width: 25px;
        fill: ${theme2024.primary};
    }
`

export const SmallNavLinkRightSvgWrapper = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    flex-grow: 1;
    justify-content: right;

    svg{
        height: 25px;
        width: 25px;
        fill: ${theme2024.textMain};
        stroke: ${theme2024.textMain};
    }
`

export const SmallNavSecondaryLinkWrapper = styled.div`
    width: 100%;
    padding-top: 10px;
    height: ${props => !props.open && '0px'};
    overflow-y: ${props => !props.open && 'hidden'};
    visibility: ${props => props.open ? 'visible' : 'hidden'};
    opacity: ${props => props.open ? '100%' : '0%'};
    transition: visibility 0s, opacity 0.5s;       
`

export const SmallNavSecondaryLink = styled.div`
    width: 100%;
    color: ${theme2024.textSecondaryDarker};
    font-size: 14px;
    font-weight: 600;    
    letter-spacing: 0.7px;
    padding-top: 5px;
    padding-left: 5px;
    transition: color 0.3s;

    :hover{
        color: ${theme2024.textMain};
    }

    p{
        text-align: center;
        display: inline-block;
        width: 50px;
        color: ${theme2024.textMain};
        background-color: ${theme2024.danger};
        border-radius: 5px;
        font-weight:600;
        padding: 3px;
        margin-left: 7px;
    }    
`

export const SmallNavBottomWrapper = styled.div`
    width: 100%;
    border-top: 1px solid ${theme2024.primary};
    padding-top: 10px;
    padding-bottom: 70px;
    color: ${theme2024.textSecondaryDarker};
`

export const CloseDivXIconWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 10px;
    padding-top: 15px;
    
    svg{
        width: 20px;
        height: 20px;
        stroke: ${theme2024.textMain};
    }
`

export const SmallNavSocialIconsDiv = styled.div`
    width: 100%;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 15px;
    display: flex;
    justify-content: left;
    gap: 20px;
    color: ${theme2024.primary};

    svg{
        fill: ${theme2024.primary};
        opacity: 60%;
        width: 20px;
        height: 20px;
        transition: fill 0.3s;

        :hover{
            fill: ${theme2024.textMain}
            opacity: 100%;
            cursor: pointer;
        }
    }
`

export const LargeNavSocialIconsDiv = styled.div`
    width: auto;
    padding-top: 13px;
    //padding-left: 10px;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    gap: 20px;
    color: ${theme2024.primary};

    svg{
        fill: ${theme2024.primary};
        opacity: 60%;
        width: 20px;
        height: 20px;
        transition: fill 0.3s;

        :hover{
            fill: ${theme2024.textMain}
            opacity: 100%;
            cursor: pointer;
        }
    }
`