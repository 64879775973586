import React, { createContext, useContext, useState} from 'react';

const WalletContext = createContext();


export const WalletContextProvider = ({ children }) => {
    const [unstakedPageCount, setUnstakedPageCount] = useState(1);
    const [depositAmount, setDepositAmount] = useState("");
    const [listOfAssets, setListOfAssets] = useState([]);
    const [stakedAssets, setStakedAssets] = useState([]);

    return (
        <WalletContext.Provider
            value={{ 
                unstakedPageCount,
                setUnstakedPageCount,
                depositAmount,
                setDepositAmount,
                listOfAssets,
                setListOfAssets,
                stakedAssets,
                setStakedAssets,


                
                


             }}
        >
            {children}
        </WalletContext.Provider>
    )
}

export const useWalletContext = () => useContext(WalletContext);