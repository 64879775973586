import { useState } from "react";
import { currentApiList, currentUsername, getCurrentApiList } from "../../data/config";
import axios from 'axios';
import { Name } from "eos-common";

export const useGetCollectionRamBalance = () => {
    
    const [collectionRamBalance, setCollectionRamBalance] = useState([]);
    const [collectionRamBalanceIsLoading, setCollectionRamBalanceIsLoading] = useState(true);

    const getCollectionRamBalance = (collection_name) => {
    
        const nameValue = new Name(collection_name).raw().toString();

        axios
        .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
            table: "rambalances",
            scope: "waxdaomarket",
            code: "waxdaomarket",
            limit: 1,
            lower_bound: nameValue,
            upper_bound: nameValue,
            json: true,
        })
        .then((res) => {
            setCollectionRamBalance(res.data.rows);
        })
        .catch((error) => console.log(error))
        .finally(() => setCollectionRamBalanceIsLoading(false))

    }

    return [collectionRamBalance, getCollectionRamBalance, collectionRamBalanceIsLoading]
}


