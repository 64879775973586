import { useState } from "react";
import { currentApiList, getCurrentApiList, getCurrentAtomicApiList } from "../../data/config";
import axios from 'axios';

export const useGetUnstakedForV1Farm = () => {

    let list = [];

    const [unstaked, setUnstaked] = useState([]);
    const [loading, setLoading] = useState(false);

    const getUnstakedAssets = async (waxUsername, farmname, farmtype, rewardType, thisCollection, thisSchema, page = 1, retry = 3) => { 
        setLoading(true);       
        try {
            let atomicResponse;
            if (farmtype === "collection" || (farmtype === "template" && rewardType !== "dynamic")) {
                atomicResponse = await axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${thisCollection}&owner=${waxUsername}&page=${page}&limit=500&order=desc&sort=transferred`);

                list = list.concat(atomicResponse.data.data);
                setUnstaked([...list]);

            }
            else if (farmtype === "schema" || farmtype === "s.exclude") {
                atomicResponse = await axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${thisCollection}&schema_name=${thisSchema}&owner=${waxUsername}&page=${page}&limit=500&order=desc&sort=transferred`);

                list = list.concat(atomicResponse.data.data);
                setUnstaked([...list]);
            }
            else if ((farmtype === "template" && rewardType === "dynamic") || (farmtype === "nc.c.shared") || (farmtype === "nc.v2" && rewardType === "dynamic")) {
                atomicResponse = await axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${thisCollection}&owner=${waxUsername}&page=${page}&limit=500&order=desc&sort=transferred`);

                list = list.concat(atomicResponse.data.data);
                setUnstaked([...list]);
            }

            if (atomicResponse.data.data.length === 500) {
                await getUnstakedAssets(waxUsername, farmname, farmtype, rewardType, thisCollection, thisSchema, page + 1, retry);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            if (retry > 0) {            
                return getUnstakedAssets(waxUsername, farmname, farmtype, rewardType, thisCollection, thisSchema, page, retry - 1);
            }
            throw error;
        }
    }

    return [unstaked, getUnstakedAssets, loading]
}
