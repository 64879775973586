import { useEffect } from "react";
import { useGetDAOsByCreator } from "../../components/CustomHooks/useGetDAOsByCreator";
import LoadingDiv from "../../components/LoadingDiv";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentUsername, currentWebsiteURL } from "../../data/config";
import { ClaimInfo, TokenCard } from "../../data/css/WalletStyles";

import { SmallButton } from "../../Styles";
import { buildIpfsUrl } from "../../data/functions/global_functions";

export const showManageDAOsSection = (currentToolsSection) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();

  const [daosByCreator, getDAOsByCreator, creatorDAOsAreLoading] =
    useGetDAOsByCreator(currentUsername);


  useEffect(() => {
    if (currentToolsSection == "Manage DAOs") {
      getDAOsByCreator(currentUsername);
    }
  }, [currentToolsSection]);

  return (
    <span className={currentToolsSection != "Manage DAOs" && "hidden"}>
      {!creatorDAOsAreLoading && (
        <span>
          {daosByCreator?.length > 0
            ? daosByCreator.map((item, index) => (
                <span key={index}>
                  <TokenCard>
                    <a href={`${currentWebsiteURL}/daos/${item.daoname}`}>
                      <img
                        src={buildIpfsUrl(item.logo, 200)}
                        onError={(e) =>
                          (e.target.onerror = null)(
                            (e.target.src =
                              "https://logos.waxdao.io/default-logo.png")
                          )
                        }
                      />
                    </a>

                        {(item.daoname == null || item.daoname == "") ? (
                            <span>Unnamed</span>
                        ) : (
                            <a href={`${currentWebsiteURL}/daos/${item.daoname}`}>
                                {item.daoname}
                            </a>
                        )}

                    <ClaimInfo>
                      {item.daoname}
                        <a href={`${currentWebsiteURL}/edit-dao/${item.ID}`}>
                      <SmallButton>Edit DAO</SmallButton>
                      </a>
                    </ClaimInfo>
                  </TokenCard>
                </span>
              ))
            :  (<div className="w-100 text-center pt-3">
            No DAOs to show
          </div>)}
        </span>
      )}
       {creatorDAOsAreLoading && <LoadingDiv />}
    </span>
  );
};
