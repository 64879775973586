import { useState } from "react";
import { currentApiList, currentUsername, getCurrentApiList } from "../../data/config";
import axios from 'axios';
import { Name } from "eos-common";

export const useGetBlendRamBalance = () => {
    
    const [blendRamBalance, setBlendRamBalance] = useState([]);
    const [blendRamBalanceIsLoading, setBlendRamBalanceIsLoading] = useState(true);
    const nameValue = new Name(currentUsername).raw().toString();

    const getBlendRamBalance = () => {
    
        axios
        .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
            table: "userram",
            scope: "waxdaomarket",
            code: "waxdaomarket",
            limit: 1,
            lower_bound: nameValue,
            upper_bound: nameValue,
            json: true,
        })
        .then((res) => {
            setBlendRamBalance(res.data.rows);
        })
        .catch((error) => console.log(error))
        .finally(() => setBlendRamBalanceIsLoading(false))

    }

    return [blendRamBalance, getBlendRamBalance, blendRamBalanceIsLoading]
}


