import { useState } from "react";
import { currentApiList, currentAtomicApiList, getCurrentApiList, getCurrentAtomicApiList } from "../../data/config";
import axios from 'axios';

export const useGetStakedAssets = () => {

    const [stakedAssets, setStakedAssets] = useState([]);
    const [userCanUnlock, setUserCanUnlock] = useState("");
    const [validAssets, setValidAssets] = useState([]);
    const [claimable, setClaimable] = useState("");

    const tryEachAPI = async (apiList, operation, ...params) => {
        for (const api of apiList) {
            try {
                const response = await operation(api, ...params);
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
            } catch (err) {
                console.error(`API call failed on ${api} with error: ${err}`);
            }
        }
        throw new Error('All API calls failed');
    };

    const getStakedAssets = async (username, farmname, farmtype) => {
        try {
            const postOperation = (api, username) => axios.post(`${api}/v1/chain/get_table_rows`,{
                table:"stakebackup",
                scope:"waxdaofarmer",
                code:"waxdaofarmer",
                key_type: 'name',
                index_position: 2,
                limit:1000,
                lower_bound: username,
                upper_bound: username,
                json:true
            });
            const res = await tryEachAPI(currentApiList, postOperation, username);
            console.log(res);

            var itr = 0;
            while(itr < res.data.rows.length){
                console.log(res.data.rows[itr].farmname);
                if(res.data.rows[itr].farmname == farmname){
                    setClaimable(res.data.rows[itr].claimable);
                    if(res.data.rows[itr].assets != ""){
                        console.log(res.data.rows[itr].assets.length + " total assets");
                        setValidAssets(res.data.rows[itr].assets);
                        setUserCanUnlock(res.data.rows[itr].unlocktime);

                        const getOperation = (api, assetIds) => axios.get(`${api}/atomicassets/v1/assets?ids=${assetIds.slice(0,800).join("%2C")}&page=1&limit=1000&order=desc&sort=asset_id`);
                        const validResponse = await tryEachAPI(currentAtomicApiList, getOperation, res.data.rows[itr].assets);

                        console.log("Getting staked assets");
                        console.log(validResponse.data.data);
                        setStakedAssets(validResponse.data.data);
                    }
                    break;
                } else {
                    itr ++;
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    return [stakedAssets, getStakedAssets, userCanUnlock, validAssets, claimable]
};
