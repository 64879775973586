import styled from 'styled-components';
import { theme } from '../../Styles';
import { theme2024 } from '../../Styles2024';

export const Form = styled.form`
  @media (min-width: 640px) {
    width: 60%;
    margin: 0 auto;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #1F2A44;
  border-radius: 1rem;
  color: #fff;
`;

export const Label = styled.label`
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 2px solid #fff;
  border-radius: 0.25rem;
`;

export const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 2px solid #fff;
  border-radius: 0.25rem;
  background-color: #1F2A44;
  color: #fff;
`;

export const Option = styled.option`
  background-color: #1F2A44;
  color: #fff;
`;

export const SetttingsRow = styled.div`
  width: 100%;
  border: 1px solid gray;

  h2{
    border-bottom: 1px solid gray;
    color: gray;
    font-size: 14px;
  }
`

export const SettingsColumn = styled.div`
  width: 33.3%;
  border: 1px solid gray;
  display: inline-block;
`

export const Table = styled.table`
  width: 100%;
  //border-collapse: collapse;
  border-collapse: separate;
  border-spacing: 0 10px;  
  tr td:first-child, tr th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

/* Top right and bottom right corners of the last cell in each row */
tr td:last-child, tr th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
  
`;

export const TableHeader = styled.th`
  padding: 12px 8px 12px 8px;
  text-align: left;
  color: ${theme2024.primary};

    @media (max-width: 760px) {
      font-size: 14px;
    }   

`;

export const TableData = styled.td`
  color: ${theme2024.textSecondary};
  padding: 12px 8px 12px 8px;

  @media (max-width: 760px) {
    font-size: 12px;
  }   
`;

export const TableRow = styled.tr`
  background-color: ${theme2024.secondaryDark};
  border-radius: 10px;
  
  
  //&:nth-child(even) {
  //  background-color: transparent;
  //}

`;


export const V2PropCard = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
`;

export const V2TopLine = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
`;

export const V2Title = styled.h2`
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0px;
`;

export const V2Description = styled.p`
    font-size: 14px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1;
    margin-top: 0px;
    
`;

export const V2BottomLine = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    margin-top: 10px;
`;

export const V2Button = styled.button`
    background: transparent;
    border: 1px solid ${theme.secondary};
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8rem;

`;


export const ChoiceButton = styled.button`

    font-size: 14px;
    font-weight: normal;
    color: ${props => props.selected == true ? theme2024.primary : theme2024.textSecondaryDarker};
    background-color: transparent;
    border: 1px solid ${props => props.selected == true ? theme2024.primary : theme2024.textSecondaryDarker};
    width: 65%;
    max-width: 65%;
    padding: 5px;
    border-radius: 10px;
    margin-top:10px;
    transition: color 0.3s, border 0.3s;

    :hover{
        border: 1px solid ${theme2024.primary};
        color: ${theme2024.textMain};
    }
`

export const SubmitPropV2Button = styled.button`

    font-size: 18px;
    font-weight: normal;
    color: ${props => props.selected == true ? "white" : "#696969"};
    background-color: ${props => props.selected == true ? theme.secondary : "transparent"};
    //border: 1px solid #696969;
    border: 1px solid ${props => props.selected == true ? theme.secondary : "#696969"};
    width: 65%;
    max-width: 65%;
    padding: 5px;
    border-radius: 20px;
    margin-top:10px;

`

export const NameAndCreatedBy = styled.div`
    width: 100%;
    margin-top: 35px;
    display: flex;
    align-items: center;
    
    h2{
      font-size: 22px;
      font-weight: 600;
      color: ${theme2024.textMain};
      padding-left: 25px;
    }

    h3{
      font-size: 14px;
      font-weight: 600;
      padding-top: 4px;
      color: ${theme2024.textSecondaryDarker};
      padding-left: 10px;
    }    
`

export const ProposalWrapper = styled.div`
    width: 100%;
    border-radius: 10px;
    background-color: ${theme2024.secondaryDark};
    padding: 15px;
    margin-bottom: 20px;
`

export const ProposalDetailCard = styled.div`
    width: 500px;
    max-width: 98%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 14px;
    //height: 130px;
    border: 1px solid ${theme2024.primary};
    border-radius: 10px;
    color: ${theme2024.textSecondaryDarker};
    flex-wrap: wrap;

    h2{
      //width: 100%;
      font-size: 18px;
      letter-spacing: 0.7px;
      color: ${theme2024.textMain};
      font-weight: 600;
    

      a{
        color: ${theme2024.primary};
        font-size: 14px;
      }
    }

    h3{
      //width: 100%;
      font-size: 14px;
      font-weight: 600;
      color: ${theme2024.textSecondary};

    }

    button{
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.7px;
      color: ${theme2024.textSecondaryDarker};
      border: 1px solid ${theme2024.textSecondaryDarker};
      padding: 4px 10px 4px 10px;
      border-radius: 7px;
      cursor: text;
    }
`

export const ProposalPageResultsWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 30px;

    h2{
      font-weight: 600;
      letter-spacing: 0.7px;
      font-size: 18px;
      color: ${theme2024.textMain};
    }
`