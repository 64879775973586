import { useState, useRef } from "react";
import { currentApiList, getCurrentApiList } from "../../data/config";
import axios from 'axios';

export const useGetBlendsByUser = () => {
    
    const [blendsByUser, setBlendsByUser] = useState([]);
    const [userBlendsAreLoading, setUserBlendsAreLoading] = useState(true);
    const apiIndex = useRef(0);

    const getBlendsByUser = (UserName) => {
        if (apiIndex.current >= currentApiList.length) {
            console.error("All API requests failed");
            return;
        }

        axios.post(`${currentApiList[apiIndex.current]}/v1/chain/get_table_rows`,{
            table: "blends",
            scope: "waxdaomarket",
            code: "waxdaomarket",
            key_type: "name",
            reverse: true,
            index_position: 2,
            limit: 100,
            lower_bound: UserName,
            upper_bound: UserName,
            json: true,
        }).then((res) => {
            setBlendsByUser(res.data.rows);
            console.log(res.data.rows);
        }).catch((error) => {
            console.error(error);
            apiIndex.current++;
            getBlendsByUser(UserName);
        }).finally(() => setUserBlendsAreLoading(false))
    }

    return [blendsByUser, getBlendsByUser, userBlendsAreLoading]
}
