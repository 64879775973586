import { MilestoneContainer, MilestoneDescription } from "../Styles";
import { formatVotes } from "../pages/daosv2/ProposalPageV2";
import { DarkBgTextBox } from "../pages/utilities/PowerupStyles";

const showVotePercentage = (choice_votes, total_votes) => {
    if(choice_votes === 0){
        return "0%";
    }

    return (Number(choice_votes) / Number(total_votes) * 100).toFixed(2) + "%";
}

const CurrentResultCard = ({ daoData, choice, total_votes }) => {
    return (
        <DarkBgTextBox>
            <h3>Votes: {formatVotes(daoData, choice.total_votes)} ({showVotePercentage(choice.total_votes, total_votes)})</h3>
            <p>
            {choice.description}
            </p>
        </DarkBgTextBox>
    );
};

export default CurrentResultCard;
