import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import {
  currentUsername,
  currentWebsiteURL,
  mainnetWebsiteURL,
} from "../data/config";
import {
  CollectionProfileAvatarCont,
  CollectionProfileBGContainer,
  CollectionProfileContainer,
  EditProfileButtonCont,
} from "../data/css/CollectionPageStyles";
import { buildIpfsUrl } from "../data/functions/global_functions";
import { createAvatar } from "@dicebear/core";
import { croodles } from "@dicebear/collection";
import { useGetUserProfile } from "../components/CustomHooks/useGetUserProfile";
import { useGetNsfwCollections } from "../components/CustomHooks/useGetNsfwCollections";
import collection_profile_stock_bg from "../data/default_cover.png";
import {
  CreateLockButton,
  FolderTab2024,
  FolderTabInScroll,
  FoldersContainer2024,
  FoldersContainerWithScroll,
  FoldersRowWithScroll,
  InfoIsLoadingBlur,
  Modal2024,
  ModalContent2024,
  ModalOverlay2024,
  PageBody2024,
  PageWrapper2024,
  ProfileDescriptionInnerScrollWrapper,
  ProfileSocialsWrapper,
  theme2024,
} from "../Styles2024";
import { getProfileSocials2024 } from "../data/functions/farm_functions";
import { DarkBgTextBox } from "./utilities/PowerupStyles";
import { FoldersRowCentered } from "./locker/LockStyles";
import { checked_svg, left_arrow_svg, right_arrow_svg } from "../data/svgs";
import { useGetGalleryNfts } from "../components/CustomHooks/useGetGalleryNfts";
import LoadingDiv from "../components/LoadingDiv";
import DaoNftCard from "../components/DaoNftCard";
import { useGetCountsByUser } from "../components/CustomHooks/useGetCountsByUser";
import UserItems from "../components/UserItems";
import {
  AmountSelectedWrapper,
  BulkActionsDropDown,
  BulkActionsWrapper,
  CollectionsDropDownWrapper,
  CollectionsOpener,
  SchemasDropDownWrapper,
  SchemasOpener,
  SelectAllWrapper,
  SortDropDownWrapper,
  SortOpener,
  WalletActionsWrapper,
  WalletCollectionsHeader,
  WalletFiltersQuadrant,
  WalletFiltersVerticalHalf,
  WalletFiltersWrapper,
  WalletSchemasHeader,
  WalletSortHeader,
} from "./utilities/WalletStyles2024";
import { FarmFilterRow } from "./farmsv2/FarmStyles2024";
import { useGetUserNfts2024 } from "../components/CustomHooks/useGetUserNfts2024";
import { useGetMyCollections } from "../components/CustomHooks/useGetMyCollections";
import { useGetBackedAssetsByUser } from "../components/CustomHooks/useGetBackedAssetsByUser";
import { useStateContext } from "../contexts/ContextProvider";
import {
  Spinner,
  SpinnerBlue,
  SpinnerGreen,
  SpinnerRed,
} from "../components/LeftDiv";
import BulkActionsModal from "../components/BulkActionsModal";
import GoToTopButton from "../components/WalletComponents/GoToTopButton";

const isItem = (currentTab) => {
  if (
    currentTab == "NFT Farms" ||
    currentTab == "Token Farms" ||
    currentTab == "DAOs" ||
    currentTab == "Drops" ||
    currentTab == "Blends" ||
    currentTab == "Unbox"
  ) {
    return true;
  }
  return false;
};

const sortMethods = [
  { display: "Transferred (Newest)", param: "&order=desc&sort=transferred" },
  { display: "Transferred (Oldest)", param: "&order=asc&sort=transferred" },
  { display: "Mint Number (Highest)", param: "&order=desc&sort=template_mint" },
  { display: "Mint Number (Lowest)", param: "&order=asc&sort=template_mint" },
  { display: "Alphabetical (a-z)", param: "&order=asc&sort=name" },
  { display: "Alphabetical (z-a)", param: "&order=desc&sort=name" },
  { display: "Time Created (Newest)", param: "&order=desc&sort=minted" },
  { display: "Time Created (Oldest)", param: "&order=asc&sort=minted" },
];

const handleFocus = (setShowSortMethods) => {
  setShowSortMethods(true);
};

const handleFilterBlur = (setShowFilterMethods) => {
  setShowFilterMethods(false);
};

const selectAll = (nfts, setAssetVector) => {
  let all_assets = [];
  for (const nft of nfts) {
    if (nft.asset_id) {
      all_assets.push(nft.asset_id);
    }
  }
  setAssetVector(all_assets);
};

const MyAvatar = ({ username }) => {
  const avatar = createAvatar(croodles, {
    seed: username + "@waxdao",
  });

  const svg = avatar.toString();

  return (
    <img
      src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`}
      alt={`Avatar-${username}`}
      style={{
        width: "auto",
        height: "auto",
        minHeight: "100%",
        minWidth: "100%",
      }}
    />
  );
};

const UserPage2024 = () => {
  const { UserName } = useParams();
  const { wharfSession } = useStateContext();

  const [enterModalDisplay, setEnterModalDisplay] = useState("hidden");
  const [enterModalText, setEnterModalText] = useState("Awaiting signature...");
  const [loadingDisplay, setLoadingDisplay] = useState("none");
  const [currentTab, setCurrentTab] = useState("NFTs");
  const [nftViewMode, setNftViewMode] = useState("Gallery");
  const [schemaSearchText, setSchemaSearchText] = useState("");
  const [collectionSearchText, setCollectionSearchText] = useState("");
  const [showFilterMethods, setShowFilterMethods] = useState(false);
  const [showFilters2, setShowFilters2] = useState(false);
  const [assetVector, setAssetVector] = useState([]);
  const [allAreSelected, setAllAreSelected] = useState(false);

  const [showSchemas, setShowSchemas] = useState(false);
  const [showSchemas2, setShowSchemas2] = useState(false);
  const [showSortMethods, setShowSortMethods] = useState(false);
  const [showSortMethods2, setShowSortMethods2] = useState(false);

  const [
    nfts,
    getNfts,
    nftsAreLoading,
    hasNextPage,
    collectionName,
    setCollectionName,
    setNfts,
    currentPage,
    setCurrentPage,
    schemaName,
    setSchemaName,
    sortMethod,
    setSortMethod,
  ] = useGetUserNfts2024();

  const [userProfile, getUserProfile, userProfileIsLoading, setUserProfile] =
    useGetUserProfile();
  const [nsfwCollections, getNsfwCollections, nsfwIsLoading] =
    useGetNsfwCollections();
  const [galleryNfts, getGalleryNfts, galleryIsLoading] = useGetGalleryNfts();
  const [counts, getCounts, countsAreLoading] = useGetCountsByUser();
  const [myCollections, getMyCollections, collectionsWereLoaded] =
    useGetMyCollections();
  const [backedData, getBackedTokens, backedDataIsLoading] =
    useGetBackedAssetsByUser();
  const [isSticky, setIsSticky] = useState(false);
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [currentBulkAction, setCurrentBulkAction] = useState("");
  const [profileHeight, setProfileHeight] = useState(0);

  const collectionNameRef = useRef(collectionName);
  const scrollRef = useRef(null);
  const profileContainerRef = useRef(null);

  useEffect(() => {
    const updateProfileHeight = () => {
      if (!userProfileIsLoading && userProfile) {
        if (profileContainerRef.current) {
          setProfileHeight(profileContainerRef.current.offsetHeight);
        }
      }
    };

    updateProfileHeight();

    window.addEventListener("resize", updateProfileHeight);

    return () => {
      window.removeEventListener("resize", updateProfileHeight);
    };
  }, [userProfileIsLoading, userProfile]);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo({
        top: 230,
        behavior: "smooth",
      });
      getUserProfile(UserName);
      getCounts(UserName);
      getNsfwCollections();
      getMyCollections(UserName);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
        setNfts([]);
        setCurrentPage(1);
        setAllAreSelected(false);
        setAssetVector([]);            
        if(currentTab == "NFTs" && nftViewMode == "Inventory"){
            getNfts(UserName, collectionName, schemaName, sortMethod, 1);
        } else {
            setSchemaName(null);
            setCollectionName(null);
            setCollectionSearchText("");
            setSchemaSearchText("");                
        }
    }
    return () => {
      isMounted = false;
    };
  }, [collectionName, schemaName, sortMethod, nftViewMode, currentTab]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (
        !userProfileIsLoading &&
        userProfile &&
        userProfile.asset_ids.length > 0
      ) {
        getGalleryNfts(userProfile?.asset_ids);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [userProfileIsLoading]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      collectionNameRef.current = collectionName;
    }
    return () => {
      isMounted = false;
    };
  }, [collectionName]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (nftViewMode == "Inventory") {
        getBackedTokens(UserName, 1, 60, false, collectionName, schemaName);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [nftViewMode, collectionName, schemaName]);

  const toggleVisibility = (collectionName) => {
    let breakPoint = collectionNameRef.current
      ? 680 + profileHeight
      : 605 + profileHeight;
    if (window.scrollY > breakPoint) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      toggleVisibility(collectionName);
    });

    return () => {
      window.removeEventListener("scroll", () => {
        toggleVisibility(collectionName);
      });
    };
  }, []);


  return (
    <div>
      <Helmet>
        <title>{`${UserName}'s Profile`}</title>
        <meta
          name="description"
          content={`View ${UserName}'s Wax wallet on WaxDAO`}
        />
        <link rel="canonical" href={`${mainnetWebsiteURL}/u/${UserName}`} />
      </Helmet>

      <ModalOverlay2024 className={enterModalDisplay} />
      <Modal2024 className={enterModalDisplay}>
        <div
          style={{
            width: "100%",
            textAlign: "right",
            paddingRight: "5px",
            fontSize: "22px",
            fontWeight: "400",
            color: theme2024.textMain,
          }}
        >
          <button
            onClick={() => {
              setEnterModalDisplay("hidden");
            }}
          >
            X
          </button>
        </div>
        <ModalContent2024>{enterModalText}</ModalContent2024>
        <Spinner style={{ display: loadingDisplay }}>
          <SpinnerRed />
          <SpinnerBlue />
          <SpinnerGreen />
        </Spinner>
      </Modal2024>

      <CollectionProfileContainer staticSize={true}>
        <CollectionProfileBGContainer>
          {userProfile?.profile?.cover_image != null &&
          userProfile?.profile.cover_image.length > 5 ? (
            <img
              src={buildIpfsUrl(userProfile?.profile.cover_image, 900)}
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
          ) : (
            <img
              src={collection_profile_stock_bg}
              style={{
                objectFit: "cover",
                width: "auto",
                height: "auto",
                minHeight: "100%",
                minWidth: "100%",
              }}
            />
          )}
        </CollectionProfileBGContainer>

        <CollectionProfileAvatarCont staticSize={true}>
          {userProfile?.profile?.avatar != null &&
          userProfile?.profile.avatar.length > 5 ? (
            <img
              src={buildIpfsUrl(userProfile?.profile.avatar, 200)}
              style={{
                width: "auto",
                height: "auto",
                minHeight: "100%",
                minWidth: "100%",
              }}
            />
          ) : (
            <span>{MyAvatar({ username: UserName })}</span>
          )}
        </CollectionProfileAvatarCont>
        {currentUsername == UserName && (
          <EditProfileButtonCont href={`${currentWebsiteURL}/edit-profile`}>
            EDIT PROFILE
          </EditProfileButtonCont>
        )}
      </CollectionProfileContainer>

      <PageWrapper2024 profile={true}>
        <PageBody2024 fullWidth={true}>
          <div className="pt-10">
            <ProfileSocialsWrapper>
              <h2>{UserName}</h2>
              {getProfileSocials2024(userProfile, "userPage", UserName)}
            </ProfileSocialsWrapper>
            {userProfile.profile?.description &&
              userProfile.profile?.description?.length > 3 && (
                <DarkBgTextBox ref={profileContainerRef}>
                  <ProfileDescriptionInnerScrollWrapper>
                    <p>{userProfile.profile.description}</p>
                  </ProfileDescriptionInnerScrollWrapper>
                </DarkBgTextBox>
              )}
          </div>

          <FoldersContainerWithScroll>
            <button onClick={scrollLeft}>{left_arrow_svg}</button>
            <FoldersRowWithScroll ref={scrollRef}>
              <FolderTabInScroll
                disabled={currentTab == "NFTs"}
                selected={currentTab == "NFTs"}
                onClick={() => {
                  setCurrentTab("NFTs");
                }}
              >
                NFTs
              </FolderTabInScroll>
              <FolderTabInScroll
                hide={!countsAreLoading && counts && !counts.farm_count}
                disabled={currentTab == "NFT Farms"}
                selected={currentTab == "NFT Farms"}
                onClick={() => {
                  setCurrentTab("NFT Farms");
                }}
              >
                {counts?.farm_count ? (
                  `NFT Farms(${counts.farm_count})`
                ) : (
                  <InfoIsLoadingBlur width="90%" />
                )}
              </FolderTabInScroll>
              <FolderTabInScroll
                hide={!countsAreLoading && counts && !counts.token_farm_count}
                disabled={currentTab == "Token Farms"}
                selected={currentTab == "Token Farms"}
                onClick={() => {
                  setCurrentTab("Token Farms");
                }}
              >
                {counts?.token_farm_count ? (
                  `Token Farms(${counts.token_farm_count})`
                ) : (
                  <InfoIsLoadingBlur width="90%" />
                )}
              </FolderTabInScroll>
              <FolderTabInScroll
                hide={!countsAreLoading && counts && !counts.drop_count}
                disabled={currentTab == "Drops"}
                selected={currentTab == "Drops"}
                onClick={() => {
                  setCurrentTab("Drops");
                }}
              >
                {counts?.drop_count ? (
                  `Drops(${counts.drop_count})`
                ) : (
                  <InfoIsLoadingBlur width="90%" />
                )}
              </FolderTabInScroll>
              <FolderTabInScroll
                hide={!countsAreLoading && counts && !counts.blend_count}
                disabled={currentTab == "Blends"}
                selected={currentTab == "Blends"}
                onClick={() => {
                  setCurrentTab("Blends");
                }}
              >
                {counts?.blend_count ? (
                  `Blends(${counts.blend_count})`
                ) : (
                  <InfoIsLoadingBlur width="90%" />
                )}
              </FolderTabInScroll>
              <FolderTabInScroll
                hide={!countsAreLoading && counts && !counts.dao_count}
                disabled={currentTab == "DAOs"}
                selected={currentTab == "DAOs"}
                onClick={() => {
                  setCurrentTab("DAOs");
                }}
              >
                {counts?.dao_count ? (
                  `DAOs(${counts.dao_count})`
                ) : (
                  <InfoIsLoadingBlur width="90%" />
                )}
              </FolderTabInScroll>
              <FolderTabInScroll
                hide={!countsAreLoading && counts && !counts.pack_count}
                disabled={currentTab == "Unbox"}
                selected={currentTab == "Unbox"}
                onClick={() => {
                  setCurrentTab("Unbox");
                }}
              >
                {counts?.pack_count ? (
                  `Unbox(${counts.pack_count})`
                ) : (
                  <InfoIsLoadingBlur width="90%" />
                )}
              </FolderTabInScroll>
            </FoldersRowWithScroll>
            <button onClick={scrollRight}>{right_arrow_svg}</button>
          </FoldersContainerWithScroll>
          {isItem(currentTab) && (
            <UserItems item={currentTab} user={UserName} />
          )}

          {currentTab == "NFTs" && (
            <>
              <FoldersContainer2024>
                <FoldersRowCentered>
                  <FolderTab2024
                    disabled={nftViewMode == "Gallery"}
                    selected={nftViewMode == "Gallery"}
                    onClick={() => {
                      setNftViewMode("Gallery");
                    }}
                  >
                    Gallery
                  </FolderTab2024>
                  <FolderTab2024
                    disabled={nftViewMode == "Inventory"}
                    selected={nftViewMode == "Inventory"}
                    onClick={() => {
                      setNftViewMode("Inventory");
                    }}
                  >
                    Full Inventory
                  </FolderTab2024>
                </FoldersRowCentered>
              </FoldersContainer2024>

              {nftViewMode == "Gallery" && (
                <>
                  {galleryIsLoading && <LoadingDiv />}
                  {!galleryIsLoading && (
                    <>
                      {galleryNfts.length === 0 ? (
                        <DarkBgTextBox>
                          <p>This user has not created a gallery yet.</p>
                        </DarkBgTextBox>
                      ) : (
                        <>
                          <div
                            style={{ gap: "1px" }}
                            className={`w-100 flex flex-wrap justify-center mt-4`}
                          >
                            {galleryNfts.map((nft, index) => (
                              <DaoNftCard
                                key={index}
                                item={nft}
                                gallery={true}
                                nsfwIsLoading={nsfwIsLoading}
                                nsfwCollections={nsfwCollections}
                                assetVector={userProfile?.asset_ids}
                                setAssetVector={() => {}}
                                location={"userPage"}
                                backedData={[]}
                                //wharfSession={wharfSession}
                                //enterModalText={enterModalText}
                                //enterModalDisplay={enterModalDisplay}
                                //loadingDisplay={loadingDisplay}
                                //setEnterModalText={setEnterModalText}
                                //setEnterModalDisplay={setEnterModalDisplay}
                                //setLoadingDisplay={setLoadingDisplay}
                              />
                            ))}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {showBulkModal && (
                <span>
                  <ModalOverlay2024 />
                  <BulkActionsModal
                    setShowBulkModal={setShowBulkModal}
                    assetVector={assetVector}
                    setAssetVector={setAssetVector}
                    setAllAreSelected={setAllAreSelected}
                    location={"userPage"}
                    wharfSession={wharfSession}
                    enterModalText={enterModalText}
                    enterModalDisplay={enterModalDisplay}
                    loadingDisplay={loadingDisplay}
                    setEnterModalText={setEnterModalText}
                    setEnterModalDisplay={setEnterModalDisplay}
                    setLoadingDisplay={setLoadingDisplay}
                    currentBulkAction={currentBulkAction}
                    setCurrentBulkAction={setCurrentBulkAction}
                    owner={UserName}
                  />
                </span>
              )}

              {nftViewMode == "Inventory" && (
                <>
                  <WalletFiltersWrapper
                    height={collectionName ? "160px" : "85px"}
                  >
                    <WalletFiltersQuadrant
                      height={collectionName ? "150px" : "75px"}
                    >
                      <WalletFiltersVerticalHalf>
                        <WalletCollectionsHeader>
                          COLLECTION
                        </WalletCollectionsHeader>
                        <CollectionsOpener
                          tabIndex="0"
                          focused={showFilterMethods || showFilters2}
                          onFocus={() => handleFocus(setShowFilterMethods)}
                          onBlur={(e) => handleFilterBlur(setShowFilterMethods)}
                        >
                          <input
                            placeholder={
                              collectionName == null
                                ? "All Collections"
                                : collectionName
                            }
                            value={collectionSearchText}
                            maxLength={12}
                            onChange={(e) => {
                              setCollectionSearchText(
                                e.target.value.toLowerCase()
                              );
                            }}
                          />
                        </CollectionsOpener>
                        <CollectionsDropDownWrapper
                          tabIndex="0"
                          open={showFilterMethods || showFilters2}
                          onFocus={() => handleFocus(setShowFilters2)}
                          onBlur={(e) => handleFilterBlur(setShowFilters2)}
                        >
                          {collectionsWereLoaded &&
                            myCollections?.collections?.length >= 1 &&
                            collectionName && (
                              <FarmFilterRow>
                                <button
                                  onClick={(e) => {
                                    setCollectionName(null);
                                    setSchemaSearchText("");
                                    setCollectionSearchText("");
                                    setSchemaName(null);
                                    setShowFilters2(false);
                                  }}
                                >
                                  All Collections
                                </button>
                              </FarmFilterRow>
                            )}
                          {collectionsWereLoaded &&
                            myCollections?.collections?.length >= 1 &&
                            myCollections.collections
                              .sort((a, b) => b.assets - a.assets)
                              .map((item, index) => (
                                <FarmFilterRow
                                  key={index}
                                  hide={
                                    collectionSearchText.length > 0 &&
                                    item.collection.collection_name
                                      .substring(0, collectionSearchText.length)
                                      .indexOf(collectionSearchText) == -1
                                      ? true
                                      : false
                                  }
                                >
                                  <button
                                    onClick={(e) => {
                                      setCollectionName(
                                        item.collection.collection_name
                                      );
                                      setCollectionSearchText("");
                                      setSchemaSearchText("");
                                      setSchemaName(null);
                                      setShowFilters2(false);
                                    }}
                                  >
                                    {item.collection.collection_name} (
                                    {item.assets})
                                  </button>
                                </FarmFilterRow>
                              ))}
                        </CollectionsDropDownWrapper>
                      </WalletFiltersVerticalHalf>

                      {collectionName && (
                        <WalletFiltersVerticalHalf>
                          <WalletSchemasHeader visible={collectionName}>
                            SCHEMA
                          </WalletSchemasHeader>
                          <SchemasOpener
                            tabIndex="0"
                            focused={showSchemas || showSchemas2}
                            onFocus={() => handleFocus(setShowSchemas)}
                            onBlur={(e) => handleFilterBlur(setShowSchemas)}
                          >
                            <input
                              placeholder={
                                schemaName == null ? "All Schemas" : schemaName
                              }
                              value={schemaSearchText}
                              maxLength={12}
                              onChange={(e) => {
                                setSchemaSearchText(
                                  e.target.value.toLowerCase()
                                );
                              }}
                            />
                          </SchemasOpener>
                          <SchemasDropDownWrapper
                            tabIndex="0"
                            open={showSchemas || showSchemas2}
                            onFocus={() => handleFocus(setShowSchemas2)}
                            onBlur={(e) => handleFilterBlur(setShowSchemas2)}
                          >
                            {collectionsWereLoaded &&
                              myCollections?.schemas?.length >= 1 &&
                              collectionName &&
                              schemaName && (
                                <FarmFilterRow>
                                  <button
                                    onClick={(e) => {
                                      setSchemaName(null);
                                      setShowSchemas2(false);
                                      setSchemaSearchText("");
                                      setCollectionSearchText("");
                                    }}
                                  >
                                    All Schemas
                                  </button>
                                </FarmFilterRow>
                              )}

                            {collectionsWereLoaded &&
                              myCollections?.schemas?.length >= 1 &&
                              collectionName &&
                              myCollections.schemas
                                .filter(
                                  (schema) =>
                                    schema.schema.collection.collection_name ==
                                    collectionName
                                )
                                .sort((a, b) => b.assets - a.assets)
                                .map((item, index) => (
                                  <FarmFilterRow
                                    key={index}
                                    hide={
                                      schemaSearchText.length > 0 &&
                                      item.schema.schema_name
                                        .substring(0, schemaSearchText.length)
                                        .indexOf(schemaSearchText) == -1
                                        ? true
                                        : false
                                    }
                                  >
                                    <button
                                      onClick={(e) => {
                                        setSchemaSearchText("");
                                        setCollectionSearchText("");
                                        setSchemaName(item.schema.schema_name);
                                        setShowSchemas2(false);
                                      }}
                                    >
                                      {item.schema.schema_name} ({item.assets})
                                    </button>
                                  </FarmFilterRow>
                                ))}
                          </SchemasDropDownWrapper>
                        </WalletFiltersVerticalHalf>
                      )}
                    </WalletFiltersQuadrant>

                    <WalletFiltersQuadrant
                      height={collectionName ? "150px" : "75px"}
                    >
                      <WalletFiltersVerticalHalf>
                        <WalletSortHeader>SORT</WalletSortHeader>
                        <SortOpener
                          tabIndex="0"
                          focused={showSortMethods || showSortMethods2}
                          onFocus={() => handleFocus(setShowSortMethods2)}
                          onBlur={(e) => handleFilterBlur(setShowSortMethods2)}
                        >
                          {sortMethod.display}
                        </SortOpener>
                        <SortDropDownWrapper
                          tabIndex="0"
                          open={showSortMethods || showSortMethods2}
                          onFocus={() => handleFocus(setShowSortMethods2)}
                          onBlur={(e) => handleFilterBlur(setShowSortMethods2)}
                        >
                          {sortMethods.map((item, index) => (
                            <FarmFilterRow key={index}>
                              <button
                                onClick={(e) => {
                                  setSortMethod(item);
                                  setShowSortMethods2(false);
                                }}
                              >
                                {item.display}
                              </button>
                            </FarmFilterRow>
                          ))}
                        </SortDropDownWrapper>
                      </WalletFiltersVerticalHalf>

                      {collectionName && <WalletFiltersVerticalHalf />}
                    </WalletFiltersQuadrant>
                  </WalletFiltersWrapper>

                  <WalletActionsWrapper isSticky={isSticky}>
                    <SelectAllWrapper selected={allAreSelected}>
                      {allAreSelected && "UN"}SELECT ALL
                      <button
                        onClick={() => {
                          if (!allAreSelected) {
                            selectAll(nfts, setAssetVector);
                            setAllAreSelected(true);
                          } else {
                            setAssetVector([]);
                            setAllAreSelected(false);
                          }
                        }}
                      >
                        {allAreSelected && checked_svg}
                      </button>
                    </SelectAllWrapper>
                    <BulkActionsWrapper open={showBulkActions}>
                      {assetVector.length > 0 && (
                        <button
                          onClick={() => {
                            setShowBulkActions((prev) => !prev);
                          }}
                        >
                          BULK ACTIONS
                          <BulkActionsDropDown visible={showBulkActions}>
                            <button
                              onClick={() => {
                                setCurrentBulkAction("BACK");
                                setShowBulkModal(true);
                              }}
                            >
                              BACK
                            </button>
                          </BulkActionsDropDown>
                        </button>
                      )}
                    </BulkActionsWrapper>
                    <AmountSelectedWrapper>
                      {assetVector?.length > 0 &&
                        `${assetVector.length} NFTs Selected`}
                    </AmountSelectedWrapper>
                  </WalletActionsWrapper>

                  {(!collectionsWereLoaded ||
                    nftsAreLoading ||
                    backedDataIsLoading) && <LoadingDiv />}

                  {collectionsWereLoaded && nfts?.length > 0 && backedData && (
                    <span>
                      <div
                        className={`w-100 flex flex-wrap justify-center ${
                          nftViewMode != "GALLERY" && "gap-2"
                        } mt-4 ${isSticky && "pt-24"}`}
                      >
                        {nfts.map((nft, index) => (
                          <DaoNftCard
                            key={index}
                            item={nft}
                            gallery={nftViewMode == "GALLERY"}
                            nsfwIsLoading={nsfwIsLoading}
                            nsfwCollections={nsfwCollections}
                            assetVector={assetVector}
                            setAssetVector={setAssetVector}
                            location={"userPage"}
                            backedData={backedData}
                            wharfSession={wharfSession}
                            enterModalText={enterModalText}
                            enterModalDisplay={enterModalDisplay}
                            loadingDisplay={loadingDisplay}
                            setEnterModalText={setEnterModalText}
                            setEnterModalDisplay={setEnterModalDisplay}
                            setLoadingDisplay={setLoadingDisplay}
                          />
                        ))}
                      </div>
                    </span>
                  )}

                  {nftViewMode != "GALLERY" &&
                    collectionsWereLoaded &&
                    nfts?.length > 0 &&
                    hasNextPage() && (
                      <div className="w-100 flex flex-wrap justify-center gap-2 mt-4">
                        <CreateLockButton
                          onClick={() => {
                            getBackedTokens(
                              UserName,
                              currentPage + 1,
                              60,
                              false,
                              collectionName,
                              schemaName
                            );
                            getNfts(
                              UserName,
                              collectionName,
                              schemaName,
                              sortMethod,
                              currentPage + 1
                            );
                          }}
                        >
                          LOAD MORE
                        </CreateLockButton>
                      </div>
                    )}
                </>
              )}
            </>
          )}
        </PageBody2024>
      </PageWrapper2024>
      <GoToTopButton />
    </div>
  );
};

export default UserPage2024;
