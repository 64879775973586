import { useEffect } from "react";
import { useGetMyPremintedPools } from "../../components/CustomHooks/useGetMyPremintedPools";
import LoadingDiv from "../../components/LoadingDiv";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentWebsiteURL } from "../../data/config";
import { ClaimInfo, TokenCard } from "../../data/css/WalletStyles";
import { SmallButton } from "../../Styles";
import { createAvatar } from "@dicebear/core";
import { funEmoji } from "@dicebear/collection";

const MyAvatar = ({ item }) => {
  const avatar = createAvatar(funEmoji, {
    seed: item.ID + "_@waxdao",
  });

  const svg = avatar.toString();

  return (
      <img src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`} alt={`Avatar-${item.ID}`} 
      style={{borderRadius:"5px", opacity:"90%"}}
      />
  );
};

export const showManagePremintedPoolsSection = (currentToolsSection) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();

  const [myPools, getMyPremintedPools, myPoolsAreLoading] =
    useGetMyPremintedPools();

  useEffect(() => {
    if (currentToolsSection == "Premint Pools") {
      getMyPremintedPools();
    }
  }, [currentToolsSection]);

  return (
    <span className={currentToolsSection != "Premint Pools" && "hidden"}>
      {!myPoolsAreLoading && (
        <span>
          {myPools?.length > 0
            ? myPools.map((item, index) => (
                <span key={index}>
                  <TokenCard>
                    <a href={`${currentWebsiteURL}/premint-pool/${item.ID}`}>
                    {MyAvatar({item: item})}
                    </a>
                    <ClaimInfo>
                      Pool {item.ID}
                        <a href={`${currentWebsiteURL}/premint-pool/${item.ID}`}>
                      <SmallButton>Edit Pool</SmallButton>
                      </a>
                    </ClaimInfo>
                  </TokenCard>
                </span>
              ))
            : (
              <div className="w-100 text-center pt-3">No pools to show</div>
            )}
        </span>
      )}
      {myPoolsAreLoading && <LoadingDiv />}
    </span>
  );
};
