import React, { useEffect, useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import {Helmet} from "react-helmet";
import { Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, ModalOverlay } from '../../components/LeftDiv';
import { currentWebsiteURL, mainnetWebsiteURL, tokenServerURL} from '../../data/config';
import { createLockV2 } from '../../data/functions/locker_functions';
import { CautionMessage, CreateLockButton, FolderTab2024, FoldersContainer2024, LpLockCard2024, LpLockCardAmount, LpLockCardDate, LpLockCardIngredients, LpLockPicCont, Modal2024, ModalContent2024, ModalOverlay2024, MyLocksWrapper, PageWrapper2024, theme2024 } from '../../Styles2024';
import { ChooseExchangeCont, ChooseTokenButton, FoldersRowCentered, LPLockContainer, LockAmountInput, Slider, SliderContainer } from './LockStyles';
import taco_avatar from '../../data/taco_avatar.webp';
import defibox_avatar from '../../data/defibox_avatar.png';
import { useGetTokenBalsFromLightApi } from '../../components/CustomHooks/useGetTokenBalsFromLightApi';
import { isLoggedIn } from '../../data/functions/wallet_functions';
import LoadingDiv from '../../components/LoadingDiv';
import axios from 'axios';
import SelectLpExchangeModal from '../../components/SelectLpExchangeModal';
import { useGetLocksByCreator } from '../../components/CustomHooks/useGetLocksByCreator';
import SelectTokenModal from '../../components/SelectTokenModal';

export const isLpContract = (contract) => {
    return contract == "lptoken.box" || contract == "swap.taco"
}

 const handleInputChange = (e, setAmount, selectedToken) => {
  const value = e.target.value;
  if (value === "" || /^\d*\.?\d*$/.test(value)) {
    if (value === "" || !isNaN(value)) {
        if(value !== ""){
            if(value > Number(selectedToken.amount)){
                return;
            }
        }
        setAmount(value)
    }
  }
};

const handleSliderChange = (e, selectedToken, setAmountToLock) => {
    const percentage = e.target.value;
    const newAmountToLock = Number((selectedToken.amount * percentage) / 100).toFixed(selectedToken.decimals);
    setAmountToLock(newAmountToLock);
  };

  const handleTimeSliderChange = (e, setTimeToLock) => {
    const months = e.target.value;
    setTimeToLock(months);
  };  

  function showTimeToLock(months) {
    if (months < 0 || months > 60) {
        return "Invalid input";
    }

    if (months < 12) {
        return `${months} month${months !== 1 ? 's' : ''}`;
    }

    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;

    if (remainingMonths === 0) {
        return `${years} year${years !== 1 ? 's' : ''}`;
    }

    return `${years} year${years !== 1 ? 's' : ''} ${remainingMonths} month${remainingMonths !== 1 ? 's' : ''}`;
}  

const TokenLocker = () => {

  const {
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    wharfSession
  } = useStateContext();

  const [currentTab, setCurrentTab] = useState("New Lock");
  const [currentExchange, setCurrentExchange] = useState("Taco Swap")
  const [exchangeContract, setExchangeContract] = useState("swap.taco")
  const [refresh, setRefresh] = useState(false);
  const [underlying, setUnderlying] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);
  const [showSelectTokenModal, setShowSelectTokenModal] = useState(false);
  const [showSelectExchangeModal, setShowSelectExchangeModal] = useState(false);
  const [timeToLock, setTimeToLock] = useState(0);

  const [tokenBalances, getTokenBalances, balancesAreLoading] = useGetTokenBalsFromLightApi();
  const [locksByCreator, getLocksByCreator, creatorLocksAreLoading] = useGetLocksByCreator();



  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (isLoggedIn()) {
      getTokenBalances();
    }
  }, [refresh]);  

  useEffect(() => {

    axios
      .post(`${tokenServerURL}/get-all-underlying-tokens`, {}, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setUnderlying(response.data);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

  }, []);   

  return (
  <div id="seo">
    <Helmet>
    <title>Token Locker</title>
    <meta name="description" content="Use WaxDAO's open-source token locker to time-lock your tokens" />
    <link rel="canonical" href={`${mainnetWebsiteURL}/locker`} />
    </Helmet>

    <ModalOverlay2024 className={enterModalDisplay} />
    <Modal2024 className={enterModalDisplay}>
    <div style={{width: "100%", textAlign: "right", paddingRight: "5px", fontSize: "22px", fontWeight: "400", color: theme2024.textMain}}><button
            onClick={() => {
                setEnterModalDisplay("hidden");
              }}            
            >X</button></div>        
      <ModalContent2024>
        {enterModalText}

      </ModalContent2024>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>
      </Modal2024>

      

      {showSelectTokenModal && (<span>
              <ModalOverlay />
              <SelectTokenModal 
              showAttributeModal={showSelectTokenModal}
              setShowAttributeModal={setShowSelectTokenModal}
              tokens = {tokenBalances}
              selectedToken = {selectedToken}
              setSelectedToken = {setSelectedToken} 
              location = {`token-locker`}      
              exchangeContract = {exchangeContract}
              underlying = {underlying}    
            />
            </span>)} 

      {showSelectExchangeModal && (<span>
              <ModalOverlay />
              <SelectLpExchangeModal 
              showAttributeModal={showSelectExchangeModal}
              setShowAttributeModal={setShowSelectExchangeModal}
              selectedExchange = {currentExchange}
              setSelectedExchange = {setCurrentExchange}
              underlying = {underlying}    
              exchangeContract = {exchangeContract}
              setExchangeContract = {setExchangeContract}
              setSelectedToken = {setSelectedToken}
              setAmount = {setAmount}
            />
            </span>)}                  

    <PageWrapper2024>

    <CautionMessage>
    This tool is for locking regular tokens only. If you want to lock LP tokens, use our <a href={`${currentWebsiteURL}/liquidity-locker`}>Liquidity Locker</a>.
    </CautionMessage>

    <CautionMessage>
      Important! Please be aware that if you deposit tokens into the locker, they will not earn staking/farming/airdrop rewards, etc. Furthermore, our locker contract is under multisig with other block producers on WAX (for your safety). 
      This means that WaxDAO does not have the authority to reverse/modify your token locks if you make a mistake.
      </CautionMessage>         
    

    <LPLockContainer>
        <h2>Token Locker</h2>
        <FoldersContainer2024>
            <FoldersRowCentered>
                <FolderTab2024
                    selected={currentTab == "New Lock"}
                    disabled={currentTab == "New Lock"}
                    onClick={() => {
                        setCurrentTab("New Lock")
                    }}                
                >
                    New Lock
                </FolderTab2024>
                <FolderTab2024
                    selected={currentTab == "My Locks"}
                    disabled={currentTab == "My Locks"}
                    onClick={() => {
                        setCurrentTab("My Locks")
                        if(creatorLocksAreLoading || locksByCreator.length == 0){
                            getLocksByCreator();
                        }
                    }}                    
                >
                    My Locks
                </FolderTab2024>                
            </FoldersRowCentered>
        </FoldersContainer2024>
        
        <span className={currentTab != "New Lock" && "hidden"}>


        {isLoggedIn() && balancesAreLoading && <LoadingDiv />}

        {!isLoggedIn() && <ChooseExchangeCont>Please log in to proceed</ChooseExchangeCont>}

        {isLoggedIn() && !balancesAreLoading &&
        (tokenBalances.some(balance => !isLpContract(balance.contract)) ? (
            <div>
                <ChooseExchangeCont>
                Which token do you want to lock?
                </ChooseExchangeCont>

                <ChooseTokenButton
                    onClick={() => setShowSelectTokenModal(true)}
                >
                    {!selectedToken ? "Choose" : selectedToken.currency}
                </ChooseTokenButton>
               

            </div>
            )
            :
            <span><br/><br/>No balances found</span>
        )
        }

    
    <span className={selectedToken == null && "hidden"}>
    <SliderContainer>
        <h2>AMOUNT TO LOCK</h2>
      <Slider 
        type="range" 
        min="0" 
        max="100" 
        value={(amount / selectedToken?.amount) * 100} 
        onChange={(e) => handleSliderChange(e, selectedToken, setAmount)} 
      />
    </SliderContainer>

    <LockAmountInput>
    <input 
        value={amount}
        onChange={(e) => handleInputChange(e, setAmount, selectedToken)}
    />
    <h3>Max {selectedToken?.amount}</h3>            
    </LockAmountInput>

    <SliderContainer>
        <h2>TIME TO LOCK</h2>
      <Slider 
        type="range" 
        min="0" 
        max="60" 
        value={timeToLock} 
        onChange={(e) => handleTimeSliderChange(e, setTimeToLock)} 
      />
      <h3>{showTimeToLock(timeToLock)}</h3>
    </SliderContainer>

    <CreateLockButton
        disabled={selectedToken == null || timeToLock == 0 || amount == 0}
        onClick={() => {
            createLockV2(selectedToken?.contract, amount, selectedToken?.decimals, selectedToken?.currency, timeToLock, setEnterModalText, setLoadingDisplay, setEnterModalDisplay, wharfSession)
        }}
    >
    {(selectedToken == null || timeToLock == 0 || amount == 0) ?<span style={{color:theme2024.textSecondary}}>Enter lock details</span> : "LOCK TOKENS"}
    </CreateLockButton>
    </span>

    </span>
{isLpContract}
    <span className={currentTab != "My Locks" && "hidden"}>
        <MyLocksWrapper>
        {creatorLocksAreLoading && <LoadingDiv />}
        {!creatorLocksAreLoading 
          && 
          locksByCreator?.filter(lock => !isLpContract(lock.token_contract)).length === 0
          && 
          "No locks found"}

        {!creatorLocksAreLoading 
        && 
        locksByCreator?.filter(lock => !isLpContract(lock.token_contract)).length > 0 
        && 

        locksByCreator?.filter(lock => !isLpContract(lock.token_contract)).map((item, index) => (
          <a key={index} href={`${currentWebsiteURL}/locks/${item.ID}`}>
            <LpLockCard2024>
                <LpLockPicCont>
                <img
                    src={`https://logos.waxdao.io/${item.amount.split(" ")[1].toLowerCase()}_${item.token_contract}.png`}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://logos.waxdao.io/default-logo.png";
                    }}
                />
                </LpLockPicCont>
                <LpLockCardAmount>
                    {item.amount}              
                </LpLockCardAmount>

                <LpLockCardIngredients>
         
                </LpLockCardIngredients>

                <LpLockCardDate>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    width="800px" height="800px" viewBox="0 0 92.179 92.18"
                    >
                <g>
                    <path d="M73.437,36.54v-9.192C73.437,12.268,61.169,0,46.09,0S18.744,12.268,18.744,27.348h11.355
                        c0-8.818,7.173-15.992,15.991-15.992c8.817,0,15.991,7.174,15.991,15.992v9.192H9.884v55.64h72.411V36.54H73.437z M50.609,71.115
                        V83.33h-9.037V71.115c-2.102-1.441-3.482-3.858-3.482-6.6c0-4.418,3.582-8,8-8s8,3.582,8,8
                        C54.09,67.257,52.71,69.674,50.609,71.115z"/>
                </g>
                </svg> 
                <p>{new Date(item.unlock_time * 1000).toLocaleDateString()}</p>
                </LpLockCardDate>

            </LpLockCard2024>
            </a>
        ))}
        </MyLocksWrapper>
    </span>

        
    </LPLockContainer>

    </PageWrapper2024>
    </div>
  )
}

export default TokenLocker