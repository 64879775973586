import React, { useState } from 'react'
import { BackedTokensWrapper, ChooseProductButton, ChooseProductPopup, ProductButton } from '../pages/utilities/WalletStyles2024'
import { CreateLockButton, theme2024 } from '../Styles2024';
import NumberFormat from 'react-number-format';
import { ConfirmUnderstandingWrapper, DetailsModalWrapper } from '../data/css/NftStyles2024'
import { checked_svg } from '../data/svgs';
import { burnNft, getWhitelisted } from '../pages/utilities/wallet_functions_2024';
import { currentUsername, currentWebsiteURL } from '../data/config';
import { ChooseExchangeButton } from '../pages/locker/LockStyles';

const isActionable = (location) => {
    if(location == 'wallet' || location == 'asset_id_page'){
        return true
    }
    return false
}

const getFormattedAmountFromInt64AndPrecision = (token) => {
    if(token.token_precision == 0) return token.amount;

    return token.amount / Math.pow(10, token.token_precision);
}

const displayBackedAmount = (token, index, backed_tokens) => {
    let amt = getFormattedAmountFromInt64AndPrecision(token)
    let start_string;
    if(index === 0){
        start_string = " "
    }
    else if(index > 0 && index + 1 < backed_tokens.length){
        start_string = ", "
    }
    else{
        start_string = " and "
    }

    if(token.token_symbol){
        console.log('aye')
        console.log(token)
        return(
            <span>
            {start_string}<NumberFormat displayType='text' thousandSeparator={true} value={amt} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />{" "}{token.token_symbol}
            </span>
        )        
    } else {
        console.log('bye')
        console.log(token)
        return <span>
        {start_string}{" "}{token.quantity}
        </span>
    }
}

const showBackedTokens = (nft, backedData) => {
    let backed_tokens = [];
    if(nft?.collection?.collection_name && nft?.collection?.collection_name == "hoodpunknfts"
        &&
        nft?.schema?.schema_name && nft?.schema?.schema_name == "waxywojaks"
    ){backed_tokens.push({token_contract: 'token.waxdao', token_symbol: 'WAXDAO', token_precision: 8, amount: 2500000000000})}

    if(nft?.backed_tokens?.length > 0){
        for(const tkn of nft.backed_tokens){
            backed_tokens.push(tkn)
        }
    }
    if(backedData?.findIndex(backed => backed.asset_id == nft.asset_id) > -1){
        for(const t of backedData[backedData.findIndex(backed => backed.asset_id == nft.asset_id)].backed_tokens){
            backed_tokens.push(t)
        }
    }
    if(backed_tokens.length !== 0){
        return <BackedTokensWrapper>
            This NFT is backed with
            {backed_tokens.map((t, index) => (
                <span key={index}>
                    {displayBackedAmount(t, index, backed_tokens)}
                </span>
            ))}.
        </BackedTokensWrapper> 
    }   
}

const DetailsModal = (props) => {
    const nft = props.nft;
    const setShowDetailsModal = props.setShowDetailsModal;
    const detailToShow = props.detailToShow;
    let backedData = props.backedData;
    const wharfSession=  props.wharfSession;
    const enterModalText= props.enterModalText;
    const enterModalDisplay= props.enterModalDisplay;
    const loadingDisplay= props.loadingDisplay;
    const setEnterModalText= props.setEnterModalText;
    const setEnterModalDisplay= props.setEnterModalDisplay;
    const setLoadingDisplay= props.setLoadingDisplay;    
    let location = props.location;
    if(!location){
        location = '';
    }
    if(!backedData){
        backedData = [];
    }

    const [understands, setUnderstands] = useState(false);
    const [showProductPopup, setShowProductPopup] = useState(false);
    const [chosenProduct, setChosenProduct] = useState(null);

  return (
        <DetailsModalWrapper>

            <div style={{width: "auto", marginLeft: 'auto', marginTop: '10px', marginBottom: '10px', textAlign: "right", paddingRight: "15px", fontSize: "22px", fontWeight: "400", color: theme2024.textMain}}><button
            onClick={() => {
                setShowDetailsModal(false);
              }}            
            >X</button></div>   


        {detailToShow && detailToShow == "backed tokens" && (
            <>
            {showBackedTokens(nft, backedData)}
            <br/>
            {location && (isActionable(location)) && (
                <>
                    <BackedTokensWrapper>
                        Please confirm that you understand that by redeeming this NFT for backed tokens,{" "}
                        you will be <span style={{color: theme2024.danger, fontWeight: 600}}>PERMANENTLY DESTROYING</span> the NFT.
                    </BackedTokensWrapper>
                    <br/>
                    <ConfirmUnderstandingWrapper
                        understands={understands}
                    >
                    <button
                        onClick={() => {
                            setUnderstands((prev) => !prev);
                        }}            
                    >
                    {understands && checked_svg}    
                    </button> I UNDERSTAND
                    </ConfirmUnderstandingWrapper>
                    <br/>
                    <CreateLockButton
                        disabled={!understands}
                        onClick={() => {
                            setShowDetailsModal(false);
                            burnNft(
                                nft?.asset_id,
                                true,
                                setEnterModalDisplay,
                                setEnterModalText,
                                setLoadingDisplay,
                                wharfSession
                            )
                        }}
                    >
                    {understands ? 'REDEEM BACKED TOKENS' : 'MISSING CONFIRMATION'}
                    </CreateLockButton>              
                </>
            )}
            {!isActionable(location) && currentUsername && nft?.owner && currentUsername == nft.owner && 
                <BackedTokensWrapper>
                    You can redeem the backed tokens from this NFT on the <a href={`${currentWebsiteURL}/wallet`}>wallet page</a>.
                </BackedTokensWrapper>
            }            
            </>
        )}



        {detailToShow && detailToShow == "whitelist" && (
            <>
            <BackedTokensWrapper>
                This NFT can be redeemed for whitelist access to certain WaxDAO products.{" "}
                It is important for you to understand that by redeeming this NFT for whitelisting,{" "}
                you <b>will not get the backed tokens</b> from the NFT.
                <br/><br/>
                You should consider checking the{" "}
                <a href={`${currentWebsiteURL}/tokens/WAXDAO@token.waxdao`} target="none">price of the WAXDAO token</a> and determining if it's smarter to{" "}
                use backed tokens for whitelisting instead.
            </BackedTokensWrapper>
            <br/>
            {location && (isActionable(location)) && (
                <>
                    <ChooseProductButton
                        onClick={() => {setShowProductPopup((prev) => !prev)}}
                    >
                            {chosenProduct ? chosenProduct : "CHOOSE PRODUCT"}
                            <ChooseProductPopup visible={showProductPopup}>
                                <ProductButton
                                    onClick={() => {
                                        setChosenProduct("TOKEN CREATOR")
                                    }}
                                >
                                TOKEN CREATOR
                                </ProductButton>
                                <ProductButton
                                    onClick={() => {
                                        setChosenProduct("ROYALTY CONTRACT")
                                    }}                        
                                >
                                ROYALTY CONTRACT
                                </ProductButton>                        
                            </ChooseProductPopup>
                    </ChooseProductButton>
                    <br/>
                    <CreateLockButton
                        disabled={!chosenProduct}
                        onClick={() => {
                            setShowDetailsModal(false);
                            getWhitelisted(
                                nft?.asset_id,
                                chosenProduct,
                                setEnterModalDisplay,
                                setEnterModalText,
                                setLoadingDisplay,
                                wharfSession
                            )
                        }}
                    >
                    {chosenProduct ? 'WHITELIST ME' : 'NO PRODUCT CHOSEN'}
                    </CreateLockButton>              
                </>
            )}

            {!isActionable(location) && currentUsername && nft?.owner && currentUsername == nft.owner && 
                <BackedTokensWrapper>
                    You can redeem this NFT for whitelisting on the <a href={`${currentWebsiteURL}/wallet`}>wallet page</a>.
                </BackedTokensWrapper>
            }
            </>
        )}

     
        </DetailsModalWrapper>
  )
}

export default DetailsModal