import styled, { createGlobalStyle } from 'styled-components'
import { theme2024 } from './Styles2024';

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  body {
    font-family: 'Exo', sans-serif;
  }
`

export const theme = {
  main: "#1F2A44",
  mainDarker: "#151f36",
  secondary: "#01B1AE",
  secondaryHover:"#1f9290",
  textMain: "white",
  textSecondary: "#BCBCBC",
  gradientLeft: "#5BBBEB",
  gradientRight: "#F8BBEB",
  //font: "'Tektur', sans-serif",
};

export const currentTheme = {
    main: "#47158e",
    secondary: "#c30000",
    secondaryHover:"#dc0bb6",
    textMain: "white",
  };

  export const testTheme = {
    mainDarkest: "#210240",
    mainDarker: "#2c0b4e",
    mainDark: "#310859",
    mainLight: "#340773",
    secondary: "#D91E75",
    secondaryHover:"#A066F2",
    lightBlue: "#3574F2",
    textMain: "white",
    textSecondary: "#BCBCBC",
  };


export const MainWrapper = styled.div`
  position: relative;
  display: flex;
  background-color: ${theme2024.backgroundDark};
  

`


export const Body = styled.div`
    min-height: 80vh;
    padding: 0px;
    background-color: ${theme2024.backgroundDark};
`


export const CloseModalButton = styled.button`
  width: 200px;
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 900;
  border: 1px solid white;
  margin-top: 1em;

  @media (max-width: 500px) {
      font-size: 14px;
  }

  color: ${currentTheme.textMain};

  :hover{
      background-color: ${currentTheme.textMain};
      color: ${currentTheme.main};
      transition: background-color 1s;
  }
`

export const ExploreFiltersCont = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: ${theme.mainDarker};
    color: ${theme.textSecondary};
    height: auto;
    max-height: 300px;
    color: ${theme.textMain};
    padding-bottom: 1em;
    border-radius: 7px;
    padding-top: 1em;
    margin-top: 25px;

    h2{
        font-size: 18px;
        font-weight: 900;
        padding-left: 20px;
    }

    p{
        color: ${theme.textSecondary};
        font-size: 14px;
        font-weight: 500;
        padding-left: 20px;
        padding-top: 1em;
    }

    a{
        color: ${theme.secondary}
    }    

    @media (max-width: 900px) {
        position: unset;
        width: 100%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
`

export const ExploreFiltersInnerCont = styled.div`
    position: relative;
    width: 96%;
    background-color: ${theme.main};
    color: ${theme.textSecondary};
    height: auto;
    max-height: 300px;
    color: ${theme.textMain};
    padding: 1em;
    //margin-top: 25px;
    margin-left: 2%;
    border-radius: 5px;



    h2{
        font-size: 18px;
        font-weight: 900;
        padding-left: 20px;
    }

    h3{
        font-size: 14px;
        font-weight: 900;
        //padding-left: 20px;
        margin-bottom: 5px;
    }

    p{
        color: ${theme.textSecondary};
        font-size: 14px;
        font-weight: 500;
        padding-left: 20px;
        padding-top: 1em;
    }

    input{
        border-radius: 0px;
        padding: 0.5em;

    }

`

export const ExploreFiltersScrollCont = styled.div`
    overflow-y: scroll;
    margin-top: 15px;
    max-height: 180px;
    width:95%;
`

export const SkillsButton = styled.button`
    width: auto;
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid ${props => props.selectedList?.indexOf(props.thisSkill) > -1 ? theme.textMain : theme.textSecondary };
    color: ${props => props.selectedList?.indexOf(props.thisSkill) > -1 ? theme.textMain : theme.textSecondary };
    margin-top: 10px;
    margin-left: 20px;
    font-size: 14px;
    border-radius: 15px;
    background-color: ${props => props.selectedList?.indexOf(props.thisSkill) > -1 ? theme.secondary : "transparent" };

    :hover{
        background-color: ${props => props.selectedList?.indexOf(props.thisSkill) > -1 ? theme.secondaryHover : theme.textMain };
        color: ${props => props.selectedList?.indexOf(props.thisSkill) > -1 ? theme.textSecondary : theme.main };
        transition: background-color 1s;       
    }
`

export const MainButton = styled.button`

    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 10px;
    color: ${theme.textMain};
    background-color: ${theme.secondary};

    :hover{
        background-color: ${theme.secondaryHover};
        transition: background-color 1s;
    }
`

export const SmallButton = styled.button`

    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 0.7px;

    color: ${theme2024.textMain};
    background-color: ${theme2024.hoverColor};
    transition: background-color 0.5s;

    :hover{
        background-color: ${theme2024.primary};
    }
`

export const ViewPoolButton = styled.button`
    //display: inline-block;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
    color: ${theme2024.primary};
    border: 3px solid ${theme2024.primary};
    //background-color: ${theme.secondary};

    :hover{
        background-color: #dfdcdc40;
        color: ${theme.textMain};
        transition: background-color 0.5s;
        transition: color 0.5s;
    }

`

export const LockedPoolButton = styled.button`
    //display: inline-block;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
    color: indianred;
    border: 3px solid indianred;


`

export const FoldersContainer = styled.div`
    width: 100%;
    margin-top: 15px;
`

export const FoldersRow = styled.div`
    width: 100%;
    height: 30px;
    font-size: 14px;
    @media (max-width: 768px) {
        font-size: 12px;
    }

    @media (max-width: 450px) {
        font-size: 10px;
    }

    @media (max-width: 375px) {
        font-size: 8px;
    }
`

export const SingleFolderTab = styled.button`
    display: inline-block;
    width: 200px;
    border-bottom: 2px solid ${props => props.selected == true ? theme.secondary : theme.textSecondary };
    height: 30px;
    color: ${props => props.selected == true ? theme.secondary : theme.textSecondary };
    font-weight: 500;

    :hover{
        color: ${theme.textMain};
    }

    @media (max-width: 768px) {
        width: 50%;
    }

    
`

export const SingleFolderThird = styled.button`
    display: inline-block;
    width: 200px;
    border-bottom: 2px solid ${props => props.selected == true ? theme.secondary : theme.textSecondary };
    height: 30px;
    color: ${props => props.selected == true ? theme.secondary : theme.textSecondary };
    font-weight: 500;

    :hover{
        color: ${theme.textMain};
    }

    @media (max-width: 768px) {
        width: 33%;
    }
`

export const SingleFolderFourth = styled.button`
    display: inline-block;
    width: 175px;
    border-bottom: 2px solid ${props => props.selected == true ? theme.secondary : theme.textSecondary };
    height: 30px;
    color: ${props => props.selected == true ? theme.secondary : theme.textSecondary };
    font-weight: 500;

    :hover{
        color: ${theme.textMain};
    }

    @media (max-width: 768px) {
        width: 25%;
    }
`

export const NewListingCont = styled.div`
    width: 100vw;
    max-width: 100%;
    min-height: 900px;
    margin-left: 0px; 
    margin-right: 0px;
    margin-top: 70px;
    background-color: ${theme.main};
    padding-bottom: 2em;
    padding-top: 50px;
    color: ${theme.textMain};
    word-wrap: break-word;

    @media (max-width: 900px) {
        height: auto;
    }

`

export const NewListingBody = styled.div`
    width: 95%;
    margin-left: auto;
    margin-right: auto;

    h2{
        font-size: 18px;
        font-weight: 900;
    }

    p{
        margin-top: 1em;
    }

    input{
        border-radius: 10px;
        background-color: ${theme.main};
        border: 1px solid ${theme.textSecondary};
        padding-left: 0.5em;
        max-width: 90%;

        :focus{
            outline:none;
        }
    }

    textarea{
        width: 90%;
        height: 250px;
        resize: none;
        border-radius: 10px;
        background-color: ${theme.main};
        border: 1px solid ${theme.textSecondary};
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 0.5em;
        :focus{
            outline:none;
        }
    }

    @media (max-width: 900px) {
        height: auto;
    }

`

export const DarkerPageContainer = styled.div`
    width: 100vw;
    max-width: 100%;
    min-height: 900px;
    margin-left: 0px; 
    margin-right: 0px;
    margin-top: 70px;
    background-color: ${theme.mainDarker};
    padding-bottom: 2em;
    padding-top: 20px;
    color: ${theme.textMain};
    word-wrap: break-word;

    h2{
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        font-size: 24px;
    }

    @media (max-width: 900px) {
        height: auto;
    }

`

export const DarkerPageDiv = styled.div`
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    background-color: ${theme.main}
    padding: 1em;
    border-radius: 10px;
    margin-bottom: 30px;

    h2{
        font-size: 18px;
        font-weight: 900;
    }

    p{
        margin-top: 1em;
    }

    input{
        border-radius: 10px;
        background-color: ${theme.main};
        border: 1px solid ${theme.textSecondary};
        padding-left: 0.5em;
        max-width: 90%;

        :focus{
            outline:none;
        }
    }

    textarea{
        width: 90%;
        height: 250px;
        resize: none;
        border-radius: 10px;
        background-color: ${theme.main};
        border: 1px solid ${theme.textSecondary};
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 0.5em;
        :focus{
            outline:none;
        }
    }

    @media (max-width: 900px) {
        height: auto;
    }

`

export const DarkerPageTableHeader = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${theme.textMain};
    border-bottom: 0.5px solid ${theme.textSecondary};
    padding: 1em;
`

export const DarkerPageTableRow = styled.div`
    color: ${theme.textSecondary};
    font-weight: 200;
    font-size: 16px;
    border-bottom: 0.5px solid ${theme.textSecondary};
    padding: 1em;
`




export const JobOfferTitle = styled.div`
    position: relative;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    width: 100%;
    padding-left: 10px;
    padding-bottom: 10px;

    button{
        position: absolute;
        right: 15px;
        color: ${theme.secondary};
    }
`

export const MilestoneContainer = styled.div`
    background-color: ${theme.mainDarker};
    padding: 10px;
    padding-left: 15px;
    border-radius: 10px;
    margin-top: 25px;
    box-shadow: 0px 1px 3px 1px ${theme.secondaryHover};
    
`

export const FarmDescriptionContainer = styled.div`
    overflow-y: scroll;
    max-height: 180px;
    background-color: ${theme.mainDarker};
    padding: 10px;
    padding-left: 15px;
    border-radius: 10px;
    margin-top: 25px;
    box-shadow: 0px 1px 3px 1px ${theme.secondaryHover};
    
`

export const MilestoneDescription = styled.div`
    color: ${theme.textSecondary};
    li{
        margin-left: 2em;
    }
    a{
        color: ${theme.secondary}
    }
`

export const MilestonePrice = styled.div`
    color: ${theme.textSecondary};
    font-size: 12px;
    margin-top: 10px;
`


export const TallFiltersCont = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: ${theme.mainDarker};
    color: ${theme.textSecondary};
    height: auto;
    color: ${theme.textMain};
    padding-bottom: 1em;
    border-radius: 7px;
    padding-top: 1em;
    margin-top: 25px;

    h2{
        font-size: 18px;
        font-weight: 900;
        padding-left: 20px;
        padding-right: 10px;
    }

    p{
        color: ${theme.textSecondary};
        font-size: 14px;
        font-weight: 500;
        padding-left: 20px;
        padding-top: 1em;
    }

    a{
        color: ${theme.secondary}
    }

    @media (max-width: 900px) {
        position: unset;
        width: 100%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

`

export const TallFiltersInnerCont = styled.div`
    position: relative;
    width: 96%;
    background-color: ${theme.main};
    color: ${theme.textSecondary};
    height: auto;
    color: ${theme.textMain};
    padding: 1em;
    //margin-top: 25px;
    margin-left: 2%;
    border-radius: 5px;



    h2{
        font-size: 18px;
        font-weight: 900;
        padding-left: 20px;
    }

    h3{
        font-size: 14px;
        font-weight: 900;
        //padding-left: 20px;
        margin-bottom: 5px;
    }

    p{
        color: ${theme.textSecondary};
        font-size: 14px;
        font-weight: 500;
        padding-left: 20px;
        padding-top: 1em;
    }


    input{
        border-radius: 10px;
        background-color: ${theme.main};
        border: 1px solid ${theme.textSecondary};
        padding: 0.5em;
        max-width: 90%;

        :focus{
            outline: none;
            box-shadow: 0px 0px 2px 2px white;
        }
    }

    textarea{
        border-radius: 10px;
        background-color: ${theme.main};
        border: 1px solid ${theme.textSecondary};
        padding: 0.5em;
        max-width: 90%;

        :focus{
            outline: none;
            box-shadow: 0px 0px 2px 2px white;
        }
    }    

    select{
        width: 200px;
        background-color: ${theme.mainDarker};
        height: 45px;
    }

    a{
        color: ${theme.secondary};
    }

    ul{
        padding-top: 1em;
        padding-left: 3.5em;
        list-style-type: disc;
    }

`

export const RequiredNftsContainer = styled.div`
    width: 95%;
    display: flex;
    gap: 20px;
    padding-bottom: 10px;
    height: 420px;
    margin-bottom: 15px;

    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: scrollbar;  /* IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: ${theme.secondary} black; 

    ::-webkit-scrollbar{
        height: 4px;
    }

    ::-webkit-scrollbar-thumb{

        background: ${theme.secondary};
    }

    ::-webkit-scrollbar-track{

        background: #dfdcdc40;
    }
`


export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Slider = styled.div`
  width: 50px;
  height: 25px;
  background-color: ${(props) => (props.toggled ? theme.secondary : theme.textSecondary)};
  border-radius: 25px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.5s ease;
`;

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.5s ease;
  left: ${(props) => (props.toggled ? 'calc(100% - 12px)' : '12px')};
`;

