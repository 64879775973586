import { useState, useRef } from "react";
import { currentApiList, getCurrentApiList } from "../../data/config";
import axios from 'axios';

export const useGetDropsByCreator = () => {
    
    const [myDrops, setMyDrops] = useState([]);
    const [myPacks, setMyPacks] = useState([]);
    const [dropsAreLoading, setDropsAreLoading] = useState(true);
    const apiIndex = useRef(0);

    const getDropsByCreator = (UserName) => {
        if (apiIndex.current >= currentApiList.length) {
            console.error("All API requests failed");
            return;
        }
        
        axios.post(`${currentApiList[apiIndex.current]}/v1/chain/get_table_rows`,{
            table:"drops",
            scope:"waxdaomarket",
            code:"waxdaomarket",
            reverse: true,
            key_type: 'name',
            index_position: '2',
            lower_bound: UserName,
            upper_bound: UserName,
            limit:1000,
            json:true
            }).then((res) => {
                if(res.data.rows.length > 0){
                    let i = 0;
                    let drop_list = [];
                    let pack_list = [];
                    while(i < res.data.rows.length){
                        if(res.data.rows[i].drop_type != "premint.pack"){
                            drop_list.push(res.data.rows[i])
                        }else{
                            pack_list.push(res.data.rows[i])
                        }
                        i++;
                    }
                    setMyDrops(drop_list);
                    setMyPacks(pack_list);

                    console.log(res.data.rows);
                }
            }).catch((error) => {
                console.error(error);
                apiIndex.current++;
                getDropsByCreator(UserName);
            }).finally(() => setDropsAreLoading(false))
    }

    return [myDrops, myPacks, getDropsByCreator, dropsAreLoading]
}
