import React from 'react'
import { FarmCard2024Avatar, FarmCard2024DetailsRow, FarmCard2024DetailsWrapper, FarmCard2024Name, FarmCard2024Separator, FarmCard2024Tags, FarmCard2024Wrapper } from '../pages/farmsv2/FarmStyles2024'
import { buildIpfsUrl } from '../data/functions/global_functions';
import { isAnOfficialFarm, isAnOfficialFarm2024 } from '../data/functions/farm_functions';
import { currentWebsiteURL } from '../data/config';
import { theme2024 } from '../Styles2024';

const showFarmApr = (farm) => {
    if(farm.stk_symbol == farm.reward_symbol && farm.stk_contract == farm.reward_contract){
        let hourlyPool = Number(farm?.hourly_reward?.split(' ')[0])
        let totalStaked = Number(farm.total_staked?.split(' ')[0])
        let apr = (hourlyPool / totalStaked) * 8760 * 100;
        return <span style={{color: theme2024.success}}>{`${apr.toFixed(2)}% APR`}</span>
    }

    return `Unknown APR`
}

const buildFarmUrl = (farm) => {
    return `${currentWebsiteURL}/pool/${farm.poolname}`;
}

const TokenFarmCard2024 = (props) => {
    const farm = props.farm;
    const calculatedHeight = 160;
    const height = Math.max(calculatedHeight, 180);

  return (
    <FarmCard2024Wrapper height={height} href={buildFarmUrl(farm)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g id="_19-Arrow_Right" data-name="19-Arrow Right"><path d="M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm0,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30Z"/><path d="M26.71,15.29l-7-7L18.29,9.71,23.59,15H5v2H23.59l-5.29,5.29,1.41,1.41,7-7A1,1,0,0,0,26.71,15.29Z"/></g></svg>
        <FarmCard2024Avatar src={buildIpfsUrl(farm?.logo, 150)} />
        <FarmCard2024Name>{farm?.poolname}</FarmCard2024Name>
        <FarmCard2024Tags>
            V1, {showFarmApr(farm)}
        </FarmCard2024Tags>
        <FarmCard2024Separator />
        <FarmCard2024DetailsWrapper>
            <FarmCard2024DetailsRow><h3>STAKE</h3><h4>{farm?.stk_symbol?.split(',')[1]}</h4></FarmCard2024DetailsRow>
            <FarmCard2024DetailsRow><h3>EARN</h3><h4>{farm?.reward_symbol?.split(',')[1]}</h4></FarmCard2024DetailsRow>
            <FarmCard2024DetailsRow><h3>ENDS</h3><h4>{new Date(farm.enddate * 1000).toLocaleDateString()}</h4></FarmCard2024DetailsRow>
        </FarmCard2024DetailsWrapper>

    </FarmCard2024Wrapper>
  )
}

export default TokenFarmCard2024