import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  ModalOverlay,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  Modal,
  CloseWindow,
} from "../../components/LeftDiv";
import ".././datepicker.css";
import {
  currentWebsiteURL,
  mainnetWebsiteURL,
} from "../../data/config";

import {
  MainButton,
  MilestoneContainer,
  NewListingBody,
  NewListingCont,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import { createFarmsDotWaxDaoFarm, handleAddRewardToken, handleRemoveRewardToken, handleRewardTokenValueChange } from "./v2_farm_functions";
import WojakCard from "../../components/WojakCard";
import { getWojakNfts } from "../daosv2/dao_v2_functions";
import farm_warning from "../../data/farm_warning.png"
import { useStateContext } from "../../contexts/ContextProvider";
import { WOJAK_SECONDARY_LINK } from "../../data/constants";
import { useGetDynamicRatio } from "../../components/CustomHooks/useGetDynamicRatio";
import { Modal2024, ModalContent2024, ModalOverlay2024, theme2024 } from "../../Styles2024";
import { getWaxdaoPrice } from "../TokenCreator";

const CreateFarmV2 = () => {
  const { wharfSession } = useStateContext();

  const [loadingDisplay, setLoadingDisplay] = useState("none");
  const [farmName, setFarmName] = useState("");
  const [avatar, setAvatar] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [description, setDescription] = useState("");
  const [rewardTokens, setRewardTokens] = useState([]);
  const [hoursBetweenPayouts, setHoursBetweenPayouts] = useState("");
  const [farmType, setFarmType] = useState("");

  const [website, setWebsite] = useState("");
  const [telegram, setTelegram] = useState("");
  const [discord, setDiscord] = useState("");
  const [twitter, setTwitter] = useState("");
  const [medium, setMedium] = useState("");
  const [youtube, setYoutube] = useState("");
  const [atomichub, setAtomichub] = useState("");
  const [waxdao, setWaxdao] = useState("");  
  const [paymentMethod, setPaymentMethod] = useState("");
  const [myWojaks, setMyWojaks] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState("");
  const [confirmedWatchingVideo, setConfirmedWatchingVideo] = useState(false);
  const [confirmText, setConfirmText] = useState("");

  const [enterButtonsDisplay, setEnterButtonsDisplay] = useState("");
  const [enterModalDisplay, setEnterModalDisplay] = useState("hidden");
  const [enterModalText, setEnterModalText] = useState("Awaiting signature...");
  const [ratio, getDynamicRatio, ratioIsLoading] = useGetDynamicRatio();

 
  useEffect(() => {
   let isMounted = true;
   if (isMounted && !ratio?.next_push) {
       getDynamicRatio();
   }
 
   const interval = setInterval(() => {
       if (isMounted && ratio && new Date().getTime() > ratio.next_push * 1000) {
           getDynamicRatio();
       }
   }, 5000); 
 
   return () => {
       clearInterval(interval);
       isMounted = false;
   };
 }, [ratio, getDynamicRatio]);

  return (
    <div id="seo">
      <Helmet>
        <title>Create NFT Farm - WaxDAO</title>
        <meta
          name="description"
          content="Launch your own NFT farm on Wax. Your community will be able to stake your NFTs and earn passive income. "
        />
        <link rel="canonical" href={`${mainnetWebsiteURL}/v2/create-farm`} />
      </Helmet>

        <ModalOverlay2024 className={enterModalDisplay} />
        <Modal2024 className={enterModalDisplay}>
        <div style={{width: "100%", textAlign: "right", paddingRight: "5px", fontSize: "22px", fontWeight: "400", color: theme2024.textMain}}><button
            onClick={() => {
                setEnterModalDisplay("hidden");
              }}            
            >X</button></div>              
          <ModalContent2024>{enterModalText}</ModalContent2024>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>
        </Modal2024>

        <NewListingCont>
          <NewListingBody>
            <TallFiltersCont>
              <h2>V2 Farm Creator</h2>
              <br />
              <TallFiltersInnerCont>
                We've launched a brand new non-custodial farm contract with loads of new features!
                <br />
                <br />
                Check out our{" "}
                <a href={`${currentWebsiteURL}/v2/farm-faq`} target="none">
                  FAQ page
                </a> for more info
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <MilestoneContainer>
              <b>NOTE:</b> Be aware that in order for you to add stakable assets to this farm,{" "}
              you must be authorized on the NFT collection(s).
            </MilestoneContainer>

            <span className={confirmedWatchingVideo && "hidden"}>
            <TallFiltersCont>
              <div style={{width: "90%"}} className="ml-auto mr-auto">
              <MilestoneContainer>
              <b>VERY IMPORTANT!!!!!</b>{" "}
              You must confirm that you understand how these new farms work before you are allowed to create one.
              Watch the following video and then enter "I understand how the new farms work" (without quotes) into the 
              box below. Once you do that, the farm creation form will magically appear.
              <br/><br/>
              <a href="https://youtu.be/PIV_ojHzkS8" target="none">
              <img src={farm_warning} style={{width: "288px", height: "162px"}} />
              </a>
              </MilestoneContainer>
              <br/>
              <TallFiltersInnerCont>
                <h3>Confirm</h3>
                <textarea 
                  placeholder="Enter 'I understand how the new farms work' without quotes (case sensitive)"
                  value={confirmText}
                  onChange={(e) => {
                    setConfirmText(e.target.value)
                    if(e.target.value == "I understand how the new farms work"){
                      setConfirmedWatchingVideo(true);
                    }
                  }}
                />
              </TallFiltersInnerCont>
              </div>
            </TallFiltersCont>
            </span>


            {confirmedWatchingVideo && (
              <span>
            <TallFiltersCont>
              <h2>Farm Info</h2>
              <br />

              <TallFiltersInnerCont>
                <h3>Farm Name</h3>
                <input
                  type="text"
                  maxLength={12}
                  id="farmName"
                  placeholder="e.g. mynewnftfarm"
                  value={farmName}
                  onChange={(e) => {
                    setFarmName(e.target.value.toLowerCase());
                  }}
                />
                <br />
                <br />

                <h3>Avatar Image IPFS Hash</h3>
                <input
                  type="text"
                  maxLength={150}
                  placeholder="e.g. QMasdas..."
                  value={avatar}
                  onChange={(e) => {
                    setAvatar(e.target.value);
                  }}
                />
                <br />
                <br />  

                <h3>Cover Image IPFS Hash</h3>
                <input
                  type="text"
                  maxLength={150}
                  placeholder="e.g. QMasdas..."
                  value={coverImage}
                  onChange={(e) => {
                    setCoverImage(e.target.value);
                  }}
                />
                <br />
                <br />                                

                <h3>Description</h3>
                <textarea
                  type="text"
                  maxLength={1500}
                  placeholder="Provide some info about your farm"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                <br />
                <br />   

                <h3>Hours Between Payouts</h3>
                <input
                  type="text"
                  maxLength={3}
                  placeholder="e.g. 1"
                  value={hoursBetweenPayouts}
                  onChange={(e) => {
                    setHoursBetweenPayouts(e.target.value);
                  }}
                />
                <br />
                <span className="text-sm italic">
                  Enter a number between 1-720
                </span>
                <br />   
                <br/>

                <h3>Farm Type</h3>
                <select
                  onChange={(e) => {
                    setFarmType(e.target.value)
                  }}
                >
                <option value="" hidden>Choose</option>
                <option value="0">Full Collections</option> 
                <option value="1">Full Schemas</option>
                <option value="2">Template IDs</option>  
                <option value="3">Attributes</option>                  
                </select>
                <br />   
                <br/>                                
                
              </TallFiltersInnerCont>

            </TallFiltersCont>

            <TallFiltersCont>
                <h2>Reward Tokens</h2>
            </TallFiltersCont>
                  <MilestoneContainer>
                    You can reward people with up to 3 different tokens when they stake{" "}
                    in your farm.
                  </MilestoneContainer>

            {rewardTokens.length > 0 && rewardTokens.map((item, index) => (
              <TallFiltersCont key={index}>
                <h2>Reward Token {index + 1}</h2>
                <br/>
                <TallFiltersInnerCont>
                <h3>Token Name</h3>
                <input 
                  placeholder="e.g. WAX"
                  name="token_name"
                  value={item.token_name}
                  onChange={(e) => {
                    handleRewardTokenValueChange(e, index, rewardTokens, setRewardTokens);
                  }}
                  maxLength={7}
                />
                <br/><br/>

                <h3>Decimal Places</h3>
                <input 
                  placeholder="e.g. 8"
                  name="precision"
                  value={item.precision}
                  onChange={(e) => {
                    handleRewardTokenValueChange(e, index, rewardTokens, setRewardTokens);
                  }}
                  maxLength={2}
                />
                <br/><br/>        

                <h3>Token Contract</h3>
                <input 
                  placeholder="e.g. eosio.token"
                  name="contract"
                  value={item.contract}
                  onChange={(e) => {
                    handleRewardTokenValueChange(e, index, rewardTokens, setRewardTokens);
                  }}
                  maxLength={13}
                />
                <br/><br/>                           
                </TallFiltersInnerCont>
                  <div className="text-center mt-6 w-100 justify-center items-center">
                    <MainButton
                      onClick={() => {
                        handleRemoveRewardToken(setRewardTokens, rewardTokens, index)
                      }}
                    >
                      Remove This Token
                    </MainButton>
                  </div>
              </TallFiltersCont>
            ))}

            {rewardTokens.length < 3 && (
                <TallFiltersCont>
              <div className="text-center w-100 pt-3">

                <MainButton
                  onClick={() => {
                    handleAddRewardToken(setRewardTokens, rewardTokens);
                  }}
                >
                  Add Reward Token
                </MainButton>
          
              </div>                    
                </TallFiltersCont>
            )}      


            <TallFiltersCont>
              <h2>Socials (Optional)</h2>
              <br />

              <TallFiltersInnerCont>
              <h3>Website</h3>
              <input 
              placeholder="e.g. my-site.com"
              value={website}
              maxLength={140}
              onChange={(e) => setWebsite(e.target.value)}               
              />
              <br/><br/> 

              <h3>Telegram</h3>
              <input 
              placeholder="e.g. t.me/hoodpunks"
              value={telegram}
              maxLength={140}
              onChange={(e) => setTelegram(e.target.value)}               
              />
              <br/><br/>   

              <h3>Discord</h3>
              <input 
              placeholder="e.g. discord.gg/my-server"
              value={discord}
              maxLength={140}
              onChange={(e) => setDiscord(e.target.value)}               
              />
              <br/><br/>                                 

              <h3>Twitter</h3>
              <input 
              placeholder="e.g. twitter.com/MikeD_Crypto"
              value={twitter}
              maxLength={140}
              onChange={(e) => setTwitter(e.target.value)}               
              />
              <br/><br/>   

              <h3>Medium</h3>
              <input 
              placeholder="e.g. medium.com/my-blog"
              value={medium}
              maxLength={140}
              onChange={(e) => setMedium(e.target.value)}               
              />
              <br/><br/>    

              <h3>YouTube</h3>
              <input 
              placeholder="e.g. youtube.com/my-channel"
              value={youtube}
              maxLength={140}
              onChange={(e) => setYoutube(e.target.value)}               
              />
              <br/><br/>                              

              <h3>AtomicHub</h3>
              <input 
              placeholder="e.g. atomichub.io/some-link"
              value={atomichub}
              maxLength={140}
              onChange={(e) => setAtomichub(e.target.value)}               
              />
              <br/><br/>   

              <h3>WaxDAO</h3>
              <input 
              placeholder="e.g. waxdao.io/farm/myfarm"
              value={waxdao}
              maxLength={140}
              onChange={(e) => setWaxdao(e.target.value)}               
              />
              <br/><br/>   
                       

              <h3>Payment Method</h3>
                <select
                  onChange={(e) => {
                    setPaymentMethod(e.target.value);
                    if(e.target.value == "Burn 1 Wojak NFT"){
                      getWojakNfts(myWojaks, setMyWojaks);
                    }
                  }}
                >
                  <option value="" hidden>
                    Choose
                  </option>
                  <option value="Pay 250 Wax">Pay 250 Wax</option>
                  <option value="Pay WAXDAO">Pay {Number(getWaxdaoPrice(250, ratio)).toLocaleString('en')} WAXDAO</option>
                  <option value="Burn 1 Wojak NFT">Burn 1 Wojak NFT</option>
                </select>
                <br />
                <br />

                <span
                  className={paymentMethod != "Burn 1 Wojak NFT" && "hidden"}
                >                  
                  <TallFiltersCont>
                    <div className="pl-4 pr-4">
                      Select the Wojak NFT you want to burn.
                      <br />
                      <br />
                      If you don't have any Wojaks, you can buy them{" "}
                      <a href={WOJAK_SECONDARY_LINK} target="none">on secondary.</a>
                    </div>
                  </TallFiltersCont>
                  <br />
                  <br />
                  <div className="w-100 flex flex-wrap justify-center gap-4 text-center">
                  {myWojaks.length >= 1 ? myWojaks.map((item, index) => (
                    <WojakCard item={item} selectedAsset={selectedAsset} setSelectedAsset={setSelectedAsset} />
                  )) : (<span>You don't have any Wojaks</span>)}
                  <br />
                  <br />
                  </div>
                </span>                
              </TallFiltersInnerCont>

            </TallFiltersCont>


            <TallFiltersCont>
              <div className="text-center w-100 pt-3">

                <MainButton
                  onClick={() => {
                    createFarmsDotWaxDaoFarm(
                      farmName,
                      avatar,
                      coverImage,
                      description,
                      rewardTokens,
                      selectedAsset,
                      paymentMethod,
                      ratio && Number(getWaxdaoPrice(250, ratio)),
                      website,
                      telegram,
                      discord,
                      twitter,
                      atomichub,
                      waxdao,
                      medium,
                      youtube,
                      farmType,
                      hoursBetweenPayouts,
                      setEnterModalText,
                      setLoadingDisplay,
                      setEnterButtonsDisplay,
                      setEnterModalDisplay,
                      wharfSession                   
                    )
                  }}
                >
                  Create Farm
                </MainButton>
          
              </div>
            </TallFiltersCont>                
              </span>
            )}


          </NewListingBody>
        </NewListingCont>
      <br />
      <br />
      <br />
    </div>
  );
};

export default CreateFarmV2;