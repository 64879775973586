import { useState } from "react";
import { currentApiList, currentUsername, getCurrentApiList, getCurrentAtomicApiList } from "../../data/config";
import axios from 'axios';

export const useGetStakedOnlyFarms = () => {
    
    const [myFarms, setMyFarms] = useState([]);
    const [farmsAreLoading, setFarmsAreLoading] = useState(true);
    const [farmsWereLoaded, setFarmsWereLoaded] = useState(false);


    const getStakedOnlyFarms = () => {
        
        axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
            table:"stakebackup",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            key_type: 'name',
            index_position: '2',
            limit:1000,
            lower_bound:currentUsername,
            upper_bound:currentUsername,
            json:true
            }).then((res) => {
                setMyFarms(res.data.rows);
                setFarmsWereLoaded(true);
            })
            
            .catch((error) => console.log(error))
            .finally(() => setFarmsAreLoading(false))

    }

    return [myFarms, getStakedOnlyFarms, farmsAreLoading, farmsWereLoaded]
}