import { useState } from "react";
import { currentApiList, v2DAOContract, v2FarmContract } from "../../data/config";
import axios from 'axios';
import { getPrecisionFromAsset, getTokenNameFromAsset } from "../../data/functions/pool_functions";

export const useGetV2FarmInfo = () => {
    
    const [farmData, setFarmData] = useState([]);
    const [farmProfile, setFarmProfile] = useState({});
    const [farmSocials, setFarmSocials] = useState({});
    const [farmDataIsLoading, setFarmDataIsLoading] = useState(true);
    const [amountsToAdd, setAmountsToAdd] = useState([]);
    const [amountsToDeposit, setAmountsToDeposit] = useState([]);

    const getFarmData = async (farm) => {
        setFarmDataIsLoading(true);
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "farms",
                    scope: v2FarmContract,
                    code: v2FarmContract,
                    limit: 1,
                    lower_bound: farm,
                    upper_bound: farm,
                    json: true,
                });
                
                if (res.data.rows) {
                    console.log(res)
                    setFarmData(res.data.rows[0]);
                    setFarmProfile(res.data.rows[0].profile);
                    setFarmSocials(res.data.rows[0].socials);
                    let amounts = [];
                    for(let p of res.data.rows[0].reward_pools){
                        amounts.push({amount: 0, contract: p.contract, precision: getPrecisionFromAsset(p.total_funds), symbol: getTokenNameFromAsset(p.total_funds)});
                    }
                    setAmountsToAdd(amounts);
                    setAmountsToDeposit(amounts);
                    console.log(res.data.rows);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }
        setFarmDataIsLoading(false);
    }

    return [farmData, getFarmData, farmDataIsLoading, farmProfile, farmSocials, setFarmProfile, setFarmSocials, amountsToAdd, setAmountsToAdd, amountsToDeposit, setAmountsToDeposit]
}
