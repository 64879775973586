import styled from "styled-components";
import { theme2024 } from "../../Styles2024";

export const SettingsCategoryWrapper = styled.div`
    width: 450px;
    max-width: 98%;
    min-height: 120px;
    border: 1px solid ${theme2024.darkGrey};
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding-bottom: 10px;
`

export const SettingsCategoryTopWrapper = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background-color: ${theme2024.secondaryDarker};
    color: ${theme2024.textMain};
    height: 35px;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid ${theme2024.darkGrey};
    box-sizing: border-box;
    margin-bottom: 10px;

    svg{
        width: 20px;
        height: 20px;
        fill: ${theme2024.primary};
    }
`

export const SettingsCategoryRowWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    color: ${theme2024.textMain};
    height: 35px;
    box-sizing: border-box;
    //border-bottom: 1px solid ${theme2024.darkGrey};

    h3{
        color: ${theme2024.textSecondaryDarker};
        padding-left: 10px;
    }

    svg{
        width: 20px;
        height: 20px;
        fill: ${theme2024.primary};
    }
`

export const SettingsCategoryRowRightWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 10px;
`

export const SettingsDropDownButton = styled.div`
    width: 200px;
    position: relative;
    color: ${theme2024.textSecondaryDarker};
    height: 25px;
    border: 1px solid ${theme2024.darkGrey};
    border-radius: 5px;
    display: flex; 
    align-items: center;
    justify-content: center;
    font-size: 14px;
    transition: border 0.3s;

    :hover{
        cursor: pointer;
        border: 1px solid ${theme2024.textMain};
    }
`

export const SettingsDropDownWrapper = styled.div`
    position: absolute;
    top: 25px;
    z-index: 1000;
    border: 1px solid ${theme2024.darkGrey};
    border-radius: 10px;
    background-color: ${theme2024.backgroundDark};
    color: ${theme2024.textSecondaryDarker};
    left: 50%;
    transform: translateX(-50%);
    min-height: 100px;
    width: 200px;
    visibility: ${props => props.open ? 'visible' : 'hidden'};
    opacity: ${props => props.open ? '100%' : '0%'};
    transition: visibility 0s, opacity 0.5s;          
`

export const SettingsDropDownRow = styled.div`
    width: 100%;
    padding: 5px;
    padding-left: 10px;
    transition: color 0.3s, background-color 0.3s;

    :hover{
        background-color: ${theme2024.hoverColor};
        color: ${theme2024.textMain};
    }
`