import { useState } from "react";
import { currentApiList, v2DAOContract } from "../../data/config";
import axios from 'axios';

export const useGetV2DAOInfo = () => {
    
    const [daoData, setDaoData] = useState([]);
    const [daoDataIsLoading, setDaoDataIsLoading] = useState(true);

    const getDaoData = async (dao) => {
        setDaoDataIsLoading(true);
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "daos",
                    scope: v2DAOContract,
                    code: v2DAOContract,
                    limit: 1,
                    lower_bound: dao,
                    upper_bound: dao,
                    json: true,
                });
                
                if (res.data.rows) {
                    console.log(res)
                    setDaoData(res.data.rows[0]);
                    console.log(res.data.rows);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }
        setDaoDataIsLoading(false);
    }

    return [daoData, getDaoData, daoDataIsLoading]
}
