import React, { useState } from 'react'
import { AttributeModalTopLeft, AttributeModalTopMiddle, AttributeModalTopSection, AttributeModalWrapper2024, AttributesWrapper2024, BackedTokensWrapper } from '../utilities/WalletStyles2024'
import { buildNftImage2024 } from '../../data/functions/global_functions';
import { theme2024 } from '../../Styles2024';
import NumberFormat from 'react-number-format';
import { flame_svg, transfer_icon } from '../../data/svgs';

const showTransferableAndBurnableIcons = (nft) => {
    let icons = [];
    if(nft?.is_burnable){
        icons.push(flame_svg)
    }
    if(nft?.is_transferable){
        icons.push(transfer_icon)
    }    

    if(icons.length > 0){
        return <span>
            {icons.map((icon, index) => (
                <span key={index}>
                    {icon}
                </span>
            ))}
        </span>
    }
}

export const getFormattedAmountFromInt64AndPrecision = (token) => {
    if(token.token_precision == 0) return token.amount;

    return token.amount / Math.pow(10, token.token_precision);
}

const displayBackedAmount = (token, index, backed_tokens) => {
    let amt = getFormattedAmountFromInt64AndPrecision(token)
    let start_string;
    if(index === 0){
        start_string = " "
    }
    else if(index > 0 && index + 1 < backed_tokens.length){
        start_string = ", "
    }
    else{
        start_string = " and "
    }

    return(
        <span>
        {start_string}<NumberFormat displayType='text' thousandSeparator={true} value={amt} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />{" "}{token.token_symbol}
        </span>
    )
}

const showBackedTokens = (nft) => {
    let backed_tokens = [];
    if(nft?.collection?.collection_name && nft?.collection?.collection_name == "hoodpunknfts"
        &&
        nft?.schema?.schema_name && nft?.schema?.schema_name == "waxywojaks"
    ){backed_tokens.push({token_contract: 'token.waxdao', token_symbol: 'WAXDAO', token_precision: 8, amount: 2500000000000})}

    if(nft?.backed_tokens?.length > 0){
        for(const tkn of nft.backed_tokens){
            backed_tokens.push(tkn)
        }
    }
    if(backed_tokens.length !== 0){
        return <BackedTokensWrapper>
            This NFT is backed with
            {backed_tokens.map((t, index) => (
                <span key={index}>
                    {displayBackedAmount(t, index, backed_tokens)}
                </span>
            ))}. You can only unlock these tokens{" "}
            by burning (permanently destroying) the NFT.
        </BackedTokensWrapper> 
    }   
}

const AttributeModal2024 = (props) => {
    const nft = props.nft;
    const setShowAttributeModal = props.setShowAttributeModal;

    const [loading, setIsLoading] = useState(true);
  return (
    <AttributeModalWrapper2024 >
        <AttributeModalTopSection>
            <AttributeModalTopLeft>
            {buildNftImage2024(nft.data, setIsLoading)}
            </AttributeModalTopLeft>
            <AttributeModalTopMiddle>
                <h2>
                    <span>{nft?.data?.name && nft?.data?.name}</span>
                    <span>{showTransferableAndBurnableIcons(nft)}</span>
                </h2>
                <h3>{nft?.asset_id && `#${nft.asset_id}`}</h3>
                <h4><b>COLLECTION:</b> {nft?.collection?.collection_name && nft?.collection?.collection_name}</h4>
                <h4><b>SCHEMA:</b> {nft?.schema?.schema_name && nft?.schema?.schema_name}</h4>
                <h4><b>TEMPLATE:</b> {nft?.template?.template_id && nft?.template?.template_id}</h4>
            </AttributeModalTopMiddle>
            <div style={{width: "auto", marginLeft: 'auto', textAlign: "right", paddingRight: "15px", fontSize: "22px", fontWeight: "400", color: theme2024.textMain}}><button
            onClick={() => {
                setShowAttributeModal(false);
              }}            
            >X</button></div>   
        </AttributeModalTopSection>
        {showBackedTokens(nft)}

        
            {Object.entries(nft.data).length > 0 && (
                <AttributesWrapper2024>
                    {Object.entries(nft.data).map(([attr, index]) => (
                        <div key={index}>
                        <h2>
                        {attr}
                        </h2>
                        <h3>
                        {index}
                        </h3>
                        </div>
                    ))}
                </AttributesWrapper2024>
            )}            
        

        {/* {nft?.template?.template_id && JSON.stringify(nft.template)} */}
    </AttributeModalWrapper2024>
  )
}

export default AttributeModal2024