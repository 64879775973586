import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  ModalOverlay,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  Modal,
  CloseWindow,
  CreateFarmIPFS,
} from "../components/LeftDiv";
import "./datepicker.css";
import DatePicker from "react-datepicker";
import {
  currentWebsiteURL,
  mainnetWebsiteURL,
} from "../data/config";
import {
  createFarm, createNonCustodialFarm,
} from "../data/functions/farm_functions";
import { BsInfoCircle } from "react-icons/bs";
import {
  FoldersContainer,
  FoldersRow,
  MainButton,
  NewListingBody,
  NewListingCont,
  SingleFolderThird,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../Styles";
import { handleCalendarChange } from "../data/functions/pack_functions";
import CreateNonCustodial from "../components/CreateNonCustodial";
import { useStateContext } from "../contexts/ContextProvider";
import { buildIpfsUrl } from "../data/functions/global_functions";
import { STANDARD_NFT_IMAGE_WIDTH, WOJAK_SECONDARY_LINK } from "../data/constants";
import { showCautionMessage } from "./token_tracker/tracker_functions";
import { CreateLockButton, FolderTab2024, FoldersContainer2024, HeaderCont2024, Modal2024, ModalContent2024, ModalOverlay2024, PageBody2024, PageWrapper2024, theme2024 } from "../Styles2024";
import { DarkBgTextBox } from "./utilities/PowerupStyles";
import { FoldersRowCentered, LPLockContainer, LockAmountInput } from "./locker/LockStyles";
import { useGetDynamicRatio } from "../components/CustomHooks/useGetDynamicRatio";
import { getWaxdaoPrice } from "./TokenCreator";

const CreateFarm = () => {
  const { wharfSession } = useStateContext();

  const [rewardAmount, setRewardAmount] = useState("0");
  const [loadingDisplay, setLoadingDisplay] = useState("none");
  const [decimals, setDecimals] = useState("");
  const [farmName, setFarmName] = useState("");
  const [rewardToken, setRewardToken] = useState("");
  const [rewardContract, setRewardContract] = useState("");
  const [farmLogo, setFarmLogo] = useState("");
  const [farmType, setFarmType] = useState("Select Farm Type");
  const [paymentMethod, setPaymentMethod] = useState("Payment Method");
  const [schema, setSchema] = useState("");
  const [collection, setCollection] = useState("");
  const [stakingPeriod, setStakingPeriod] = useState("");
  const [rewardTypeValue, setRewardTypeValue] = useState("sharedpool");
  const [rewardType, setRewardType] = useState("");
  const [stakingSeconds, setStakingSeconds] = useState("");
  const [farmTypeValue, setFarmTypeValue] = useState("");
  const [templates, setTemplates] = useState("");

  const [assetToBurn, setAssetToBurn] = useState("");
  const [timestamp, setTimestamp] = useState(Date.now() / 1000);
  const [enterModalDisplay, setEnterModalDisplay] = useState("hidden");
  const [enterModalText, setEnterModalText] = useState("Awaiting signature...");
  
  //noncustodial
  const [custodial, setCustodial] = useState(true);
  const [farmConfig, setFarmConfig] = useState("nc.c.shared");

  //custom hooks
  const [ratio, getDynamicRatio, ratioIsLoading] = useGetDynamicRatio();

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !ratio?.next_push) {
        getDynamicRatio();
    }
  
    const interval = setInterval(() => {
        if (isMounted && ratio && new Date().getTime() > ratio.next_push * 1000) {
            getDynamicRatio();
        }
    }, 5000); 
  
    return () => {
        clearInterval(interval);
        isMounted = false;
    };
  }, [ratio, getDynamicRatio]);  

  return (
    <div id="seo">
      <Helmet>
        <title>Create NFT Farm - WaxDAO</title>
        <meta
          name="description"
          content="Launch your own NFT farm on Wax. Your community will be able to stake your NFTs and earn passive income. "
        />
        <link rel="canonical" href={`${mainnetWebsiteURL}/create-farm`} />
      </Helmet>

      <ModalOverlay2024 className={enterModalDisplay}/>
        <Modal2024 className={enterModalDisplay}>
        <div style={{width: "100%", textAlign: "right", paddingRight: "5px", fontSize: "22px", fontWeight: "400", color: theme2024.textMain}}><button
            onClick={() => {
                setEnterModalDisplay("hidden");
              }}            
            >X</button></div>            
          <ModalContent2024>{enterModalText}</ModalContent2024>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>
        </Modal2024>

            <PageWrapper2024>
              <PageBody2024>
              <HeaderCont2024>
              <div>
              <h2>FARM WIZARD</h2>
              </div>

              <div>
                <h3>
                  Get your NFT farm set up in minutes.
                </h3>
              </div>
            </HeaderCont2024>                             

            <DarkBgTextBox>
              <p>
                Tip: Did you know that you can now set different staking values
                for each template in your farm?
                <br />
                <br />
                For a full tutorial on creating a Version 2 NFT Farm,{" "}
                <a href="https://youtu.be/SFeuzMjHxoc" target="none">
                  watch this video
                </a>
                </p>
                </DarkBgTextBox>


            <DarkBgTextBox>
              <p>
              NOTE: If you are looking for the more advanced V2 farm with unlimited staking, there is{" "}
              <a href={`${currentWebsiteURL}/v2/create-farm`} style={{color: theme2024.primary}}>a different page</a> for that.
              </p>
            </DarkBgTextBox>
            <br/>
            <LPLockContainer>
              <h2>Farm Details</h2>

              <FoldersContainer2024>
                <FoldersRowCentered>
                  <FolderTab2024 selected={!custodial && true}
                   onClick={() => setCustodial(false)}
                  >
                  Non-Custodial
                  </FolderTab2024>
                  <FolderTab2024 selected={custodial && true}
                  onClick={() => setCustodial(true)}
                  >
                  Custodial
                  </FolderTab2024>
                </FoldersRowCentered>
              </FoldersContainer2024>
              <br/>

              {!custodial && 
                <CreateNonCustodial 
                  farmName={farmName} setFarmName={setFarmName} 
                  farmLogo={farmLogo} setFarmLogo={setFarmLogo}
                  rewardToken={rewardToken} setRewardToken={setRewardToken}
                  rewardContract={rewardContract} setRewardContract={setRewardContract}
                  setEnterModalDisplay={setEnterModalDisplay} setEnterModalText={setEnterModalText}
                  decimals={decimals} setDecimals={setDecimals}
                  timestamp={timestamp} setTimestamp={setTimestamp}
                  paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod}
                  assetToBurn={assetToBurn} setAssetToBurn={setAssetToBurn}
                  farmConfig={farmConfig} setFarmConfig={setFarmConfig}
                  ratio={ratio}
              />}

              {custodial && (
              <LockAmountInput wide={true} allowResize={true}>
                {showCautionMessage(
                <span>
                  Custodial means that the NFTs will be locked in our smart contract when users stake. If you want the NFTs to remain in the user's wallet so they can freely trade/transfer them, use the Non-Custodial option.
                </span>                  
                )}
                <h3>Farm Name</h3>
                <input
                  type="text"
                  maxLength={12}
                  id="farmName"
                  placeholder="e.g. mynewnftfarm"
                  value={farmName}
                  onChange={(e) => {
                    setFarmName(e.target.value.toLowerCase());
                  }}
                />
                <br />
                <br />
                <h3>Farm Logo (IPFS Hash)</h3>
                <input
                  type="text"
                  placeholder="e.g. Qmabc....."
                  id="farmLogo"
                  value={farmLogo}
                  onChange={(e) => {
                    setFarmLogo(e.target.value);
                  }}
                />
                <br />
                <h3>*100px by 100px</h3>

                {farmLogo != null && farmLogo != "" && farmLogo.length > 10 && (
                  <div className="w-100 justify-center flex mt-3">
                  <CreateFarmIPFS
                    src={buildIpfsUrl(farmLogo, STANDARD_NFT_IMAGE_WIDTH)}
                  />                  
                  <br />
                  <br />                  
                  </div>
                )}

                <br /> 
                <h3>Farm Type</h3>
                <select
                  onChange={(e) => {
                    if (e.target.value == "Specific Templates") {
                      setFarmTypeValue("template");
                    } else if (e.target.value == "Entire Collection") {
                      setFarmTypeValue("collection");
                    } else if (e.target.value == "Schema w/ Exclusions") {
                      setFarmTypeValue("s.exclude");
                    } else if (e.target.value == "Entire Schema") {
                      setFarmTypeValue("schema");
                    } else if (e.target.value == "Version 2") {
                      setFarmTypeValue("template");
                    }
                    setFarmType(e.target.value);
                  }}
                >
                  <option value="" hidden>
                    Choose
                  </option>
                  <option value="Version 2">Version 2</option>
                  <option value="Entire Collection">Entire Collection</option>
                  <option value="Entire Schema">Entire Schema</option>
                  <option value="Schema w/ Exclusions">
                    Schema w/ Exclusions
                  </option>
                  <option value="Specific Templates">Specific Templates</option>
                </select>

                {farmType == "Version 2" && (
                  <>
                  <br />
                  <br />
                  <DarkBgTextBox>
                    <p>
                      <span className="font-bold">Note:</span> For Version 2
                      farms, you can fill out all the template/collection info
                      AFTER you create the farm.
                      <br />
                      This includes which templates can be staked, their staking
                      values, which collections can be staked etc.
                      </p>
                  </DarkBgTextBox>
                  </>
                )}



                {farmType != "Version 2" && (
                  <>
                  <br />
                  <br />
                  <h3>Collection To Stake</h3>

                  <input
                    type="text"
                    id="collection"
                    maxLength={12}
                    placeholder="e.g. hoodpunknfts"
                    value={collection}
                    onChange={(e) => {
                      setCollection(e.target.value.toLowerCase());
                    }}
                  />

                  <span
                    className={
                      farmTypeValue != "schema" &&
                      farmTypeValue != "s.exclude" &&
                      "hidden"
                    }
                  >
                    <br />
                    <br />
                    <h3>Schema To Stake</h3>
                    <input
                      type="text"
                      id="collection"
                      maxLength={12}
                      placeholder="e.g. wojakpacks"
                      value={schema}
                      onChange={(e) => {
                        setSchema(e.target.value.toLowerCase());
                      }}
                    />
                  </span>

                  <span
                    className={
                      farmTypeValue != "template" &&
                      farmTypeValue != "s.exclude" &&
                      "hidden"
                    }
                  >
                    <br />
                    <br />
                    <h3>Template IDs (10 Max)</h3>
                    <textarea
                      placeholder="123456,987654,696969,420420,444444,169169,420069"
                      maxLength="200"
                      id="template"
                      value={templates}
                      onChange={(e) => {
                        setTemplates(e.target.value.replace(" ", ""));
                      }}
                    />

                    <span className={`text-sm italic`}>
                      <br />
                      {`You can ${
                        farmTypeValue == "s.exclude" ? "exclude" : "include"
                      } up to template IDs, comma separated`}
                    </span>
                  </span>                  
                  </>
                )}

              

                    {showCautionMessage(<span>
                      <span className="font-bold">Warning:</span> Entering
                    incorrect token details will result in a pool that doesn't
                    work, and WaxDAO will not be able to help you. <br />
                    Make 100% sure that you have the correct symbol, precision
                    and contract for the reward token.
                    <br />
                    WaxDAO will not be held responsible for user errors.
                    </span>)}



                <h3>Reward Token Symbol</h3>

                <input
                  type="text"
                  id="rewardToken"
                  placeholder="e.g. WOJAK"
                  maxLength={7}
                  value={rewardToken}
                  onChange={(e) => {
                    setRewardToken(e.target.value.toUpperCase());
                  }}
                />


                <h3 className="flex justify-center mt-3">
                  Reward Token Contract&nbsp;&nbsp;
                  <BsInfoCircle
                    className="mt-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEnterModalDisplay("");
                      setEnterModalText(
                        <span>
                          If WAX is the reward token, the info is: <br />
                          Contract: eosio.token
                          <br />
                          Decimals: 8<br />
                          For other tokens, search our{" "}
                          <a
                            href="https://waxdao.io/tokens"
                            target="none"
                            className="text-secondary"
                          >
                            Token Tracker
                          </a>{" "}
                          for this info
                        </span>
                      );
                    }}
                  />
                  <br />
                </h3>

                <input
                  type="text"
                  id="rewardContract"
                  maxLength={12}
                  placeholder="e.g. eosio.token"
                  value={rewardContract}
                  onChange={(e) => {
                    setRewardContract(e.target.value.toLowerCase());
                  }}
                />


                <h3 className="flex justify-center mt-3">
                  Reward Token Decimals&nbsp;&nbsp;
                  <BsInfoCircle
                    className="mt-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEnterModalDisplay("");
                      setEnterModalText(
                        <span>
                          If WAX is the reward token, the info is: <br />
                          Contract: eosio.token
                          <br />
                          Decimals: 8<br />
                          For other tokens, search our{" "}
                          <a
                            href="https://waxdao.io/tokens"
                            target="none"
                            className="text-secondary"
                          >
                            Token Tracker
                          </a>{" "}
                          for this info
                        </span>
                      );
                    }}
                  />
                  <br />
                </h3>

                <input
                  type="number"
                  id="decimals"
                  placeholder="e.g. 8"
                  value={decimals}
                  onChange={(e) => {
                    setDecimals(e.target.value);
                  }}
                />

                <span className={farmType == "Version 2" && "hidden"}>
                  <br />
                  <br />
                  <h3>Reward Type</h3>
                  <select
                    onChange={(e) => {
                      if (e.target.value == "Shared Pool") {
                        setRewardTypeValue("sharedpool");
                      } else if (e.target.value == "Exact Amount Per NFT") {
                        setRewardTypeValue("static");
                      }
                      setRewardType(e.target.value);
                    }}
                  >
                    <option value="" hidden>
                      Choose
                    </option>
                    <option value="Shared Pool">Shared Pool</option>
                    <option value="Exact Amount Per NFT">
                      Exact Amount Per NFT
                    </option>
                  </select>

                  <span
                    htmlFor="rewardAmountContent"
                    className={
                      (farmType == "Version 2" ||
                        rewardTypeValue == "sharedpool") &&
                      "hidden"
                    }
                  >
                    <br />
                    <br />
                    <h3>Hourly Reward Per NFT</h3>

                    <input
                      type="number"
                      id="rewardAmountContent"
                      placeholder="numbers only"
                      value={rewardAmount}
                      onChange={(e) => {
                        setRewardAmount(e.target.value);
                      }}
                    />
                  </span>
                </span>

                <br />
                <br />
                <h3>Minimum Staking Period (Days)</h3>

                <input
                  type="number"
                  id="stakingPeriod"
                  placeholder=" e.g. 1"
                  value={stakingPeriod}
                  onChange={(e) => {
                    let prd = e.target.value;
                    if (prd > 1) {
                      prd = Math.round(prd);
                    }
                    setStakingPeriod(prd);
                    setStakingSeconds(prd * 86400);
                  }}
                />

                <br />
                <br />
                <h3>Farm Expiration Date</h3>

                <DatePicker
                  showTimeSelect
                  timeIntervals={15}
                  selected={new Date(timestamp * 1000)}
                  onChange={(e) => {
                    handleCalendarChange(e, setTimestamp);
                  }}
                />

                <br />
                <br />
                <h3>Payment Method</h3>
                <select
                  onChange={(e) => {
                    setPaymentMethod(e.target.value);
                  }}
                >
                  <option value="" hidden>
                    Choose
                  </option>
                  <option value="Pay 250 Wax">Pay 250 Wax</option>
                  <option value="Pay WAXDAO">Pay {Number(getWaxdaoPrice(250, ratio)).toLocaleString('en')} WAXDAO</option>
                  <option value="Burn 1 Wojak NFT">Burn 1 Wojak NFT</option>
                  <option value="Use 1 WaxDAO Credit">
                    Use 1 WaxDAO Credit
                  </option>
                </select>

                {paymentMethod == "Burn 1 Wojak NFT" && (
                  <>
                  <br />
                  <br />
                  <h3>Asset ID To Burn</h3>
                  <input
                    placeholder="e.g. 19353289652"
                    id="assetID"
                    value={assetToBurn}
                    onChange={(e) => {
                      setAssetToBurn(e.target.value);
                    }}
                  />
                  <br />                  
                  </>
                  )}
                

              </LockAmountInput>
               )}

                    <br/>
                {custodial &&
                <CreateLockButton
                  onClick={() => {
                    createFarm(
                      paymentMethod,
                      ratio && Number(getWaxdaoPrice(250, ratio)),
                      farmName,
                      farmLogo,
                      collection,
                      schema,
                      rewardToken,
                      rewardContract,
                      decimals,
                      timestamp,
                      assetToBurn,
                      stakingSeconds,
                      farmType,
                      farmTypeValue,
                      templates,
                      rewardTypeValue,
                      rewardAmount,
                      setEnterModalDisplay,
                      setEnterModalText,
                      setLoadingDisplay,
                      wharfSession
                    );
                  }}
                >
                  CREATE FARM
                </CreateLockButton>
              }

                {!custodial &&
                <CreateLockButton
                  onClick={() => {
                    createNonCustodialFarm(
                      paymentMethod,
                      ratio && Number(getWaxdaoPrice(250, ratio)),
                      farmConfig,
                      farmName,
                      farmLogo,
                      rewardToken,
                      rewardContract,
                      decimals,
                      timestamp,
                      assetToBurn,
                      setEnterModalDisplay,
                      setEnterModalText,
                      setLoadingDisplay,
                      wharfSession
                    );
                  }}
                >
                  CREATE FARM
                </CreateLockButton>
              }     

              
            </LPLockContainer>

            </PageBody2024>
            </PageWrapper2024>
    </div>
  );
};

export default CreateFarm;