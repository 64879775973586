import { useState } from "react";
import axios from 'axios';
import { currentAtomicApiList } from "../../data/config";



export const useGetUserNfts2024 = () => {
    const [nfts, setNfts] = useState([]);
    const [nftsAreLoading, setNftsAreLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [collectionName, setCollectionName] = useState(null);
    const [schemaName, setSchemaName] = useState(null);
    const [lastPageSize, setLastPageSize] = useState(0);
    const [sortMethod, setSortMethod] = useState({display: 'Transferred (Newest)', param: '&order=desc&sort=transferred'})

    const getNfts = async (owner, collection = collectionName, schema = schemaName, sortMethod = sortMethod, page) => {
        setNftsAreLoading(true);
    
        for (const api of currentAtomicApiList) {
            try {
                const collectionParam = collection ? `&collection_name=${collection}` : '';
                const schemaParam = (collection && schema) ? `&schema_name=${schema}` : '';
                const res = await axios.get(`${api}/atomicassets/v1/assets?owner=${owner}&page=${page}&limit=60${sortMethod.param}${collectionParam}${schemaParam}`, {});
                
                if (res.data.data) {
                    if (page === 1) {
                        setNfts(res.data.data); 
                    } else {
                        setNfts(prevNfts => [...prevNfts, ...res.data.data]); 
                    }
                    setLastPageSize(res.data.data.length)
                    console.log(res.data.data);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }
    
        setCurrentPage(page);
        setNftsAreLoading(false);
    }
    

    const hasNextPage = () => {
        return !nftsAreLoading && lastPageSize === 60;
    }

    return [nfts, getNfts, nftsAreLoading, hasNextPage, collectionName, setCollectionName, setNfts, currentPage, setCurrentPage, schemaName, setSchemaName, sortMethod, setSortMethod]
}
