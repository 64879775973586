import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { AllFarmsCont } from '../../components/LeftDiv';
import { currentUsername, currentWebsiteURL, getCurrentApiList } from '../../data/config';
import CurrentHighlights from '../../components/CurrentHighlights';
import LoadingPage from '../../components/LoadingPage';
import { ExploreFiltersCont, ExploreFiltersInnerCont, FoldersContainer, FoldersRow, NewListingBody, NewListingCont, SingleFolderTab } from '../../Styles';
import PoolCard from '../../components/PoolCard';
import { poolIsExpired } from './create_pool_functions';



const Pools = () => {

  const [poolList, setPoolList] = useState([]);
  const [poolsAreLoading, setPoolsAreLoading] = useState(true);
  const [poolToSearchFor, setPoolToSearchFor] = useState("");
  const [poolCreator, setPoolCreator] = useState("");
  const [rewardToken, setRewardToken] = useState("");
  const [tokenToStake, setTokenToStake] = useState("");
  const [stakedOnly, setStakedOnly] = useState("no");
  const [poolsWereLoaded, setPoolsWereLoaded] = useState(false);
  const [stakedPoolsWereLoaded, setStakedPoolsWereLoaded] = useState(false);
  const [stakedPoolList, setStakedPoolList] = useState([]);

useEffect(() => {
  const abortCont6 = new AbortController();


  if(stakedOnly != 'yes' && !poolsWereLoaded){
  axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
    table:"pools",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    limit:1000,
    json:true,
    signal: abortCont6.signal
  })
    .then((response) => {

      const listToModify = response.data.rows;
      var apy;

      var i = 0;
      if(listToModify.length >= 1){
        while(i < listToModify.length){
          if(listToModify[i].stkcontract != null && listToModify[i].contract != null && listToModify[i].stkcontract == listToModify[i].contract && listToModify[i].rwdtoken != null && listToModify[i].tokentostake != null && listToModify[i].rwdtoken == listToModify[i].tokentostake){
            apy = Number((Number(listToModify[i].hourlyreward.substring(0, listToModify[i].hourlyreward.indexOf(' '))) * 8760) / Number(listToModify[i].totalstaked.substring(0, listToModify[i].totalstaked.indexOf(' '))) * 100).toFixed(2);
          } else {
            apy = 0;
          }
          listToModify[i] = {...listToModify[i], "apy": apy};
          i++;
        }
        setPoolList(listToModify);
        setPoolsWereLoaded(true);
        console.log(listToModify);
      } 
    })
    .catch((error) => console.log(error))
    .finally(() => setPoolsAreLoading(false))
  }
  else if(stakedOnly == 'yes' && !stakedPoolsWereLoaded && poolsWereLoaded){
    if(!currentUsername){return('');}

    axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
      table:"tokens",
      scope:"waxdaofarmer",
      code:"waxdaofarmer",
      key_type: 'name',
      index_position: '2',
      limit:1000,
      lower_bound:currentUsername,
      upper_bound:currentUsername,
      json:true
    }).then((userPoolResponse) => {

    let thisUsersPools = [];
    for (var i = 0; i < userPoolResponse.data.rows.length; i++) {
      thisUsersPools.push(userPoolResponse.data.rows[i].poolname);
    }

      setStakedPoolList([]);
      let testList = [];
      for (var b = 0; b < poolList.length; b++) {
        if(thisUsersPools.includes(poolList[b].poolname)){
          testList.push(poolList[b]);
        }
      }
      setStakedPoolList(testList)
      setStakedPoolsWereLoaded(true)
      console.log(testList)
    })
    
    .catch((error) => console.log(error))
    } //end if stakedonly == yes




    return() => abortCont6.abort();

}, [stakedOnly]);


if(!poolsAreLoading){
  return (
  <div id="seo">
    <Helmet>
    <title>Staking Pools</title>
    <meta name="description" content="Wax token staking pools on Waxdao" />
    <link rel="canonical" href={`${currentWebsiteURL}/pools`} />
    </Helmet>

      <NewListingCont>
        <NewListingBody>


         <CurrentHighlights />
<br/>

          <AllFarmsCont>

            <ExploreFiltersCont>
              <h2>Filters</h2>
              <FoldersContainer>
                <FoldersRow>
                  <SingleFolderTab onClick={(e) => { setStakedOnly("no") }} selected={stakedOnly == "no" && true}>
                    All Pools
                  </SingleFolderTab>
                  <SingleFolderTab onClick={(e) => { setStakedOnly("yes") }} selected={stakedOnly == "yes" && true}>
                    Staked Only
                  </SingleFolderTab>
                </FoldersRow>
              </FoldersContainer>
            <br/>
            <ExploreFiltersInnerCont>
            <input placeholder='Pool name' value={poolToSearchFor} onChange={(e) => setPoolToSearchFor(e.target.value.toLowerCase())} />
            &nbsp;&nbsp;
            <input placeholder='Pool creator' value={poolCreator} onChange={(e) => setPoolCreator(e.target.value.toLowerCase())} />
            &nbsp;&nbsp;
            <input placeholder='Token to stake' value={tokenToStake} onChange={(e) => setTokenToStake(e.target.value.toUpperCase())} />
            &nbsp;&nbsp;
            <input placeholder='Reward token' value={rewardToken} onChange={(e) => setRewardToken(e.target.value.toUpperCase())} />

            </ExploreFiltersInnerCont>
            </ExploreFiltersCont>


            <div className={stakedOnly == "no" ? "flex flex-wrap gap-5 mt-4 justify-center" : "hidden"}>

              {poolList != null && poolList.length > 0 ? poolList.sort((a,b) => b.apy - a.apy).map((item, index) => (
                <span className={( (poolCreator.length >= 1 && item.creator.substring(0,poolCreator.length).indexOf(poolCreator) == -1 ) || (poolToSearchFor.length >= 1 && item.poolname.substring(0,poolToSearchFor.length).indexOf(poolToSearchFor) == -1 ) || (tokenToStake.length >= 1 && item.tokentostake.substring(item.tokentostake.indexOf(",") + 1, item.tokentostake.indexOf(",") + 1 + tokenToStake.length).indexOf(tokenToStake) == -1 ) || (rewardToken.length >= 1 && item.rwdtoken.substring(item.rwdtoken.indexOf(",") + 1, item.rwdtoken.indexOf(",") + 1 + rewardToken.length).indexOf(rewardToken) == -1 ) || (poolIsExpired(item.enddate)) ) && "hidden" }>
                  <a href={`${currentWebsiteURL}/pool/${item.poolname}`}>
                  <PoolCard item={item} />
                  </a> 
                </span>
              )) : ("No pools")}

            </div>

            <div className={stakedOnly == "yes" && stakedPoolsWereLoaded ? "flex flex-wrap gap-5 mt-4 justify-center" : "hidden"}>

              {stakedPoolList != null && stakedPoolList.length > 0 ? stakedPoolList.sort((a,b) => b.apy - a.apy).map((item, index) => (
                <span className={( (poolCreator.length >= 1 && item.creator.substring(0,poolCreator.length).indexOf(poolCreator) == -1 ) || (poolToSearchFor.length >= 1 && item.poolname.substring(0,poolToSearchFor.length).indexOf(poolToSearchFor) == -1 ) || (tokenToStake.length >= 1 && item.tokentostake.substring(item.tokentostake.indexOf(",") + 1, item.tokentostake.indexOf(",") + 1 + tokenToStake.length).indexOf(tokenToStake) == -1 ) || (rewardToken.length >= 1 && item.rwdtoken.substring(item.rwdtoken.indexOf(",") + 1, item.rwdtoken.indexOf(",") + 1 + rewardToken.length).indexOf(rewardToken) == -1 ) ) && "hidden" }>
                  <a href={`${currentWebsiteURL}/pool/${item.poolname}`}>
                  <PoolCard item={item} />
                  </a>
                </span>
              )) : ("No pools")}

            </div>
            <div className={stakedOnly == "yes" && !stakedPoolsWereLoaded ? "flex flex-wrap gap-5 mt-4 justify-center" : "hidden"}>
                Loading pools...
            </div>
    </AllFarmsCont>
    </NewListingBody>
    </NewListingCont>
    <br/><br/><br/>
    </div>
  )}else{
    return(<LoadingPage />)
  }
}

export default Pools