import React, { useEffect, useState } from 'react'
import { currentWebsiteURL } from '../data/config';
import { buildDaoCardImage2024, buildDropCardImage2024, collectionIsNsfw, showNsfwOverlay } from '../data/functions/global_functions';
import { hasNsfwHidden } from '../pages/Settings';
import { DropCardCollectionNameWrapper2024, DropCardPriceAndSupplyWrapper, DropCardTopHalf2024, DropCardWrapper2024, NftCardShowDetailsWrapper, UnboxCardUnboxNow } from '../data/css/NftStyles2024';
import LoadingSpinnerImageFallback from './LoadingSpinnerImageFallback';


const DaoCard2024 = (props) => {
    const item = props.item;

    const [isLoading, setIsLoading] = useState(true);

  return (
    <DropCardWrapper2024>
        <DropCardTopHalf2024>
          {(isLoading)&& <LoadingSpinnerImageFallback />}


          <a href={`${currentWebsiteURL}/v2/daos/${item.daoname}`}>
          {buildDaoCardImage2024(item, setIsLoading)}
          </a>
        </DropCardTopHalf2024>


      {item.creator && (
          <a href={`${currentWebsiteURL}/u/${item.creator}`}>
        <DropCardCollectionNameWrapper2024>
        {item.creator}
        </DropCardCollectionNameWrapper2024>
        </a>
      )}

        <DropCardPriceAndSupplyWrapper>
            <h2>{item?.daoname && item.daoname}</h2>    
        </DropCardPriceAndSupplyWrapper>

        <NftCardShowDetailsWrapper>
            <UnboxCardUnboxNow href={`${currentWebsiteURL}/v2/daos/${item.daoname}`}>
            VOTE NOW
            </UnboxCardUnboxNow>
        </NftCardShowDetailsWrapper>
    </DropCardWrapper2024>
  )
}

export default DaoCard2024