import { useState } from "react";
import { currentApiList } from "../../data/config";
import axios from 'axios';

export const useGetDynamicRatio = () => {
    
    const [ratio, setRatio] = useState([]);
    const [ratioIsLoading, setRatioIsLoading] = useState(true);

    const getDynamicRatio = async () => {
        setRatioIsLoading(true);
        
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table:"config",
                    scope:"twap.waxdao",
                    code:"twap.waxdao",
                    limit:1,
                    json:true
                });

                if (res.data.rows) {
                    setRatio(res.data.rows[0]);
                    console.log(res.data.rows);
                    break;
                }
            } catch (error) {
                console.log(`Failed on API endpoint ${api}. Error: ${error}`);
            }
        }

        setRatioIsLoading(false);
    }

    return [ratio, getDynamicRatio, ratioIsLoading]
}
