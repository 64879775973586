import axios from "axios";
import React, { useState } from "react";
import {currentUsername, getCurrentApiList } from "../../data/config";

import {
  buySynth,
  createSynth,
  redeemSynth,
} from "../../data/functions/SynthFunctions";
import {
  FoldersContainer,
  FoldersRow,
  MainButton,
  SingleFolderFourth,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import {
  getQuantityFromAsset,
  getTokenNameFromAsset,
} from "../../data/functions/pool_functions";
import NumberFormat from "react-number-format";
import { useStateContext } from "../../contexts/ContextProvider";

const Synthetics = (props) => {
  const { wharfSession } = useStateContext();

  const [selectedSynthOption, setSelectedSynthOption] = useState("create");
  const [synthTokenName, setSynthTokenName] = useState("");
  const [paymentReceivers, setPaymentReceivers] = useState([]);
  const [listOfSynths, setListOfSynths] = useState([]);
  const [amountToBuy, setAmountToBuy] = useState("");
  const [synthToBuy, setSynthToBuy] = useState("");
  const [synthToRedeem, setSynthToRedeem] = useState("");
  const [amountToRedeem, setAmountToRedeem] = useState("");
  const [listOfBalances, setListOfBalances] = useState([]);
  const [synthsWereFetched, setSynthsWereFetched] = useState(false);

  const enterModalDisplay = props.a;
  const setEnterModalDisplay = props.b;
  const enterModalText = props.c;
  const setEnterModalText = props.d;
  const loadingDisplay = props.e;
  const setLoadingDisplay = props.f;

  const getSynthList = () => {
    if (!synthsWereFetched) {
      axios
        .post(
          `${
            getCurrentApiList
          }/v1/chain/get_table_rows`,
          {
            table: "synthtokens",
            scope: "waxdaosynths",
            code: "waxdaosynths",
            limit: 1000,
            json: true,
          }
        )
        .then((validResponse) => {
          setListOfSynths(validResponse.data.rows);
        });
    }
  };

  const getSynthBalances = () => {
    if (currentUsername) {
      axios
        .post(
          `${
            getCurrentApiList
          }/v1/chain/get_table_rows`,
          {
            table: "accounts",
            scope: currentUsername,
            code: "waxdaosynths",
            limit: 1000,
            json: true,
          }
        )
        .then((validResponse) => {
          setListOfBalances(validResponse.data.rows);
          getSynthList();
        });
    } else {
      return;
    }
  };

  const handleReceiverAdd = (setPaymentReceivers, paymentReceivers) => {
    setPaymentReceivers([
      ...paymentReceivers,
      { contract: "", amount: "", token_symbol: "" },
    ]);
  };

  const handleReceiverRemove = (
    setPaymentReceivers,
    paymentReceivers,
    index
  ) => {
    const list = [...paymentReceivers];
    list.splice(index, 1);
    setPaymentReceivers(list);
  };

  const handleReceiverContractChange = (
    e,
    index,
    paymentReceivers,
    setPaymentReceivers
  ) => {
    const { name, value } = e.target;
    const list = [...paymentReceivers];
    list[index][name] = value.toLowerCase();
    setPaymentReceivers(list);
  };

  const handleReceiverSymbolChange = (
    e,
    index,
    paymentReceivers,
    setPaymentReceivers
  ) => {
    const { name, value } = e.target;
    const list = [...paymentReceivers];
    list[index][name] = value.toUpperCase();
    setPaymentReceivers(list);
  };

  const handleReceiverValueChange = (
    e,
    index,
    paymentReceivers,
    setPaymentReceivers
  ) => {
    const { name, value } = e.target;
    const list = [...paymentReceivers];
    list[index][name] = value;
    setPaymentReceivers(list);
  };

  return (
    <span>
      <TallFiltersCont>
        <TallFiltersInnerCont>
          <FoldersContainer>
            <FoldersRow>
              <SingleFolderFourth
                onClick={() => setSelectedSynthOption("create")}
                selected={selectedSynthOption == "create" && true}
              >
                Create
              </SingleFolderFourth>
              <SingleFolderFourth
                onClick={() => {
                  setSelectedSynthOption("redeem");
                  getSynthBalances();
                }}
                selected={selectedSynthOption == "redeem" && true}
              >
                Redeem
              </SingleFolderFourth>
              <SingleFolderFourth
                onClick={() => {
                  setSelectedSynthOption("buy");
                  getSynthList();
                }}
                selected={selectedSynthOption == "buy" && true}
              >
                Buy
              </SingleFolderFourth>
            </FoldersRow>
          </FoldersContainer>
        </TallFiltersInnerCont>
      </TallFiltersCont>

      <span className={selectedSynthOption != "create" && "hidden"}>
        <TallFiltersCont>
          <TallFiltersInnerCont>
            <h3>Token Name</h3>
            <input
              value={synthTokenName}
              placeholder="e.g. MYSYNTH"
              maxLength={7}
              onChange={(e) => {
                setSynthTokenName(e.target.value.toUpperCase());
              }}
            />
          </TallFiltersInnerCont>
        </TallFiltersCont>

        <TallFiltersCont>
          <h2>Choose Underlying Assets</h2>
          <br />
          <TallFiltersInnerCont>
            Each 1 synthetic token will be worth the amounts that you enter
            below.
            <br />
            Example: if you put 0.25 WAX and 10 WOJAK, it means that each
            synthetic token will be worth 0.25 WAX and 10 WOJAK.
          </TallFiltersInnerCont>
        </TallFiltersCont>

        {paymentReceivers.length >= 1 &&
          paymentReceivers.map((singleReceiver, receiverIndex) => (
            <TallFiltersCont key={receiverIndex}>
              <h2>Asset {receiverIndex + 1}</h2>
              <br />
              <TallFiltersInnerCont>
                <h3>Token Symbol</h3>
                <input
                  name="token_symbol"
                  placeholder="e.g. 8,WAX"
                  maxLength={10}
                  value={paymentReceivers[receiverIndex].token_symbol}
                  onChange={(e) => {
                    handleReceiverSymbolChange(
                      e,
                      receiverIndex,
                      paymentReceivers,
                      setPaymentReceivers
                    );
                  }}
                />
                <br />
                <br />

                <h3>Contract Address</h3>
                <input
                  name="contract"
                  placeholder="e.g. eosio.token"
                  maxLength={13}
                  value={paymentReceivers[receiverIndex].contract}
                  onChange={(e) => {
                    handleReceiverContractChange(
                      e,
                      receiverIndex,
                      paymentReceivers,
                      setPaymentReceivers
                    );
                  }}
                />
                <br />
                <br />

                <h3>Token Amount</h3>
                <input
                  name="amount"
                  placeholder="e.g. 69.420"
                  maxLength={15}
                  value={paymentReceivers[receiverIndex].amount}
                  onChange={(e) => {
                    handleReceiverValueChange(
                      e,
                      receiverIndex,
                      paymentReceivers,
                      setPaymentReceivers
                    );
                  }}
                />
              </TallFiltersInnerCont>

              {/* END OF FUNGIBLE FIELDS */}

              {/* END OF NONFUNGIBLE FIELDS */}

              <div className="text-center w-100">
                <br />
                <MainButton
                  onClick={() => {
                    handleReceiverRemove(
                      setPaymentReceivers,
                      paymentReceivers,
                      receiverIndex
                    );
                  }}
                >
                  Remove This Asset
                </MainButton>
              </div>
            </TallFiltersCont>
          ))}

        {/* END PAYMENT RECEIVERS */}

        {paymentReceivers.length < 5 && (
          <TallFiltersCont>
            <div className="text-center w-100 pt-3">
              <MainButton
                onClick={() => {
                  handleReceiverAdd(setPaymentReceivers, paymentReceivers);
                }}
              >
                Add Asset
              </MainButton>
              <br />
            </div>
          </TallFiltersCont>
        )}

        <TallFiltersCont>
          <div className="text-center w-100 pt-3">
            <MainButton
              onClick={() => {
                createSynth(
                  synthTokenName,
                  paymentReceivers,
                  setEnterModalText,
                  setLoadingDisplay,
                  setEnterModalDisplay,
                  wharfSession
                );
              }}
            >
              Create Synth
            </MainButton>
          </div>
        </TallFiltersCont>
      </span>




      <span className={selectedSynthOption != "buy" && "hidden"}>

        <TallFiltersCont>
            <TallFiltersInnerCont>
                <h3>Choose Token</h3>

                <select
                onChange={(e) => {
                    setSynthToBuy(e.target.value);
                    console.log(e.target.value);
                }}
                >
                <option value="">Choose</option>

                {listOfSynths?.length >= 1 &&
                    listOfSynths.map((item, index) => (
                    <option
                        key={index}
                        value={
                        item.max_supply.substring(item.max_supply.indexOf(" ") + 1) +
                        "|" +
                        JSON.stringify(item.basket_of_assets)
                        }
                    >
                        {item.max_supply != null &&
                        item.max_supply.substring(item.max_supply.indexOf(" ") + 1)}
                    </option>
                    ))}
                </select>    

                <br />
                <br />
                <h3>Amount:</h3>
                <input
                value={amountToBuy}
                onChange={(e) => {
                    setAmountToBuy(e.target.value);
                }}
                />                

            </TallFiltersInnerCont>
        </TallFiltersCont>

        <TallFiltersCont>
        <h2>Required Inputs</h2>    
        <br/>
        <TallFiltersInnerCont>
        {synthToBuy?.length > 0 && (
          <span>
            {Array.from(
              JSON.parse(synthToBuy.substring(synthToBuy.indexOf("|") + 1))
            ).map((synthItem, synthIndex) => (
              <span key={synthIndex}>
                {synthItem.amount != null &&
                  Number(
                    Number(
                      synthItem.amount.substring(
                        0,
                        synthItem.amount.indexOf(" ")
                      )
                    ) * Number(amountToBuy)
                  ).toFixed(8) +
                    synthItem.amount.substring(synthItem.amount.indexOf(" "))}
                <br />
              </span>
            ))}
          </span>
        )}
        </TallFiltersInnerCont>
        </TallFiltersCont>
        

        <TallFiltersCont>
        <div className="text-center w-100 pt-3">
        <MainButton
          onClick={() => {
            buySynth(
              amountToBuy,
              synthToBuy,
              "waxdaosynths",
              "deposit",
              setEnterModalText,
              setLoadingDisplay,
              setEnterModalDisplay,
              wharfSession
            );
          }}
        >
              Buy{" "}
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={amountToBuy}
                style={{
                  backgroundColor: "transparent",
                  textAlign: "center",
                  width: "100%",
                  maxWidth: "100%",
                }}
              />{" "}
              {synthToBuy != null && synthToBuy.substring(0, synthToBuy.indexOf("|"))}
        </MainButton>
        </div>
        </TallFiltersCont>
      </span>

      <span className={selectedSynthOption != "redeem" && "hidden"}>
        <TallFiltersCont>
          <h2>Input</h2>
          <br />
          <TallFiltersInnerCont>
            <h3>Choose Token</h3>
            <select
              onChange={(e) => {
                setSynthToRedeem(e.target.value);
                console.log(e.target.value);
              }}
            >

              {synthToRedeem === "" && (
                <option value="">
                  Choose
                </option>
              )}
              {listOfBalances?.length >= 1 &&
                listOfBalances.map((item, index) => (
                  <option
                    key={index}
                    value={item.balance.substring(
                      item.balance.indexOf(" ") + 1
                    )}
                  >
                    {item.balance != null &&
                      item.balance.substring(item.balance.indexOf(" ") + 1)}
                  </option>
                ))}
            </select>
            <br />
            <br />
            <h3>Amount</h3>
            <input
              value={amountToRedeem}
              onChange={(e) => {
                setAmountToRedeem(e.target.value);
              }}
            />
          </TallFiltersInnerCont>
        </TallFiltersCont>

        <TallFiltersCont>
          <h2>Output</h2>
          <br />
          <TallFiltersInnerCont>
            {synthToRedeem?.length > 0 && listOfSynths?.length > 0 && (
              <span>
                {Array.from(
                  listOfSynths[
                    listOfSynths.findIndex(
                      (attributeIndex) =>
                        attributeIndex.max_supply.substring(
                          attributeIndex.max_supply.indexOf(" ") + 1
                        ) === synthToRedeem
                    )
                  ].basket_of_assets
                ).map((synthItem, synthIndex) => (
                  <span key={synthIndex}>
                    {synthItem.amount != null && (
                      <span>
                        <NumberFormat
                          displayType="text"
                          thousandSeparator={true}
                          value={(
                            getQuantityFromAsset(synthItem.amount) *
                            Number(amountToRedeem)
                          ).toFixed(8)}
                          style={{
                            backgroundColor: "transparent",
                            textAlign: "center",
                            width: "100%",
                            maxWidth: "100%",
                          }}
                        />
                        &nbsp; {getTokenNameFromAsset(synthItem.amount)}
                      </span>
                    )}
                    <br />
                  </span>
                ))}
              </span>
            )}
          </TallFiltersInnerCont>
        </TallFiltersCont>

        <TallFiltersCont>
          <div className="text-center w-100 pt-3">
            <MainButton
              onClick={() => {
                redeemSynth(
                  amountToRedeem,
                  synthToRedeem,
                  8,
                  "waxdaosynths",
                  "",
                  setEnterModalText,
                  setLoadingDisplay,
                  setEnterModalDisplay,
                  wharfSession
                );
              }}
            >
              Redeem{" "}
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={amountToRedeem}
                style={{
                  backgroundColor: "transparent",
                  textAlign: "center",
                  width: "100%",
                  maxWidth: "100%",
                }}
              />{" "}
              {synthToRedeem}
            </MainButton>
          </div>
        </TallFiltersCont>
      </span>
    </span>
  );
};

export default Synthetics;
