import { defaultSpinnerDuration, defaultTxSettings } from "../config";
import { PROCESSING_TX_TEXT } from "../constants";

export const redeemSynth = async (amount, symbol, precision, receiver, memo, setEnterModalText, setLoadingDisplay, setEnterModalDisplay, wharfSession) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession 

    let amount_to_transfer;

    if(Number(precision) == 0){
        amount_to_transfer = Math.round(Number(amount)) + " " + symbol;
    } else {
        amount_to_transfer = Number(amount).toFixed(Number(precision)) + " " + symbol;
    }

    let contract_account = "waxdaosynths";
    let response_message = "Your tokens have been redeemed! Check bloks to verify that the tx is confirmed";

    const action = [{
      account: contract_account,
      name: 'transfer',
      authorization: [session.permissionLevel],
    data: {
        from: session.actor,
        to: receiver,
        quantity: amount_to_transfer,
        memo: memo,
    }
  }]
    
  try {
    const result = await session.transact({ actions: action }, defaultTxSettings);
    setLoadingDisplay("");
    setEnterModalText(PROCESSING_TX_TEXT);
    const timer = setTimeout(() => {
      setEnterModalText(response_message)
      setLoadingDisplay("none");
    }, defaultSpinnerDuration);
    return () => clearTimeout(timer);
  } catch (e) {
    console.log('ERROR: ', e);
    setEnterModalText(e.message);
    setEnterModalDisplay("");
  }    

  }//end redeemSynth

  export const createSynth = async (synthTokenName, underlyingAssets, setEnterModalText, setLoadingDisplay, setEnterModalDisplay, wharfSession) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession 

    let contract_account = "waxdaosynths";
    let response_message = "Your new synth has been created!";

    let asset_index = 0;
    let final_asset_vector = [];
    for(let i = 0; i < underlyingAssets.length; i++){
        if(Number(underlyingAssets[asset_index].token_symbol.substring(0, underlyingAssets[asset_index].token_symbol.indexOf(","))) == 0){
            final_asset_vector = [...final_asset_vector, {"amount": Math.round(underlyingAssets[asset_index].amount) + " " + underlyingAssets[asset_index].token_symbol.substring(underlyingAssets[asset_index].token_symbol.indexOf(",") + 1), "contract":underlyingAssets[asset_index].contract}];
        } else {
            final_asset_vector = [...final_asset_vector, {"amount": Number(underlyingAssets[asset_index].amount).toFixed(underlyingAssets[asset_index].token_symbol.substring(0, underlyingAssets[asset_index].token_symbol.indexOf(","))) + " " + underlyingAssets[asset_index].token_symbol.substring(underlyingAssets[asset_index].token_symbol.indexOf(",") + 1), "contract":underlyingAssets[asset_index].contract}];
        }
        asset_index ++;

        console.log(final_asset_vector)
    }

            const action = [{
              account: contract_account,
              name: 'newsynth',
              authorization: [session.permissionLevel],
            data: {
                user: session.actor,
                token_symbol: "8," + synthTokenName,
                basket_of_assets: final_asset_vector,
            }
          }]

          try {
            const result = await session.transact({ actions: action }, defaultTxSettings);
            setLoadingDisplay("");
            setEnterModalText(PROCESSING_TX_TEXT);
            const timer = setTimeout(() => {
              setEnterModalText(response_message)
              setLoadingDisplay("none");
            }, defaultSpinnerDuration);
            return () => clearTimeout(timer);
          } catch (e) {
            console.log('ERROR: ', e);
            setEnterModalText(e.message);
            setEnterModalDisplay("");
          }                  
          

  }//end createSynth


export const buySynth = async (amount, synthToBuy, receiver, memo, setEnterModalText, setLoadingDisplay, setEnterModalDisplay, wharfSession) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession 

    let response_message = "Your order has gone through. Check bloks to verify that the tx is confirmed";

    let symbol = synthToBuy.substring(0, synthToBuy.indexOf("|"));

    let synth_actions = [];
    let ingredient_list = [...Array.from(JSON.parse(synthToBuy.substring(synthToBuy.indexOf("|") + 1)))];
    
    let index = 0;
    for(const ing of ingredient_list){

      let THIS_SYMBOL = ing.amount.substring(ing.amount.indexOf(" ") + 1);
      let THIS_AMOUNT = Number(ing.amount.substring(0, ing.amount.indexOf(" "))) * Number(amount).toFixed(8);
      let THIS_PRECISION; 

      if(ing.amount.indexOf(".") > -1){
          THIS_PRECISION = Number(ing.amount.substring(ing.amount.indexOf(".") + 1, ing.amount.indexOf(" ")).length);
      } else {
          THIS_PRECISION = 0;
      }

      let THIS_AMOUNT_TO_TRANSFER = THIS_AMOUNT.toFixed(THIS_PRECISION) + " " + THIS_SYMBOL;
            
      synth_actions.push({
          account: ing.contract,
          name: 'transfer',
          authorization: [session.permissionLevel],
        data: {
          to: 'waxdaosynths',
          from: session.actor,
          quantity: THIS_AMOUNT_TO_TRANSFER,
          memo: '|deposit|' + symbol + '|' + index + '|',
        }
      })

      index ++;
    }

    const action = [{
        account: "waxdaosynths",
        name: 'assertsynth',
        authorization: [session.permissionLevel],
      data: {
          user: session.actor,
          quantity: Number(amount).toFixed(8) + " " + symbol,
      }
    }]

    const final_action = [...action, ...synth_actions]

    try {
      const result = await session.transact({ actions: final_action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText(response_message)
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }    
  }//end buySynth