import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { Helmet } from "react-helmet";
import {
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  DisabledButton,
  WalletSearchBarCont,
  WalletSearchBar,
  CloseWalletSearchBar,
  WalletSearchBarCollectionList,
  WalletSearchBarSingleCollectionButton,
} from "../../components/LeftDiv";
import ".././datepicker.css";
import {
  currentUsername,
  currentWebsiteURL,
  mainnetWebsiteURL,
} from "../../data/config";
import { useGetCollectionsByAuthorizedAccount } from "../../components/CustomHooks/useGetCollectionsByAuthorizedAccount";
import {
  MainButton,
  NewListingBody,
  NewListingCont,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import { createPremintPool } from "../../data/functions/premint_functions";

const CreatePremint = () => {
  const {
    collection,
    setCollection,
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
    wharfSession
  } = useStateContext();

  const [myCollections, getMyCollections, collectionsWereLoaded] =
    useGetCollectionsByAuthorizedAccount(currentUsername);

  const [displayName, setDisplayName] = useState("");
  const [enteredText, setEnteredText] = useState("");
  const [showSearchCollections, setShowSearchCollections] = useState(false);

  useEffect(() => {
    if (!collectionsWereLoaded) {
      getMyCollections(currentUsername);
    }
  }, []);

  return (
    <div id="seo">
      <Helmet>
        <title>Create Preminted Packs - WaxDAO</title>
        <meta
          name="description"
          content="Create preminted NFT packs on WaxDAO, and accept any WAX token for payment."
        />
        <link rel="canonical" href={`${mainnetWebsiteURL}/create-premint`} />
      </Helmet>


        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <GameButton onClick={() => setEnterModalDisplay("hidden")}>
            Close Window
          </GameButton>
        </Modal>
        <NewListingCont>
          <NewListingBody>
            <TallFiltersCont>
              <h2>Create Pre-Minted Pool</h2>
              <br />
              <TallFiltersInnerCont>
                After your pool is created, you can manage it on the{" "}
                <a href={`${currentWebsiteURL}/create-pack`}>
                  Pre-Minted Pools Page
                </a>
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Pool Details</h2>
              <br />
              <TallFiltersInnerCont>
                <h3>Collection</h3>
                <WalletSearchBarCont className="flex">
                  <WalletSearchBar
                    placeholder="Start typing..."
                    value={enteredText}
                    maxLength={13}
                    onChange={(e) => {
                      setEnteredText(e.target.value.toLowerCase());
                    }}
                    onFocus={() => {
                      setShowSearchCollections(true);
                    }}
                  />
                  <CloseWalletSearchBar
                    onClick={() => {
                      setShowSearchCollections(false);
                    }}
                    className={
                      !showSearchCollections ? "hidden" : "inline-block"
                    }
                  >
                    X
                  </CloseWalletSearchBar>
                  <br />
                  <WalletSearchBarCollectionList
                    className={showSearchCollections == false && "hidden"}
                  >
                    {myCollections.length > 0
                      ? myCollections.map((item, index) => (
                          <span
                            key={index}
                            className={
                              enteredText.length >= 1 &&
                              item.collection_name
                                .substring(0, enteredText.length)
                                .indexOf(enteredText) == -1 &&
                              "hidden"
                            }
                          >
                            {item.collection_name != null && (
                              <WalletSearchBarSingleCollectionButton
                                key={index}
                                onClick={() => {
                                  setCollection(item.collection_name);
                                  setEnteredText(item.collection_name);
                                  setShowSearchCollections(false);
                                }}
                              >
                                {item.collection_name}
                              </WalletSearchBarSingleCollectionButton>
                            )}
                          </span>
                        ))
                      : "No collections"}
                  </WalletSearchBarCollectionList>
                </WalletSearchBarCont>
                <br />

                <h3>Display Name</h3>
                <input
                  type="text"
                  maxLength={25}
                  id="dropPrice"
                  placeholder="e.g. My NFT Pool"
                  value={displayName}
                  onChange={(e) => {
                    setDisplayName(e.target.value);
                  }}
                />
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <br />
            <br />
            <div className="text-center w-100">
              {collection != "" && displayName != "" ? (
                <MainButton
                  onClick={() => {
                    createPremintPool(
                      displayName,
                      collection,
                      setEnterModalText,
                      setLoadingDisplay,
                      setEnterModalDisplay,
                      wharfSession
                    );
                  }}
                >
                  Create Pool
                </MainButton>
              ) : (
                <DisabledButton>Enter Pool Details</DisabledButton>
              )}
            </div>

            <br />
            <br />
          </NewListingBody>
        </NewListingCont>
      <br />
      <br />
      <br />
    </div>
  );
};

export default CreatePremint;
