import React from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { Helmet } from "react-helmet";
import {
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
} from "../../components/LeftDiv";
import {
  mainnetWebsiteURL,
} from "../../data/config";
import {
  MilestoneDescription,
  NewListingBody,
  NewListingCont,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import '../markdown-styles.css'; 

const DAOFAQV2 = () => {
  const {
    enterModalText,
    loadingDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
  } = useStateContext();

  return (
    <div id="seo">
      <Helmet>
        <title>V2 DAO FAQ - WaxDAO</title>
        <meta
          name="description"
          content="FAQ for creating your own DAO on WaxDAO"
        />
        <link rel="canonical" href={`${mainnetWebsiteURL}/v2/dao-faq`} />
      </Helmet>

        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <GameButton onClick={() => setEnterModalDisplay("hidden")}>
            Close Window
          </GameButton>
        </Modal>

        <NewListingCont>
          <NewListingBody>
            <TallFiltersCont>
                <h2>DAO FAQ</h2>
            </TallFiltersCont>

            <TallFiltersCont>
                <h2>How should I format the DAO name?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                DAO names have the same format as WAX addresses. Typically a max of 12 characters, a-z, 1-5 and . are all valid characters. Capital letters are invalid, as well as spaces, 0, and 6-9.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
                <h2>What do I put for the IPFS hash?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                These are your DAO's logos - the cover image is a large background pic, and the avatar should be a small (e.g. 300 x 300) pic. Both should be IPFS hash only, do NOT put the full URL.
                <br/><br/>
                jpeg and png formats are supported.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>   

            <TallFiltersCont>
                <h2>Are social links required?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                No, but it's helpful if you put links to your project so people know where to ask questions.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>   

            <TallFiltersCont>
                <h2>Can you explain the different DAO types?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                Yes.<br/><br/>
                - "Custodial NFT Farm" means that in order for people to vote, they must have NFTs staked in a custodial NFT farm (on the waxdaofarmer contract). Please note, if you choose this option, anyone who votes will have their NFTs locked in the farm contract until the proposal they voted on has ended. Also, you will not be able to kick users out of your farm if you link it to a DAO. This is due to the conflicts that kicking users would cause between the farm and the DAO.
                <br/><br/>
                - "Custodial Token Pool" is the same as above, except people need to stake tokens in a token pool instead of NFTs in an NFT farm. Everything else mentioned above applies to this farm type as well.
                <br/><br/>
                - "Stake Tokens To DAO" means that users will have to stake their governance tokens directly to the dao.waxdao contract in order to get voting power. These tokens can be unstaked at any time, UNLESS they have voted on a proposal, in which case they can not unstake until the proposal has ended.
                <br/><br/>
                - "Non Custodial (NFTs)" means that users need to hold governance NFTs in their wallet in order to vote. These NFTs do not need to be transferred to the contract in order to vote. Each NFT counts as 1 vote, and each vote is tied to the NFT itself (NOT the wallet). In other words, if someone uses an NFT to vote and then sells their NFT, the new owner can not vote on the same proposal as the old owner. However, they can vote on any proposals where that NFT has not been used yet. DAO creators need to specify which schemas can be used for voting, and the DAO creator must be authorized on any collections/schemas that they try to add to their DAO.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>                                    

            <TallFiltersCont>
                <h2>What is "Gov Farm Name" or "Gov Pool Name"?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                This only applies to DAOs where the user is required to stake to the waxdaofarmer contract for voting power. In those cases, "Gov Farm/Pool Name" is the name of the farm/pool that they need to stake in.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont> 

            <TallFiltersCont>
                <h2>What is the Threshold setting for?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                For all proposal types besides "Ranked Choice", this is the percentage of votes that a choice needs to have in order for a proposal to pass. If you set this to 51 for example, that means at least 51% of votes need to be for a single choice, or the proposal will not pass.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont> 

            <TallFiltersCont>
                <h2>What are the different proposer types?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                Authors only means that only whitelisted WAX wallets can make a proposal. You specify these whitelisted wallets in the "authors" field. 
                <br/><br/>
                Anyone can propose means exactly that - anyone can propose. 
                <br/><br/>
                Stake weight means that only people who have a certain amount of the governance asset(s) can make a proposal.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>                             

            <TallFiltersCont>
                <h2>What is Minimum Votes?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                This is the minimum amount of vote weight that a proposal needs to have in order for it to pass. For example, if your DAO type is based on NFTs, and you set minimum weight to 100 - this means that at least 100 NFTs need to vote on a proposal, or it will not pass.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>  

            <TallFiltersCont>
                <h2>What is proposal cost?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                This is the amount of WAX that you want users to pay in order to submit a proposal. This WAX will go directly into your DAO's treasury. It's meant as a spam prevention mechanism, but you can set it to 0 if you like. This cost applies no matter what you set the proposer type to.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>    

            <TallFiltersCont>
                <h2>What is Minimum Weight?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                This is related to the proposer types mentioned above. If you set the proposer type to stake weight, and you set the minimum weight to 100, the proposer would need to have at least 100 of the governance asset(s) in order to submit a proposal.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>  

            <TallFiltersCont>
                <h2>How can I add assets to the treasury?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                On the DAO page, under the "Actions" section - there is a Token Deposit tab, and an NFT deposit tab. Simply select the asset(s) you want to deposit into your DAO's treasury, and click the deposit button. Your asset(s) will be transferred to the treasury, and can only be retrieved via a successful DAO vote.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>  

            <TallFiltersCont>
                <h2>Can the DAO settings be modified later?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                We are currently working on implementing this feature, so soon the answer will be "yes, but only certain settings". 
                However, these settings will only be able to be modified via a proposal.
                There is a new proposal type being worked on - this will allow you to propose changes to settings, and if the proposal passes, the settings will automatically be updated to the ones you put in the proposal.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>                                                    

            <TallFiltersCont>
                <h2>Why does voting with NFTs use my RAM?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                    <ReactMarkdown className="my-markdown">
                    In order to vote with NFTs in a non-custodial DAO, we need to store a record of each NFT that you voted with. 
                    Each one of these records costs RAM. However, once the proposal has ended, you can get back the vast majority of this RAM using the [claimvoteram contract action](https://waxblock.io/account/dao.waxdao?action=claimvoteram#contract-actions).
                    </ReactMarkdown>
                    <br/>       
                    <b>User</b> is your wallet
                    <br/><br/>       
                    <b>DAO</b> is the DAO you voted in
                    <br/><br/>         
                    <b>Proposal ID</b> is the ID# of the proposal you are removing votes for
                    <br/><br/>         
                    <b>Max</b> is the maximum amount of votes you want to remove at once (usually this is the amount of NFTs you voted with)
                    <br/><br/>         
                    <b>Skip</b> is only important if you voted with so many NFTs that you need to break this up into multiple transactions. Usually, you can just put <b>0</b> for skip.


                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>  

          </NewListingBody>
        </NewListingCont>
      <br />
      <br />
      <br />
    </div>
  );
};

export default DAOFAQV2;
