import styled from "styled-components";
import { theme2024 } from "../../Styles2024";

export const AssetIdPageTopWrapper = styled.div`
    width: 100%;
    display: flex;
    height: 550px;
    //border: 1px solid ${theme2024.darkGrey};
    justify-content: left;

    @media (max-width: 875px) {
        flex-wrap: wrap;
        justify-content: center;
        height: auto;
    } 
`

export const AssetIdImageWrapper = styled.div`
    width: 350px;
    min-width: 350px;
    height: 100%;
    //border: 1px solid green;
    display: flex;
    justify-content: center;
    padding-top: 40px;
    align-items: start;

    img{
        width: auto;
        height: auto;
        max-width: 80%;
        max-height: 80%;
    }

    @media (max-width: 875px) {
        width: 100%;
        max-width: 100%;
    }     
    
`

export const AssetIdDetailsWrapper = styled.div`
    display: flex;
    //flex-direction: column;
    flex-grow: 1;
    height: 100%;
    align-items: center;
    //border: 1px solid magenta;
    flex-wrap: wrap;
    overflow: hidden;

    h2{
        width: 100%;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.7px;
        color: ${theme2024.primary};
        padding-top: 25px;
        padding-left: 20px;
    }

    @media (max-width: 875px) {
        width: 100%;
        justify-content: center;
        
        h2{
            padding-left: 0px;
            text-align: center;
        }
    }     
`

export const AssetIdDetailsHalf = styled.div`
    width: 50%;
    display: flex;
    //border: 1px solid yellow;
    height: 350px;
    flex-wrap: wrap;
    padding-top: 30px;
    flex-direction: column;
    align-items: start;

    svg{
        fill: ${theme2024.primary};
        display: inline-block;
        //margin-top: 3px;
        width: 22px;
        height: 22px;
        vertical-align: top;
        margin-right: 5px;
    }    

    h3{
        width: 100%;
        font-size: 12px;
        font-weight: 600;
        color: ${theme2024.textSecondaryDarker};
        letter-spacing: 1px;
        padding-left: 20px;
    }

    h4{
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: ${theme2024.offWhite};
        letter-spacing: 0.7px;
        padding-left: 20px;     
        margin-bottom: 20px;   
        display: flex;
        align-items: center;
        padding-right: 15px;
    }

    h5{
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: ${theme2024.offWhite};
        letter-spacing: 0.7px;
        padding-left: 20px;     
        margin-bottom: 20px;   
        display: flex;
        align-items: center;
        padding-right: 15px;
    }    

    @media (max-width: 875px) {
        //width: 30%;
        //overflow: hidden;
        padding-left: 10%;
    }   
    
    @media (max-width: 650px) {
        h4{
            font-size: 14px;
        }
    }
    
    @media (max-width: 500px) {
        padding-left: 5%;
    } 
    
    @media (max-width: 400px) {
        padding-left: 2%;
    }     
`

export const BackedTokensScroller = styled.div`
    overflow-y: scroll;
    //border: 1px solid green;
    width: 90%;
    height: 90px;

    -ms-overflow-style: scrollbar;  /* IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: ${theme2024.primary} black; 

    ::-webkit-scrollbar{
        width: 4px;
    }

    ::-webkit-scrollbar-thumb{

        background: ${theme2024.primary};
    }

    ::-webkit-scrollbar-track{

        background: #dfdcdc40;
    }        
`

export const AssetIdOwnerPanel = styled.div`
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    //border: 1px solid blue;
    height: 130px;
    display: flex;
    justify-content: left;
    padding-left: 5%;
    align-items: center;

    @media (max-width: 875px) {
        justify-content: center;
        padding: 0px;
    }    
`

export const AssetIdOwnerInnerWrapper = styled.div`
    width: 75%;
    max-width: 75%;
    overflow: hidden;
    height: 80%;
    display: flex;
    //background-color: ${theme2024.secondaryDark};
    border: 1px solid ${theme2024.primary};
    border-radius: 7px;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 15px;
    align-items: center;
    gap: 10px;
    

    button{
        width: ${props => !props.wide ? "120px" : "auto"};
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.7px;
        color: ${theme2024.textSecondaryDarker};
        border: 1px solid ${theme2024.textSecondaryDarker};
        border-radius: 15px;
        //height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 10px 4px 10px;
        transition: background-color 0.5s, color 0.5s, border-color 0.5s;

        :hover{
            background-color: ${theme2024.hoverColor};
            color: ${theme2024.offWhite};
            border-color: ${theme2024.offWhite};
        }
    }

    @media (max-width: 400px) {
        width: 95%;
        max-width: 95%;
    }      
`