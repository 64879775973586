import { useState } from "react";
import { currentApiList } from "../../data/config";
import axios from 'axios';

export const useGetAirdropSingle = () => {
    
    const [airdrops, setAirdrops] = useState([]);
    const [loading, setLoading] = useState(true);

    const getAirdrops = async (AirdropId) => {
        setLoading(true);
        
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "airdrops",
                    scope: "wufclaimtool",
                    code: "wufclaimtool",
                    limit: 1,
                    lower_bound: AirdropId,
                    upper_bound: AirdropId,
                    reverse: true,              
                    json: true,
                });

                if (res.data.rows?.length > 0) {
                    setAirdrops(res.data.rows[0]);
                    console.log(res.data.rows);
                    break;
                }
            } catch (error) {
                console.log(`Failed on API endpoint ${api}. Error: ${error}`);
            }
        }

        setLoading(false);
    }

    return [airdrops, getAirdrops, loading]
}
