import { useState } from "react";
import { currentApiList, currentUsername } from "../../data/config";
import axios from 'axios';
import { isLoggedIn } from "../../data/functions/wallet_functions";

export const useGetLocksByCreator = () => {
    
    const [locksByCreator, setLocksByCreator] = useState([]);
    const [creatorLocksAreLoading, setCreatorLocksAreLoading] = useState(true);

    const getLocksByCreator = async () => {
        setCreatorLocksAreLoading(true);
        
        if(!isLoggedIn()) {
            setCreatorLocksAreLoading(false);
            setLocksByCreator([]);
            return [locksByCreator, getLocksByCreator, creatorLocksAreLoading]
        }
        

        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table:"locks",
                    scope:"waxdaolocker",
                    code:"waxdaolocker",
                    key_type: 'name',
                    index_position: 2,
                    limit:100,
                    reverse: true,
                    lower_bound:currentUsername,
                    upper_bound:currentUsername,
                    json:true
                });

                if (res.data.rows) {
                    setLocksByCreator(res.data.rows);
                    console.log(res.data.rows);
                    break;
                }
            } catch (error) {
                console.log(`Failed on API endpoint ${api}. Error: ${error}`);
            }
        }

        setCreatorLocksAreLoading(false);
    }

    return [locksByCreator, getLocksByCreator, creatorLocksAreLoading]
}
