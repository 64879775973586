import NumberFormat from "react-number-format";
import { defaultSpinnerDuration, defaultTxSettings } from "../config";
import { PROCESSING_TX_TEXT } from "../constants";



export const stakeTokens = async (stakingAmount, tokenContract, tokenToStake, PoolName, precision, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession
      
      const action = [{
        account: "waxdaofarmer",
        name: 'staketokens',
        authorization: [session.permissionLevel],
      data: {
          user: session.actor,
          poolname: PoolName,
  
      }
    },{
            account: tokenContract,
            name: 'transfer',
            authorization: [session.permissionLevel],
          data: {
              to: 'waxdaofarmer',
              from: session.actor,
              quantity: Number(stakingAmount).toFixed(Number(precision)) + ' ' + tokenToStake,
              memo: '|stake_tokens|' + PoolName + '|',
          }
        }]
        
        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText("Your tokens have been staked")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }        
  
  } // end staketokens
  


export const unstakeTokens = async (stakingAmount, tokenToStake, PoolName, precision, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

      const action = [{
            account: 'waxdaofarmer',
            name: 'unstaketoken',
            authorization: [session.permissionLevel],
          data: {
              user: session.actor,
              amount: Number(stakingAmount).toFixed(Number(precision)) + ' ' + tokenToStake,
              poolname: PoolName,
          }
        }]
        
        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText("Your tokens have been unstaked")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }

  } // end unstaketokens


  export const unstakeAllTokens = async (PoolName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

      const action = [{
            account: 'waxdaofarmer',
            name: 'unstakalltkn',
            authorization: [session.permissionLevel],
          data: {
              user: session.actor,
              poolname: PoolName,
          }
        }]
        
        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText("Your tokens have been unstaked")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }        
  } // end unstakealltokens

  

export const claimRewards = async (PoolName, contractToOpenRow, rwdtoken, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

      const action = [{
            account: contractToOpenRow,
            name: 'open',
            authorization: [session.permissionLevel],
          data: {
            owner: session.actor,
            symbol: rwdtoken,
            ram_payer: session.actor,
          }
      },
        {
            account: 'waxdaofarmer',
            name: 'claimpoolrwd',
            authorization: [session.permissionLevel],
          data: {
              user: session.actor,
              pool: PoolName,
          }
        }]

        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText("Your rewards have been sent")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }        
  
  }


export const getTokenToStake = (pool) => {
  let tokenToStake = pool.tokentostake.substring(pool.tokentostake.indexOf(",") + 1)

  return tokenToStake;
}

export const getRewardToken = (pool) => {
  let rewardToken = pool.rwdtoken.substring(pool.rwdtoken.indexOf(",") + 1)

  return rewardToken;
}

export const getPrecisionFromSymbol = (symbol) => {
  let precision = symbol.substring(0, symbol.indexOf(","))

  return precision;
}

export const getPrecisionFromAsset = (asset) => {
  let precision = 0;
  if(asset.indexOf(".") > -1){
    precision = asset.substring(asset.indexOf(".") + 1, asset.indexOf(" ")).length
  }
  
  return precision;
}

export const getQuantityFromAsset = (asset) => {
  let quantity = asset.substring(0, asset.indexOf(" "))
 
  return quantity;
}

export const getTokenNameFromAsset = (asset) => {
  let token = asset.substring(asset.indexOf(" ") + 1)
 
  return token;
}

export const getTokenNameFromSymbol = (symbol) => {
  let precision = symbol.substring(symbol.indexOf(",") + 1)

  return precision;
}

export const getTokenPrecision = (pool) => {
  let precision = pool.tokentostake.substring(0, pool.tokentostake.indexOf(","))

  return precision;
}

export const getHourlyReward = (pool) => {
  let amount = Number(pool.hourlyreward.substring(0, pool.hourlyreward.indexOf(" "))).toFixed(2)
  let symbol = pool.hourlyreward.substring(pool.hourlyreward.indexOf(" ") + 1)
  return (<span><NumberFormat displayType='text' thousandSeparator={true} value={amount} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />{" " + symbol}</span>);
}

export const getPoolSize = (pool) => {
  let amount = Number(pool.poolsize.substring(0, pool.poolsize.indexOf(" "))).toFixed(0)
  let symbol = pool.poolsize.substring(pool.poolsize.indexOf(" ") + 1)
  return (<span><NumberFormat displayType='text' thousandSeparator={true} value={amount} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />{" " + symbol}</span>);
}

export const getTotalStaked = (pool) => {
  let amount = Number(pool.totalstaked.substring(0, pool.totalstaked.indexOf(" "))).toFixed(0)
  let symbol = pool.totalstaked.substring(pool.totalstaked.indexOf(" ") + 1)
  return (<span><NumberFormat displayType='text' thousandSeparator={true} value={amount} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />{" " + symbol}</span>);
}

export const roundWalletBalance = (balance) => {
  let amount = Number(balance.substring(0, balance.indexOf(" "))).toFixed(2)
  let symbol = balance.substring(balance.indexOf(" ") + 1)
  return (<span><NumberFormat displayType='text' thousandSeparator={true} value={amount} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />{" " + symbol}</span>);
}

export const roundToDecimals = (balance, decimals) => {
  let amount = Number(balance.substring(0, balance.indexOf(" "))).toFixed(Number(decimals))
  let symbol = balance.substring(balance.indexOf(" ") + 1)
  return (<span><NumberFormat displayType='text' thousandSeparator={true} value={amount} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />{" " + symbol}</span>);
}

export const getStakeAllAmount = (balance) => {
  let amount = Number(balance.substring(0, balance.indexOf(" ")))
  return (<span><NumberFormat displayType='text' thousandSeparator={true} value={amount} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /></span>);
}

export const getStakeAllAmountUnformatted = (balance) => {
  let amount = Number(balance.substring(0, balance.indexOf(" ")))
  return amount
}


export const changeLogo = async (poolname, logoHash, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

      const action = [{
            account: 'waxdaofarmer',
            name: 'setpoollogo',
            authorization: [session.permissionLevel],
          data: {
            poolname: poolname,
            ipfs_hash: logoHash,
          }
        }]
        
        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText("Your logo has been updated")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }        

}; //end change logo 


export const setPoolDays = async (poolname, minimumDays, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

      const action = [{
            account: 'waxdaofarmer',
            name: 'setpooldays',
            authorization:[session.permissionLevel],
          data: {
            poolname: poolname,
            minimum_stake_DAYS: minimumDays,
          }
        }]
        
        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText("Minimum stake time was updated")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }        

};


export const depositTokens = async (
  poolname,
  amountToAdd,
  contract,
  rewardtoken,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession  

  let precision = getPrecisionFromSymbol(rewardtoken);
  let tokenName = getTokenNameFromSymbol(rewardtoken);

      const action = [
        {
          account: contract,
          name: "transfer",
          authorization: [session.permissionLevel],
          data: {
            to: "waxdaofarmer",
            from: session.actor,
            quantity: Number(amountToAdd).toFixed(precision) + " " + tokenName,
            memo: "|pool_deposit|" + poolname + "|",
          },
        },
      ];

      try {
        const result = await session.transact({ actions: action }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);
        const timer = setTimeout(() => {
          setEnterModalText("Your tokens have been added to the reward pool")
          setLoadingDisplay("none");
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(e.message);
        setEnterModalDisplay("");
      }      

};


export const extendPool = async (
  PoolName,
  daysToExtend,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

      const action = [
        {
          account: "waxdaofarmer",
          name: "extendpool",
          authorization: [session.permissionLevel],
          data: {
            poolname: PoolName,
            days_to_add: daysToExtend,
          },
        },
      ];
      
      try {
        const result = await session.transact({ actions: action }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText(PROCESSING_TX_TEXT);
        const timer = setTimeout(() => {
          setEnterModalText("Your pool has been extended by " + daysToExtend + " days")
          setLoadingDisplay("none");
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(e.message);
        setEnterModalDisplay("");
      }      
        
};
