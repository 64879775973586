import styled from 'styled-components'
import { testTheme, theme } from '../../Styles'
import { theme2024 } from '../../Styles2024'


<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>

export const TokenCreatorActionsCont = styled.div`

    width: 100%;
    height: auto;
    border-top: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    margin-top: 2em;
    color: #bcbcbc;
    padding: 2em;

`

export const WhitelistCallToAction = styled.button`
    display: inline-block;
    width: 200px;
    height: 40px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 900;
    background-color: ${theme2024.primary};
    :hover{
        background-color: ${theme2024.hoverColor};
        transition: background-color 1s;
    }

    @media (max-width: 500px) {
        font-size: 14px;
        width: 170px;

    }

    @media (max-width: 400px) {
        font-size: 12px;
        width: 140px;

    }
`

export const TokenCreatorActionsForm = styled.div`

    width: 100%;
    height: auto;
    border: 1px solid #bcbcbc;
    background-color: #3d049e6f;
    color: #bcbcbc;
    padding: 2em;

    h2{
        text-align: center;
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 1em;
    }

    label{
        float: left;
    }

    select{
        display: inline-block;
        width: 150px;
        margin-left: 1em;
        color: black;
        border-radius: 3px;

        :focus{
            outline: none;
        }
    }

`

export const TokenCreatorRow = styled.div`

    width: 100%;
    //border: 1px solid #bcbcbc;
    position: relative;
    margin-top: 1em;
    padding-bottom: 2em;
`

export const TokenCreatorInput = styled.input`

    font-size: 14px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 2px solid #c30000;
    width: 100px;
    max-width: 80%;
    padding: 2px;
    border-radius: 5px;
    padding-left: 0.5em;
    position: absolute;
    left: 100px;

    :focus{
        outline: 1px solid white;
    }


`

export const TokenCreatorCallToAction = styled.button`
    width: 80%;
    padding: 0.5em;
    height: auto;
    border-radius: 5px;
    font-size: 12px;
    font-family: Roboto;
    color: white;
    background-color: #c30000;
    position: absolute;
    left: 10%;
    right: auto;
`

export const LargeCallToAction = styled.button`
    display: inline-block;
    width: 200px;
    height: 60px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 900;
    background-color: ${theme.secondary};
    text-align: center;
    vertical-align: middle;
    :hover{
        background-color: ${theme.secondaryHover};
        transition: background-color 1s;
    }

    @media (max-width: 500px) {
        font-size: 14px;
        width: 170px;
        padding-left: 1em;
        padding-right: 1em;

    }
`


export const AlreadyHaveTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 22px;
    font-family: Roboto;
    font-weight: 500;
    text-align: center;

    @media (max-width: 748px) {
        font-size: 18px;
    }
`


export const AlreadyHaveText = styled.div`
    display: inline-block;
    width: auto;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    padding: 1em;
    padding-top: auto;

`