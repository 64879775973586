import React from 'react'
import { currentWebsiteURL } from '../data/config';
import { DropCardBottomHalf, DropCardName, DropCardTitle, DropCardTopHalf, DropCardWrapper, PremintPoolCardViewPool } from '../data/css/DropStyles'
import { NFTCardCheckbox } from '../data/css/PremiintStyles';
import { WalletCardMint } from '../data/css/WalletStyles';
import { handleAddAsset, handleRemoveAsset } from '../data/functions/premint_functions';
import { LockedPoolButton, ViewPoolButton } from '../Styles';
import { createAvatar } from '@dicebear/core';
import { funEmoji } from '@dicebear/collection';


const MyAvatar = ({ item }) => {
    const avatar = createAvatar(funEmoji, {
      seed: item.ID + "_@waxdao",
    });
  
    const svg = avatar.toString();
  
    return (
        <img src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`} alt={`Avatar-${item.ID}`} 
        style={{borderRadius:"15px", opacity:"90%"}}
        />
    );
  };



const PremintPoolCard = (props) => {
    const item = props.item;
    const assetVector = props.assetVector;
    const setAssetVector = props.setAssetVector;
    const createPack = props.createPack;


  return (
    <DropCardWrapper>
        <DropCardTopHalf>        
        {MyAvatar({ item })}
        </DropCardTopHalf>


            {assetVector != null && (
            <NFTCardCheckbox selected={assetVector.indexOf(item.ID) > -1 ? "yes" : "no"} onClick={() => {assetVector.indexOf(item.ID) > -1 ? handleRemoveAsset(item.ID, assetVector, setAssetVector) : handleAddAsset(item.ID, assetVector, setAssetVector)}}>
            {assetVector.indexOf(item.ID) > -1 && (<span>&#x2713;</span>)}
        </NFTCardCheckbox>
            )}








        {item.assets != null && (
            <WalletCardMint>
            {item.assets.length} NFTs
            </WalletCardMint>
        )}

      {item.collection != null && (
          <a href={`${currentWebsiteURL}/collection/${item.collection}`}>
        <DropCardTitle>
        {item.collection}
        </DropCardTitle>
        </a>
      )}

        {item.display_name != null && (
            <DropCardName>
                {item.display_name}
            </DropCardName>
        )}

        <DropCardBottomHalf />

        <PremintPoolCardViewPool>
        {item.ID != null && (
            

                <a href={`${currentWebsiteURL}/premint-pool/${item.ID}`}>
                <ViewPoolButton>
                    View Pool
                </ViewPoolButton>
                </a>
            
            
        )}

        {createPack && item.status == "available" && (

                <a href={`${currentWebsiteURL}/create-pack/${item.ID}`}>
                <ViewPoolButton>
                    Create Pack
                </ViewPoolButton>
                </a>

        )}

{createPack && item.status != "available" && (


<LockedPoolButton>
    Locked
</LockedPoolButton>


)}


</PremintPoolCardViewPool>

    </DropCardWrapper>
  )
}

export default PremintPoolCard