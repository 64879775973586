import React from "react";
import {
  SmallButton,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import { ModalErrorCont, ModalSuccessCont, ProfileSocialsWrapper } from "../../Styles2024";
import { DarkBgTextBox } from "../../pages/utilities/PowerupStyles";
import {
  defaultTxSettings,
  currentWebsiteURL,
  defaultSpinnerDuration,
} from "../config";
import { PROCESSING_TX_TEXT, error_svg, success_svg } from "../constants";
import { atomic_svg, discord_svg, drop_svg, telegram_svg, twitter_svg, waxdao_svg, www_svg } from "../svgs";

export const setEpochTime = (e, setTimestamp) => {
  let date = new Date(e.target.value);
  var epochTime = date.getTime() / 1000;
  setTimestamp(epochTime);
};

export const handleRemoveAsset = (asset_id, assetVector, setAssetVector) => {
  const assetList = [...assetVector];
  assetList.splice(assetList.indexOf(asset_id), 1);
  setAssetVector(assetList);
  console.log("handled removing it");
};

export const handleAddAsset = (asset_id, assetVector, setAssetVector) => {
  setAssetVector([...assetVector, asset_id]);
  console.log("handled adding it");
};

export const claimRewards = async (
  FarmName,
  rewardSymbol,
  rewardContract,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

    const action = [
      {
        account: rewardContract,
        name: 'open',
        authorization: [session.permissionLevel],
      data: {
        owner: session.actor,
        symbol: rewardSymbol,
        ram_payer: session.actor,
      }
  },{
        account: "waxdaofarmer",
        name: "claimrewards",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          farm: FarmName,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your rewards have been claimed")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }         
};

export const setFarmStatus = async (
  FarmName,
  newStatus,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession 

  let action_name;

  if (newStatus == 0) {
    action_name = "tmpclosefarm";
  } else if (newStatus == 1) {
    action_name = "openfarm";
  } else if (newStatus == 2) {
    action_name = "deletefarm";
  } else {
    setEnterModalText("Invalid farm status");
    return;
  }

    const action = [
      {
        account: "waxdaofarmer",
        name: action_name,
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          farmname: FarmName,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your farm status has been updated")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }     

};


export const unstakeMany = async (
  FarmName,
  asset_ids,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

    const action = [
      {
        account: "waxdaofarmer",
        name: "unstakemany",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          asset_ids: asset_ids,
          farmname: FarmName,
        },
      },
    ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your NFTs have been unstaked")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }          
    
};

export const stakeMany = async (
  FarmName,
  assetVector,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession  

    const action = [
      {
        account: "waxdaofarmer",
        name: "assertstake",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          farmname: FarmName,
          assets_to_assert: assetVector,
        },
      },
      {
        account: "atomicassets",
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          to: "waxdaofarmer",
          from: session.actor,
          asset_ids: assetVector,
          memo: "|stake|" + FarmName + "|",
        },
      },
    ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your NFTs have been staked")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }      
    
}; //end stakeMany


export const stakeNonCustodial = async (
  FarmName,
  assetVector,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession  

  let contract_account = "waxdaofarmer"
  let action_name = "stakenoncust"

    const action = [
      {
        account: contract_account,
        name: action_name,
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          farmname: FarmName,
          assets_to_stake: assetVector,
        },
      },
    ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your NFTs have been staked")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }       
    
}; //end stakeNonCustodial

export const unstakeNonCustodial = async (
  FarmName,
  assetVector,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession  

  let contract_account = "waxdaofarmer"
  let action_name = "unstknoncust"

    const action = [
      {
        account: contract_account,
        name: action_name,
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          farmname: FarmName,
          assets_to_unstake: assetVector,
        },
      },
    ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your NFTs have been unstaked")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }       
    
}; //end unstakeNonCustodial


export const createFarm = async (
  paymentMethod,
  waxdaoAmount,
  farmName,
  farmLogo,
  collection,
  schema,
  rewardToken,
  rewardContract,
  decimals,
  timestamp,
  assetToBurn,
  stakingSeconds,
  farmType,
  farmTypeValue,
  templates,
  rewardTypeValue,
  rewardAmount,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  wharfSession
) => {

  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession 

  let reward_type = rewardTypeValue;
  if(farmType == "Version 2" && farmTypeValue == "template"){
    reward_type = "dynamic";
  }

  const memo = "|createfarm|" +
  farmName +
  "|" +
  rewardToken +
  "|" +
  decimals +
  "|" +
  rewardContract +
  "|" +
  collection +
  "|" +
  schema +
  "|" +
  farmLogo +
  "|" +
  timestamp +
  "|" +
  stakingSeconds +
  "|" +
  farmTypeValue +
  "|" +
  templates +
  "|" +
  reward_type +
  "|" +
  rewardAmount +
  "|";

  let action;

  if (paymentMethod == "Pay 250 Wax") {
      action = [
        {
          account: "eosio.token",
          name: "transfer",
          authorization: [session.permissionLevel],
          data: {
            to: "waxdaofarmer",
            from: session.actor,
            quantity: "250.00000000 WAX",
            memo: memo
          },
        },
      ];
    }

  else if (paymentMethod == "Pay WAXDAO") {
      action = [
        {
          account: "token.waxdao",
          name: "transfer",
          authorization: [session.permissionLevel],
          data: {
            to: "waxdaofarmer",
            from: session.actor,
            quantity: `${waxdaoAmount.toFixed(8)} WAXDAO`,
            memo: memo
          },
        },
      ];
  } 

  else if (paymentMethod == "Burn 1 Wojak NFT") {
      action = [
        {
          account: "atomicassets",
          name: "transfer",
          authorization: [session.permissionLevel],
          data: {
            asset_ids: [assetToBurn],
            from: session.actor,
            to: "waxdaofarmer",
            memo: memo
          },
        },
      ];
  } //end if paymentMethod == burn NFT
  else if (paymentMethod == "Use 1 WaxDAO Credit") {
    action = [
        {
          account: "waxdaofarmer",
          name: "createfarm",
          authorization: [session.permissionLevel],
          data: {
            user: session.actor,
            farmname: farmName,
            rewardtoken: rewardToken,
            precision: decimals,
            rewardcontract: rewardContract,
            collectionToStake: collection,
            schemaToStake: schema,
            ipfs: farmLogo,
            endtime: timestamp,
            mintime: stakingSeconds,
            farmtype: farmTypeValue,
            template_csv: templates,
            reward_type: reward_type,
            decimal_hourly_cap: rewardAmount,
          },
        },
      ];
  } //end if paymentMethod == 1 WaxDAO Credit

  try {
    const result = await session.transact({ actions: action }, defaultTxSettings);
    setLoadingDisplay("");
    setEnterModalText(PROCESSING_TX_TEXT);
    const timer = setTimeout(() => {
      setEnterModalText(<span>
            <ModalSuccessCont>
            {success_svg}
            </ModalSuccessCont>          
        Your farm has been created. Users can stake on the{" "}
        <a href={`${currentWebsiteURL}/farm/${farmName}`}>farm page.</a>
        <br/><br/>
        You can manage your farm on the <a href={`${currentWebsiteURL}/edit-farm/${farmName}`}>farm management page.</a>
      </span>)
      setLoadingDisplay("none");
    }, defaultSpinnerDuration);
    return () => clearTimeout(timer);
  } catch (e) {
    console.log('ERROR: ', e);
    setEnterModalText(<span>
      <ModalErrorCont>
      {error_svg}
      </ModalErrorCont>
      {e.message}        
    </span>);
    setEnterModalDisplay("");
  }     

};

export const createNonCustodialFarm = async (
  paymentMethod,
  waxdaoAmount,
  farm_config,
  farmName,
  farmLogo,
  rewardToken,
  rewardContract,
  decimals,
  timestamp,
  assetToBurn,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession  

    const farmConfigMap = {
      "Full Collection": { reward_type: "sharedpool", farm_type: "nc.c.shared" },
      "Dynamic": { reward_type: "dynamic", farm_type: "nc.v2" },
    };

    if (!farmConfigMap[farm_config]) {
      setEnterModalText("Invalid farm config");
      return;
    }

    const { reward_type, farm_type } = farmConfigMap[farm_config];

    const buildMemo = () =>
      "|createfarm|" +
      [farmName, rewardToken, decimals, rewardContract, "", "", farmLogo, timestamp, 0, farm_type, "", reward_type, 0].join("|") +
      "|";

    const buildAction = (account, name, data) => [
      {
        account,
        name,
        authorization: [session.permissionLevel],
        data: { from: session.actor, ...data },
      },
    ];

  const paymentMethodMap = {
    "Pay 250 Wax": {
      account: "eosio.token",
      name: "transfer",
      data: {
        to: "waxdaofarmer",
        quantity: "250.00000000 WAX",
        memo: buildMemo(),
      },
    },
    "Pay WAXDAO": {
      account: "token.waxdao",
      name: "transfer",
      data: {
        to: "waxdaofarmer",
        quantity: `${waxdaoAmount.toFixed(8)} WAXDAO`,
        memo: buildMemo(),
      },
    },
    "Burn 1 Wojak NFT": {
      account: "atomicassets",
      name: "transfer",
      data: {
        asset_ids: [assetToBurn],
        to: "waxdaofarmer",
        memo: buildMemo(),
      },
    },
    "Use 1 WaxDAO Credit": {
      account: "waxdaofarmer",
      name: "createfarm",
      data: {
        user: session.actor,
        farmname: farmName,
        rewardtoken: rewardToken,
        precision: decimals,
        rewardcontract: rewardContract,
        collectionToStake: "",
        schemaToStake: "",
        ipfs: farmLogo,
        endtime: timestamp,
        mintime: 0,
        farmtype: farm_type,
        template_csv: "",
        reward_type: reward_type,
        decimal_hourly_cap: 0,
      },
    },
  };

  if (!paymentMethodMap[paymentMethod]) {
    setEnterModalText("Invalid payment method");
    return;
  }

  const actions = buildAction(
    paymentMethodMap[paymentMethod].account,
    paymentMethodMap[paymentMethod].name,
    paymentMethodMap[paymentMethod].data
  );

  try {
    const result = await session.transact({ actions: actions }, defaultTxSettings);
    setLoadingDisplay("");
    setEnterModalText(PROCESSING_TX_TEXT);
    const timer = setTimeout(() => {
      setEnterModalText(<span>
            <ModalSuccessCont>
            {success_svg}
            </ModalSuccessCont>             
        Your farm has been created. Users can stake on the{" "}
        <a href={`${currentWebsiteURL}/farm/${farmName}`}>farm page.</a>
        <br/><br/>
        You can manage your farm on the <a href={`${currentWebsiteURL}/edit-farm/${farmName}`}>farm management page.</a>
      </span>)
      setLoadingDisplay("none");
    }, defaultSpinnerDuration);
    return () => clearTimeout(timer);
  } catch (e) {
    console.log('ERROR: ', e);
    setEnterModalText(<span>
      <ModalErrorCont>
      {error_svg}
      </ModalErrorCont>
      {e.message}        
    </span>);
    setEnterModalDisplay("");
  }      
};




export const unstakeAll = async (
  FarmName,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession 

    const action = [
      {
        account: "waxdaofarmer",
        name: "unstakeall",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          farmname: FarmName,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your NFTs have been unstaked")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }         
};

export const isAnOfficialFarm = (farmData) => {
  if ( (farmData.reward_type == "dynamic" || (farmData.farmtype == "nc.c.shared" && farmData.reward_type == "sharedpool") ) ) {
    let i = 0;
    while (i < farmData.collections.length) {
      if (farmData.collections[i].is_authorized == 0) {
        return false;
      }
      i++;
    }
  } else {
    if (farmData.collections.length == 0) {
      return false;
    } else {
      let i = 0;
      while (i < farmData.collections.length) {
        if (
          farmData.collections[i].collection_name != farmData.collection ||
          farmData.collections[i].is_authorized == 0
        ) {
          return false;
        }
        i++;
      }
    }
  }
  return true;
};

export const isAnOfficialFarm2024 = (farmData) => {
  if ( (farmData.reward_type == "dynamic" || (farmData.farm_type_v1 == "nc.c.shared" && farmData.reward_type == "sharedpool") ) ) {
    let i = 0;
    while (i < farmData.collections.length) {
      if (farmData.collections[i].is_authorized == 0) {
        return false;
      }
      i++;
    }
  } else {
    if (farmData.collections.length == 0) {
      return false;
    } else {
      let i = 0;
      while (i < farmData.collections.length) {
        if (
          farmData.collections[i].collection_name != farmData.collection ||
          farmData.collections[i].is_authorized == 0
        ) {
          return false;
        }
        i++;
      }
    }
  }
  return true;
};

const checkHTTPSPrefix = (link) => {
  let url = link
  if(url.indexOf("http") == -1){
    url = "https://" + link;
  }
  return url
}

export const getFarmSocials = (profile) => {
  let socialsList = [];
  if (profile?.telegram?.indexOf("http") > -1 || profile?.telegram?.indexOf("t.me") > -1) {
    socialsList = [
      ...socialsList,
      { display_name: "telegram", link: profile.telegram },
    ];
  }
  if (
    profile?.discord?.indexOf("http") > -1 ||
    profile?.discord?.indexOf("discord.") > -1
  ) {
    socialsList = [
      ...socialsList,
      { display_name: "discord", link: profile.discord },
    ];
  }
  if (
    profile?.twitter?.indexOf("http") > -1 ||
    profile?.twitter?.indexOf("twitter.com") > -1
  ) {
    socialsList = [
      ...socialsList,
      { display_name: "twitter", link: profile.twitter },
    ];
  }
  if (profile?.website?.indexOf("http") > -1 || profile?.website?.length >= 7) {
    socialsList = [
      ...socialsList,
      { display_name: "website", link: profile.website },
    ];
  }
  if (
    profile?.latest_drop?.indexOf("http") > -1 ||
    profile?.latest_drop?.length >= 10
  ) {
    socialsList = [
      ...socialsList,
      { display_name: "nft drop", link: profile.latest_drop },
    ];
  }
  if (
    profile?.atomichub?.indexOf("http") > -1 ||
    profile?.atomichub?.length >= 10
  ) {
    socialsList = [
      ...socialsList,
      { display_name: "atomichub", link: profile.atomichub },
    ];
  }
  if (
    profile?.waxdao?.indexOf("http") > -1 ||
    profile?.waxdao?.length >= 10
  ) {
    socialsList = [
      ...socialsList,
      { display_name: "waxdao", link: profile.waxdao },
    ];
  }  


  if (socialsList.length > 0) {
    return (
      <DarkBgTextBox>
        <h2>Socials</h2>
          <div className="flex flex-wrap gap-5 pl-4 pr-4 mt-4">
          {socialsList.map((item, index) => (
            <a key={index} href={checkHTTPSPrefix(item.link)} target="none">
              <SmallButton>{item.display_name}</SmallButton>
            </a>
          ))}
          </div>
      </DarkBgTextBox>
    );
  } else {
    return null;
  }
};

export const getProfileSocials2024 = (rawProfile, location, userName) => {
  let profile = rawProfile;
  if(location == 'userPage'){
    profile = rawProfile.socials;
  }
  let socialsList = [];
  if (profile?.telegram?.indexOf("http") > -1 || profile?.telegram?.indexOf("t.me") > -1) {
    socialsList.push(<a href={checkHTTPSPrefix(profile.telegram)} key='telegram' target="none">
      {telegram_svg}
    </a>);
  }
  if (
    profile?.discord?.indexOf("http") > -1 ||
    profile?.discord?.indexOf("discord.") > -1
  ) {
    socialsList.push(<a href={checkHTTPSPrefix(profile.discord)} key='discord' target="none">
      {discord_svg}
    </a>);
  }
  if (
    profile?.twitter?.indexOf("http") > -1 ||
    profile?.twitter?.indexOf("twitter.com") > -1
  ) {
    socialsList.push(<a href={checkHTTPSPrefix(profile.twitter)} key='twitter' target="none">
      {twitter_svg}
    </a>);
  }

  if (profile?.website?.indexOf("http") > -1 || profile?.website?.length >= 7) {
    socialsList.push(<a href={checkHTTPSPrefix(profile.website)} key='website' target="none">
      {www_svg}
    </a>);
  }

  if (
    profile?.latest_drop?.indexOf("http") > -1 ||
    profile?.latest_drop?.length >= 10
  ) {
      socialsList.push(<a href={checkHTTPSPrefix(profile.latest_drop)} key='drop' target="none">
      {drop_svg}
    </a>);
  }

  if (location != 'userPage' &&
  (
    profile?.atomichub?.indexOf("http") > -1 ||
    profile?.atomichub?.length >= 10
  )
  ) {
    socialsList.push(<a href={checkHTTPSPrefix(profile.atomichub)} key='atomichub' target="none">
    {atomic_svg}
  </a>);
  }

  if (location == 'userPage') {
    socialsList.push(<a href={`https://wax.atomichub.io/profile/wax-mainnet/${userName}`} key='atomichub' target="none">
    {atomic_svg}
  </a>);
  }  

  if (
    location != 'userPage' &&
    (
    profile?.waxdao?.indexOf("http") > -1 ||
    profile?.waxdao?.length >= 10
    )
  ) {
    socialsList.push(<a href={checkHTTPSPrefix(profile.waxdao)} key='waxdao' target="none">
    {waxdao_svg}
  </a>);
  }  


  if (socialsList.length > 0) {
    return (
      <>
        {socialsList}
      </>
    );
  } else {
    return null;
  }
};

export const farmIsExpired = (expires) => {
  let isExpired = false;

  if(expires < Date.now() / 1000){
    isExpired = true;
  }
  return isExpired
}

export const createCSV = (list) => {
  let csvHeader = '"WAX Address","Total NFTs","Asset IDs"' + "\n";
  let csvData;
  if(list.length > 0){
      csvData = list.sort((a, b) => b.assets.length - a.assets.length)
      .map((item, index) => (
            '"' + item.username + '","' + item.assets.length + '","' + item.assets.join(",") + '"\n'
      ))
  }

  let csvParts = []
  const file = new Blob([csvHeader], {type: 'text/plain'});
  csvParts.push(file)
  const file2 = new Blob(csvData, {type: 'text/plain'});
  csvParts.push(file2)
  const final = new Blob(csvParts, {type: 'text/plain'});

 // anchor link
 const element = document.createElement("a");
 element.href = URL.createObjectURL(final);
 element.download = "stakers_list-" + Date.now() + ".csv";

 // simulate link click
 document.body.appendChild(element);    
 element.click(); 

}