import styled from 'styled-components'
import { currentTheme, testTheme, theme } from '../../Styles'

export const PayerDripCardWrapper = styled.div`
    display: block;
    position: relative;
    width: 100%;
    height: 300px;
    background-color: ${theme.mainDarker};
    border: 1px solid transparent;
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 2em;
    /*
    :hover{
        // background-color: #dfdcdc40;
        // transition: background-color 0.5s;
    }
    */
    a{
        color: ${theme.secondary};
    }
`

export const ReceiverIDNumber = styled.h3`
    width: 100%;
    font-size: 12px;
    font-weight: 900;
`

export const ReceiverStatus = styled.h3`
    width: 100%;
    font-size: 14px;
    font-weight: 900;
    padding-top: 0.5em;
`

export const ReceiverDetails = styled.p`
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding-top: 0.5em;
`

export const ReceiverTotal = styled.h3`
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding-top: 0.5em;
`