import { useEffect, useState } from "react";
import { useGetCollectionsByAuthorizedAccount } from "../../components/CustomHooks/useGetCollectionsByAuthorizedAccount";
import LoadingDiv from "../../components/LoadingDiv";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentUsername } from "../../data/config";
import {
  TokenCard,
  TokenDetailsButton,
  TokenDetailsContainer,
} from "../../data/css/WalletStyles";
import {
  addCollectionNotify,
  authorizeWaxDAO,
  notifyWaxDAO,
  removeCollectionNotify,
  removeWaxDAOCollectionAuth,
} from "../../data/functions/wallet_functions";
import {
  SmallButton,
  theme,
} from "../../Styles";
import { buildMyCollectionsImg } from "../../data/functions/global_functions";

export const showCollectionSection = (
  currentMainSection,
  currentToolsSection,
  setLoadingDisplay
) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
    wharfSession
  } = useStateContext();

  const [selectedCollection, setSelectedCollection] = useState("");

  const [myCollections, getMyCollections, collectionsWereLoaded, collectionsAreLoading] =
    useGetCollectionsByAuthorizedAccount(currentUsername);

  useEffect(() => {
    if (
      !collectionsWereLoaded &&
      currentToolsSection == "Collections" &&
      currentMainSection == "Tools"
    ) {
      getMyCollections(currentUsername);
    }
  }, [currentToolsSection, currentMainSection]);

  return (
    <span className={currentToolsSection != "Collections" && "hidden"}>
      {collectionsWereLoaded && myCollections?.length > 0 && myCollections?.map((item, index) => (
            <TokenCard
              selected={
                selectedCollection == item.collection_name ? true : false
              }
              key={index}
            >
              {buildMyCollectionsImg(item)}
              {item.collection_name}
              <TokenDetailsButton
                onClick={() => {
                  setSelectedCollection(
                    selectedCollection != item.collection_name
                      ? item.collection_name
                      : ""
                  );
                }}
              >
                Details
              </TokenDetailsButton>
              <br />
              <TokenDetailsContainer
                className={
                  selectedCollection != item.collection_name && "hidden"
                }
              >
                <br />
                <h3>Creator</h3>
                <span className="pl-2" style={{ color: theme.textSecondary }}>
                  {item.author}
                </span>

                <br />
                <br />

                <h3>
                  Authorized Accounts&nbsp;
                  {item.authorized_accounts.indexOf("waxdaomarket") > -1 ? (
                    <SmallButton
                      onClick={() => {
                        removeWaxDAOCollectionAuth(
                          item.collection_name,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        );
                      }}
                    >
                      Remove WaxDAO
                    </SmallButton>
                  ) : (
                    <SmallButton
                      onClick={() => {
                        authorizeWaxDAO(
                          item.collection_name,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        );
                      }}
                    >
                      Add WaxDAO
                    </SmallButton>
                  )}
                </h3>
                {item?.authorized_accounts?.length > 0 ? (
                  item?.authorized_accounts?.map((acct, acctIndex) => (
                    <span
                      className="pl-2"
                      style={{ color: theme.textSecondary }}
                    >
                      {acct}
                      <br />
                    </span>
                  ))
                ) : (
                  <span className="pl-2" style={{ color: theme.textSecondary }}>
                    No accounts to show
                  </span>
                )}

                <br />

                <h3>
                  Notify Accounts&nbsp;
                  {item.notify_accounts.indexOf("waxdaomarket") > -1 ? (
                    <SmallButton
                      onClick={() => {
                        removeCollectionNotify(
                          "waxdaomarket",
                          item.collection_name,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        );
                      }}
                    >
                      Remove waxdaomarket
                    </SmallButton>
                  ) : (
                    <SmallButton
                      onClick={() => {
                        notifyWaxDAO(
                          item.collection_name,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        );
                      }}
                    >
                      Add waxdaomarket
                    </SmallButton>
                  )}

                  {item.notify_accounts.indexOf("farms.waxdao") > -1 ? (
                    <SmallButton
                      onClick={() => {
                        removeCollectionNotify(
                          "farms.waxdao",
                          item.collection_name,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        );
                      }}
                    >
                      Remove farms.waxdao
                    </SmallButton>
                  ) : (
                    <SmallButton
                      onClick={() => {
                        addCollectionNotify(
                          "farms.waxdao",
                          item.collection_name,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        );
                      }}
                    >
                      Add farms.waxdao
                    </SmallButton>
                  )}

                  {item.notify_accounts.indexOf("waxdaofarmer") > -1 ? (
                    <SmallButton
                      onClick={() => {
                        removeCollectionNotify(
                          "waxdaofarmer",
                          item.collection_name,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        );
                      }}
                    >
                      Remove waxdaofarmer
                    </SmallButton>
                  ) : (
                    <SmallButton
                      onClick={() => {
                        addCollectionNotify(
                          "waxdaofarmer",
                          item.collection_name,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        );
                      }}
                    >
                      Add waxdaofarmer
                    </SmallButton>
                  )}                  

                </h3>
                {item?.notify_accounts?.length > 0 ? (
                  item?.notify_accounts?.map((acct, acctIndex) => (
                    <span
                      className="pl-2"
                      style={{ color: theme.textSecondary }}
                    >
                      {acct}
                      <br />
                    </span>
                  ))
                ) : (
                  <span className="pl-2" style={{ color: theme.textSecondary }}>
                    No accounts to show
                  </span>
                )}
              </TokenDetailsContainer>
            </TokenCard>
          ))}
    {collectionsAreLoading && <LoadingDiv />}
    {collectionsWereLoaded && myCollections?.length == 0 && (
      <div className="w-100 text-center pt-3">
        No collections to show
      </div>
    )}
    {!collectionsWereLoaded && !collectionsAreLoading && (
      <div className="w-100 text-center pt-3">
        API error, please refresh the page to see your collections
      </div>
    )}


    </span>
  );
};
