import React from 'react'
import NumberFormat from 'react-number-format';
import { currentWebsiteURL } from '../data/config';
import { DropCardBottomHalf, DropCardEndDate, DropCardImage, DropCardLiveStatus, DropCardName, DropCardTitle, DropCardTopHalf, DropCardWrapper, FarmCardPoolSize } from '../data/css/DropStyles'
import { FarmCardUnofficial } from '../data/css/FarmsListCSS';
import { dropIsLive, dropIsSoldOut } from '../data/functions/DropFunctions';
import { isAnOfficialFarm } from '../data/functions/farm_functions';
import { buildIpfsUrl } from '../data/functions/global_functions';
import { STANDARD_NFT_IMAGE_WIDTH } from '../data/constants';

const FarmCard = (props) => {
    const item = props.item;
    const sort = props.sort;



  return (
    <DropCardWrapper>
        <DropCardTopHalf>
            {item.logo != null && (
                 <DropCardImage 
                 src={buildIpfsUrl(item.logo, STANDARD_NFT_IMAGE_WIDTH)}             
                 />
            )}
        </DropCardTopHalf>


        <FarmCardUnofficial official={!isAnOfficialFarm(item) ? false : true}>
        {!isAnOfficialFarm(item) ? "UNOFFICIAL" : "OFFICIAL"}
        </FarmCardUnofficial>


      {item.creator != null && (
        <a href={`${currentWebsiteURL}/u/${item.creator}`}>
            <DropCardTitle>
            {item.creator}
            </DropCardTitle>
        </a>
      )}

        {item.farmname != null && (
            <DropCardName>
                {item.farmname}
            </DropCardName>
        )}

        <DropCardBottomHalf />

        {item.enddate != null &&(
            <DropCardEndDate>
                Ends {new Date(item.enddate * 1000).toLocaleDateString()}
            </DropCardEndDate>
        )}


        {item.poolsize != null && sort != "popular" && (
        <FarmCardPoolSize>
            <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
            {item.poolsize.substring(item.poolsize.indexOf(' '))}
        </FarmCardPoolSize>
        )}

        {sort != null && sort == "popular" && (
        <FarmCardPoolSize>
            <NumberFormat displayType='text' thousandSeparator={true} value={item.totalstaked} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
            &nbsp;NFTs
        </FarmCardPoolSize>
        )}



        {dropIsSoldOut(item) && (
            <DropCardLiveStatus status="sold out">
            SOLD OUT
            </DropCardLiveStatus>
        )}

        {dropIsLive(item) && (
            <DropCardLiveStatus status="live">
            LIVE!!!
            </DropCardLiveStatus>
        )}  


    </DropCardWrapper>
  )
}

export default FarmCard