import React from 'react'
import { currentUsername } from '../data/config';
import { LOCK_STATUSES } from '../data/constants';
import { DropCardImage, DropCardTopHalf, DropPageCardCooldown, DropPageCardEndDate, DropPageCardLimitPerUser, DropPageCardStartDate, DropPageNFTCardWrapper } from '../data/css/DropStyles'
import { MainButton, SmallButton, theme } from '../Styles';
import lock_and_key from "../data/lock_icon.png";
import { cancelLock, depositLockFunds, withdrawLock } from '../data/functions/locker_functions';
import { useStateContext } from '../contexts/ContextProvider';

const LockCard = (props) => {
    const item = props.item;

    const { 
        enterModalText,
        setEnterModalText,
        loadingDisplay, 
        setLoadingDisplay, 
        enterButtonsDisplay,
        setEnterButtonsDisplay,
        enterModalDisplay, 
        setEnterModalDisplay,
        wharfSession
      } = useStateContext();

  return (
    <DropPageNFTCardWrapper>
        <DropCardTopHalf>
        <DropCardImage src={lock_and_key} />
        </DropCardTopHalf>

        {item.status != null &&(
            <DropPageCardStartDate>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Status</div>
                <div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.textMain}}>
                    
                    {
                      LOCK_STATUSES[
                        LOCK_STATUSES.findIndex(
                          (attributeIndex) =>
                            attributeIndex.value === item.status
                        )
                      ].display_text
                    }
                    </div>
                </div>
            </DropPageCardStartDate>
        )}

        {item.amount != null &&(
            <DropPageCardEndDate>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Quantity</div><div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.textMain}}>{item.amount}</div>
                </div>
            </DropPageCardEndDate>
        )}        


        {item.receiver != null && (
            <DropPageCardCooldown>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Receiver</div> <div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.textMain}}>{item.receiver}</div>
                </div>
            </DropPageCardCooldown>
        )}

        {item.unlock_time != null && (
            <DropPageCardLimitPerUser>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Unlock Date</div><div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.textMain}}>{new Date(item.unlock_time * 1000).toLocaleDateString()}</div>
                </div>
            </DropPageCardLimitPerUser>
        )}

        <br/><br/>
        {item.status == 0 && currentUsername == item.creator && (
            <div className='mt-44 flex justify-around'>
                <SmallButton onClick={() => {depositLockFunds(item.ID, item.amount, item.token_contract, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession)}}>
                    Deposit
                </SmallButton>
                <SmallButton onClick={() => {cancelLock(item.ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession)}} >
                    Cancel
                </SmallButton>
                </div>
            )}

        {item.status == 1 && currentUsername == item.receiver && item.unlock_time < Math.round(Date.now() / 1000) && (
            <div className='mt-44'>
                <MainButton
                    onClick={() => {withdrawLock(item.ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession);}}
                >
                Withdraw
                </MainButton>
            </div>
        )}
    </DropPageNFTCardWrapper>
  )
}

export default LockCard