import { useState } from "react";
import { getCurrentAtomicApiList } from "../../data/config";
import axios from 'axios';

export const useGetCollectionsByAuthorizedAccount = () => {
    
    const [myCollections, setMyCollections] = useState([]);
    const [collectionsWereLoaded, setCollectionsWereLoaded] = useState(false);
    const [collectionsAreLoading, setCollectionsAreLoading] = useState(true);

    const getMyCollections = (UserName) => {
        
        axios
        .get(`${getCurrentAtomicApiList}/atomicassets/v1/collections?authorized_account=${UserName}&page=1&limit=100&order=desc&sort=created`)
        .then((atomicResponse) => {
          setMyCollections(atomicResponse.data.data);
          setCollectionsWereLoaded(true);
          console.log(atomicResponse.data.data)
        })
        .catch((error) => console.log(error))
        .finally(() => setCollectionsAreLoading(false))

    }

    return [myCollections, getMyCollections, collectionsWereLoaded, collectionsAreLoading]
}