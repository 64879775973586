import { useState } from "react";
import { backedTokensApi } from "../../data/config";
import axios from 'axios';

export const useGetBackedAssetsByUser = () => {
    
    const [backedData, setBackedData] = useState({});
    const [backedDataIsLoading, setBackedDataIsLoading] = useState(true);

    const getBackedTokens = async (owner, page, limit, claimable_only, collectionName, schemaName) => {

        setBackedDataIsLoading(true);

        const requestData = {
            owner: owner,
            page: page,
            limit: limit,
            claimable_only: claimable_only,
            collection_name: collectionName, 
            schema_name: schemaName
          };
          
          try {
            const res = await axios
            .post(`${backedTokensApi}/get-user-assets`, requestData, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            })    

            if (res.data && res.data.nftData) {
                setBackedData(res.data.nftData);
                console.log(res.data);
            }
        } catch (error) {
            console.log(`Failed to fetch token data: ` + error);
        } finally {
          setBackedDataIsLoading(false);
        }           

    }  

    return [backedData, getBackedTokens, backedDataIsLoading]
}
