import React, { useEffect, useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import {Helmet} from "react-helmet";
import { CreateFarmIPFS, NewRow, Spinner, SpinnerBlue, SpinnerGreen, SpinnerRed } from '../../components/LeftDiv';
import { currentWebsiteURL, mainnetWebsiteURL } from '../../data/config';
import { SmallButton } from '../../Styles';
import { WOJAK_SECONDARY_LINK, resizerPrefix, resizerSuffix } from '../../data/constants';
import { createPool, getTokenHelp } from './create_pool_functions';
import DatePicker from "react-datepicker";
import { handleCalendarChange } from '../../data/functions/pack_functions';
import { useGetDynamicRatio } from '../../components/CustomHooks/useGetDynamicRatio';
import { CreateLockButton, HeaderCont2024, Modal2024, ModalContent2024, ModalOverlay2024, NotFoundWrapper, PageBody2024, PageWrapper2024, theme2024 } from '../../Styles2024';
import { getWaxdaoPrice } from '../TokenCreator';
import { LPLockContainer, LockAmountInput } from '../locker/LockStyles';
import { showCautionMessage } from '../token_tracker/tracker_functions';



const CreatePool = () => {
  
  const {   
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    wharfSession
  } = useStateContext();
 
const [farmName, setFarmName] = useState("");
const [farmLogo, setFarmLogo] = useState("");
const [decimals, setDecimals] = useState(0);
const [tokenToStake, setTokenToStake] = useState("");
const [tokenContract, setTokenContract] = useState("");
const [rewardToken, setRewardToken] = useState("");
const [rewardContract, setRewardContract] = useState("");
const [rewardDecimals, setRewardDecimals] = useState(0);
const [timestamp, setTimestamp] = useState(Date.now() / 1000);
const [paymentMethod, setPaymentMethod] = useState("");
const [assetToBurn, setAssetToBurn] = useState("");
const [stakingAmount, setStakingAmount] = useState(0);
const [stakingPeriod, setStakingPeriod] = useState(1);
const [stakingSeconds, setStakingSeconds] = useState(86400);

const [ratio, getDynamicRatio, ratioIsLoading] = useGetDynamicRatio();

 
useEffect(() => {
 let isMounted = true;
 if (isMounted && !ratio?.next_push) {
     getDynamicRatio();
 }

 const interval = setInterval(() => {
     if (isMounted && ratio && new Date().getTime() > ratio.next_push * 1000) {
         getDynamicRatio();
     }
 }, 5000); 

 return () => {
     clearInterval(interval);
     isMounted = false;
 };
}, [ratio, getDynamicRatio]);


  return (
    <PageWrapper2024>
    <PageBody2024>
      <NotFoundWrapper>
        <h2>Stop!</h2>
        <h3>New Farm Version</h3>
        <p>
        We've released a new and improved version of our token farms. They are now in a different location.
        </p>

        <NewRow />
        
        <a href={"https://farms.waxdao.io/farms?tab=create"} target="none">
        TAKE ME THERE
        </a>
        
      </NotFoundWrapper>
    </PageBody2024>
  </PageWrapper2024>
  )
}

export default CreatePool