import React from "react";
import { NewListingBody, NewListingCont, TallFiltersCont } from "../Styles";

const GuildCandidacy = () => {
  return (
    <NewListingCont>
      <NewListingBody>
        <TallFiltersCont>
          <h2>Guild Candidacy Announcement</h2>
        </TallFiltersCont>
        <p>
          WaxDAO is thrilled to announce that we have applied to become a guild
          on the WAX Blockchain.
        </p>

        <p>
          After a year of building tools on WAX, we have decided to contribute
          more to the current and future health of the network by providing
          public infrastructure for users and developers.
        </p>

        <p>
          <TallFiltersCont>
            <h2>Our current tools/products include:</h2>
          </TallFiltersCont>
          <br />
          <br />
          <ul>
            <li>&nbsp;&nbsp;&nbsp;&nbsp;• NFT farms and token farms</li>

            <li>&nbsp;&nbsp;&nbsp;&nbsp;• DAOs</li>

            <li>
              &nbsp;&nbsp;&nbsp;&nbsp;• Blends, NFT drops, and pre-minted packs
            </li>

            <li>&nbsp;&nbsp;&nbsp;&nbsp;• Synthetic tokens</li>

            <li>
              &nbsp;&nbsp;&nbsp;&nbsp;• Liquidity locker and slow-release escrow
            </li>

            <li>&nbsp;&nbsp;&nbsp;&nbsp;• Token creator</li>

            <li>
              &nbsp;&nbsp;&nbsp;&nbsp;• Market cap tracker for WAX-based tokens
            </li>
          </ul>
        </p>

        <p>
          <TallFiltersCont>
            <h2>Our current infrastructure includes:</h2>
          </TallFiltersCont>

          <br />
          <br />
          <ul>
            <li>&nbsp;&nbsp;&nbsp;&nbsp;• WAX Mainnet Producer node</li>

            <li>&nbsp;&nbsp;&nbsp;&nbsp;• WAX Testnet Producer node</li>

            <li>
              &nbsp;&nbsp;&nbsp;&nbsp;• WAX Mainnet API node (api.waxdaobp.io)
            </li>

            <li>
              &nbsp;&nbsp;&nbsp;&nbsp;• WAX Testnet API node
              (test-api.waxdaobp.io)
            </li>

            <li>
              &nbsp;&nbsp;&nbsp;&nbsp;• WAX Mainnet Partial History/Hyperion
              (hyperion.waxdaobp.io)
            </li>

            <li>
              &nbsp;&nbsp;&nbsp;&nbsp;• WAX Testnet History node
              (history-test.waxdaobp.io)
            </li>

            <li>&nbsp;&nbsp;&nbsp;&nbsp;• Atomic API node (aa.waxdaobp.io)</li>

            <li>
              &nbsp;&nbsp;&nbsp;&nbsp;• WAX Testnet peer node
              (p2p-test.waxdaobp.io)
            </li>

            <li>
              &nbsp;&nbsp;&nbsp;&nbsp;• WAX Mainnet peer node (p2p.waxdaobp.io)
            </li>

            <li>&nbsp;&nbsp;&nbsp;&nbsp;• IPFS node (ipfs.waxdaobp.io)</li>
          </ul>
        </p>

        <p>All of our tools can be found at <a href="https://waxdao.io">waxdao.io</a></p>

        <p>Info about our guild can be found at <a href="https://waxdaobp.io">waxdaobp.io</a></p>

        <p>
          We look forward to serving the WAX community - thank you for your
          continued support!
        </p>
      </NewListingBody>
    </NewListingCont>
  );
};

export default GuildCandidacy;
