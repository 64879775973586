import React from 'react'
import { NewProposal } from './LeftDiv'

const WaxLabs = () => {
  return (
    <NewProposal>
        <br/><br/><br/><br/><br/>
        Hello WaxLabs<br/><br/>

        Here is the mainnet version of the super blender:<br/>
        <a href="https://waxdao.io/blends" target="none" className="text-red">Blends</a>
        <br/><br/>
        The contract for that is waxdaomarket

        <br/><br/>
        Mainnet UI for the synthetic asset contract is located at <a href="https://waxdao.io/wallet" target="none" className="text-red">waxdao.io/wallet</a> under the "Tokens" &#10140; "Synthetics" section.
        <br/><br/>
        You can create, buy, and redeem synthetic tokens that represent a basket of up to 5 other assets.
        <br/><br/>
        The contract for that is waxdaosynths
        <br/><br/>
        You might not see any tokens created on mainnet by the time you see this, so if you want to test out buying/redeeming synths, you can do it on testnet at <a href="https://test.waxdao.io/wallet" target="none" className="text-red">test.waxdao.io/wallet</a> under the "Tokens" &#10140; "Synthetics" section... or you can just create a new synth on mainnet if you like.
        <br/><br/>
        If you have any other questions just let me know, thanks.



    </NewProposal>
  )
}

export default WaxLabs