import { useEffect } from "react";
import { useGetDropsByCreator } from "../../components/CustomHooks/useGetDropsByCreator";
import LoadingDiv from "../../components/LoadingDiv";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentUsername, currentWebsiteURL } from "../../data/config";
import { resizerPrefix, resizerSuffix } from "../../data/constants";
import { ClaimInfo, TokenCard } from "../../data/css/WalletStyles";

import { SmallButton } from "../../Styles";
import { buildIpfsUrl } from "../../data/functions/global_functions";

export const showManageDropsSection = (currentToolsSection) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();

  const [myDrops, myPacks, getDropsByCreator, dropsAreLoading] =
    useGetDropsByCreator(currentUsername);

  useEffect(() => {
    if (currentToolsSection == "Manage Drops") {
      getDropsByCreator(currentUsername);
    }
  }, [currentToolsSection]);

  return (
    <span className={currentToolsSection != "Manage Drops" && "hidden"}>
      {!dropsAreLoading && (
        <span>
          {myDrops?.length > 0 ? (
            myDrops.map((item, index) => (
              <span key={index}>
                <TokenCard>
                  <a href={`${currentWebsiteURL}/drops/${item.ID}`}>
                    <img
                      src={buildIpfsUrl(item.drop_logo, 200)}
                      onError={(e) =>
                        (e.target.onerror = null)(
                          (e.target.src =
                            "https://logos.waxdao.io/default-logo.png")
                        )
                      }
                    />
                  </a>
                  <ClaimInfo>
                    Drop {item.ID}
                    <a href={`${currentWebsiteURL}/edit-drop/${item.ID}`}>
                      <SmallButton>Edit Drop</SmallButton>
                    </a>
                  </ClaimInfo>
                </TokenCard>
              </span>
            ))
          ) : (
            <div className="w-100 text-center pt-3">No drops to show</div>
          )}
        </span>
      )}
      {dropsAreLoading && <LoadingDiv />}
    </span>
  );
};
