import styled from 'styled-components'
import { theme } from '../../Styles'


<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>

//COLORS
//#c30000
//#47158e
//#dc0bb6

export const DynamicTempIDRow = styled.div`
    width: 100%;
`

export const TemplateTable = styled.div`
    width: 100%;
    border: 1px solid #bcbcbc;
   
`

export const TemplateTitleRow = styled.div`
    width: 100%;
    border-bottom: 1px solid #bcbcbc;
   
`

export const TemplateIDHeader = styled.div`
    padding-left: 5px;
    display: inline-block;
    width: 40%;
    border-bottom: 1px solid #bcbcbc;
   
`

export const StakingValueHeader = styled.div`
    padding-left: 5px;
    display: inline-block;
    width: 60%;
    border-bottom: 1px solid #bcbcbc;
    border-left: 1px solid #bcbcbc;
   
`


export const TemplateTblRow = styled.div`
    width: 100%;
    border-bottom: 1px solid #bcbcbc;
    font-size: 14px;
   
`

export const TemplateIDColumn = styled.div`
    padding-left: 5px;
    display: inline-block;
    width: 40%;
    border-bottom: 1px solid #bcbcbc;
   
`

export const StakingValueColumn = styled.div`
    padding-left: 5px;
    display: inline-block;
    width: 60%;
    border-bottom: 1px solid #bcbcbc;
    border-left: 1px solid #bcbcbc;
   
`

export const CollectionTable = styled.div`
    width: 100%;
    border: 1px solid #bcbcbc;
   
`

export const CollectionRow = styled.div`
    width: 100%;
    border-bottom: 1px solid #bcbcbc;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
   
`


export const FarmNFT = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width: 200px;
    max-width: 100%;
    padding:15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;   
`

export const FarmNFTTitle = styled.div`
    width: 100%;
    max-width: 100%;
    padding:0px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: nowrap;
    justify-content: center;   
`

export const FarmNFTImageBG = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;
    padding:0px;
    height: 160px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    justify-content: center;   
    background-color: #343334;
    margin-bottom: 15px;
`

export const FarmNFTLogo = styled.img`
    width: auto;
    max-width: 95%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
`

export const FarmNFTVideo = styled.video`
    width: auto;
    max-width: 95%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
`

export const FarmNFTStakingRate = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 350px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${theme.secondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const StakeableAssetsContainer = styled.div`
    color: ${theme.textSecondary};
    a{
        color: ${theme.secondary};
    }
`

export const BoldWhiteSpan = styled.span`
    color: ${theme.textMain};
    font-weight: 900;
`