import { useEffect } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentWebsiteURL } from "../../data/config";

import {
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";

export const showManageEscrowSection = (currentToolsSection) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();

  useEffect(() => {
    //   if (currentToolsSection == "Escrow") {
    //
    //   }
  }, [currentToolsSection]);

  return (
    <span className={currentToolsSection != "Escrow" && "hidden"}>
      <TallFiltersCont>
        <h2>Escrow Manager</h2>
        <br />
        <TallFiltersInnerCont>
          This section is coming soon, but for now, you can manage your escrow
          deals on the{" "}
          <a href={`${currentWebsiteURL}/manage-escrow`}>Manage Escrow Section</a> of WaxDAO.
        </TallFiltersInnerCont>
      </TallFiltersCont>
    </span>
  );
};
