import React from 'react'
import { useStateContext } from '../contexts/ContextProvider';
import { ESCROW_STATUSES } from '../data/constants';
import { PayerDripCardWrapper, ReceiverDetails, ReceiverIDNumber, ReceiverStatus, ReceiverTotal } from '../data/css/EscrowStyles';
import { cancelDrip, depositEscrowFunds, finalizeDrip } from '../data/functions/escrow_functions';
import { SmallButton } from '../Styles';
const PayerDripCard = (props) => {
    const item = props.item;

    const { 
        enterModalText,
        setEnterModalText,
        loadingDisplay, 
        setLoadingDisplay, 
        enterButtonsDisplay,
        setEnterButtonsDisplay,
        enterModalDisplay, 
        setEnterModalDisplay,
        wharfSession
      } = useStateContext();

  return (
    <PayerDripCardWrapper>
        <ReceiverIDNumber>ID: {item.ID}</ReceiverIDNumber>
        <ReceiverStatus>
        Status:                     {
                      ESCROW_STATUSES[
                        ESCROW_STATUSES.findIndex(
                          (attributeIndex) =>
                            attributeIndex.value === item.status
                        )
                      ].display_text
                    }
        </ReceiverStatus>   

        <ReceiverDetails>
        Details: {item.payout_amount}@{item.token_contract} released to <a href={`https://waxblock.io/account/${item.receiver}`} target="none">{item.receiver}</a>, every {item.hours_between_payouts} hours from {new Date(item.start_time * 1000).toLocaleDateString()} to {new Date(item.end_time * 1000).toLocaleDateString()}
        </ReceiverDetails>

        <ReceiverTotal>
        Total: {item.total_amount}
        </ReceiverTotal>
        <br/><br/>

        <div className='flex justify-around w-100'>
            {item.status == 0 && (
                <SmallButton onClick={() => {depositEscrowFunds(item.ID, item.total_amount, item.token_contract, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession)}}>
                    Deposit
                </SmallButton>
            )}

            {item.status == 1 && (
                <SmallButton onClick={() => {finalizeDrip(item.ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession)}} >
                    Finalize
                </SmallButton>
            )}

            {(item.status == 0 || item.status == 1) && (
                <SmallButton onClick={() => {cancelDrip(item.ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession)}} >
                    Cancel
                </SmallButton>
            )}

        </div>

    </PayerDripCardWrapper>
  )
}

export default PayerDripCard