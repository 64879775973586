import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  ModalOverlay,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  Modal,
  CloseWindow,
} from "../components/LeftDiv";
import "./datepicker.css";
import {
  mainnetWebsiteURL,
} from "../data/config";
import {
    MainButton,
  NewListingBody,
  NewListingCont,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../Styles";
import { isLoggedIn } from "../data/functions/wallet_functions";
import Error from "../components/Error";
import { deployBlankContract } from "../data/functions/royalty";
import { useStateContext } from "../contexts/ContextProvider";



const RemoveContract = () => {
    const {
        enterModalText,
        setEnterModalText,
        loadingDisplay,
        setLoadingDisplay,
        setEnterButtonsDisplay,
        enterModalDisplay,
        setEnterModalDisplay,
        wharfSession
      } = useStateContext();


      const [enteredText, setEnteredText] = useState("");

  
  
if(isLoggedIn()){
    return (
        <div id="seo">
          <Helmet>
            <title>Remove Contract - WaxDAO</title>
            <meta
              name="description"
              content="Remove a contract from your Wax account using this tool"
            />
            <link rel="canonical" href={`${mainnetWebsiteURL}/remove-contract`} />
          </Helmet>
    
            <ModalOverlay className={enterModalDisplay} />
            <Modal className={enterModalDisplay}>
              <ModalContent>{enterModalText}</ModalContent>
    
              <Spinner style={{ display: loadingDisplay }}>
                <SpinnerRed />
                <SpinnerBlue />
                <SpinnerGreen />
              </Spinner>
    
              <br />
              <CloseWindow
                onClick={() => {
                  setEnterModalDisplay("hidden");
                }}
              >
                Close Window
              </CloseWindow>
            </Modal>
    
            <NewListingCont>
              <NewListingBody>

                <TallFiltersCont>
                  <h2>Contract Removal Tool</h2>
                  <br />
                  <TallFiltersInnerCont>
                    IMPORTANT: This tool will ERASE ANY CONTRACT on your Wax account. Only use this if you are 100% positive you want to remove your contract!
                    <br/><br/>
                    WaxDAO will NOT be held responsible for any mistakes you make. This process is IRREVERSIBLE!
                    <br/><br/>
                    Type "I want to remove my contract" (without quotes) into the box, and a button will magically appear.

                    <br/><br/>
                    <textarea 
                        placeholder="Are you sure bro?"
                        value={enteredText}
                        onChange={(e) => {
                            setEnteredText(e.target.value)
                        }}
                        maxLength={40}
                    />
                  </TallFiltersInnerCont>
                </TallFiltersCont>
                                

                {enteredText == "I want to remove my contract" && (
                    <span>
                    <TallFiltersCont>
                    <h2>DANGEROUS! NO TURNING BACK!</h2>
                    <br />
                    <div className="pl-5">Click the button below to ruin the lives of anyone who is depending on the existence of your contract.</div>
                    </TallFiltersCont>  

                    <TallFiltersCont>
                    <div className="w-100 text-center items-center justify-center mt-4">
                        <MainButton
                            onClick={() => {
                                deployBlankContract(
                                    setEnterModalText,
                                    setLoadingDisplay,
                                    setEnterButtonsDisplay,
                                    setEnterModalDisplay,
                                    wharfSession                                    
                                )
                            }}
                        >
                            ERASE CONTRACT NOW
                        </MainButton>
                    </div>
                    </TallFiltersCont>  

                    </span>
                )}
                          
    
                
              </NewListingBody>
            </NewListingCont>
          <br />
          <br />
          <br />
        </div>
      );
}
else if(!isLoggedIn()){
    return <Error error="You need to log in to use this tool" />
}

};

export default RemoveContract;