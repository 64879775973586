import { currentWebsiteURL, defaultSpinnerDuration, defaultTxSettings } from "../config";
import { getWalletSession } from "./wallet_functions";

export const isClaimable = (drip) => {
    let secondsBetweenPayouts = drip.hours_between_payouts * 3600;
    if(drip.last_claim + secondsBetweenPayouts < Date.now() / 1000){
        return true;
    } else {
        return false;
    }
}

export const getNextClaim = (drip) => {
    let secondsBetweenPayouts = drip.hours_between_payouts * 3600;
    if(drip.last_claim + secondsBetweenPayouts > Date.now() / 1000){
        return Math.round(( (drip.last_claim + secondsBetweenPayouts) - (Date.now() / 1000) ) / 60);
    } else {
        return null;
    }
}

export const handleEscrowFilter = (textToFind, escrowFilters, setEscrowFilters) => {
    const list = [...escrowFilters];
    if(list.indexOf(textToFind) > -1){
        list.splice(list.indexOf(textToFind), 1);
        setEscrowFilters(list);
    } else {
        setEscrowFilters([...list, textToFind]);
    }
}

export const createDrip = async (receivingAccount, token_contract, payout_amount, precision, token_name, timestamp, hours_between_payouts, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

    let amountToSend = Number(payout_amount).toFixed(Number(precision)) + " " + token_name.toUpperCase();

      const action = [{
        account: 'waxdaoescrow',
        name: 'createdrip',
        authorization: [session.permissionLevel],
        data: {
            payer: session.actor,
            receiver: receivingAccount,
            token_contract: token_contract,
            payout_amount: amountToSend,
            end_time: timestamp,
            hours_between_payouts: hours_between_payouts,
        }
        }]

        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText("Processing your transaction...");
          const timer = setTimeout(() => {
            setEnterModalText(<span>
              Your slow drip has been created. To deposit the funds, go to the{" "}
              <a href={`${currentWebsiteURL}/manage-escrow`}>manage escrow page</a>
              </span>
              );
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }        
        
} //end createDrip

export const finalizeDrip = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

      const action = [{
            account: 'waxdaoescrow',
            name: 'finalizedrip',
            authorization: [session.permissionLevel],
          data: {
              ID: ID,
              payer: session.actor,
          }
        }]

        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText("Processing your transaction...");
          const timer = setTimeout(() => {
            setEnterModalText("Your drip has been finalized")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }        
        
  } //end finalizeDrip

export const cancelDrip = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

      const action = [{
            account: 'waxdaoescrow',
            name: 'canceldrip',
            authorization: [session.permissionLevel],
          data: {
              ID: ID,
              payer: session.actor,
          }
        }]

        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText("Processing your transaction...");
          const timer = setTimeout(() => {
            setEnterModalText("Your drip has been cancelled")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }        

  } //end cancelDrip  


export const claimDrip = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

      const action = [{
            account: 'waxdaoescrow',
            name: 'claimdrip',
            authorization: [session.permissionLevel],
          data: {
              ID: ID,
              receiver: session.actor,
          }
        }]
        
        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText("Processing your transaction...");
          const timer = setTimeout(() => {
            setEnterModalText("Your claim was successful")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }        

  } //end claimDrip

  export const depositEscrowFunds = async (ID, amount, contract, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

      const action = [{
            account: contract,
            name: 'transfer',
            authorization: [session.permissionLevel],
          data: {
              from: session.actor,
              to: 'waxdaoescrow',
              quantity: amount,
              memo: "|drip_deposit|" + ID + "|",
          }
        }]

        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText("Processing your transaction...");
          const timer = setTimeout(() => {
            setEnterModalText("Your deposit was successful")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }

  } //end depositEcrowFunds