import styled from 'styled-components'
import { currentTheme, testTheme, theme } from '../../Styles'

export const CreatorLockCardWrapper = styled.div`
    display: block;
    position: relative;
    width: 100%;
    height: 230px;
    background-color: ${theme.mainDarker};
    border: 1px solid transparent;
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 2em;
    a{
        color: ${theme.secondary};
    }
    @media (max-width: 460px) {
        height: 260px;
    }
    @media (max-width: 375px) {
        height: 280px;
    }    
`