import React, {useState} from 'react'
import { AttributeModalInnerWrapper, AttributeModalWrapper } from '../data/css/PremiintStyles';
import { MainButton } from '../Styles';

import { GameButton, ModalContent } from './LeftDiv'
import { handleCalendarChange } from '../data/functions/pack_functions';
import DatePicker from "react-datepicker";
import ".././pages/datepicker.css";
import { setFarmExpiration } from '../pages/farmsv2/v2_farm_functions';
import { useStateContext } from '../contexts/ContextProvider';

const OpenFarmModal = (props) => {
    const { wharfSession } = useStateContext();

    const openOrExtend = props.openOrExtend;
    const showOpenFarmModal = props.showOpenFarmModal;
    const setShowOpenFarmModal = props.setShowOpenFarmModal;
    const farmName = props.farmName;
    const setEnterModalDisplay = props.setEnterModalDisplay;
    const setEnterModalText = props.setEnterModalText;
    const setLoadingDisplay = props.setLoadingDisplay;
    const setEnterButtonsDisplay = props.setEnterButtonsDisplay;
    
    const [endTime, setEndTime] = useState(Math.round(Date.now() / 1000));


  return (
    <AttributeModalWrapper className={!showOpenFarmModal && "hidden"}>
        <AttributeModalInnerWrapper>
            <ModalContent>
            <h3>Farm Expiration</h3>
                  <DatePicker
                    showTimeSelect
                    timeIntervals={15}
                    selected={new Date(endTime * 1000)}
                    onChange={(e) => {
                        handleCalendarChange(e, setEndTime);
                    }}
                  />
                  <br />
                  <br />

 
            <MainButton onClick={() => { 
                setFarmExpiration(farmName,
                    openOrExtend,
                    endTime,
                    setEnterModalText,
                    setLoadingDisplay,
                    setEnterButtonsDisplay,
                    setEnterModalDisplay,
                    wharfSession)
                setShowOpenFarmModal(false);
                
                }}>
            {openOrExtend} Farm
            </MainButton>        
            
        </ModalContent>
      </AttributeModalInnerWrapper>
      <br/>
      <GameButton onClick={() => setShowOpenFarmModal(false) }>
        Close Window
      </GameButton>
      </AttributeModalWrapper>
  )
}

export default OpenFarmModal