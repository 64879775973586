import React, { useState, useEffect } from 'react';
import { AttributeModalInnerWrapper, AttributeModalWrapper } from '../data/css/PremiintStyles';
import { GameButton, ModalContent, ModalOverlay } from './LeftDiv';

const NotificationModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasSeenUpgradeNotification = localStorage.getItem('saw_upgrade_notification');

    if (!hasSeenUpgradeNotification) {
      //setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem('saw_upgrade_notification', 'true');
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <span>
        <ModalOverlay />
        <AttributeModalWrapper>
          <AttributeModalInnerWrapper>
            <ModalContent>
                <div className='text-center'>
              New version of WaxDAO available. 
              <br/><br/>
              If you don't clear your browser cache, you will have issues logging in and signing transactions.
              </div>
            </ModalContent>
          </AttributeModalInnerWrapper>
          <br />
          <GameButton onClick={handleCloseModal}>
            I Understand
          </GameButton>
        </AttributeModalWrapper>
        </span>
      )}
    </>
  );
};

export default NotificationModal;
