import React, { useEffect } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import {Helmet} from "react-helmet";
import { currentWebsiteURL, mainnetWebsiteURL } from '../../data/config';
import { JobOfferTitle, MilestoneContainer, NewListingBody, NewListingCont } from '../../Styles';
import { MdGeneratingTokens, MdListAlt } from 'react-icons/md';
import { RiExchangeDollarFill, RiStockLine } from 'react-icons/ri';


const TokenNav = () => {
  
  const {setCurrentNavbarLocation } = useStateContext();

  useEffect(() => {
    setCurrentNavbarLocation("Tokens");
  }, [])
  

  return (
    <div id="seo">
    <Helmet>
    <title>Wax Tokens On WaxDAO</title>
    <meta name="description" content="Create, view and submit WAX tokens" />
    <link rel="canonical" href={`${mainnetWebsiteURL}/wax-tokens`} />
    </Helmet>


    <NewListingCont>
        <NewListingBody>
            <JobOfferTitle>
                Tokens
            </JobOfferTitle>

            <a href={`${currentWebsiteURL}/tokens`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><RiStockLine className="inline-block" /> Market Cap Tracker</h3>
            </MilestoneContainer>
            </a>            

            <a href={`${currentWebsiteURL}/token-creator`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><MdGeneratingTokens className="inline-block" /> WAX Token Creator</h3>
            </MilestoneContainer>
            </a>

            <a href={`${currentWebsiteURL}/migrate`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><RiExchangeDollarFill className="inline-block" /> Token Migration</h3>
            </MilestoneContainer>
            </a>            

            <a href={`${currentWebsiteURL}/request-listing`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><MdListAlt className="inline-block" /> Listing Request</h3>
            </MilestoneContainer>
            </a>




        </NewListingBody>
    </NewListingCont>   

    </div>
  )
}

export default TokenNav