import { useState, useRef } from "react";
import { currentApiList, getCurrentApiList } from "../../data/config";
import axios from 'axios';

export const useGetPoolsByCreator = () => {
    
    const [poolsByCreator, setPoolsByCreator] = useState([]);
    const [creatorPoolsAreLoading, setCreatorPoolsAreLoading] = useState(true);
    const apiIndex = useRef(0);

    const getPoolsByCreator = (UserName) => {
        if (apiIndex.current >= currentApiList.length) {
            console.error("All API requests failed");
            return;
        }

        axios.post(`${currentApiList[apiIndex.current]}/v1/chain/get_table_rows`,{
            table: "pools",
            scope: "waxdaofarmer",
            code: "waxdaofarmer",
            key_type: "name",
            index_position: 3,
            limit: 100,
            lower_bound: UserName,
            upper_bound: UserName,
            json: true,
        }).then((res) => {
            setPoolsByCreator(res.data.rows);
            console.log(res.data.rows);
        }).catch((error) => {
            console.error(error);
            apiIndex.current++;
            getPoolsByCreator(UserName, setShowPools);
        }).finally(() => setCreatorPoolsAreLoading(false))
    }

    return [poolsByCreator, getPoolsByCreator, creatorPoolsAreLoading]
}
