import React, { useEffect, useState } from 'react'
import { currentWebsiteURL } from '../data/config';
import { buildDropCardImage2024, collectionIsNsfw, showNsfwOverlay } from '../data/functions/global_functions';
import { hasNsfwHidden } from '../pages/Settings';
import { DropCardCollectionNameWrapper2024, DropCardPriceAndSupplyWrapper, DropCardTopHalf2024, DropCardWrapper2024, NftCardShowDetailsWrapper, UnboxCardUnboxNow } from '../data/css/NftStyles2024';
import LoadingSpinnerImageFallback from './LoadingSpinnerImageFallback';


const UnboxCard2024 = (props) => {
    const item = props.item;
    const nsfwCollections= props.nsfwCollections;
    const nsfwIsLoading = props.nsfwIsLoading;

    const [isLoading, setIsLoading] = useState(true);


  return (
    <DropCardWrapper2024>
        <DropCardTopHalf2024>
          {(isLoading)&& <LoadingSpinnerImageFallback />}

        {!nsfwIsLoading && (
          <a href={`${currentWebsiteURL}/unbox/${item.id}`}>
        {collectionIsNsfw(item.collection_name, nsfwCollections) && hasNsfwHidden() ? 
        showNsfwOverlay() : 
        buildDropCardImage2024(item, setIsLoading)}
          </a>
        )} 
        </DropCardTopHalf2024>


      {item.collection_name && (
          <a href={`${currentWebsiteURL}/collection/${item.collection_name}`}>
        <DropCardCollectionNameWrapper2024>
        {item.collection_name}
        </DropCardCollectionNameWrapper2024>
        </a>
      )}

        <DropCardPriceAndSupplyWrapper>
            <h2>{item?.id && `Pack ${item.id}`}</h2>    
        </DropCardPriceAndSupplyWrapper>

        <NftCardShowDetailsWrapper>
            <UnboxCardUnboxNow href={`${currentWebsiteURL}/unbox/${item.id}`}>
            UNBOX NOW
            </UnboxCardUnboxNow>
        </NftCardShowDetailsWrapper>
    </DropCardWrapper2024>
  )
}

export default UnboxCard2024