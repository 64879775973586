import { useState } from "react";
import { backedTokensApi, waxdaoApi } from "../../data/config";
import axios from 'axios';

export const useGetCountsByUser = () => {
    
    const [counts, setCounts] = useState({});
    const [countsAreLoading, setCountsAreLoading] = useState(true);

    const getCounts = async (user) => {

        setCountsAreLoading(true);

        const requestData = {
            user: user,
          };
          
          try {
            const res = await axios
            .post(`${waxdaoApi}/get-user-counts`, requestData, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            })    

            if (res.data) {
                setCounts(res.data);
                console.log(res.data);
            }
        } catch (error) {
            console.log(`Failed to fetch token data: ` + error);
        } finally {
          setCountsAreLoading(false);
        }           

    }  

    return [counts, getCounts, countsAreLoading]
}
