import { useState } from "react";
import { mainnetApiList } from "../../data/config";
import axios from 'axios';

export const useGetBannerAds = () => {
    
    const [bannerAds, setBannerAds] = useState([]);
    const [bannersAreLoading, setBannersAreLoading] = useState(true);

    const getBanners = async () => {

        setBannersAreLoading(true);

        for (const api of mainnetApiList) {

            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`, {
                    table:"adslots",
                    scope:"ads.waxdao",
                    code:"ads.waxdao",
                    limit:100,
                    lower_bound: (Math.round(Date.now() / 1000) - 86400),
                    upper_bound: Math.round(Date.now()),
                    json:true
                });

                if (res.data.rows) {
                    setBannerAds(res.data.rows);
                    console.log(res.data.rows);
                    break;
                }
            } catch (error) {
                console.log(`Failed on API endpoint ${api}. Error: ${error}`);
            }
        }
        
        setBannersAreLoading(false);
    }

    return [bannerAds, getBanners, bannersAreLoading]
}
