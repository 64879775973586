import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  ModalOverlay,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  Modal,
  CloseWindow,
} from "../components/LeftDiv";
import {
  mainnetWebsiteURL,
} from "../data/config";
import refresh_icon from '../data/refresh_icon.png';
import { useStateContext } from "../contexts/ContextProvider";
import { HeaderCont2024, Modal2024, ModalContent2024, ModalOverlay2024, PageBody2024, PageWrapper2024, RightAlignedContainer, SubmitSwapButton, SwapCont, SwapInputCont, SwapOutputCont, SwapRow1, SwapRow2, SwapRow3, SwapRowContractName, SwapTokenAmountDiv, SwapTokenDiv, SwapTopCont, theme2024 } from "../Styles2024";
import { isLoggedIn, transferTokensNew } from "../data/functions/wallet_functions";
import { useGetTokenBalsFromLightApi } from "../components/CustomHooks/useGetTokenBalsFromLightApi";
import { theme } from "../Styles";
import SelectTokenModal from "../components/SelectTokenModal";

export const showTokenBalance = (selectedToken, tokenBalances, balancesAreLoading) => {
  if(!isLoggedIn()){
    return "0";
  }
  if (balancesAreLoading) {
    return "LOADING...";
  }
  
  if (selectedToken && tokenBalances) {
    const matchingToken = tokenBalances.find(token => 
      token.contract === selectedToken.contract && token.currency === selectedToken.currency);

    return matchingToken ? matchingToken.amount : "0";
  }

  return "0";
}

export const calculateTokensFromPercentage = (selectedToken, tokenBalances, balancesAreLoading, setInputAmount, setOutputAmount, percentage) => {
  if (balancesAreLoading) {
    return;
  }

  const validPercentages = [25, 50, 75, 100]

  if(!percentage || !validPercentages.includes(percentage)){
    return;
  }
  
  if (selectedToken && tokenBalances) {
    const matchingToken = tokenBalances.find(token => 
      token.contract === selectedToken.contract && token.currency === selectedToken.currency);

    if(matchingToken){
      if(matchingToken.amount == 0){
        setInputAmount(0)
        setOutputAmount(0)
        return
      }
      else{
        let new_amount = Number(Number(matchingToken.amount) / 100) * Number(percentage);
        setInputAmount(new_amount)
        setOutputAmount(new_amount)
        return;
      }
    } 
  }

  return;
}

export const handleInputChange = (e, setInputAmount, setOutputAmount) => {
  const value = e.target.value;
  if (value === "" || /^\d*\.?\d*$/.test(value)) {
    if (value === "" || !isNaN(value)) {
      setInputAmount(value);
      setOutputAmount(value);
    }
  }
}

const Migrate = () => {
  const { wharfSession } = useStateContext();

  const [enterModalDisplay, setEnterModalDisplay] = useState("hidden");
  const [enterModalText, setEnterModalText] = useState("Awaiting signature...");
  const [loadingDisplay, setLoadingDisplay] = useState("none");

  const [inputAmount, setInputAmount] = useState(null);
  const [outputAmount, setOutputAmount] = useState(null);

  const [tokenBalances, getTokenBalances, balancesAreLoading] = useGetTokenBalsFromLightApi();
  const [refresh, setRefresh] = useState(false);
  const [selectedToken, setSelectedToken] = useState({contract:"mdcryptonfts", currency:"WOJAK",decimals:0})
  const [newWaxdaoToken, setNewWaxdaoToken] = useState({contract:"token.waxdao", currency:"WAXDAO"})
  const [showSelectTokenModal, setShowSelectTokenModal] = useState(false);

  useEffect(() => {
    if(isLoggedIn()){
      getTokenBalances();
    }
  }, [refresh])

  return (
    <div id="seo">
      <Helmet>
        <title>Migrate WAXDAO Tokens - WaxDAO</title>
        <meta
          name="description"
          content="Migrate to the new WAXDAO token"
        />
        <link rel="canonical" href={`${mainnetWebsiteURL}/migrate`} />
      </Helmet>

        <ModalOverlay2024 className={enterModalDisplay} />
        <Modal2024 className={enterModalDisplay}>
        <div style={{width: "100%", textAlign: "right", paddingRight: "5px", fontSize: "22px", fontWeight: "400", color: theme2024.textMain}}><button
            onClick={() => {
                setEnterModalDisplay("hidden");
              }}            
            >X</button></div>             
          <ModalContent2024>{enterModalText}</ModalContent2024>
          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>
        </Modal2024>

            {showSelectTokenModal && (<span>
              <ModalOverlay2024 />
              <SelectTokenModal 
              showAttributeModal={showSelectTokenModal}
              setShowAttributeModal={setShowSelectTokenModal}
              tokens = {tokenBalances}
              selectedToken = {selectedToken}
              setSelectedToken = {setSelectedToken} 
              location = {`migrate`}          
            />
            </span>)}

        <PageWrapper2024>
          <PageBody2024>
            <HeaderCont2024>
              <div>
              <h2>MIGRATE</h2>
              </div>

              <div>
                <h3>
                  Swap your old WOJAK or WAXDAO tokens for the new WAXDAO token.
                </h3>
              </div>
            </HeaderCont2024>

            <SwapCont>
              <SwapTopCont>
                <RightAlignedContainer>
                  <button
                    onClick={() => {
                      setRefresh((prev) => !prev)
                    }}
                  >
              <img src={refresh_icon} 
                style={{
                  filter: 'invert(25%) brightness(1) contrast(1)',
                  width: '25px',
                  height: '25px'
                }}
              />
              </button>
              </RightAlignedContainer>
            </SwapTopCont>
            <SwapInputCont>
              <SwapRow1><div>SELL</div><div>BALANCE: {showTokenBalance(selectedToken, tokenBalances, balancesAreLoading)}</div></SwapRow1>
              
              <SwapRow2>
                <SwapTokenDiv>
                  <button
                    onClick={() => {
                      setShowSelectTokenModal(true)
                    }}
                  >
                  {selectedToken?.currency}
                  </button>
                  </SwapTokenDiv>
                <SwapTokenAmountDiv>
                  <input 
                    type="text"
                    name="amountIn"
                    placeholder="69420"
                    value={inputAmount}
                    onChange={(e) => {
                      handleInputChange(e, setInputAmount, setOutputAmount);
                    }}
                    />
                  </SwapTokenAmountDiv>
              </SwapRow2>

              <SwapRowContractName>
                mdcryptonfts
              </SwapRowContractName>

            <SwapRow3>
              <button
                onClick={() => {
                  calculateTokensFromPercentage(selectedToken, tokenBalances, balancesAreLoading, setInputAmount, setOutputAmount, 25)
                }}
              >25%</button>
              <button
                onClick={() => {
                  calculateTokensFromPercentage(selectedToken, tokenBalances, balancesAreLoading, setInputAmount, setOutputAmount, 50)
                }}              
              >50%</button>
              <button
                onClick={() => {
                  calculateTokensFromPercentage(selectedToken, tokenBalances, balancesAreLoading, setInputAmount, setOutputAmount, 75)
                }}              
              >75%</button>
              <button
                onClick={() => {
                  calculateTokensFromPercentage(selectedToken, tokenBalances, balancesAreLoading, setInputAmount, setOutputAmount, 100)
                }}              
              >100%</button>
            </SwapRow3>

            </SwapInputCont>

            <SwapOutputCont>
            <SwapRow1><div>BUY</div><div>BALANCE: {showTokenBalance(newWaxdaoToken, tokenBalances, balancesAreLoading)}</div></SwapRow1>
            <SwapRow2>
                <SwapTokenDiv>WAXDAO</SwapTokenDiv>
                <SwapTokenAmountDiv>
                <input 
                    type="text"
                    name="amountOut"
                    placeholder="69420"
                    value={outputAmount}
                    onChange={(e) => {
                      handleInputChange(e, setInputAmount, setOutputAmount);
                    }}
                    />
                </SwapTokenAmountDiv>
              </SwapRow2>

              <SwapRowContractName>
                token.waxdao
              </SwapRowContractName>

            <SwapRow3>
            <button
                onClick={() => {
                  calculateTokensFromPercentage(newWaxdaoToken, tokenBalances, balancesAreLoading, setInputAmount, setOutputAmount, 25)
                }}
              >25%</button>
              <button
                onClick={() => {
                  calculateTokensFromPercentage(newWaxdaoToken, tokenBalances, balancesAreLoading, setInputAmount, setOutputAmount, 50)
                }}              
              >50%</button>
              <button
                onClick={() => {
                  calculateTokensFromPercentage(newWaxdaoToken, tokenBalances, balancesAreLoading, setInputAmount, setOutputAmount, 75)
                }}              
              >75%</button>
              <button
                onClick={() => {
                  calculateTokensFromPercentage(newWaxdaoToken, tokenBalances, balancesAreLoading, setInputAmount, setOutputAmount, 100)
                }}              
              >100%</button>
            </SwapRow3>


            </SwapOutputCont>
            
            <SubmitSwapButton
              disabled={(inputAmount == null || inputAmount == 0) }
              onClick={() => {
                transferTokensNew("token.waxdao",
                  inputAmount,
                  selectedToken,
                  "token migration",
                  setEnterModalDisplay,
                  setEnterModalText,
                  setLoadingDisplay,
                  wharfSession)
              }}
            >
            {(inputAmount == null || inputAmount == 0) ? (<span style={{color:theme.textSecondary}}>Enter an amount to swap</span>) : 
              <span>
              SWAP
              </span>}
            </SubmitSwapButton>

            </SwapCont>
          </PageBody2024>
        </PageWrapper2024>
    </div>
  );
};

export default Migrate;