import { useState } from "react";
import {
  currentAtomicApiList
} from "../../data/config";
import axios from "axios";

export const useGetMyCollections = () => {
  const [myCollections, setMyCollections] = useState([]);
  const [collectionsWereLoaded, setCollectionsWereLoaded] = useState(false);

  const getMyCollections = async (UserName) => {
    setCollectionsWereLoaded(false);
    for (const api of currentAtomicApiList) {
      try {
        const res = await axios.get(
          `${api}/atomicassets/v1/accounts/${UserName}`
        );

        if (res.data.data) {
          setMyCollections(res.data.data);
          console.log(res.data.data);
          break;
        }
      } catch (error) {
        console.log(error);
      }
    }
    setCollectionsWereLoaded(true);
  };

  return [myCollections, getMyCollections, collectionsWereLoaded];
};
