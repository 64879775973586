import {
  FoldersContainer,
  FoldersRow,
  MainButton,
  SingleFolderThird,
  SmallButton,
  TallFiltersCont,
  theme,
} from "../../Styles";
import { ModalErrorCont, ModalSuccessCont } from "../../Styles2024";
import LoadingDiv from "../../components/LoadingDiv";
import OpenFarmModal from "../../components/OpenFarmModal";
import { currentWebsiteURL, defaultSpinnerDuration, defaultTxSettings, v2FarmContract } from "../../data/config";
import { PROCESSING_TX_TEXT, V2_FARM_STATUSES, error_svg, success_svg } from "../../data/constants";
import {
  buildCollectionUrl,
  buildSchemaUrl,
  buildTemplateUrl,
} from "../../data/functions/global_functions";
import { getWalletSession } from "../../data/functions/wallet_functions";
import { Table, TableData, TableHeader, TableRow } from "../daosv2/DAOStylesV2";

export const handleAddRewardToken = (setRewardTokens, rewardTokens) => {
  setRewardTokens([
    ...rewardTokens,
    { token_name: "", precision: "", contract: "" },
  ]);
};

export const handleRewardTokenValueChange = (
  e,
  index,
  rewardTokens,
  setRewardTokens
) => {
  const { name, value } = e.target;
  const list = [...rewardTokens];

  if (name == "token_name") {
    list[index][name] = value.toUpperCase();
  } else if (name == "precision") {
    if (isNaN(+value)) {
      return;
    }
    if (value === "") {
      list[index][name] = value;
    } else {
      list[index][name] = parseFloat(value);
    }
  } else if (name == "contract") {
    list[index][name] = value.toLowerCase();
  }

  setRewardTokens(list);
  console.log("Reward Tokens:");
  console.log(list);
};

export const handleRemoveRewardToken = (
  setRewardTokens,
  rewardTokens,
  index
) => {
  const list = [...rewardTokens];
  list.splice(index, 1);
  setRewardTokens(list);
};

export const handleProfileValueChange = (e, profile, setProfile) => {
  const { name, value } = e.target;
  const updatedProfile = { ...profile, [name]: value };
  setProfile(updatedProfile);
};

export const handleRewardAmountValueChange = (
  e,
  index,
  rewardAmounts,
  setRewardAmounts
) => {
  const { name, value } = e.target;
  const updated = [...rewardAmounts];
  updated[index][name] = value;
  setRewardAmounts(updated);
};

export const createFarmsDotWaxDaoFarm = async (
  farmName,
  avatar,
  coverImage,
  description,
  rewardTokens,
  selectedAsset,
  paymentMethod,
  waxdaoAmount,
  website,
  telegram,
  discord,
  twitter,
  atomichub,
  waxdao,
  medium,
  youtube,
  farmType,
  hoursBetweenPayouts,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

  let assertAction = {
    account: v2FarmContract,
    name: "assertpoint",
    authorization: [session.permissionLevel],
    data: {
      user: session.actor,
    },
  };

  let socials = {
    website: website,
    telegram: telegram,
    discord: discord,
    twitter: twitter,
    atomichub: atomichub,
    waxdao: waxdao,
    medium: medium,
    youtube: youtube,
  };

  let profile = {
    avatar: avatar,
    cover_image: coverImage,
    description: description,
  };

  let rwd_tokens = [];

  for (let tkn of rewardTokens) {
    let sym = Number(tkn.precision) + "," + tkn.token_name;
    rwd_tokens.push({ token_symbol: sym, contract: tkn.contract });
  }

  let paymentAction;

  if (paymentMethod == "Pay 250 Wax") {
    paymentAction = {
      account: "eosio.token",
      name: "transfer",
      authorization: [session.permissionLevel],
      data: {
        from: session.actor,
        to: v2FarmContract,
        quantity: "250.00000000 WAX",
        memo: "|create_farm|",
      },
    };
  } else if (paymentMethod == "Pay WAXDAO") {
    paymentAction = {
      account: "token.waxdao",
      name: "transfer",
      authorization: [session.permissionLevel],
      data: {
        from: session.actor,
        to: v2FarmContract,
        quantity: `${waxdaoAmount.toFixed(8)} WAXDAO`,
        memo: "|create_farm|",
      },
    };
  } else if (paymentMethod == "Burn 1 Wojak NFT") {
    paymentAction = {
      account: "atomicassets",
      name: "transfer",
      authorization: [session.permissionLevel],
      data: {
        from: session.actor,
        to: v2FarmContract,
        asset_ids: [selectedAsset],
        memo: "|create_farm|",
      },
    };
  }

    const action = [
      assertAction,
      paymentAction,
      {
        account: v2FarmContract,
        name: "createfarm",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          farmname: farmName,
          reward_tokens: rwd_tokens,
          profile: profile,
          socials: socials,
          hours_between_payouts: hoursBetweenPayouts,
          farm_type: farmType,
        },
      },
    ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText(
          <span>
            <ModalSuccessCont>
            {success_svg}
            </ModalSuccessCont>                  
          Your farm has been created. Manage it at the{" "}
          <a
            style={{ color: theme.secondary }}
            href={`${currentWebsiteURL}/v2/edit-farm/${farmName}`}
          >
            edit-farm page
          </a>
        </span>          
        )
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(<span>
        <ModalErrorCont>
        {error_svg}
        </ModalErrorCont>
        {e.message}        
      </span>);
      setEnterModalDisplay("");
    }

};

export const showEditFarmProfile = (
  farm_name,
  farmProfile,
  setFarmProfile,
  farmSocials,
  setFarmSocials,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  return (
    <span>
      <h3>Cover Image</h3>
      <input
        value={farmProfile.cover_image}
        placeholder="e.g. QMabc123..."
        name="cover_image"
        onChange={(e) => {
          handleProfileValueChange(e, farmProfile, setFarmProfile);
        }}
      />
      <br />
      <br />

      <h3>Avatar</h3>
      <input
        value={farmProfile.avatar}
        placeholder="e.g. QMabc123..."
        name="avatar"
        onChange={(e) => {
          handleProfileValueChange(e, farmProfile, setFarmProfile);
        }}
      />
      <br />
      <br />

      <h3>Description</h3>
      <textarea
        value={farmProfile.description}
        name="description"
        placeholder="Share some info about your farm/project"
        onChange={(e) => {
          handleProfileValueChange(e, farmProfile, setFarmProfile);
        }}
      />
      <br />

      <TallFiltersCont>
        <h2>Socials</h2>
      </TallFiltersCont>
      <br />
      <br />

      <h3>Website</h3>
      <input
        value={farmSocials.website}
        name="website"
        placeholder="e.g. waxdao.io"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>Twitter</h3>
      <input
        value={farmSocials.twitter}
        name="twitter"
        placeholder="e.g. twitter.com/MikeD_Crypto"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>Telegram</h3>
      <input
        value={farmSocials.telegram}
        placeholder="e.g. t.me/hoodpunks"
        name="telegram"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>Discord</h3>
      <input
        value={farmSocials.discord}
        placeholder="e.g. discord.gg/hello"
        name="discord"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>Medium</h3>
      <input
        value={farmSocials.medium}
        name="medium"
        placeholder="e.g. medium.com/wax"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>YouTube</h3>
      <input
        value={farmSocials.youtube}
        name="youtube"
        placeholder="e.g. youtube.com/MikeDCrypto"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>AtomicHub</h3>
      <input
        value={farmSocials.atomichub}
        name="atomichub"
        placeholder="e.g. wax.atomichub.io/waxdao"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>WaxDAO</h3>
      <input
        value={farmSocials.waxdao}
        name="waxdao"
        placeholder="e.g. waxdao.io/u/mikedcrypto5"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <div className="w-100 text-center items-center justify-center">
        <MainButton
          onClick={() => {
            saveProfile(
              farm_name,
              farmProfile,
              farmSocials,
              setEnterModalText,
              setLoadingDisplay,
              setEnterButtonsDisplay,
              setEnterModalDisplay,
              wharfSession
            );
          }}
        >
          Save Profile
        </MainButton>
      </div>
    </span>
  );
};

const saveProfile = async (
  farm_name,
  farmProfile,
  farmSocials,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

  const action = [
    {
      account: v2FarmContract,
      name: "setprofile",
      authorization: [session.permissionLevel],
      data: {
        user: session.actor,
        farmname: farm_name,
        profile: farmProfile,
        socials: farmSocials,
      },
    },
  ];

  try {
    const result = await session.transact({ actions: action }, defaultTxSettings);
    setLoadingDisplay("");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your profile has been saved")
      setLoadingDisplay("none");
    }, defaultSpinnerDuration);
    return () => clearTimeout(timer);
  } catch (e) {
    console.log('ERROR: ', e);
    setEnterModalText(e.message);
    setEnterModalDisplay("");
  }

};

export const showEditFarmStatus = (
  farmData,
  showOpenFarmModal,
  setShowOpenFarmModal,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  openOrExtend,
  setOpenOrExtend,
  setEnterButtonsDisplay,
  wharfSession
) => {
  return (
    <span>
      <OpenFarmModal
        showOpenFarmModal={showOpenFarmModal}
        setShowOpenFarmModal={setShowOpenFarmModal}
        farmName={farmData.farmname}
        setEnterModalDisplay={setEnterModalDisplay}
        setEnterModalText={setEnterModalText}
        setLoadingDisplay={setLoadingDisplay}
        openOrExtend={openOrExtend}
        setEnterButtonsDisplay={setEnterButtonsDisplay}
      />

      <Table>
        <tbody>
          <TableRow>
            <TableHeader>Status</TableHeader>
            <TableData>
              {
                V2_FARM_STATUSES[
                  V2_FARM_STATUSES.findIndex(
                    (attributeIndex) => attributeIndex.value === farmData.status
                  )
                ].display_text
              }
              {farmData.status == 0 && (
                <span>
                  {"  "}
                  <SmallButton
                    onClick={() => {
                      setOpenOrExtend("Open");
                      setShowOpenFarmModal(true);
                    }}
                  >
                    Open Farm
                  </SmallButton>
                </span>
              )}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Expires</TableHeader>
            <TableData>
              {new Date(farmData.expiration * 1000).toLocaleDateString()}{" "}
              <SmallButton
                onClick={() => {
                  setOpenOrExtend("Extend");
                  setShowOpenFarmModal(true);
                }}
              >
                Extend Farm
              </SmallButton>
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>NFTs Staked</TableHeader>
            <TableData>{farmData.total_staked}</TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Payout Frequency</TableHeader>
            <TableData>
              {farmData.hours_between_payouts} Hour
              {farmData.hours_between_payouts > 1 && "s"}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Reward Pools</TableHeader>
            <TableData>
              {farmData.reward_pools.map((item, index) => (
                <span>
                  {item.total_funds}
                  <br />
                </span>
              ))}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Rewards Per Payout</TableHeader>
            <TableData>
              {farmData.reward_pools.map((item, index) => (
                <span>
                  {item.total_hourly_reward}
                  <br />
                </span>
              ))}
            </TableData>
          </TableRow>
        </tbody>
      </Table>
    </span>
  );
};

export const showEditFarmRewards = (
  farmData,
  currentRewardTab,
  setCurrentRewardTab,
  stakeables,
  stakeablesAreLoading,
  currentStakeablesTab,
  setCurrentStakeablesTab,
  collectionToAdd,
  setCollectionToAdd,
  schemaToAdd,
  setSchemaToAdd,
  templateToAdd,
  setTemplateToAdd,
  amountsToAdd,
  setAmountsToAdd,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  amountsToDeposit,
  setAmountsToDeposit,
  attributeName,
  setAttributeName,
  attributeOp,
  setAttributeOp,
  attributeFilterBy,
  setAttributeFilterBy,
  attributeMinValue,
  setAttributeMinValue,
  attributeMaxValue,
  setAttributeMaxValue,
  attributeValuesList,
  setAttributeValuesList,
  wharfSession
) => {
  return (
    <span>
      <FoldersContainer>
        <FoldersRow>
          <SingleFolderThird
            selected={currentRewardTab == "Stakeables"}
            onClick={() => {
              setCurrentRewardTab("Stakeables");
            }}
          >
            Stakeables
          </SingleFolderThird>

          <SingleFolderThird
            selected={currentRewardTab == "Reward Pools"}
            onClick={() => {
              setCurrentRewardTab("Reward Pools");
            }}
          >
            Reward Pools
          </SingleFolderThird>
        </FoldersRow>
      </FoldersContainer>

      {currentRewardTab == "Stakeables" && (
        <span>
          <FoldersContainer>
            <FoldersRow>
              <SingleFolderThird
                selected={currentStakeablesTab == "View"}
                onClick={() => {
                  setCurrentStakeablesTab("View");
                }}
              >
                View
              </SingleFolderThird>

              <SingleFolderThird
                selected={currentStakeablesTab == "Add"}
                onClick={() => {
                  setCurrentStakeablesTab("Add");
                }}
              >
                Add
              </SingleFolderThird>
            </FoldersRow>
          </FoldersContainer>

          {currentStakeablesTab == "View" && (
            <span>
              {stakeablesAreLoading && <LoadingDiv />}

              {!stakeablesAreLoading && (
                <span>
                  {stakeables.length == 0 && (
                    <div>
                      <br />
                      Nothing to show
                    </div>
                  )}
                  {stakeables.length > 0 && (
                    <span>
                      <br />
                      <Table>
                        <tbody>
                          {stakeables.map((item, index) => (
                            <TableRow key={index}>
                              <TableHeader>
                                {farmData.farm_type == 0 &&
                                  item.collection_name}
                                {farmData.farm_type == 1 && item.schema_name}
                                {farmData.farm_type == 2 && item.template_id}
                                {farmData.farm_type == 3 && item.attribute_name}
                              </TableHeader>
                              <TableData>
                                {farmData.farm_type == 3 && (
                                  <span>
                                    Collection: {item.collection_name}
                                    <br />
                                    {item.filter_by == 1 && (
                                      <span>
                                        Schema: {item.schema_name}
                                        <br />
                                      </span>
                                    )}
                                    {item.op == 1 && (
                                      <span>
                                        Values:{" "}
                                        {item.values_list.map(
                                          (val, valIndex) => (
                                            <span key={valIndex}>
                                              {val}
                                              {valIndex <
                                                item.values_list.length - 1 &&
                                                ", "}
                                            </span>
                                          )
                                        )}
                                        <br />
                                      </span>
                                    )}
                                    {item.op == 2 && (
                                      <span>
                                        Minimum Value: {item.min_value}
                                        <br />
                                        Maximum Value: {item.max_value}
                                        <br />
                                      </span>
                                    )}
                                  </span>
                                )}
                                {item.reward_values.map((rwdItem, rwdIndex) => (
                                  <span key={rwdIndex}>
                                    {rwdItem.quantity}
                                    <br />
                                  </span>
                                ))}
                              </TableData>
                            </TableRow>
                          ))}
                        </tbody>
                      </Table>
                    </span>
                  )}
                </span>
              )}
            </span>
          )}

          {currentStakeablesTab == "Add" && (
            <span>
              <TallFiltersCont>
                <h2>Asset Info</h2>
              </TallFiltersCont>
              <br />
              <br />
              <h3>Collection Name</h3>
              <input
                placeholder="e.g. hoodpunknfts"
                value={collectionToAdd}
                maxLength={13}
                onChange={(e) => {
                  setCollectionToAdd(e.target.value.toLowerCase());
                }}
              />
              <br />
              <br />

              {farmData.farm_type == 3 && (
                <span>
                  <h3>Filter By</h3>
                  <select
                    onChange={(e) => {
                      setAttributeFilterBy(e.target.value);
                    }}
                  >
                    <option value="" hidden>
                      Choose
                    </option>
                    <option value="0">Collection Wide</option>
                    <option value="1">Specific Schema</option>
                  </select>
                  <br />
                  <br />

                  <h3>Validation Method</h3>
                  <select
                    onChange={(e) => {
                      setAttributeOp(e.target.value);
                    }}
                  >
                    <option value="" hidden>
                      Choose
                    </option>
                    <option value="0">Attribute Exists</option>
                    <option value="1">Value In List</option>
                    <option value="2">Value In Range</option>
                  </select>
                  <br />
                  <br />
                </span>
              )}

              {(farmData.farm_type == 1 ||
                (farmData.farm_type == 3 && attributeFilterBy == 1)) && (
                <span>
                  <h3>Schema Name</h3>
                  <input
                    placeholder="e.g. wojakpacks"
                    value={schemaToAdd}
                    maxLength={13}
                    onChange={(e) => {
                      setSchemaToAdd(e.target.value.toLowerCase());
                    }}
                  />
                  <br />
                  <br />
                </span>
              )}

              {farmData.farm_type == 3 && (
                <span>
                  <h3>Attribute Name</h3>
                  <input
                    placeholder="e.g. rarity"
                    value={attributeName}
                    maxLength={200}
                    onChange={(e) => {
                      setAttributeName(e.target.value);
                    }}
                  />
                  <br />
                  <br />

                  {attributeOp == 1 && (
                    <span>
                      <h3>List Of Values</h3>
                      <textarea
                        placeholder="rare|legendary|mythic"
                        value={attributeValuesList}
                        onChange={(e) => {
                          setAttributeValuesList(e.target.value);
                        }}
                      />
                      <br />
                      <span className="text-sm italic">
                        *Note: Separate each value using a pipe ( | ) character.
                        And do not leave spaces before/after the character
                        UNLESS the attribute is supposed to have a space at the
                        beginning/end.
                      </span>
                      <br />
                    </span>
                  )}

                  {attributeOp == 2 && (
                    <span>
                      <h3>Minimum Value</h3>
                      <input
                        placeholder="e.g. 6"
                        value={attributeMinValue}
                        onChange={(e) => {
                          setAttributeMinValue(e.target.value);
                        }}
                      />
                      <br />
                      <br />

                      <h3>Maximum Value</h3>
                      <input
                        placeholder="e.g. 69"
                        value={attributeMaxValue}
                        onChange={(e) => {
                          setAttributeMaxValue(e.target.value);
                        }}
                      />
                      <br />
                      <br />
                    </span>
                  )}
                </span>
              )}

              {farmData.farm_type == 2 && (
                <span>
                  <h3>Template ID</h3>
                  <input
                    placeholder="e.g. 123456"
                    value={templateToAdd}
                    maxLength={7}
                    onChange={(e) => {
                      setTemplateToAdd(e.target.value);
                    }}
                  />
                  <br />
                  <br />
                </span>
              )}
              <TallFiltersCont>
                <h2>Reward Values</h2>
              </TallFiltersCont>

              {amountsToAdd?.length > 0 &&
                amountsToAdd.map((item, index) => (
                  <span key={index}>
                    <br />
                    <br />
                    <h3>{item.symbol} Per Payout</h3>
                    <input
                      name="amount"
                      value={item.amount}
                      onChange={(e) => {
                        handleRewardAmountValueChange(
                          e,
                          index,
                          amountsToAdd,
                          setAmountsToAdd
                        );
                      }}
                    />
                  </span>
                ))}
              <br />
              <br />
              <MainButton
                onClick={() => {
                  setRewardValues(
                    farmData.farmname,
                    farmData.farm_type,
                    amountsToAdd,
                    collectionToAdd,
                    schemaToAdd,
                    templateToAdd,
                    attributeName,
                    attributeOp,
                    attributeFilterBy,
                    attributeMinValue,
                    attributeMaxValue,
                    attributeValuesList,
                    setEnterModalText,
                    setLoadingDisplay,
                    setEnterButtonsDisplay,
                    setEnterModalDisplay,
                    wharfSession
                  );
                }}
              >
                Set Values
              </MainButton>
            </span>
          )}
        </span>
      )}

      {currentRewardTab == "Reward Pools" && (
        <span>
          <br />
          {amountsToDeposit.length > 0 &&
            amountsToDeposit.map((item, index) => (
              <span key={index}>
                <h3>{item.symbol} Amount</h3>
                <input
                  name="amount"
                  value={item.amount}
                  onChange={(e) => {
                    handleRewardAmountValueChange(
                      e,
                      index,
                      amountsToDeposit,
                      setAmountsToDeposit
                    );
                  }}
                />
                <br />
                <br />
              </span>
            ))}
          <MainButton
            onClick={() => {
              depositRewards(
                farmData.farmname,
                amountsToDeposit,
                setEnterModalText,
                setLoadingDisplay,
                setEnterButtonsDisplay,
                setEnterModalDisplay,
                wharfSession
              );
            }}
          >
            Deposit Tokens
          </MainButton>
        </span>
      )}
    </span>
  );
};

const setRewardValues = async (
  farm_name,
  farm_type,
  amountsToAdd,
  collection_name,
  schema_name,
  template_id,
  attributeName,
  attributeOp,
  attributeFilterBy,
  attributeMinValue,
  attributeMaxValue,
  attributeValuesList,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

  let action;
  let values;
  let fixed_amounts = [];

  if (farm_type == 0) {
    for (let a of amountsToAdd) {
      if (a.amount > 0) {
        fixed_amounts.push({
          quantity:
            Number(a.amount).toFixed(Number(a.precision)) + " " + a.symbol,
          contract: a.contract,
        });
      }
    }

    values = [
      { collection_name: collection_name, hourly_rewards: fixed_amounts },
    ];

    action = [
      {
        account: v2FarmContract,
        name: "setcolvalues",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          farmname: farm_name,
          values: values,
        },
      },
    ];
  } else if (farm_type == 1) {
    for (let a of amountsToAdd) {
      if (a.amount > 0) {
        fixed_amounts.push({
          quantity:
            Number(a.amount).toFixed(Number(a.precision)) + " " + a.symbol,
          contract: a.contract,
        });
      }
    }

    values = [
      {
        collection_name: collection_name,
        schema_name: schema_name,
        hourly_rewards: fixed_amounts,
      },
    ];

    action = [
      {
        account: v2FarmContract,
        name: "setschvalues",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          farmname: farm_name,
          values: values,
        },
      },
    ];
  } else if (farm_type == 2) {
    for (let a of amountsToAdd) {
      if (a.amount > 0) {
        fixed_amounts.push({
          quantity:
            Number(a.amount).toFixed(Number(a.precision)) + " " + a.symbol,
          contract: a.contract,
        });
      }
    }

    values = [
      {
        collection_name: collection_name,
        template_id: template_id,
        hourly_rewards: fixed_amounts,
      },
    ];

    action = [
      {
        account: v2FarmContract,
        name: "settmpvalues",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          farmname: farm_name,
          values: values,
        },
      },
    ];
  } else if (farm_type == 3) {
    for (let a of amountsToAdd) {
      if (a.amount > 0) {
        fixed_amounts.push({
          quantity:
            Number(a.amount).toFixed(Number(a.precision)) + " " + a.symbol,
          contract: a.contract,
        });
      }
    }

    let sch = attributeFilterBy == 0 ? "" : schema_name;
    let val_list = attributeOp == 1 ? attributeValuesList.split("|") : [];
    let att_min = attributeOp == 2 ? attributeMinValue : 0;
    let att_max = attributeOp == 2 ? attributeMaxValue : 0;

    values = [
      {
        op: attributeOp,
        filter_by: attributeFilterBy,
        collection_name: collection_name,
        schema_name: sch,
        attribute_name: attributeName,
        values_list: val_list,
        min_value: att_min,
        max_value: att_max,
        hourly_rewards: fixed_amounts,
      },
    ];

    action = [
      {
        account: v2FarmContract,
        name: "setattvalues",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          farmname: farm_name,
          values: values,
        },
      },
    ];
  }

  try {
    const result = await session.transact({ actions: action }, defaultTxSettings);
    setLoadingDisplay("");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Asset values have been set")
      setLoadingDisplay("none");
    }, defaultSpinnerDuration);
    return () => clearTimeout(timer);
  } catch (e) {
    console.log('ERROR: ', e);
    setEnterModalText(e.message);
    setEnterModalDisplay("");
  }
};

export const setFarmExpiration = async (
  farm_name,
  openOrExtend,
  expiration,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

  let action_name = openOrExtend == "Open" ? "openfarm" : "extendfarm";

  const action = [
    {
      account: v2FarmContract,
      name: action_name,
      authorization: [session.permissionLevel],
      data: {
        user: session.actor,
        farmname: farm_name,
        expiration: expiration,
      },
    },
  ];

  try {
    const result = await session.transact({ actions: action }, defaultTxSettings);
    setLoadingDisplay("");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Expiration has been set")
      setLoadingDisplay("none");
    }, defaultSpinnerDuration);
    return () => clearTimeout(timer);
  } catch (e) {
    console.log('ERROR: ', e);
    setEnterModalText(e.message);
    setEnterModalDisplay("");
  }

};

const depositRewards = async (
  farm_name,
  amountsToDeposit,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

  let action = [];

  for (let a of amountsToDeposit) {
    if (a.amount > 0) {
      action.push({
        account: a.contract,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: v2FarmContract,
          quantity:
            Number(a.amount).toFixed(Number(a.precision)) + " " + a.symbol,
          memo: "|farm_deposit|" + farm_name + "|",
        },
      });
    }
  }

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText("Your funds were added to the farm")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }
};

export const isInUse = (
  asset_id,
  listing_index,
  assetVector,
  whitelistVector,
  currentVector,
  stakedAssets
) => {
  const isInOtherVector =
    currentVector === "asset"
      ? whitelistVector.some((asset) => asset.asset_id === asset_id)
      : assetVector.some((asset) => asset.asset_id === asset_id);

  const hasDifferentListingIndexInSameVector =
    currentVector === "asset"
      ? assetVector.some(
          (asset) =>
            asset.asset_id === asset_id && asset.listing_index !== listing_index
        )
      : whitelistVector.some(
          (asset) =>
            asset.asset_id === asset_id && asset.listing_index !== listing_index
        );

  const isStakedAlready = stakedAssets.indexOf(asset_id) > -1;

  return (
    hasDifferentListingIndexInSameVector || isInOtherVector || isStakedAlready
  );
};

export const handleRemoveAsset = (assetObject, assetVector, setAssetVector) => {
  const index = assetVector.findIndex(
    (asset) =>
      asset.asset_id === assetObject.asset_id &&
      asset.listing_index === assetObject.listing_index
  );

  console.log("index: " + index);
  if (index !== -1) {
    const assetList = [...assetVector]; // clone the vector to a new array
    assetList.splice(index, 1); // remove the asset
    setAssetVector(assetList); // update state
  }
};

export const handleAddAsset = (assetObject, assetVector, setAssetVector) => {
  setAssetVector([...assetVector, assetObject]);
};

export const showFarmStatus = (farmData) => {
  return (
    <span>
      <Table>
        <tbody>
          <TableRow>
            <TableHeader>Status</TableHeader>
            <TableData>
              {
                V2_FARM_STATUSES[
                  V2_FARM_STATUSES.findIndex(
                    (attributeIndex) => attributeIndex.value === farmData.status
                  )
                ].display_text
              }
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Expires</TableHeader>
            <TableData>
              {new Date(farmData.expiration * 1000).toLocaleDateString()}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>NFTs Staked</TableHeader>
            <TableData>{farmData.total_staked}</TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Payout Frequency</TableHeader>
            <TableData>
              {farmData.hours_between_payouts} Hour
              {farmData.hours_between_payouts > 1 && "s"}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Reward Pools</TableHeader>
            <TableData>
              {farmData.reward_pools.map((item, index) => (
                <span>
                  {item.total_funds}
                  <br />
                </span>
              ))}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Rewards Per Payout</TableHeader>
            <TableData>
              {farmData.reward_pools.map((item, index) => (
                <span>
                  {item.total_hourly_reward}
                  <br />
                </span>
              ))}
            </TableData>
          </TableRow>
        </tbody>
      </Table>
    </span>
  );
};

export const showMyInfo = (myInfo, farmData) => {
  return (
    <span>
      <Table>
        <tbody>
          <TableRow>
            <TableHeader>NFTs Staked</TableHeader>
            <TableData>{myInfo?.asset_ids?.length}</TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Last Update</TableHeader>
            <TableData>
              {myInfo?.last_state_change != null
                ? timeSince(myInfo.last_state_change) + " ago"
                : "N/A"}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Claimable Balances</TableHeader>
            <TableData>
            {getClaimableBalances(myInfo, farmData)?.map((item, index) => (
                <span key={index}>
                  {item.quantity}
                  <br />
                </span>
              ))}

              {/* 
              {myInfo?.claimable_balances?.map((item, index) => (
                <span key={index}>
                  {item.quantity}
                  <br />
                </span>
              ))}
               */}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Rewards Per Payout</TableHeader>
            <TableData>
              {myInfo?.rates_per_hour?.map((item, index) => (
                <span key={index}>
                  {item.quantity}
                  <br />
                </span>
              ))}
            </TableData>
          </TableRow>
        </tbody>
      </Table>
    </span>
  );
};

export const stakeV2 = async (
  farmData,
  assetVector,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

  let assets = [];
  console.log(farmData.farm_type)
  if (farmData.farm_type != 3) {
    for (let a of assetVector) {
      assets.push(a.asset_id);
    }
  }

  let action = [
    {
      account: v2FarmContract,
      name: "stakenfts",
      authorization: [session.permissionLevel],
      data: {
        user: session.actor,
        farmname: farmData.farmname,
        assets_to_stake: assets,
      },
    },
  ];

  try {
    const result = await session.transact({ actions: action }, defaultTxSettings);
    setLoadingDisplay("");
    setEnterModalText(PROCESSING_TX_TEXT);
    const timer = setTimeout(() => {
      setEnterModalText("Your NFTs have been staked")
      setLoadingDisplay("none");
    }, defaultSpinnerDuration);
    return () => clearTimeout(timer);
  } catch (e) {
    console.log('ERROR: ', e);
    setEnterModalText(e.message);
    setEnterModalDisplay("");
  }
};

export const showNftIngredientTitle = (farmData, ingredient) => {
  let i = ingredient;

  if (farmData.farm_type === 0) {
    //collection
    return (
      <TallFiltersCont>
        <h2>
          Any NFT from{" "}
          <a href={buildCollectionUrl(i.collection_name)} target="none">
            {i.collection_name}
          </a>{" "}
          collection
        </h2>
      </TallFiltersCont>
    );
  } else if (farmData.farm_type === 1) {
    //schema
    return (
      <TallFiltersCont>
        <h2>
          Any NFT from{" "}
          <a href={buildCollectionUrl(i.collection_name)} target="none">
            {i.collection_name}
          </a>{" "}
          collection, using{" "}
          <a
            href={buildSchemaUrl(i.collection_name, i.schema_name)}
            target="none"
          >
            {i.schema_name}
          </a>{" "}
          schema
        </h2>
      </TallFiltersCont>
    );
  } else if (farmData.farm_type === 2) {
    //template
    return (
      <TallFiltersCont>
        <h2>
          Any NFT from{" "}
          <a href={buildCollectionUrl(i.collection_name)} target="none">
            {i.collection_name}
          </a>{" "}
          collection, using template ID #
          <a
            href={buildTemplateUrl(i.collection_name, i.template_id)}
            target="none"
          >
            {i.template_id}
          </a>
        </h2>
      </TallFiltersCont>
    );
  }
};

export const claimV2 = async (
  farmname,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

  let action = [
    {
      account: v2FarmContract,
      name: "claim",
      authorization: [session.permissionLevel],
      data: {
        user: session.actor,
        farmname: farmname,
      },
    },
  ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your rewards have been claimed")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }
};

export const unstakeV2 = async (
  farmname,
  assets,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

  let action = [
    {
      account: v2FarmContract,
      name: "unstake",
      authorization: [session.permissionLevel],
      data: {
        user: session.actor,
        farmname: farmname,
        asset_ids: assets,
      },
    },
  ];

  try {
    const result = await session.transact({ actions: action }, defaultTxSettings);
    setLoadingDisplay("");
    setEnterModalText(PROCESSING_TX_TEXT);
    const timer = setTimeout(() => {
      setEnterModalText("Your assets have been unstaked")
      setLoadingDisplay("none");
    }, defaultSpinnerDuration);
    return () => clearTimeout(timer);
  } catch (e) {
    console.log('ERROR: ', e);
    setEnterModalText(e.message);
    setEnterModalDisplay("");
  }
};

export function timeSince(date) {
  var seconds = Math.floor(new Date() / 1000 - date);
  var interval = seconds / 31536000;

  if (interval > 1) {
    return (
      Math.floor(interval) + (Math.floor(interval) === 1 ? " year" : " years")
    );
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return (
      Math.floor(interval) + (Math.floor(interval) === 1 ? " month" : " months")
    );
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return (
      Math.floor(interval) + (Math.floor(interval) === 1 ? " day" : " days")
    );
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return (
      Math.floor(interval) + (Math.floor(interval) === 1 ? " hour" : " hours")
    );
  }
  interval = seconds / 60;
  if (interval > 1) {
    return (
      Math.floor(interval) +
      (Math.floor(interval) === 1 ? " minute" : " minutes")
    );
  }
  return (
    Math.floor(seconds) + (Math.floor(seconds) === 1 ? " second" : " seconds")
  );
}

function parseQuantity(quantity) {
  const [amount, symbol] = quantity.split(" ");
  const decimalPlaces = (amount.split(".")[1] || "").length;
  return { amount: parseFloat(amount), symbol, decimalPlaces };
}

export const getClaimableBalances = (userData, farmData) => {
  if(userData.length == 0 || userData == null){
    return
  }

  let now = Math.floor(Date.now() / 1000);

  let end_time = Math.min(now, farmData.expiration);  

  const payouts_owed = end_time <= userData.last_state_change ? 0 
  : Math.floor( (end_time - userData.last_state_change) / (farmData.hours_between_payouts * 3600) );

  if(payouts_owed == 0){
    return userData.claimable_balances;
  }
  
  let total_claimable = [];

  for (let r of userData.rates_per_hour) {
    let matchingClaimable = userData.claimable_balances.find(
      (c) => c.contract === r.contract && c.quantity.includes(r.quantity.split(" ")[1])
    );
  
    if (matchingClaimable) {
      const parsedRate = parseQuantity(r.quantity);
      const parsedClaimable = parseQuantity(matchingClaimable.quantity);
  
      const totalAmount = (
        parsedClaimable.amount + parsedRate.amount * payouts_owed
      ).toFixed(parsedRate.decimalPlaces);
  
      total_claimable.push({
        quantity: `${totalAmount} ${parsedRate.symbol}`,
        contract: r.contract,
      });
    }
  }
  
  return total_claimable;
}