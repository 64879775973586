import React, { useEffect, useState } from "react";
import config from "../../data/config2024.json"
import { Helmet } from "react-helmet";
import {
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
} from "../../components/LeftDiv";
import { currentUsername, mainnetWebsiteURL } from "../../data/config";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  Modal2024,
  ModalContent2024,
  ModalOverlay2024,
  PageBody2024,
  PageWrapper2024,
  theme2024,
} from "../../Styles2024";
import GoToTopButton from "../../components/WalletComponents/GoToTopButton";
import { useParams } from "react-router-dom";
import { buildAirdropImage2024 } from "../../data/functions/global_functions";
import {
  AssetIdDetailsHalf,
  AssetIdDetailsWrapper,
  AssetIdImageWrapper,
  AssetIdOwnerInnerWrapper,
  AssetIdOwnerPanel,
  AssetIdPageTopWrapper,
} from "./AssetIdStyles";
import LoadingSpinnerImageFallback from "../../components/LoadingSpinnerImageFallback";
import { AttributesWrapper2024 } from "./WalletStyles2024";
import { useGetAirdropSingle } from "../../components/CustomHooks/useGetAirdropSingle";
import { isLoggedIn } from "../../data/functions/wallet_functions";
import { useGetTokenBalsFromLightApi } from "../../components/CustomHooks/useGetTokenBalsFromLightApi";
import { useGetAirdropClaims } from "../../components/CustomHooks/useGetAirdropClaims";
import { makeAction, waxTransaction } from "./wallet_functions_2024";
import ReactMarkdown from "react-markdown";


const AirdropPage = () => {
  const { AirdropId } = useParams();
  const { wharfSession } = useStateContext();

  const [enterModalDisplay, setEnterModalDisplay] = useState("hidden");
  const [enterModalText, setEnterModalText] = useState("Awaiting signature...");
  const [loadingDisplay, setLoadingDisplay] = useState("none");

  const [airdrop, getAirdrop, airdropLoading] = useGetAirdropSingle();
  const [claims, getClaims, claimsLoading] = useGetAirdropClaims();
  const [isEligible, setIsEligible] = useState(false);
  const [tokenBalances, getTokenBalances, balancesAreLoading] =
    useGetTokenBalsFromLightApi();  

  const [picIsLoading, setPicIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getAirdrop(AirdropId);

      if(isLoggedIn()){
        getTokenBalances()
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);


useEffect(() => {
    let isMounted = true;

    if(isMounted){
        if(airdropLoading || !airdrop || balancesAreLoading || !tokenBalances) return;
        if(!isLoggedIn()) return;

        const now = Date.now();
        const snap_time = Number(airdrop?.snapshot_time) * 1000;    
        if(now > snap_time){
            getClaims(AirdropId)
        } else {

            for(const t of tokenBalances){
                if(t.currency == airdrop?.min_amount.split(" ")[1] && t.contract == airdrop?.holder_contract){
                    if( Number(t.amount) >= Number(airdrop?.min_amount.split(" ")[0]) ){
                        setIsEligible(true);
                    }
                    break;
                }
            }
        }
    }

  return () => {
    isMounted = false;
  }
}, [airdropLoading, balancesAreLoading, balancesAreLoading, tokenBalances])


  return (
    <div id="seo">
      <Helmet>
        <title>{`Airdrop #${AirdropId} - WaxDAO`}</title>
        <meta
          name="description"
          content="Asset ID information for WAX Blockchain NFTs"
        />
        <link rel="canonical" href={`${mainnetWebsiteURL}/airdrop/${AirdropId}`} />
      </Helmet>

      <ModalOverlay2024 className={enterModalDisplay} />
      <Modal2024 className={enterModalDisplay}>
        <div
          style={{
            width: "100%",
            textAlign: "right",
            paddingRight: "5px",
            fontSize: "22px",
            fontWeight: "400",
            color: theme2024.textMain,
          }}
        >
          <button
            onClick={() => {
              setEnterModalDisplay("hidden");
            }}
          >
            X
          </button>
        </div>
        <ModalContent2024>{enterModalText}</ModalContent2024>
        <Spinner style={{ display: loadingDisplay }}>
          <SpinnerRed />
          <SpinnerBlue />
          <SpinnerGreen />
        </Spinner>
      </Modal2024>

      <PageWrapper2024>
        <PageBody2024 fullWidth={true}>
          {!airdropLoading && !airdrop?.airdrop_id && (
            <div className="flex justify-center w-100 mt-4">
              <h2>Airdrop could not be located</h2>
            </div>
          )}
          {!airdropLoading && airdrop?.airdrop_id && (
            <AssetIdPageTopWrapper>
              <AssetIdImageWrapper>
                {picIsLoading && <LoadingSpinnerImageFallback />}
                {airdrop?.display_data && buildAirdropImage2024(JSON.parse(airdrop?.display_data), setPicIsLoading)}
              </AssetIdImageWrapper>

              <AssetIdDetailsWrapper>
                <h2>{airdrop?.display_data && JSON.parse(airdrop.display_data).name}</h2>

                <AssetIdDetailsHalf>
                  <h3>AIRDROP ID</h3>
                  <h4>{airdrop?.airdrop_id && airdrop.airdrop_id}</h4>

                  <h3>CREATOR</h3>
                  <h4>
                    {airdrop?.creator && airdrop.creator}
                  </h4>
                    
                  <h3>REMAINING AIRDROP</h3>
                  <h4>
                    {airdrop?.token && airdrop.token}
                  </h4>

                  <h3>AIRDROP TOKEN CONTRACT</h3>
                  <h4>
                    {airdrop?.contract && airdrop.contract}
                  </h4>

                </AssetIdDetailsHalf>

                <AssetIdDetailsHalf>



                </AssetIdDetailsHalf>


                  <AssetIdOwnerPanel>
                    <AssetIdOwnerInnerWrapper wide={true}>
                        {Date.now() >  Number(airdrop?.snapshot_time) * 1000 && (
                            <span>
                              {claims && claims?.length > 0 &&
                              <span>
                                <button
                                    onClick={() => {
                                        waxTransaction(    
                                        [makeAction(config.contracts.wufficlaim, "claim", {airdrop_id: AirdropId, account: currentUsername}, wharfSession)],
                                            "Your airdrop has been claimed!",
                                            setEnterModalDisplay,
                                            setEnterModalText,
                                            setLoadingDisplay,
                                            wharfSession)
                                    }}
                                >
                                {`Claim ${claims[0]?.amount}`}
                                </button>
                                </span>}

                                {claims && claims?.length == 0 &&
                              <span>
                                You are not eligible for this airdrop.
                                </span>}                                
                            </span>
                        )}

                        {Date.now() <=  Number(airdrop?.snapshot_time) * 1000 && (
                            <span>
                              {`You are ${!isEligible && 'not'} currently eligible, but the snapshot has not been taken yet`}
                            </span>
                        )}                        
                    </AssetIdOwnerInnerWrapper>
                  </AssetIdOwnerPanel>

              </AssetIdDetailsWrapper>
            </AssetIdPageTopWrapper>
          )}

       
            <AttributesWrapper2024 location={"asset_id_page"}>
              <h5>HOW TO QUALIFY</h5>
                <div>
                    <h2>Hold at least</h2>
                    <h3>{airdrop?.min_amount && airdrop.min_amount}</h3>
                </div>
                <div>
                    <h2>Token contract</h2>
                    <h3>{airdrop?.holder_contract && airdrop.holder_contract}</h3>
                </div>        
                <div>
                    <h2>Capped at</h2>
                    <h3>{airdrop?.max_amount && airdrop.max_amount}</h3>
                </div>        
                <div>
                    <h2>Snapshot time</h2>
                    <h3>{airdrop?.snapshot_time && new Date(airdrop?.snapshot_time * 1000).toLocaleString()}</h3>
                </div>                                        
            </AttributesWrapper2024>
      
            <AttributesWrapper2024 location={"asset_id_page"}>
              <h5>DESCRIPTION</h5>
         
                <ReactMarkdown>
                {airdrop?.display_data && JSON.parse(airdrop.display_data).description}
                </ReactMarkdown>
                                          
            </AttributesWrapper2024>
    
        </PageBody2024>
      </PageWrapper2024>
      <GoToTopButton />
    </div>
  );
};

export default AirdropPage;
