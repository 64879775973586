import { useEffect } from "react";
import { useGetBlendsByUser } from "../../components/CustomHooks/useGetBlendsByUser";
import LoadingDiv from "../../components/LoadingDiv";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentUsername, currentWebsiteURL } from "../../data/config";
import { EditBlendButtonCont, TokenCard } from "../../data/css/WalletStyles";

import { SmallButton } from "../../Styles";
import { buildIpfsUrl } from "../../data/functions/global_functions";

export const showManageBlendsSection = (currentToolsSection) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();

  const [blendsByUser, getBlendsByUser, userBlendsAreLoading] =
    useGetBlendsByUser(currentUsername);

  useEffect(() => {
    if (currentToolsSection == "Manage Blends") {
      getBlendsByUser(currentUsername);
    }
  }, [currentToolsSection]);

  return (
    <span className={currentToolsSection != "Manage Blends" && "hidden"}>
      {!userBlendsAreLoading && (
        <span>
          {blendsByUser?.length > 0
            ? blendsByUser.map((item, index) => (
                <span key={index}>
                  <TokenCard>
                    <a href={`${currentWebsiteURL}/blends/${item.ID}`}>
                      <img
                        src={buildIpfsUrl(item.cover_image, 200)}
                        onError={(e) =>
                          (e.target.onerror = null)(
                            (e.target.src =
                              "https://logos.waxdao.io/default-logo.png")
                          )
                        }
                      />
                    </a>

                    <a href={`${currentWebsiteURL}/blends/${item.ID}`}>
                    {item.blend_title}
                    </a>
                    <EditBlendButtonCont>
                      Blend {item.ID}
                        <a href={`${currentWebsiteURL}/edit-blend/${item.ID}`}>
                      <SmallButton>Edit Blend</SmallButton>
                      </a>
                    </EditBlendButtonCont>
                  </TokenCard>
                </span>
              ))
            : 
            (<div className="w-100 text-center pt-3">
            No blends to show
          </div>)}
        </span>
      )}
      {userBlendsAreLoading && <LoadingDiv />}
    </span>
  );
};
