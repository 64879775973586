import { useState } from "react";
import axios from 'axios';
import { currentAtomicApiList } from "../../data/config";

export const useGetGalleryNfts = () => {
    const [nfts, setNfts] = useState([]);
    const [nftsAreLoading, setNftsAreLoading] = useState(false);


    const getNfts = async (asset_ids) => {
        setNftsAreLoading(true);

        for (const api of currentAtomicApiList) {
            try {

                const res = await axios.get(`${api}/atomicassets/v1/assets?ids=${asset_ids.slice(0,60).join("%2C")}&page=1&limit=60`, {});
                
                if (res.data.data) {
                    setNfts(res.data.data)
                    console.log(res.data);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }

        setNftsAreLoading(false);
    }

    return [nfts, getNfts, nftsAreLoading]
}
