import styled from "styled-components";
import { theme2024 } from "../../Styles2024";

export const Farms2024FiltersWrapper = styled.div`
    width: 920px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    position: relative;
    height: 75px;
    //border-bottom: 1px solid ${theme2024.secondaryLight};

    h3{
        font-size: 12px;
        font-weight: 600;
    }

    @media (max-width: 975px) {
        width: 600px;
        height: 150px;
    }    

    @media (max-width: 610px) {
        width: 100%;
        }     
        
        @media (max-width: 500px) {
            height: 200px;
            }             
`

export const FarmsTextFilterBy = styled.h3`
    position: absolute;
    top: 15px;
    left: 15px;
`

export const FarmsTextSortBy = styled.h3`
    position: absolute;
    top: 15px;
    left: 420px;

    @media (max-width: 975px) {
        left: 455px;
    }    
    
    @media (max-width: 610px) {
        left: 340px;
        }   
        
    @media (max-width: 500px) {
        left: 15px;
        top: 170px;
        }          
`

export const FarmsTextSearch = styled.h3`
    position: absolute;
    top: 15px;
    left: 570px;

    @media (max-width: 975px) {
        top: 90px;
        left: 125px;
    }     
    
    @media (max-width: 500px) {
        left: 15px;
    }      
`

export const FarmsTextStakedOnly = styled.h3`
    position: absolute;
    top: 15px;
    left: 230px;

    @media (max-width: 975px) {
        left: 265px;
    } 
    
    @media (max-width: 610px) {
        left: 200px;
        }     
`

export const FarmsSearchByWrapper = styled.div`
    position: absolute;
    top: 10px;
    left: 650px;
    width: 265px;
    height: 25px;
    border-radius: 10px;
    border: 1px solid ${theme2024.secondaryLight};
    box-sizing: border-box;
    padding: 0px;

    @media (max-width: 975px) {
        top: 85px;
        left: 205px;;
    }  
    
    @media (max-width: 500px) {
        left: 85px;
    } 
    
    @media (max-width: 500px) {
        display: flex;
        justify-content: space-between;
        width: auto;
    }      
`

export const FarmsSearchByItem = styled.div`
    display: inline-block;
    width: 33.33%;
    height: 100%;
    box-sizing: border-box;
    border: ${props => props.selected ? `1px solid ${theme2024.primary}` : 'none'};
    border-radius: 10px;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    margin: 0px;
    vertical-align: top;
    padding-top: 4px;

    :hover{
        cursor: pointer;
    }

    @media (max-width: 500px) {
        padding-left: 8px;
        padding-right: 8px;
        width: unset;
    }     
`

export const Farms2024SearchBox = styled.input`
    position: absolute;
    top: 40px;
    left: 570px;
    font-size: 12px;
    width: 345px;
    background-color: transparent;
    outline: none;
    border: 1px solid ${theme2024.secondaryLight};
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    :focus{
        border: 2px solid ${theme2024.primary};
    }

    @media (max-width: 975px) {
        top: 115px;
        left: 125px;
    }     

    @media (max-width: 500px) {
        left: 15px;
        width: 200px;
    }    
`

export const Farms2024SortBy = styled.div`
    position: absolute;
    top: 40px;
    left: 420px;
    font-size: 12px;
    width: 120px;
    background-color: ${theme2024.secondaryDark};
    outline: none;
    border: 1px solid ${theme2024.secondaryLight};
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    :focus{
        border: 2px solid ${theme2024.primary};
        border-radius: 7px 7px 0px 0px;
        transition: border-radius 0.3s;
    }

    @media (max-width: 975px) {
        left: 455px;
    }   
    
    @media (max-width: 610px) {
        left: 340px;
        }  

    @media (max-width: 500px) {
        left: 75px;
        top: 162px;
        }         
`

export const FarmSortMethodsWrapper = styled.div`
    position: absolute;
    top: 72px;
    left: 420px;
    font-size: 12px;
    width: 120px;    
    opacity: ${props => props.open ? 1 : 0};
    visibility: ${props => props.open ? 'visible' : 'hidden'};
    transition: opacity 0.3s, visibility 0.3s;
    background-color: ${theme2024.secondaryDark};
    border: 1px solid ${theme2024.secondaryLight};
    z-index: 1000;

    @media (max-width: 975px) {
        left: 455px;
    }   
    
    @media (max-width: 610px) {
        left: 340px;
        }   
        
@media (max-width: 500px) {
    left: 75px;
    top: 194px;
    }           
`

export const FarmSortButton = styled.button`
    width: 100%;
    padding: 7px;
    text-align: left;
    display: ${props => props.thisMethod == props.currentSortMethod && "none"};

    :hover{
        background-color: ${theme2024.primary};
    }
`

export const Farm2024StakedOnlyToggleContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 45px;
  left: 230px;

  @media (max-width: 975px) {
    left: 265px;
    }   

  @media (max-width: 610px) {
    left: 200px;
    }    
`;

export const Farm2024Slider = styled.div`
  width: 40px;
  height: 20px;
  background-color: ${(props) => (props.toggled ? theme2024.primary : theme2024.secondaryMedium)};
  border-radius: 20px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.5s ease;
`;

export const Farm2024Circle = styled.div`
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.5s ease;
  left: ${(props) => (props.toggled ? 'calc(100% - 10px)' : '10px')};
`;

export const Farms2024FilterBy = styled.div`
    position: absolute;
    top: 40px;
    left: 15px;
    font-size: 12px;
    width: 150px;
    background-color: ${theme2024.secondaryDark};
    outline: none;
    border: ${props => props.focused ? `2px solid ${theme2024.primary}` : `1px solid ${theme2024.secondaryLight}`};
    border-radius: ${props => props.focused ? `7px 7px 0px 0px` : `7px`};
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    :hover{
        cursor: pointer;
    }    

    :focus{
        //border: 2px solid ${theme2024.primary};
        //border-radius: 7px 7px 0px 0px;
        transition: border-radius 0.3s;
    }
`

export const FarmFilterMethodsWrapper = styled.div`
    position: absolute;
    top: 72px;
    left: 15px;
    font-size: 12px;
    width: 150px;    
    opacity: ${props => props.open ? 1 : 0};
    visibility: ${props => props.open ? 'visible' : 'hidden'};
    transition: opacity 0.3s, visibility 0.3s;
    background-color: ${theme2024.secondaryDark};
    border: 1px solid ${theme2024.secondaryLight};
    z-index: 1000;
`

export const FarmFilterRow = styled.div`
    position: relative;
    width: 100%;
    padding: 7px;
    display: ${props => props.hide ? 'none' : 'flex'};
    justify-content: space-between;
    background-color: ${theme2024.secondaryDark};
    z-index: 1002;

    :hover{
        cursor: pointer;
    }
`

export const Farm2024FilterMethodToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FarmsContainer2024 = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 30px;
    row-gap: 10px;
`

export const FarmCard2024Wrapper = styled.a`
    width: 800px;
    position: relative;
    border: 1px solid ${theme2024.secondaryLight};
    border-radius: 10px;
    height: ${props => `${props.height}px`};
    max-width: 100vw;

    :hover{
        border: 1px solid ${theme2024.textMain};
        cursor: pointer;
        transform: scale(1.01);
        transition: transform 0.2s;
    }

    svg{
        position: absolute;
        width: 25px;
        height: 25px;
        top: 22px;
        right: 15px;
        fill: ${theme2024.primary};
    }
`

export const FarmCard2024Avatar = styled.img`
    max-width: 40px;
    width: auto;
    max-height: 40px;
    height: auto;
    position: absolute;
    top: 15px;
    left: 15px;
    border-radius: 10px;
`
export const FarmCard2024Separator = styled.div`
    width: 770px;
    height: 3px;
    border-bottom: 1px solid ${theme2024.secondaryLight};
    position: absolute;
    left: 15px;
    top: 65px;
    max-width: 96%;

    @media (max-width: 900px) {
        left: 2%;
        width: 96%;
    }    
`
export const FarmCard2024Name = styled.h3`
    position: absolute;
    top: 15px;
    left: 70px;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: ${theme2024.textMain};
    letter-spacing: 2px;
`
export const FarmCard2024Tags = styled.h4`
    position: absolute;
    top: 38px;
    left: 70px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    letter-spacing: 1px;
    color: ${theme2024.textSecondaryDarker};
`
export const FarmCard2024DetailsWrapper = styled.div`
    position: absolute;
    top: 67px;
    width: 100%;
    min-height: 110px;
    height: auto;
    //border: 1px solid green;
`
export const FarmCard2024DetailsRow = styled.div`
    width: 100%;
    //border: 1px solid magenta;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3{
        color: ${theme2024.textSecondaryDarker};
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        padding-left: 15px;
        padding-top: 7px;
        padding-bottom: 7px;
    }

    h4{
        color: ${theme2024.primary};
        font-size: 14px;
        font-weight: 600;
        text-align: right;
        padding-right: 15px;
        padding-top: 7px;
        padding-bottom: 7px;        
    }    
`