import React, { useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import {Helmet} from "react-helmet";
import { Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, UserWhitelist, DaoButton, MissingInfoMsg, DisabledButton } from '../../components/LeftDiv';
import DatePicker from "react-datepicker";
import { mainnetWebsiteURL } from '../../data/config';
import { createDrip } from '../../data/functions/escrow_functions';
import { ExploreFiltersCont, MainButton, MilestoneContainer, MilestoneDescription, NewListingBody, NewListingCont, TallFiltersCont, TallFiltersInnerCont } from '../../Styles';
import { BsInfoCircle } from 'react-icons/bs';
import { handleCalendarChange } from '../../data/functions/pack_functions';


const Escrow = () => {

  const {
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    wharfSession
  } = useStateContext();

  const [payoutAmount, setPayoutAmount] = useState(0);
  const [tokenContract, setTokenContract] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [hoursBetweenPayouts, setHoursBetweenPayouts] = useState(0);
  const [precision, setPrecision] = useState("");
  const [timestamp, setTimestamp] = useState(Date.now() / 1000);
  const [receivingAccount, setReceivingAccount] = useState("");

  return (
  <div id="seo">
    <Helmet>
    <title>Escrow</title>
    <meta name="description" content="Use WaxDAO's slow drip contract to create an escrow agreement" />
    <link rel="canonical" href={`${mainnetWebsiteURL}/escrow`} />
    </Helmet>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>

<NewListingCont>
  <NewListingBody>
<ExploreFiltersCont>
   <h2>Create Slow Drip</h2>
</ExploreFiltersCont>

<MilestoneContainer>
  <MilestoneDescription>
    You have a million questions about what this page is for, and how to use it.
    <br/><br/>
    Every single question you have is likely answered in <a href="https://youtu.be/zZy6nE0Qmz8" target="none">our tutorial video</a> - please watch it.
  </MilestoneDescription>
</MilestoneContainer>

<TallFiltersCont>
  <TallFiltersInnerCont>
  <h3>Receiving Account</h3>
    <input
    maxLength={12}
    value={receivingAccount}
    placeholder="e.g. mike.wam"
    onChange={(e) => {
        setReceivingAccount(e.target.value.toLowerCase());
    }}
    />
    <br/><br/>

    <h3>Amount Per Payment</h3>
    <input
    maxLength={18}
    value={payoutAmount}
    placeholder="e.g. 69.420"
    onChange={(e) => {
      setPayoutAmount(e.target.value);
    }}
    />
    <br/><br/> 

    <h3>Token Name</h3>
    <input
    maxLength={7}
    value={tokenName}
    placeholder="e.g. WAX"
    onChange={(e) => {
      setTokenName(e.target.value.toUpperCase());
    }}
    />
    <br/><br/>    

    <h3>Token Contract</h3>
    <input
    maxLength={13}
    value={tokenContract}
    placeholder="e.g. eosio.token"
    onChange={(e) => {
      setTokenContract(e.target.value.toLowerCase());
    }}
    />
    <br/><br/>

                <h3 className="flex">
                  Token Precision&nbsp;&nbsp;
                  <BsInfoCircle
                    className="mt-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEnterModalDisplay("");
                      setEnterModalText(
                        <span>
                          If WAX is the token, the info is: <br />
                          Contract: eosio.token
                          <br />
                          Decimals: 8<br />
                          For other tokens, search our{" "}
                          <a
                            href="https://waxdao.io/tokens"
                            target="none"
                            className="text-secondary"
                          >
                            Token Tracker
                          </a>{" "}
                          for this info
                        </span>
                      );
                    }}
                  />
                  <br />
                </h3>

                <input
                  type="number"
                  placeholder="e.g. 8"
                  value={precision}
                  onChange={(e) => {
                    setPrecision(e.target.value);
                  }}
                />  
  <br/><br/>                


    <h3>Hours Between Payments</h3>
    <input
    maxLength={8}
    value={hoursBetweenPayouts}
    placeholder="e.g. 24"
    onChange={(e) => {
      setHoursBetweenPayouts(e.target.value);
    }}
    />
    <br/><br/>    

    <h3>Drip Completion Date</h3>

  <DatePicker
    showTimeSelect
    timeIntervals={15}
    selected={new Date(timestamp * 1000)}
    onChange={(e) => {
      handleCalendarChange(e, setTimestamp);
    }}
  />     

  </TallFiltersInnerCont>
</TallFiltersCont>


{timestamp != null && receivingAccount != null && payoutAmount != null && timestamp > 0 && receivingAccount.length > 0 && payoutAmount.length > 0 && (
  <ExploreFiltersCont>
   <div className='text-center w-100'>
   <MainButton onClick={() => { createDrip(receivingAccount, tokenContract, payoutAmount, precision, tokenName, timestamp, hoursBetweenPayouts, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) }}>
    Create Drip
</MainButton>
</div>
</ExploreFiltersCont>


)}


{(timestamp == null || receivingAccount == null || payoutAmount == null || payoutAmount == '' || payoutAmount == 0 || timestamp == '' || receivingAccount.length == 0) && (
  <ExploreFiltersCont>
  <div className='text-center w-100'>
  <DisabledButton>
   Create Drip
</DisabledButton>
</div>
</ExploreFiltersCont>
)}






</NewListingBody>
</NewListingCont>

    <br/><br/><br/>
    </div>
  )
}

export default Escrow