import styled from "styled-components"
import { theme2024 } from "../../Styles2024"

export const NftCardWrapper2024 = styled.div`
    display: ${props => props.gallery && !props.selected ? 'none' : 'block'};
    position: relative;
    width: ${props => props.gallery ? 'auto' : '220px'};
    height: ${props => props.gallery ? '240px' : '400px'};
    background-color: ${props => props.gallery ? 'transparent' : theme2024.secondaryDarker};
    border-radius: ${props => !props.gallery && '15px'};
    margin-top: ${props => props.gallery ? '0px' : '15px'};
    box-sizing: border-box;
    border: ${props => props.selected && !props.gallery ? `1px solid ${theme2024.primary}` : 'none'};
    overflow: ${props => props.gallery && 'hidden'};
    
    @media (max-width: 480px) {
        width: ${props => props.gallery ? 'auto' : '165px'};
        border-radius: ${props => !props.gallery && '8px'};
        height: ${props => props.gallery ? '165px' : '300px'};
    }

`

export const NftcardTopHalf2024 = styled.div`
    display: flex;
    height: 240px;
    width: ${props => props.gallery ? 'auto' : '100%'};
    align-items: center;
    justify-content: center;
    justify-content: center;

    a{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: ${props => props.location == "asset_page" ? 'default' : 'pointer'}
    }

    img, video{
        border-radius: ${props => !props.gallery && '5px 5px 5px 5px'};
        max-width: ${props => props.gallery ? '100%' : '95%'};
        height: ${props => props.gallery && '100%'};
        max-height: ${props => props.gallery ? '100%' : '95%'};
        overflow: hidden;

        @media (max-width: 480px) {
            ${props => !props.gallery && '8px 8px 0px 0px'};
        }          
    }

    @media (max-width: 480px) {
        height: ${props => props.gallery ? '165px' : '180px'};
    }    
`

export const CollectionNameWrapper2024 = styled.div`
    z-index: 150;
    position: absolute;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    padding-left: 10px
    letter-spacing: 0.7px;
    color: ${theme2024.textMain};
    top: 240px;
    width: 100%;
    height: 30px;
    text-align: left;
    transition: color 0.5s;

    @media (max-width: 480px) {
        top: 180px;
    }    
    
    background: rgb(38,37,37);
    background: -moz-linear-gradient(90deg, rgba(38,37,37,1) 30%, rgba(165,166,162,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(38,37,37,1) 30%, rgba(165,166,162,1) 100%);
    background: linear-gradient(90deg, rgba(38,37,37,1) 30%, rgba(165,166,162,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#262525",endColorstr="#a5a6a2",GradientType=1);

    :hover{
        color: ${theme2024.primary};
    }
`

export const NftCardNameWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 200;
    font-size: 14px;
    font-weight: 600;
    top: 270px;
    height: 35px;
    width:100%;
    padding-right: 7px;
    //border: 1px solid magenta;
    justify-content: left;
    color: ${theme2024.textSecondaryDarker};
    box-sizing: border-box;

    @media (max-width: 480px) {
        top: 210px;
    }     

    h2{
        flex-grow: 1;
        padding-left: 7px;
        //border: 1px solid green;
    }

    h3{
        max-width: 50%;
        border: 1px solid ${theme2024.primary};
        //font-size: 12px;
        font-weight: 400;
        color: ${theme2024.primary};
        padding: 2px 7px 2px 7px;
        border-radius: 7px;
    }

`

export const NftCardMint2024 = styled.h3`
    font-size: ${props => props.small ? '8px' : '10px'};
`

export const NftCardShowDetailsWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0px;
    height: 35px;
    box-sizing: border-box;
    width: 100%;
    border-top: 1px solid #545b61;
    color: #545b61;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.7px;
    justify-content: center;
    transition: color 0.5s, background-color 0.5s;
    border-radius: 0px 0px 15px 15px;

    @media (max-width: 480px) {
        border-radius: 0px 0px 8px 8px;
        height: 25px;
    }      

`

export const NftCardShowDetailsLeftSide = styled.button`
    width: 85%;
    height: 100%;
    border-right: 1px solid #545b61;
    align-items: center;
    display: flex;
    justify-content: center;
    transition: color 0.5s, background-color 0.5s;
    border-radius: 0px 0px 0px 15px;

    @media (max-width: 480px) {
        border-radius: 0px 0px 0px 8px;
        height: 25px;
    }      

    :hover{
        background-color: ${theme2024.primary};
        color: ${theme2024.secondaryDarker};
    }    
`

export const NftCardShowDetailsRightSide = styled.button`
    width: 15%;
    height: 100%;  
    align-items: center;
    display: flex;
    justify-content: center;   
    transition: color 0.5s, background-color 0.5s, fill 0.5s;
    border-radius: 0px 0px 15px 0px;

    @media (max-width: 480px) {
        border-radius: 0px 0px 8px 0px;
        height: 25px;
    } 
    
    svg{
        width: 25px;
        height: 25px;
        stroke: ${props => props.selected ? theme2024.primary : '#545b61'};
        fill: ${props => props.selected ? theme2024.primary : ''};
        transition: stroke 0.5s;

        :hover{
            stroke: ${theme2024.primary};
        }        
    }   
`

export const NftCardIconsWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 200;
    font-size: 14px;
    font-weight: 600;
    top: 325px;
    height: 35px;
    width:100%;
    padding-right: 7px;
    //border: 1px solid magenta;
    justify-content: left;
    color: ${theme2024.textSecondaryDarker};
    box-sizing: border-box;

    button{
        //border: 1px solid green;
        margin-left: 8px;
    }

    svg{
        width: 25px;
        height: 25px;
        fill: ${theme2024.primary};
    }

    @media (max-width: 480px) {
        top: 242px;

        svg{
            width: 17px;
            height: 17px;
        }        
    }     

`

export const DetailsModalWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;    
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15011;
  width: 500px;
  max-width: 96%;
  height: auto;
  max-height: 90vh;
  background-color: ${theme2024.secondaryDark};
  border-radius: 10px;
  overflow: hidden;
  color: white;
  padding-bottom: 15px;
`;

export const ConfirmUnderstandingWrapper = styled.div`
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.7px;
    margin-left: auto;
    margin-right: auto;
    background-color: ${theme2024.secondaryDarker};
    border-radius: 10px;
    padding: 10px;
    font-size: 12px;
    box-sizing: border-box;
    border: ${props => props.understands ? `1px solid ${theme2024.primary}` : `1px solid transparent`};

    button{
        margin-right: 10px;
        width: 14px;
        height: 14px
        box-sizing: border-box;
        border: ${props => !props.understands ? `1px solid ${theme2024.textSecondaryDarker}` : `1px solid transparent`};
        border-radius: 3px;
    }

    svg{
        fill: ${theme2024.primary};
        width: 14px;
        height: 14px;
    }
`

export const DropCardPriceAndSupplyWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 200;
    font-size: 14px;
    font-weight: 600;
    top: 210px;
    height: 35px;
    width:100%;
    padding-right: 7px;
    //border: 1px solid magenta;
    justify-content: left;
    color: ${theme2024.primary};
    box-sizing: border-box;

    h2{
        flex-grow: 1;
        padding-left: 7px;
    }    

    @media (max-width: 480px) {
        top: 150px;

        h2{
            font-size: 12px;
        }          
    }     

    h3{
        max-width: 50%;
        border: 1px solid ${theme2024.primary};
        //font-size: 12px;
        font-weight: 400;
        color: ${theme2024.primary};
        padding: 2px 7px 2px 7px;
        border-radius: 7px;
    }
`

export const DropCardWrapper2024 = styled.div`
    display: ${props => props.gallery && !props.selected ? 'none' : 'block'};
    position: relative;
    width: ${props => props.gallery ? 'auto' : '220px'};
    height: 280px;
    background-color: ${props => props.gallery ? 'transparent' : theme2024.secondaryDarker};
    border-radius: ${props => !props.gallery && '15px'};
    margin-top: ${props => props.gallery ? '0px' : '15px'};
    box-sizing: border-box;
    border: ${props => props.selected && !props.gallery ? `1px solid ${theme2024.primary}` : 'none'};
    overflow: ${props => props.gallery && 'hidden'};
    
    @media (max-width: 480px) {
        width: ${props => props.gallery ? 'auto' : '165px'};
        border-radius: ${props => !props.gallery && '8px'};
        height: 220px;
    }
`

export const DropCard2024TimeWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 200;
    font-size: 14px;
    font-weight: 400;
    top: 245px;
    height: 35px;
    width:100%;
    //padding-right: 7px;
    //border: 1px solid magenta;
    justify-content: center;
    color: ${theme2024.primary};
    box-sizing: border-box;
    letter-spacing: 0.7px;

    h2{
        flex-grow: 1;
        //padding-left: 7px;
        //border: 1px solid green;
    }

    h3{
        color: ${theme2024.textSecondary};
    }

    @media (max-width: 480px) {
        top: 185px;
        h3{
            font-size: 12px;
        }
    }     


`

export const DropCardTopHalf2024 = styled.div`
    display: flex;
    height: 180px;
    width: ${props => props.gallery ? 'auto' : '100%'};
    align-items: center;
    justify-content: center;
    justify-content: center;

    a{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: ${props => props.location == "asset_page" ? 'default' : 'pointer'}
    }

    img, video{
        border-radius: ${props => !props.gallery && '5px 5px 5px 5px'};
        max-width: ${props => props.gallery ? '100%' : '95%'};
        height: ${props => props.gallery && '100%'};
        max-height: ${props => props.gallery ? '100%' : '95%'};
        overflow: hidden;

        @media (max-width: 480px) {
            ${props => !props.gallery && '8px 8px 0px 0px'};
        }          
    }

    @media (max-width: 480px) {
        height: 120px;
    }    
`

export const DropCardCollectionNameWrapper2024 = styled.div`
    z-index: 150;
    position: absolute;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    padding-left: 10px
    letter-spacing: 0.7px;
    color: ${theme2024.textMain};
    top: 180px;
    width: 100%;
    height: 30px;
    text-align: left;
    transition: color 0.5s;

    @media (max-width: 480px) {
        top: 120px;
    }    
    
    background: rgb(38,37,37);
    background: -moz-linear-gradient(90deg, rgba(38,37,37,1) 30%, rgba(165,166,162,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(38,37,37,1) 30%, rgba(165,166,162,1) 100%);
    background: linear-gradient(90deg, rgba(38,37,37,1) 30%, rgba(165,166,162,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#262525",endColorstr="#a5a6a2",GradientType=1);

    :hover{
        color: ${theme2024.primary};
    }
`

export const UnboxCardUnboxNow = styled.a`
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    transition: color 0.5s, background-color 0.5s;
    border-radius: 0px 0px 15px 15px;

    @media (max-width: 480px) {
        border-radius: 0px 0px 8px 8px;
        height: 25px;
    }      

    :hover{
        background-color: ${theme2024.primary};
        color: ${theme2024.secondaryDarker};
    }    
`