import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  DropPageAdBannerCont,
} from "../../components/LeftDiv";
import { currentWebsiteURL, getCurrentApiList, mainnetWebsiteURL } from "../../data/config";
import CurrentHighlights from "../../components/CurrentHighlights";
import { NewListingBody, NewListingCont, TallFiltersCont } from "../../Styles";
import LoadingPage from "../../components/LoadingPage";
import Error from "../../components/Error";
import LockCard from "../../components/LockCard";

const LockPage = ({ location }) => {
  const {
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
    wharfSession
  } = useStateContext();

  const { LockID } = useParams();

  const [lockData, setLockData] = useState([]);
  const [dropIsLoading, setDropIsLoading] = useState(true);

  useEffect(() => {

    axios
      .post(
        `${
          getCurrentApiList
        }/v1/chain/get_table_rows`,
        {
          table: "locks",
          scope: "waxdaolocker",
          code: "waxdaolocker",
          limit: 1,
          lower_bound: LockID,
          upper_bound: LockID,
          json: true,
        }
      )
      .then((response) => {
        setLockData(response.data.rows[0])
      })
      .catch((error) => console.log(error))
      .finally(() => setDropIsLoading(false))
  }, []);


  if(!dropIsLoading && lockData != null){
  return (
    <div id="seo">
      <Helmet>
        <title>Lock {LockID} Details</title>
        <meta
          name="description"
          content={`View details of token lock ${LockID} on WaxDao`}
        />
        <link rel="canonical" href={`${mainnetWebsiteURL}/locks/${LockID}`} />
      </Helmet>

        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <GameButton onClick={() => setEnterModalDisplay("hidden")}>
            Close Window
          </GameButton>
        </Modal>

        <NewListingCont>
          <NewListingBody>
            <DropPageAdBannerCont>
              <CurrentHighlights />
            </DropPageAdBannerCont>

            <TallFiltersCont>
            <h2>Lock {LockID}</h2>
            <p>By <a href={`${currentWebsiteURL}/u/${lockData.creator}`}>{lockData.creator}</a></p>
            </TallFiltersCont>

            <br/><br/>
            <div className="w-100 text-center flex justify-center">
            <LockCard item={lockData} />
            </div>

          </NewListingBody>
        </NewListingCont>
      <br />
      <br />
      <br />
    </div>
  )}
  else if(dropIsLoading){
    return(<LoadingPage />)
  }
  else if(!dropIsLoading && lockData == null){
    return(<Error error="Could not load token lock. If this is an error, try refreshing the page" />)
  }
};

export default LockPage;