import React, { useEffect, useState } from "react";
import { currentUsername, mainnetApiList, mainnetAtomicApiList, mainnetLightApiList } from "../data/config";
import { isLoggedIn } from "../data/functions/wallet_functions";
import Error from "../components/Error";
import { HeaderCont2024, PageBody2024, PageWrapper2024 } from "../Styles2024";
import { SettingsCategoryRowRightWrapper, SettingsCategoryRowWrapper, SettingsCategoryTopWrapper, SettingsCategoryWrapper, SettingsDropDownButton, SettingsDropDownRow, SettingsDropDownWrapper } from "../data/css/SettingsStyles";
import { apis_icon } from "../data/constants";
import { appearance_svg } from "../data/svgs";
import { Farm2024Circle, Farm2024FilterMethodToggleContainer, Farm2024Slider } from "./farmsv2/FarmStyles2024";
import { showCautionMessage } from "./token_tracker/tracker_functions";

const handleFocus = (setShow) => {
  setShow(true)
};

const handleBlur = (e, setShow, location) => {

  const locationMatches = (location, innerHTML) => {
    if(location == "chain" && innerHTML.indexOf("api.waxdaobp.io") > -1
      ||
      location == "atomic" && innerHTML.indexOf("aa.waxdaobp.io") > -1
      ||
      location == "light" && innerHTML.indexOf("light-api.waxdaobp.io") > -1
    ){
      return true;
    }
    return false;
  }

  if(e.relatedTarget && e.relatedTarget.innerHTML && e.relatedTarget.innerHTML.indexOf("Use Default") > -1){
    if(locationMatches(location, e.relatedTarget.innerHTML)){
      return;
    }
  }
  setShow(false)
};

const showApiString = (api_string) => {
  return api_string.length > 30 ? `${api_string.substring(0, 27)}...` : api_string;
}

export const hasAutoplayOn = () => {
  const storedSettings = JSON.parse(localStorage.getItem("userSettings")) || [];
  const userSettings = storedSettings.find(
    (user) => user.username === currentUsername
  );
  return userSettings && userSettings.autoplay === 1;
};

export const hasNsfwHidden = () => {
  const storedSettings = JSON.parse(localStorage.getItem("userSettings")) || [];
  const userSettings = storedSettings.find(
    (user) => user.username === currentUsername
  );
  return !userSettings || userSettings.showNsfw === 0;  
}

const updateSettings = (settingName, newSettingValue) => {
  const storedSettings = JSON.parse(localStorage.getItem("userSettings")) || [];

  const existingUserIndex = storedSettings.findIndex(
    (userSettings) => userSettings.username === currentUsername
  );

  if (existingUserIndex !== -1) {
    storedSettings[existingUserIndex][settingName] = newSettingValue;
  } else {
    const newUserSettings = {
      username: currentUsername,
      [settingName]: newSettingValue,
    };
    storedSettings.push(newUserSettings);
  }

  localStorage.setItem("userSettings", JSON.stringify(storedSettings));
};

const Settings = () => {
  const [autoplaySetting, setAutoplaySetting] = useState(0);
  const [showNsfw, setShowNsfw] = useState(0);
  const [showChainApis, setShowChainApis] = useState(false);
  const [showChainApis2, setShowChainApis2] = useState(false);
  const [selectedApi, setSelectedApi] = useState(null);

  const [showAtomicApis, setShowAtomicApis] = useState(false);
  const [showAtomicApis2, setShowAtomicApis2] = useState(false);  
  const [selectedAtomicApi, setSelectedAtomicApi] = useState(null);

  const [showLightApis, setShowLightApis] = useState(false);
  const [showLightApis2, setShowLightApis2] = useState(false);
  const [selectedLightApi, setSelectedLightApi] = useState(null);  

  useEffect(() => {
    const storedSettings =
      JSON.parse(localStorage.getItem("userSettings")) || [];

    const userSelectedAnApi = localStorage.getItem('userSelectedAnApi');
    const currentSelectedApi = localStorage.getItem('currentSelectedApi');

    const userSelectedAnAtomicApi = localStorage.getItem('userSelectedAnAtomicApi');
    const currentSelectedAtomicApi = localStorage.getItem('currentSelectedAtomicApi');    

    const userSelectedALightApi = localStorage.getItem('userSelectedALightApi');
    const currentSelectedLightApi = localStorage.getItem('currentSelectedLightApi');    

    const userSettings = storedSettings.find(
      (user) => user.username === currentUsername
    );

    if (userSettings && userSettings.autoplay !== undefined) {
      setAutoplaySetting(userSettings.autoplay);
    }

    if (userSettings && userSettings.showNsfw !== undefined) {
      setShowNsfw(userSettings.showNsfw);
    }    

    if(userSelectedAnApi != null && userSelectedAnApi){
      setSelectedApi(currentSelectedApi)
    }

    if(userSelectedAnAtomicApi != null && userSelectedAnAtomicApi){
      setSelectedAtomicApi(currentSelectedAtomicApi)
    }
    
    if(userSelectedALightApi != null && userSelectedALightApi){
      setSelectedLightApi(currentSelectedLightApi)
    }    
    

  }, [currentUsername]);

  if(isLoggedIn()){
    return (
      <PageWrapper2024>
        <PageBody2024>
        <HeaderCont2024>
              <div>
              <h2>SETTINGS</h2>
              </div>
              <div>
                <h3>
                  Customize your account settings for a better WaxDAO experience.
                </h3>
              </div>
            </HeaderCont2024>    

        <SettingsCategoryWrapper>
          <SettingsCategoryTopWrapper>
            {apis_icon}<h2>APIS</h2>
          </SettingsCategoryTopWrapper>

          {showCautionMessage("These settings are for advanced users. It is highly recommended for most users to use the default settings.")}

          <SettingsCategoryRowWrapper>
          <h3>Chain API</h3>
          <SettingsCategoryRowRightWrapper>
            <SettingsDropDownButton tabIndex="0" focused={(showChainApis || showChainApis2)} onFocus={() => {handleFocus(setShowChainApis); handleFocus(setShowChainApis2)}} onBlur={(e) => {handleBlur(e, setShowChainApis, "chain"); handleBlur(e, setShowChainApis2, "chain")}}>
              {selectedApi != null ? showApiString(selectedApi) : "Default"}
              <SettingsDropDownWrapper tabIndex="0" open={(showChainApis || showChainApis2)} focused={(showChainApis || showChainApis2)} onFocus={() => handleFocus(setShowChainApis2)} onBlur={(e) => handleBlur(e, setShowChainApis2, "chain")}>
                  <SettingsDropDownRow onClick={() => {
                    localStorage.setItem("userSelectedAnApi", false);
                    localStorage.removeItem("currentSelectedApi");  
                    setSelectedApi(null)  
                    setShowChainApis(false)
                    setShowChainApis2(false)                              
                  }}>
                    Use Default
                  </SettingsDropDownRow>                
                
                {mainnetApiList.map((item, index) => (
                  <SettingsDropDownRow key={index} onClick={() => {
                    localStorage.setItem("userSelectedAnApi", true);
                    localStorage.setItem("currentSelectedApi", item);  
                    setSelectedApi(item)  
                    setShowChainApis(false)
                    setShowChainApis2(false)        
                  }}>
                    {item}
                  </SettingsDropDownRow>
                ))}
              </SettingsDropDownWrapper>
            </SettingsDropDownButton>
          </SettingsCategoryRowRightWrapper>
          </SettingsCategoryRowWrapper>

          <SettingsCategoryRowWrapper>
          <h3>Atomic API</h3>
          <SettingsCategoryRowRightWrapper>
            <SettingsDropDownButton tabIndex="0" focused={(showAtomicApis || showAtomicApis2)} onFocus={() => {handleFocus(setShowAtomicApis); handleFocus(setShowAtomicApis2)}} onBlur={(e) => {handleBlur(e, setShowAtomicApis, "atomic"); handleBlur(e, setShowAtomicApis2, "atomic")}}>
              {selectedAtomicApi != null ? showApiString(selectedAtomicApi) : "Default"}
              <SettingsDropDownWrapper tabIndex="0" open={(showAtomicApis || showAtomicApis2)} focused={(showAtomicApis || showAtomicApis2)} onFocus={() => handleFocus(setShowAtomicApis2)} onBlur={(e) => handleBlur(e, setShowAtomicApis2, "atomic")}>
                  <SettingsDropDownRow onClick={() => {
                    localStorage.setItem("userSelectedAnAtomicApi", false);
                    localStorage.removeItem("currentSelectedAtomicApi");  
                    setSelectedAtomicApi(null)  
                    setShowAtomicApis(false)
                    setShowAtomicApis2(false)                              
                  }}>
                    Use Default
                  </SettingsDropDownRow>                
                
                {mainnetAtomicApiList.map((item, index) => (
                  <SettingsDropDownRow key={index} onClick={() => {
                    localStorage.setItem("userSelectedAnApi", true);
                    localStorage.setItem("currentSelectedApi", item);  
                    setSelectedAtomicApi(item)  
                    setShowAtomicApis(false)
                    setShowAtomicApis2(false)        
                  }}>
                    {item}
                  </SettingsDropDownRow>
                ))}
              </SettingsDropDownWrapper>
            </SettingsDropDownButton>
          </SettingsCategoryRowRightWrapper>
          </SettingsCategoryRowWrapper>

          <SettingsCategoryRowWrapper>
          <h3>Light API</h3>
          <SettingsCategoryRowRightWrapper>
          <SettingsDropDownButton tabIndex="0" focused={(showLightApis || showLightApis2)} onFocus={() => {handleFocus(setShowLightApis); handleFocus(setShowLightApis2)}} onBlur={(e) => {handleBlur(e, setShowLightApis, "light"); handleBlur(e, setShowLightApis2, "light")}}>
              {selectedLightApi != null ? showApiString(selectedLightApi) : "Default"}
              <SettingsDropDownWrapper tabIndex="0" open={(showLightApis || showLightApis2)} focused={(showLightApis || showLightApis2)} onFocus={() => handleFocus(setShowLightApis2)}>
                  <SettingsDropDownRow onClick={() => {
                    localStorage.setItem("userSelectedALightApi", false);
                    localStorage.removeItem("currentSelectedLightApi");  
                    setShowLightApis(false)
                    setShowLightApis2(false)
                    setSelectedLightApi(null)           
                  }}>
                    Use Default
                  </SettingsDropDownRow>                
                
                {mainnetLightApiList.map((item, index) => (
                  <SettingsDropDownRow key={index} onClick={() => {
                    localStorage.setItem("userSelectedALightApi", true);
                    localStorage.setItem("currentSelectedLightApi", item);  
                    setShowLightApis(false)
                    setShowLightApis2(false)
                    setSelectedLightApi(item)           
                  }}>
                    {item}
                  </SettingsDropDownRow>
                ))}
              </SettingsDropDownWrapper>
            </SettingsDropDownButton>
          </SettingsCategoryRowRightWrapper>
          </SettingsCategoryRowWrapper> 

        </SettingsCategoryWrapper>

        

        <SettingsCategoryWrapper>
          <SettingsCategoryTopWrapper>
            {appearance_svg}<h2>APPEARANCE</h2>
          </SettingsCategoryTopWrapper>

          <SettingsCategoryRowWrapper>
          <h3>Autoplay Video NFTs</h3>
          <SettingsCategoryRowRightWrapper>
            <Farm2024FilterMethodToggleContainer>
                <Farm2024Slider
                  onClick={(e) => {
                    updateSettings("autoplay", autoplaySetting == 1 ? 0 : 1);
                    setAutoplaySetting(autoplaySetting == 1 ? 0 : 1);
                  }}
                  toggled={autoplaySetting == 1}
                  >
                    <Farm2024Circle toggled={autoplaySetting == 1} />
                </Farm2024Slider>
            </Farm2024FilterMethodToggleContainer> 
          </SettingsCategoryRowRightWrapper>
          </SettingsCategoryRowWrapper> 

          <SettingsCategoryRowWrapper>
          <h3>Show NSFW Content</h3>
          <SettingsCategoryRowRightWrapper>
            <Farm2024FilterMethodToggleContainer>
                <Farm2024Slider
                  onClick={(e) => {
                    updateSettings("showNsfw", showNsfw == 1 ? 0 : 1);
                    setShowNsfw(showNsfw == 1 ? 0 : 1);
                  }}
                  toggled={showNsfw == 1}
                  >
                    <Farm2024Circle toggled={showNsfw == 1} />
                </Farm2024Slider>
            </Farm2024FilterMethodToggleContainer> 
          </SettingsCategoryRowRightWrapper>
          </SettingsCategoryRowWrapper> 

        </SettingsCategoryWrapper>



        </PageBody2024>
      </PageWrapper2024>
      );
  }
  else {
    return <Error error="You must log in to use this feature" />
  }
};

export default Settings;
