import React, { useEffect, useState } from "react";
import { BackedTokensWrapper, ChooseProductButton, ChooseProductPopup, ProductButton } from "../pages/utilities/WalletStyles2024";
import { CreateLockButton, theme2024 } from "../Styles2024";
import {
  ConfirmUnderstandingWrapper,
  DetailsModalWrapper,
} from "../data/css/NftStyles2024";
import { checked_svg } from "../data/svgs";
import {
  bulkBackNfts,
  bulkBurnNfts,
  bulkTransferNfts,
} from "../pages/utilities/wallet_functions_2024";
import { LockAmountInput } from "../pages/locker/LockStyles";
import { useGetTokenBalsFromLightApi } from "./CustomHooks/useGetTokenBalsFromLightApi";
import LoadingDiv from "./LoadingDiv";

const handleInputChange = (e, setInputAmount) => {
  const value = e.target.value;
  if (value === "" || /^\d*\.?\d*$/.test(value)) {
    if (value === "" || !isNaN(value)) {
      setInputAmount(value);
    }
  }
};

const BulkActionsModal = (props) => {
  const setShowBulkModal = props.setShowBulkModal;
  const wharfSession = props.wharfSession;
  const enterModalText = props.enterModalText;
  const enterModalDisplay = props.enterModalDisplay;
  const loadingDisplay = props.loadingDisplay;
  const setEnterModalText = props.setEnterModalText;
  const setEnterModalDisplay = props.setEnterModalDisplay;
  const setLoadingDisplay = props.setLoadingDisplay;
  const assetVector = props.assetVector;
  const setAssetVector = props.setAssetVector;
  const setAllAreSelected = props.setAllAreSelected;
  let owner = props.owner;
  if(!owner){
    owner = '';
  }
  let location = props.location;
  if (!location) {
    location = "";
  }
  const currentBulkAction = props.currentBulkAction;
  const setCurrentBulkAction = props.setCurrentBulkAction;

  const [tokenBalances, getTokenBalances, balancesAreLoading] =
    useGetTokenBalsFromLightApi();

  const [understands, setUnderstands] = useState(false);
  const [memo, setMemo] = useState("");
  const [receiver, setReceiver] = useState("");
  const [amountToBack, setAmountToBack] = useState("");
  const [showProductPopup, setShowProductPopup] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const [tokenSearchText, setTokenSearchText] = useState("");

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (currentBulkAction && currentBulkAction == "BACK") {
        getTokenBalances();
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <DetailsModalWrapper>
      <div
        style={{
          width: "auto",
          marginLeft: "auto",
          marginTop: "10px",
          marginBottom: "10px",
          textAlign: "right",
          paddingRight: "15px",
          fontSize: "22px",
          fontWeight: "400",
          color: theme2024.textMain,
        }}
      >
        <button
          onClick={() => {
            setShowBulkModal(false);
          }}
        >
          X
        </button>
      </div>

      {currentBulkAction && currentBulkAction == "BURN" && (
        <>
          <BackedTokensWrapper>
            You are about to permanently destroy {assetVector?.length} NFTs.
          </BackedTokensWrapper>
          <br />
          <ConfirmUnderstandingWrapper understands={understands}>
            <button
              onClick={() => {
                setUnderstands((prev) => !prev);
              }}
            >
              {understands && checked_svg}
            </button>{" "}
            I UNDERSTAND
          </ConfirmUnderstandingWrapper>
          <br />
          <CreateLockButton
            disabled={!understands}
            onClick={() => {
              setShowBulkModal(false);
              bulkBurnNfts(
                assetVector,
                setAssetVector,
                setAllAreSelected,
                setCurrentBulkAction,
                setEnterModalDisplay,
                setEnterModalText,
                setLoadingDisplay,
                wharfSession
              );
            }}
          >
            {understands
              ? `BURN ${assetVector.length} NFTS`
              : "MISSING CONFIRMATION"}
          </CreateLockButton>
        </>
      )}

      {currentBulkAction && currentBulkAction == "TRANSFER" && (
        <>
          <BackedTokensWrapper>
            You are about to transfer {assetVector?.length} NFTs.
          </BackedTokensWrapper>

          <LockAmountInput wide={true}>
            <div className="text-center w-100 mt-3">
              <h3>Receiving Account</h3>
              <input
                placeholder="e.g. mikedcrypto5"
                value={receiver}
                maxLength={12}
                onChange={(e) => {
                  setReceiver(e.target.value.toLowerCase());
                }}
              />
              <br />
              <br />
              <h3>Memo</h3>
              <input
                placeholder="optional"
                maxLength={240}
                value={memo}
                onChange={(e) => {
                  setMemo(e.target.value);
                }}
              />
            </div>
          </LockAmountInput>

          <br />
          <CreateLockButton
            disabled={receiver.length == 0}
            onClick={() => {
              setShowBulkModal(false);
              bulkTransferNfts(
                assetVector,
                setAssetVector,
                receiver,
                memo,
                setAllAreSelected,
                setCurrentBulkAction,
                setEnterModalDisplay,
                setEnterModalText,
                setLoadingDisplay,
                wharfSession
              );
            }}
          >
            {receiver.length > 0
              ? `TRANSFER ${assetVector.length} NFTS`
              : "MISSING RECEIVER"}
          </CreateLockButton>
        </>
      )}

      {currentBulkAction && currentBulkAction == "BACK" && (
        <>
          {balancesAreLoading && <LoadingDiv />}
          {!balancesAreLoading &&
            (!tokenBalances || tokenBalances.length == 0) && (
              <BackedTokensWrapper>
                You have no tokens to back NFTs with.
              </BackedTokensWrapper>
            )}
          {!balancesAreLoading && tokenBalances && tokenBalances.length > 0 && (
            <>
              <BackedTokensWrapper>
                You are about to back {assetVector?.length} NFTs. Select the token you want to use,{" "}
                and enter the amount to back each NFT with.
              </BackedTokensWrapper>

                    <ChooseProductButton
                        onClick={() => {setShowProductPopup((prev) => !prev)}}
                    >
                            
                            <input placeholder="CHOOSE TOKEN" 
                              value={tokenSearchText}
                              onChange={(e) => {
                                setTokenSearchText(e.target.value)
                              }}
                              maxLength={7}
                            />
                            <ChooseProductPopup visible={showProductPopup}>
                                {tokenBalances.sort((a,b) => b.amount - a.amount).map((item, index) => (
                                    <ProductButton key={index} 
                                    hide={tokenSearchText.length > 0 && 
                                      item.currency.substring(0, tokenSearchText.length).indexOf(tokenSearchText.toUpperCase()) == -1}
                                        onClick={() => {
                                            setSelectedToken(item)
                                            setTokenSearchText(item.currency)
                                        }}                        
                                    >
                                    {item.currency} ({item.amount})
                                    </ProductButton>    
                                ))}

                     
                            </ChooseProductPopup>
                    </ChooseProductButton>
                    <br/>            
        
                {selectedToken && (
                    <>
              <LockAmountInput wide={true}>
                <div className="text-center w-100 mt-3">
                  <h3>{selectedToken.currency} Amount</h3>
                  <input
                    placeholder="e.g. 69.420"
                    value={amountToBack}
                    maxLength={12}
                    onChange={(e) => {
                      handleInputChange(e, setAmountToBack);
                    }}
                  />
                </div>
              </LockAmountInput>
              {amountToBack != "" && amountToBack > 0 && (
                <>
                  <br />
                  <BackedTokensWrapper>
                    {`${amountToBack} ${selectedToken.currency} backed into each NFT adds up to to a total of ${
                      amountToBack * assetVector.length
                    } ${selectedToken.currency}`}
                  </BackedTokensWrapper>
                </>
              )}

              <br />
              <CreateLockButton
                disabled={amountToBack == "" || amountToBack == 0 || !selectedToken}
                onClick={() => {
                  setShowBulkModal(false);
                  bulkBackNfts(
                    owner,
                    assetVector,
                    amountToBack,
                    selectedToken,
                    setAssetVector,
                    setAllAreSelected,
                    setCurrentBulkAction,
                    setEnterModalDisplay,
                    setEnterModalText,
                    setLoadingDisplay,
                    wharfSession
                  );
                }}
              >
                {amountToBack != "" && amountToBack > 0
                  ? `BACK ${assetVector.length} NFTS`
                  : "ENTER AMOUNT"}
              </CreateLockButton>                    
                    </>
                )}

            </>
          )}
        </>
      )}
    </DetailsModalWrapper>
  );
};

export default BulkActionsModal;
