import React from 'react';
import { ModalContent } from './LeftDiv';
import { SelectLpTokenUnderlyingInfo, SelectTokenHeaderCont, SelectTokenModalInnerWrapper, SelectTokenModalWrapper, SelectTokenRow, SelectTokenRowTokenAmount, SelectTokenRowTokenName } from '../Styles2024';

const SelectLpExchangeModal = (props) => {
    const showAttributeModal = props.showAttributeModal;
    const setShowAttributeModal = props.setShowAttributeModal;
    const selectedExchange = props.selectedExchange;
    const setSelectedExchange = props.setSelectedExchange;
    const underlying = props.underlying;
    const exchangeContract = props.exchangeContract;
    const setExchangeContract = props.setExchangeContract;
    const setSelectedToken = props.setSelectedToken;
    const setAmount = props.setAmount;

    return (
        <SelectTokenModalWrapper className={showAttributeModal}>
            <SelectTokenHeaderCont>
                <div>Select An Exchange</div>
                <div style={{ paddingRight: "5px", fontWeight: "300" }}>
                    <button onClick={() => setShowAttributeModal(false)}>
                        X
                    </button>
                </div>
            </SelectTokenHeaderCont>
            <SelectTokenModalInnerWrapper>
                <ModalContent>

                                    <SelectTokenRow>
                                        <button
                                            onClick={() => {
                                                setExchangeContract("swap.taco")
                                                setSelectedExchange("Taco Swap")
                                                setSelectedToken(null);
                                                setAmount(0);
                                                setShowAttributeModal(false)
                                            }}
                                        >


                                            <SelectTokenRowTokenName>
                                                Taco Swap
                                            </SelectTokenRowTokenName>
                                        </button>
                                    </SelectTokenRow>

                                    <SelectTokenRow>
                                        <button
                                            onClick={() => {
                                                setExchangeContract("lptoken.box")
                                                setSelectedExchange("Defibox")
                                                setSelectedToken(null);
                                                setShowAttributeModal(false)
                                            }}
                                        >


                                            <SelectTokenRowTokenName>
                                                Defibox
                                            </SelectTokenRowTokenName>
                                        </button>
                                    </SelectTokenRow>                                    
                                    
                </ModalContent>
            </SelectTokenModalInnerWrapper>
        </SelectTokenModalWrapper>
    )
}

export default SelectLpExchangeModal;
