import React, { useEffect, useRef, useState } from "react";
import header_logo from "../data/header_logo.svg";
import { useStateContext } from "../contexts/ContextProvider";
import { Name } from "eos-common";
import { Spinner, SpinnerBlue, SpinnerGreen, SpinnerRed } from "./LeftDiv";
import {
  currentWebsiteURL,
  currentUsername,
  tokenServerURL,
  getTokenEndpoint,
} from "../data/config";

import { isLoggedIn } from "../data/functions/wallet_functions";
import {
  buildIpfsUrl,
  getCustomersData,
} from "../data/functions/global_functions";
import {
  fetchSessionsFromLocalStorage,
  logInWithWharfkit,
  logOutWharfkit,
  removeWharfSession,
  switchWharfAccount,
} from "../data/wharfkit";
import cloud_logo from "../data/mycloudwallet.png";
import anchor_logo from "../data/anchor.svg";
import wombat_logo from "../data/wombat_logo.png";
import scatter_logo from "../data/scatter_logo.png";
import { FaTrashAlt } from "react-icons/fa";
import {
  Modal2024,
  ModalContent2024,
  ModalOverlay2024,
  theme2024,
} from "../Styles2024";
import {
  CloseDivXIconWrapper,
  LargeNav2024BottomWrapper,
  LargeNav2024LinkButton,
  LargeNav2024LinksWrapper,
  LargeNav2024SearchBarWrapper,
  LargeNav2024TopItem,
  LargeNav2024TopWrapper,
  LargeNavDropDownInnerWrapper,
  LargeNavLinkDropDown,
  LargeNavLinkDropDownRow,
  LargeNavSocialIconsDiv,
  LargeNavTopLeftWrapper,
  LargeNavTopRightWrapper,
  LargeNavWrapper2024,
  LargeNavbar2024LoginButton,
  SmallNav2024SearchBarWrapper,
  SmallNavBottomWrapper,
  SmallNavDropDown,
  SmallNavLinkRightSvgWrapper,
  SmallNavLinkSvgWrapper,
  SmallNavLinkWrapper,
  SmallNavLoginButtonWrapper,
  SmallNavSecondaryLink,
  SmallNavSecondaryLinkWrapper,
  SmallNavSocialIconsDiv,
  SmallNavTopLeftWrapper,
  SmallNavTopRightWrapper,
  SmallNavWelcomeInnerCont,
  SmallNavWelcomeRightCont,
  SmallNavWelcomeSvgWrapper,
  SmallNavWelcomeWrapper,
  SmallNavWrapper2024,
  SmallNavbar2024LoginButton,
  SmallScreenWalletsWrapper,
  UserDropDown,
  UserDropDownAvatarCont,
  UserDropDownBalancesCont,
  UserDropDownBuyWaxDaoButton,
  UserDropDownLinkButton,
  UserDropDownTopLeft,
  UserDropDownTopSection,
  UserDropDownUserNameCont,
  UserDropDownWalletsSeparator,
  UserDropDownWalletsTitleCont,
  UserDrowDownInnerScrollWrapper,
} from "../data/css/NavbarStyles2024";
import {
  burger_menu_icon,
  daos_icon,
  down_arrow_svg,
  drops_icon,
  earn_svg,
  happy_svg,
  right_arrow_svg,
  tokens_svg,
  tools_svg,
  x_close_svg,
} from "../data/constants";
import { useGetUserProfile } from "./CustomHooks/useGetUserProfile";
import { createAvatar } from "@dicebear/core";
import { croodles } from "@dicebear/collection";
import { useGetTokenBalsFromLightApi } from "./CustomHooks/useGetTokenBalsFromLightApi";
import NumberFormat from "react-number-format";
import {
  WaxAccountButton,
  WaxAccountGap,
  WaxAccountInnerCont,
  WaxAccountLeft,
  WaxAccountMiddle,
  WaxAccountRight,
} from "../data/css/SidebarStyles";
import axios from "axios";
import {
  discord_svg,
  gear_svg,
  github_svg,
  medium_svg,
  telegram_svg,
  twitter_svg,
  youtube_svg,
} from "../data/svgs";

function formatNumber(num) {
  if (num < 1000000) {
    return (num / 1000).toFixed(2) + "K";
  } else {
    return (num / 1000000).toFixed(2) + "M";
  }
}

const MyAvatar = ({ username }) => {
  const avatar = createAvatar(croodles, {
    seed: username + "@waxdao",
  });

  const svg = avatar.toString();

  return (
    <img
      src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`}
      alt={`Avatar-${username}`}
      style={{
        width: "auto",
        height: "auto",
        minHeight: "100%",
        minWidth: "100%",
      }}
    />
  );
};

const showWaxAndWaxdaoBalance = (tokens, loading) => {
  const waxColor = theme2024.textSecondary;
  const waxdaoColor = theme2024.primary;

  if (loading)
    return (
      <span>
        <h4 style={{ color: waxColor }}>0 WAX</h4>
        <h4 style={{ color: waxdaoColor }}>0 WAXDAO</h4>
      </span>
    );

  let waxBalance = 0;
  let waxdaoBalance = 0;

  tokens.forEach((t) => {
    if (t.currency === "WAX" && t.contract === "eosio.token") {
      waxBalance = Number(t.amount).toFixed(2);
    } else if (t.currency === "WAXDAO" && t.contract === "token.waxdao") {
      waxdaoBalance = Number(t.amount).toFixed(2);
    }
  });

  return (
    <span>
      <h4 style={{ color: waxColor }}>
        <NumberFormat
          displayType="text"
          thousandSeparator={true}
          value={waxBalance}
        />{" "}
        WAX
      </h4>
      <h4 style={{ color: waxdaoColor }}>
        <NumberFormat
          displayType="text"
          thousandSeparator={true}
          value={waxdaoBalance}
        />{" "}
        WAXDAO
      </h4>
    </span>
  );
};

const getWalletLogo = (session) => {
  if (session.walletPlugin.id == "anchor") return anchor_logo;
  else if (session.walletPlugin.id == "cloudwallet") return cloud_logo;
  else if (session.walletPlugin.id == "wombat") return wombat_logo;
  else if (session.walletPlugin.id == "scatter") return scatter_logo;
};

const handleNavBlur = (e, setSelectedTab) => {
  if (
    e.relatedTarget &&
    e.relatedTarget.href &&
    e.relatedTarget.href.indexOf("http") > -1
  ) {
    return;
  }
  setSelectedTab("");
};

const Navbar2024 = () => {
  const {
    setCurrentUser,
    setWhitelistStatus,
    enterModalText,
    loadingDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
    setIsLoggedInContext,
    setWharfSession,
  } = useStateContext();

  const [sessions, setSessions] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [showUserDropDown, setShowUserDropDown] = useState(false);
  const [showSmallScreenDropDown, setShowSmallScreenDropDown] = useState(false);
  const [showWallets, setShowWallets] = useState(false);
  const [tokenInfo, setTokenInfo] = useState([]);
  const [price, setPrice] = useState(0);
  const [supply, setSupply] = useState(0);
  const [marketCap, setMarketCap] = useState(0);
  const [tokenInfoLoading, setTokenInfoLoading] = useState(true);

  //Custom Hooks
  const [userProfile, getUserProfile, userProfileIsLoading, setUserProfile] =
    useGetUserProfile();
  const [tokenBalances, getTokenBalances, balancesAreLoading] =
    useGetTokenBalsFromLightApi();

  const [screenSize, setScreenSize] = useState(0);
  const screenSizeRef = useRef(window.innerWidth);

  useEffect(() => {
    fetchSessionsFromLocalStorage(setSessions);
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      getUserProfile(currentUsername);
      getTokenBalances();
    }
  }, []);

  useEffect(() => {
    axios
      .post(
        `${tokenServerURL}/${getTokenEndpoint}`,
        {
          id: "WAXDAO@token.waxdao",
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        let data = response.data[0];
        setTokenInfo(response.data[0]);
        if (data?.price) {
          setPrice(data.price);
        }
        if (JSON.parse(data?.supply)?.circulating != null) {
          setSupply(JSON.parse(data?.supply)?.circulating);
        }
        if (data?.price && JSON.parse(data?.supply)?.circulating != null) {
          setMarketCap(
            Number(JSON.parse(data?.supply)?.circulating) * Number(data.price)
          );
        }
        setTokenInfoLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setTokenInfoLoading(false);
      });
  }, []);

  useEffect(() => {
    if (currentUsername && isLoggedIn()) {
      setCurrentUser(currentUsername);
      setIsLoggedInContext(true);

      const nameValue = new Name(currentUsername).raw().toString();
      getCustomersData(nameValue, setWhitelistStatus);
    } else {
      console.log("You are not logged in");
    }
  }, [currentUsername]);

  useEffect(() => {
    const handleResize = () => {
      const old_width = screenSizeRef.current;
      const new_width = window.innerWidth;
      const break_point = Number(1100);
      //console.log("resizing window from " + old_width + " to " + new_width)

      if (
        (old_width < break_point && new_width >= break_point) ||
        (old_width > break_point && new_width <= break_point)
      ) {
        //console.log("passed break point by going from " + old_width + " to " + new_width)
        setSelectedTab("");
        setShowSmallScreenDropDown(false);
        setShowUserDropDown(false);
        setShowWallets(false);
      }

      screenSizeRef.current = new_width;
      setScreenSize(new_width);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  return (
    <div className="">
      <ModalOverlay2024 className={enterModalDisplay} />
      <Modal2024 className={enterModalDisplay}>
        <div
          style={{
            width: "100%",
            textAlign: "right",
            paddingRight: "5px",
            fontSize: "22px",
            fontWeight: "400",
            color: theme2024.textMain,
          }}
        >
          <button
            onClick={() => {
              setEnterModalDisplay("hidden");
            }}
          >
            X
          </button>
        </div>
        <ModalContent2024>{enterModalText}</ModalContent2024>

        <Spinner style={{ display: loadingDisplay }}>
          <SpinnerRed />
          <SpinnerBlue />
          <SpinnerGreen />
        </Spinner>
      </Modal2024>

      {/* Large Screen Navbar */}

      <LargeNavWrapper2024>
        <LargeNav2024TopWrapper>
          <LargeNavTopLeftWrapper>
            <LargeNav2024TopItem>
              WAXDAO PRICE: {Number(price).toFixed(4)}
              {" WAX"}
            </LargeNav2024TopItem>
            <LargeNav2024TopItem>
              CIRCULATING SUPPLY: {formatNumber(Number(supply))}
            </LargeNav2024TopItem>
            <LargeNav2024TopItem>
              MARKET CAP: {formatNumber(Number(marketCap))}
              {" WAX"}
            </LargeNav2024TopItem>
            <LargeNavSocialIconsDiv>
              {/* Telegram */}
              <a href="https://t.me/hoodpunks" target="none">
                {telegram_svg}
              </a>

              {/* Twitter */}
              <a href="https://x.com/waxdao_io" target="none">
                {twitter_svg}
              </a>

              {/* Github */}
              <a href="https://github.com/mdcryptonfts" target="none">
                {github_svg}
              </a>

              {/* Discord */}
              <a href="https://discord.com/invite/xdvn5dkqvP" target="none">
                {discord_svg}
              </a>

              {/* YouTube */}
              <a href="https://www.youtube.com/@MikeDCrypto" target="none">
                {youtube_svg}
              </a>

              {/* Medium */}
              <a href="https://blog.waxdao.io" target="none">
                {medium_svg}
              </a>
            </LargeNavSocialIconsDiv>
          </LargeNavTopLeftWrapper>
          <LargeNavTopRightWrapper>
            <LargeNavbar2024LoginButton
              onClick={() => {
                if (!isLoggedIn()) {
                  logInWithWharfkit(setCurrentUser, setWharfSession);
                } else {
                  setShowUserDropDown((prev) => !prev);
                }
              }}
            >
              {isLoggedIn() ? currentUsername : "LOG IN"}
            </LargeNavbar2024LoginButton>
            <ModalOverlay2024 className={!showUserDropDown && "hidden"} />
            <UserDropDown open={showUserDropDown}>
              <UserDrowDownInnerScrollWrapper>
                <UserDropDownTopSection>
                  <UserDropDownTopLeft>
                    <UserDropDownAvatarCont>
                      {userProfile?.profile?.avatar != null &&
                      userProfile?.profile.avatar.length > 5 ? (
                        <img
                          src={buildIpfsUrl(userProfile?.profile.avatar, 200)}
                          style={{
                            width: "auto",
                            height: "auto",
                            minHeight: "100%",
                            minWidth: "100%",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <span>{MyAvatar({ username: currentUsername })}</span>
                      )}
                    </UserDropDownAvatarCont>
                    <UserDropDownUserNameCont>
                      <a href={`${currentWebsiteURL}/u/${currentUsername}`}>
                        <h3>{currentUsername}</h3>
                        <p>VIEW PROFILE</p>
                      </a>
                    </UserDropDownUserNameCont>
                  </UserDropDownTopLeft>

                  <div
                    style={{
                      //border: "1px solid yellow",
                      width: "10%",
                      textAlign: "right",
                      paddingRight: "5px",
                      fontSize: "22px",
                      fontWeight: "400",
                      color: theme2024.textMain,
                    }}
                  >
                    <button
                      onClick={() => {
                        setShowUserDropDown(false);
                      }}
                    >
                      X
                    </button>
                  </div>
                </UserDropDownTopSection>
                <UserDropDownBalancesCont>
                  <h3>MY BALANCES</h3>
                  {showWaxAndWaxdaoBalance(tokenBalances, balancesAreLoading)}
                  <a href={`${currentWebsiteURL}/swap`}>
                    <UserDropDownBuyWaxDaoButton>
                      BUY WAXDAO
                    </UserDropDownBuyWaxDaoButton>
                  </a>
                </UserDropDownBalancesCont>
                <a href={`${currentWebsiteURL}/wallet`}>
                  <UserDropDownLinkButton>
                    <h3>
                      WALLET{" "}
                      <span
                        style={{
                          fontStyle: "italic",
                          fontWeight: 700,
                          color: theme2024.danger,
                        }}
                      >
                        Beta
                      </span>
                    </h3>
                    <p>Test out our new NFT wallet</p>
                  </UserDropDownLinkButton>
                </a>
                <a href={`${currentWebsiteURL}/inventory`}>
                  <UserDropDownLinkButton>
                    <h3>INVENTORY</h3>
                    <p>View assets and manage creator tools</p>
                  </UserDropDownLinkButton>
                </a>
                <UserDropDownWalletsSeparator />
                <UserDropDownWalletsTitleCont>
                  <h3>MY WALLETS</h3>
                  <button
                    onClick={() =>
                      logInWithWharfkit(setCurrentUser, setWharfSession)
                    }
                  >
                    +
                  </button>
                </UserDropDownWalletsTitleCont>
                <span className={sessions?.length > 1 && "hidden"}>
                  <UserDropDownLinkButton
                    onClick={() =>
                      logOutWharfkit(setCurrentUser, setWharfSession)
                    }
                  >
                    <h3>LOG OUT</h3>
                    <p>End your session with WaxDAO</p>
                  </UserDropDownLinkButton>
                </span>
                {sessions?.length > 1 &&
                  sessions.map((item, index) => (
                    <span
                      key={index}
                      className={
                        item.actor == currentUsername &&
                        item.permission ==
                          JSON.parse(localStorage.getItem("wharf--session"))
                            .permission &&
                        "hidden"
                      }
                    >
                      <WaxAccountButton>
                        <WaxAccountInnerCont>
                          <WaxAccountLeft>
                            <img src={getWalletLogo(item)} />
                          </WaxAccountLeft>

                          <WaxAccountMiddle
                            onClick={() => {
                              switchWharfAccount(
                                item.actor,
                                item.permission,
                                item.walletPlugin.id,
                                setCurrentUser,
                                setWharfSession
                              );
                            }}
                          >
                            {item.actor} ({item.permission})
                          </WaxAccountMiddle>

                          <WaxAccountGap />

                          <WaxAccountRight
                            onClick={() => {
                              removeWharfSession(
                                item.actor,
                                item.permission,
                                item.walletPlugin.id,
                                setSessions
                              );
                            }}
                          >
                            <FaTrashAlt
                              style={{
                                display: "inline-block",
                                color: theme2024.primary,
                              }}
                            />
                          </WaxAccountRight>
                        </WaxAccountInnerCont>
                      </WaxAccountButton>
                    </span>
                  ))}
              </UserDrowDownInnerScrollWrapper>
            </UserDropDown>
          </LargeNavTopRightWrapper>
        </LargeNav2024TopWrapper>
        <LargeNav2024BottomWrapper>
          <a href={currentWebsiteURL}>
            <img src={header_logo} />
          </a>
          <LargeNav2024LinksWrapper>
            <LargeNav2024LinkButton
              onBlur={(e) => handleNavBlur(e, setSelectedTab)}
              onClick={() => {
                selectedTab == "Earn"
                  ? setSelectedTab("")
                  : setSelectedTab("Earn");
              }}
            >
              {earn_svg} EARN
              <LargeNavLinkDropDown open={selectedTab == "Earn"}>
                <LargeNavDropDownInnerWrapper>
                  <a href={`${currentWebsiteURL}/farms`} value="a link">
                    <LargeNavLinkDropDownRow>
                      NFT STAKING
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/pools`}>
                    <LargeNavLinkDropDownRow>
                      TOKEN STAKING
                    </LargeNavLinkDropDownRow>
                  </a>
                </LargeNavDropDownInnerWrapper>
              </LargeNavLinkDropDown>
            </LargeNav2024LinkButton>

            <LargeNav2024LinkButton
              onBlur={(e) => handleNavBlur(e, setSelectedTab)}
              onClick={() => {
                selectedTab == "Tools"
                  ? setSelectedTab("")
                  : setSelectedTab("Tools");
              }}
            >
              {tools_svg} TOOLS
              <LargeNavLinkDropDown open={selectedTab == "Tools"}>
                <LargeNavDropDownInnerWrapper>
                <a href={`https://waxfusion.io/rent`} target="none">
                    <LargeNavLinkDropDownRow>
                      <span
                        style={{ color: theme2024.danger, fontWeight: 600 }}
                      >
                        RENT CPU
                      </span>
                      <p>NEW</p>
                    </LargeNavLinkDropDownRow>
                  </a>

                  <a href={`${currentWebsiteURL}/airdrop`}>
                    <LargeNavLinkDropDownRow>
                        AIRDROP
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/create-farm`}>
                    <LargeNavLinkDropDownRow>
                      NFT FARM CREATOR
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a
                    href={`https://farms.waxdao.io/farms?tab=create`}
                    target="none"
                  >
                    <LargeNavLinkDropDownRow>
                      TOKEN FARM CREATOR
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/v2/create-dao`}>
                    <LargeNavLinkDropDownRow>
                      DAO CREATOR
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/token-creator`}>
                    <LargeNavLinkDropDownRow>
                      TOKEN CREATOR
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/create-drop`}>
                    <LargeNavLinkDropDownRow>
                      NFT DROP CREATOR
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/create-blend`}>
                    <LargeNavLinkDropDownRow>
                      BLEND CREATOR
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/liquidity-locker`}>
                    <LargeNavLinkDropDownRow>
                      LIQUIDITY LOCKER
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/locker`}>
                    <LargeNavLinkDropDownRow>
                      TOKEN LOCKER
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/escrow`}>
                    <LargeNavLinkDropDownRow>
                      SLOW RELEASE ESCROW
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/synths`}>
                    <LargeNavLinkDropDownRow>
                      SYNTHETIC ASSETS
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/create-premint`}>
                    <LargeNavLinkDropDownRow>
                      PREMINT POOL CREATOR
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/create-pack`}>
                    <LargeNavLinkDropDownRow>
                      PREMINT PACK CREATOR
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/royalty-contract`}>
                    <LargeNavLinkDropDownRow>
                      ROYALTY CONTRACT
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/remove-contract`}>
                    <LargeNavLinkDropDownRow>
                      CONTRACT REMOVAL
                    </LargeNavLinkDropDownRow>
                  </a>
                </LargeNavDropDownInnerWrapper>
              </LargeNavLinkDropDown>
            </LargeNav2024LinkButton>

            <LargeNav2024LinkButton
              onBlur={(e) => handleNavBlur(e, setSelectedTab)}
              onClick={() => {
                selectedTab == "Tokens"
                  ? setSelectedTab("")
                  : setSelectedTab("Tokens");
              }}
            >
              {tokens_svg} TOKENS
              <LargeNavLinkDropDown open={selectedTab == "Tokens"}>
                <LargeNavDropDownInnerWrapper>
                  <a href={`${currentWebsiteURL}/token-creator`}>
                    <LargeNavLinkDropDownRow>
                      TOKEN CREATOR
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/locker`}>
                    <LargeNavLinkDropDownRow>
                      TOKEN LOCKER
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/tokens`}>
                    <LargeNavLinkDropDownRow>
                      WAX TOKEN PRICES
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/migrate`}>
                    <LargeNavLinkDropDownRow>
                      WAXDAO MIGRATION
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/tokenomics`}>
                    <LargeNavLinkDropDownRow>
                      OUR TOKENOMICS
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/swap`}>
                    <LargeNavLinkDropDownRow>
                      BUY WAXDAO
                    </LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/request-listing`}>
                    <LargeNavLinkDropDownRow>
                      LISTING REQUEST
                    </LargeNavLinkDropDownRow>
                  </a>
                </LargeNavDropDownInnerWrapper>
              </LargeNavLinkDropDown>
            </LargeNav2024LinkButton>

            <LargeNav2024LinkButton
              onBlur={(e) => handleNavBlur(e, setSelectedTab)}
              onClick={() => {
                selectedTab == "Drops"
                  ? setSelectedTab("")
                  : setSelectedTab("Drops");
              }}
            >
              {drops_icon} DROPS
              <LargeNavLinkDropDown open={selectedTab == "Drops"}>
                <LargeNavDropDownInnerWrapper>
                  <a href={`${currentWebsiteURL}/drops`}>
                    <LargeNavLinkDropDownRow>NFT DROPS</LargeNavLinkDropDownRow>
                  </a>
                  <a href={`${currentWebsiteURL}/blends`}>
                    <LargeNavLinkDropDownRow>BLENDS</LargeNavLinkDropDownRow>
                  </a>
                </LargeNavDropDownInnerWrapper>
              </LargeNavLinkDropDown>
            </LargeNav2024LinkButton>

            <a href={`${currentWebsiteURL}/v2/daos`}>
              <LargeNav2024LinkButton>{daos_icon} DAOS</LargeNav2024LinkButton>
            </a>

            <a href={`${currentWebsiteURL}/settings`}>
              <LargeNav2024LinkButton>
                {gear_svg} SETTINGS
              </LargeNav2024LinkButton>
            </a>
          </LargeNav2024LinksWrapper>
          <LargeNav2024SearchBarWrapper>
            <input placeholder="Coming Soon" disabled />
          </LargeNav2024SearchBarWrapper>
        </LargeNav2024BottomWrapper>
      </LargeNavWrapper2024>

      {/* Small Screen Navbar */}

      <SmallNavWrapper2024>
        <ModalOverlay2024 className={!showSmallScreenDropDown && "hidden"} />
        <LargeNav2024TopWrapper>
          <SmallNavTopLeftWrapper>
            <a href={currentWebsiteURL}>
              <img src={header_logo} />
            </a>
          </SmallNavTopLeftWrapper>
          <SmallNavTopRightWrapper open={showSmallScreenDropDown}>
            <button
              onClick={() =>
                showSmallScreenDropDown
                  ? setShowSmallScreenDropDown(false)
                  : setShowSmallScreenDropDown(true)
              }
            >
              {!showSmallScreenDropDown && burger_menu_icon}
            </button>
          </SmallNavTopRightWrapper>
        </LargeNav2024TopWrapper>
        <LargeNav2024BottomWrapper>
          <SmallNav2024SearchBarWrapper>
            <input placeholder="Coming Soon" disabled />
          </SmallNav2024SearchBarWrapper>
        </LargeNav2024BottomWrapper>
      </SmallNavWrapper2024>
      <SmallNavDropDown open={showSmallScreenDropDown}>
        <CloseDivXIconWrapper>
          <button
            onClick={() => {
              setShowSmallScreenDropDown(false);
            }}
          >
            {x_close_svg}
          </button>
        </CloseDivXIconWrapper>

        <UserDrowDownInnerScrollWrapper>
          {!isLoggedIn() && (
            <span>
              <SmallNavWelcomeWrapper>
                <SmallNavWelcomeInnerCont>
                  <SmallNavWelcomeSvgWrapper>
                    {happy_svg}
                  </SmallNavWelcomeSvgWrapper>
                  <SmallNavWelcomeRightCont>
                    <h3>YOU MADE IT!</h3>
                    <h4>JUMP RIGHT IN</h4>
                  </SmallNavWelcomeRightCont>
                </SmallNavWelcomeInnerCont>
              </SmallNavWelcomeWrapper>
              <SmallNavLoginButtonWrapper>
                <SmallNavbar2024LoginButton
                  onClick={() => {
                    logInWithWharfkit(setCurrentUser, setWharfSession);
                  }}
                >
                  LOG IN
                </SmallNavbar2024LoginButton>
              </SmallNavLoginButtonWrapper>
            </span>
          )}

          {isLoggedIn() && (
            <span>
              <UserDropDownTopSection>
                <UserDropDownTopLeft>
                  <UserDropDownAvatarCont>
                    {userProfile?.profile?.avatar != null &&
                    userProfile?.profile.avatar.length > 5 ? (
                      <img
                        src={buildIpfsUrl(userProfile?.profile.avatar, 200)}
                        style={{
                          width: "auto",
                          height: "auto",
                          minHeight: "100%",
                          minWidth: "100%",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <span>{MyAvatar({ username: currentUsername })}</span>
                    )}
                  </UserDropDownAvatarCont>
                  <UserDropDownUserNameCont>
                    <a href={`${currentWebsiteURL}/u/${currentUsername}`}>
                      <h3>{currentUsername}</h3>
                      <p>VIEW PROFILE</p>
                    </a>
                  </UserDropDownUserNameCont>
                </UserDropDownTopLeft>
              </UserDropDownTopSection>
              <UserDropDownBalancesCont>
                <h3>MY BALANCES</h3>
                {showWaxAndWaxdaoBalance(tokenBalances, balancesAreLoading)}
                <a href={`${currentWebsiteURL}/swap`}>
                  <UserDropDownBuyWaxDaoButton>
                    BUY WAXDAO
                  </UserDropDownBuyWaxDaoButton>
                </a>
              </UserDropDownBalancesCont>
              <a href={`${currentWebsiteURL}/wallet`}>
                <UserDropDownLinkButton>
                  <h3>
                    WALLET{" "}
                    <span
                      style={{
                        fontStyle: "italic",
                        fontWeight: 700,
                        color: theme2024.danger,
                      }}
                    >
                      Beta
                    </span>
                  </h3>
                  <p>Test out our new NFT wallet</p>
                </UserDropDownLinkButton>
              </a>
              <a href={`${currentWebsiteURL}/inventory`}>
                <UserDropDownLinkButton>
                  <h3>INVENTORY</h3>
                  <p>View assets and manage creator tools</p>
                </UserDropDownLinkButton>
              </a>
              <UserDropDownWalletsSeparator />
              <UserDropDownWalletsTitleCont>
                <h3>{sessions?.length == 1 ? "ADD WALLET" : "MY WALLETS"}</h3>
                <button
                  onClick={() =>
                    logInWithWharfkit(setCurrentUser, setWharfSession)
                  }
                >
                  +
                </button>
                {sessions?.length > 1 && (
                  <button onClick={() => setShowWallets((prev) => !prev)}>
                    {showWallets ? x_close_svg : down_arrow_svg}
                  </button>
                )}
              </UserDropDownWalletsTitleCont>
              <span className={sessions?.length > 1 && "hidden"}>
                <UserDropDownLinkButton
                  onClick={() =>
                    logOutWharfkit(setCurrentUser, setWharfSession)
                  }
                >
                  <h3>LOG OUT</h3>
                  <p>End your session with WaxDAO</p>
                </UserDropDownLinkButton>
              </span>

              <SmallScreenWalletsWrapper open={showWallets}>
                {sessions?.length > 1 &&
                  sessions.map((item, index) => (
                    <span
                      key={index}
                      className={
                        item.actor == currentUsername &&
                        item.permission ==
                          JSON.parse(localStorage.getItem("wharf--session"))
                            .permission &&
                        "hidden"
                      }
                    >
                      <WaxAccountButton>
                        <WaxAccountInnerCont>
                          <WaxAccountLeft>
                            <img src={getWalletLogo(item)} />
                          </WaxAccountLeft>

                          <WaxAccountMiddle
                            onClick={() => {
                              switchWharfAccount(
                                item.actor,
                                item.permission,
                                item.walletPlugin.id,
                                setCurrentUser,
                                setWharfSession
                              );
                            }}
                          >
                            {item.actor} ({item.permission})
                          </WaxAccountMiddle>

                          <WaxAccountGap />

                          <WaxAccountRight
                            onClick={() => {
                              removeWharfSession(
                                item.actor,
                                item.permission,
                                item.walletPlugin.id,
                                setSessions
                              );
                            }}
                          >
                            <FaTrashAlt
                              style={{
                                display: "inline-block",
                                color: theme2024.primary,
                              }}
                            />
                          </WaxAccountRight>
                        </WaxAccountInnerCont>
                      </WaxAccountButton>
                    </span>
                  ))}
              </SmallScreenWalletsWrapper>
            </span>
          )}

          <SmallNavLinkWrapper>
            <SmallNavLinkSvgWrapper>{earn_svg}</SmallNavLinkSvgWrapper>
            <h3>EARN</h3>
            <SmallNavLinkRightSvgWrapper>
              <button
                onClick={() =>
                  selectedTab == "Earn"
                    ? setSelectedTab("")
                    : setSelectedTab("Earn")
                }
              >
                {selectedTab == "Earn" ? x_close_svg : down_arrow_svg}
              </button>
            </SmallNavLinkRightSvgWrapper>
            <SmallNavSecondaryLinkWrapper open={selectedTab == "Earn"}>
              <a href={`${currentWebsiteURL}/farms`}>
                <SmallNavSecondaryLink>STAKE NFTS</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/pools`}>
                <SmallNavSecondaryLink>STAKE TOKENS</SmallNavSecondaryLink>
              </a>
            </SmallNavSecondaryLinkWrapper>
          </SmallNavLinkWrapper>

          <SmallNavLinkWrapper>
            <SmallNavLinkSvgWrapper>{tools_svg}</SmallNavLinkSvgWrapper>
            <h3>TOOLS</h3>
            <SmallNavLinkRightSvgWrapper>
              <button
                onClick={() =>
                  selectedTab == "Tools"
                    ? setSelectedTab("")
                    : setSelectedTab("Tools")
                }
              >
                {selectedTab == "Tools" ? x_close_svg : down_arrow_svg}
              </button>
            </SmallNavLinkRightSvgWrapper>
            <SmallNavSecondaryLinkWrapper open={selectedTab == "Tools"}>
            <a href={`https://waxfusion.io/rent`} target="none">
                <SmallNavSecondaryLink>
                  <span style={{ color: theme2024.danger, fontWeight: 600 }}>
                    RENT CPU
                  </span>
                  <p>NEW</p>
                </SmallNavSecondaryLink>
              </a>              
              <a href={`${currentWebsiteURL}/airdrop`}>
                <SmallNavSecondaryLink>
                    AIRDROP
                </SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/create-farm`}>
                <SmallNavSecondaryLink>NFT FARM CREATOR</SmallNavSecondaryLink>
              </a>
              <a
                href={`https://farms.waxdao.io/farms?tab=create`}
                target="none"
              >
                <SmallNavSecondaryLink>
                  TOKEN FARM CREATOR
                </SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/v2/create-dao`}>
                <SmallNavSecondaryLink>DAO CREATOR</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/token-creator`}>
                <SmallNavSecondaryLink>TOKEN CREATOR</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/create-drop`}>
                <SmallNavSecondaryLink>NFT DROP CREATOR</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/create-blend`}>
                <SmallNavSecondaryLink>BLEND CREATOR</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/liquidity-locker`}>
                <SmallNavSecondaryLink>LIQUIDITY LOCKER</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/locker`}>
                <SmallNavSecondaryLink>TOKEN LOCKER</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/escrow`}>
                <SmallNavSecondaryLink>
                  SLOW RELEASE ESCROW
                </SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/synths`}>
                <SmallNavSecondaryLink>SYNTHETIC ASSETS</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/create-premint`}>
                <SmallNavSecondaryLink>
                  PREMINT POOL CREATOR
                </SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/create-pack`}>
                <SmallNavSecondaryLink>
                  PREMINT PACK CREATOR
                </SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/royalty-contract`}>
                <SmallNavSecondaryLink>ROYALTY CONTRACT</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/remove-contract`}>
                <SmallNavSecondaryLink>CONTRACT REMOVAL</SmallNavSecondaryLink>
              </a>
            </SmallNavSecondaryLinkWrapper>
          </SmallNavLinkWrapper>

          <SmallNavLinkWrapper>
            <SmallNavLinkSvgWrapper>{tokens_svg}</SmallNavLinkSvgWrapper>
            <h3>TOKENS</h3>
            <SmallNavLinkRightSvgWrapper>
              <button
                onClick={() =>
                  selectedTab == "Tokens"
                    ? setSelectedTab("")
                    : setSelectedTab("Tokens")
                }
              >
                {selectedTab == "Tokens" ? x_close_svg : down_arrow_svg}
              </button>
            </SmallNavLinkRightSvgWrapper>
            <SmallNavSecondaryLinkWrapper open={selectedTab == "Tokens"}>
              <a href={`${currentWebsiteURL}/token-creator`}>
                <SmallNavSecondaryLink>TOKEN CREATOR</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/locker`}>
                <SmallNavSecondaryLink>TOKEN LOCKER</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/tokens`}>
                <SmallNavSecondaryLink>WAX TOKEN PRICES</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/migrate`}>
                <SmallNavSecondaryLink>WAXDAO MIGRATION</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/tokenomics`}>
                <SmallNavSecondaryLink>OUR TOKENOMICS</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/swap`}>
                <SmallNavSecondaryLink>BUY WAXDAO</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/request-listing`}>
                <SmallNavSecondaryLink>LISTING REQUEST</SmallNavSecondaryLink>
              </a>
            </SmallNavSecondaryLinkWrapper>
          </SmallNavLinkWrapper>

          <SmallNavLinkWrapper>
            <SmallNavLinkSvgWrapper>{drops_icon}</SmallNavLinkSvgWrapper>
            <h3>DROPS</h3>
            <SmallNavLinkRightSvgWrapper>
              <button
                onClick={() =>
                  selectedTab == "Drops"
                    ? setSelectedTab("")
                    : setSelectedTab("Drops")
                }
              >
                {selectedTab == "Drops" ? x_close_svg : down_arrow_svg}
              </button>
            </SmallNavLinkRightSvgWrapper>
            <SmallNavSecondaryLinkWrapper open={selectedTab == "Drops"}>
              <a href={`${currentWebsiteURL}/drops`}>
                <SmallNavSecondaryLink>NFT DROPS</SmallNavSecondaryLink>
              </a>
              <a href={`${currentWebsiteURL}/blends`}>
                <SmallNavSecondaryLink>BLENDS</SmallNavSecondaryLink>
              </a>
            </SmallNavSecondaryLinkWrapper>
          </SmallNavLinkWrapper>

          <SmallNavLinkWrapper>
            <SmallNavLinkSvgWrapper>{daos_icon}</SmallNavLinkSvgWrapper>
            <a href={`${currentWebsiteURL}/v2/daos`}>
              <h3>DAOS</h3>
            </a>
            <SmallNavLinkRightSvgWrapper>
              <a href={`${currentWebsiteURL}/v2/daos`}>{right_arrow_svg}</a>
            </SmallNavLinkRightSvgWrapper>
          </SmallNavLinkWrapper>

          <SmallNavLinkWrapper>
            <SmallNavLinkSvgWrapper>{gear_svg}</SmallNavLinkSvgWrapper>
            <a href={`${currentWebsiteURL}/settings`}>
              <h3>SETTINGS</h3>
            </a>
            <SmallNavLinkRightSvgWrapper>
              <a href={`${currentWebsiteURL}/settings`}>{right_arrow_svg}</a>
            </SmallNavLinkRightSvgWrapper>
          </SmallNavLinkWrapper>

          <SmallNavBottomWrapper>
            <SmallNavSocialIconsDiv>
              {/* Telegram */}
              <a href="https://t.me/hoodpunks" target="none">
                {telegram_svg}
              </a>

              {/* Twitter */}
              <a href="https://x.com/waxdao_io" target="none">
                {twitter_svg}
              </a>

              {/* Github */}
              <a href="https://github.com/mdcryptonfts" target="none">
                {github_svg}
              </a>

              {/* Discord */}
              <a href="https://discord.com/invite/xdvn5dkqvP" target="none">
                {discord_svg}
              </a>

              {/* YouTube */}
              <a href="https://www.youtube.com/@MikeDCrypto" target="none">
                {youtube_svg}
              </a>

              {/* Medium */}
              <a href="https://blog.waxdao.io" target="none">
                {medium_svg}
              </a>
            </SmallNavSocialIconsDiv>
            <LargeNav2024TopItem>
              WAXDAO PRICE: {Number(price).toFixed(4)}
              {" WAX"}
            </LargeNav2024TopItem>
            <LargeNav2024TopItem>
              CIRCULATING SUPPLY: {formatNumber(Number(supply))}
            </LargeNav2024TopItem>
            <LargeNav2024TopItem>
              MARKET CAP: {formatNumber(Number(marketCap))}
              {" WAX"}
            </LargeNav2024TopItem>
          </SmallNavBottomWrapper>
        </UserDrowDownInnerScrollWrapper>
      </SmallNavDropDown>
    </div>
  );
};

export default Navbar2024;
