import axios from "axios";
import { GameButton, NFT, NFTLogo } from "../../components/LeftDiv";
import {
  getCurrentApiList,
  getCurrentAtomicApiList,
  currentWebsiteURL,
  defaultTxSettings,
  defaultSpinnerDuration,
} from "../config";
import { resizerPrefix, resizerSuffix } from "../constants";
import { authorizeWaxDAO } from "./wallet_functions";

export function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export const dropIsLive = (this_drop) => {
  var isLive = false;

  if (
    this_drop.end_time != null &&
    this_drop.start_time != null &&
    (this_drop.end_time == 0 || this_drop.end_time * 1000 > new Date()) &&
    (this_drop.start_time == 0 || this_drop.start_time * 1000 < new Date()) &&
    ((this_drop.total_left != null && this_drop.total_left != "0") ||
      (this_drop.total_available != null && this_drop.total_available == "0"))
  ) {
    isLive = true;
  }

  return isLive;
};

export const dropIsSoldOut = (this_drop) => {
  var isSoldOut = false;

  if (
    this_drop.total_left != null &&
    this_drop.total_left == "0" &&
    this_drop.total_available != null &&
    this_drop.total_available != "0"
  ) {
    isSoldOut = true;
  }

  return isSoldOut;
};

export const purchaseDrop = async (
  DropID,
  quantity,
  dropType,
  rewardContract,
  pricePerNFT,
  farmPrecision,
  tokenName,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

    let uniqueID = getRndInteger(100000000, 200000000);
    let apiWorked = "no";
    let amountToSend = (pricePerNFT * quantity).toFixed(farmPrecision);

    const action = [
      {
        account: "waxdaomarket",
        name: "assertdrop",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          drop_ID: DropID,
          quantity_to_assert: quantity,
        },
      },

      {
        account: rewardContract,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          to: "waxdaomarket",
          from: session.actor,
          quantity: amountToSend + " " + tokenName,
          memo: "|purchase_drop|" + DropID + "|" + uniqueID + "|",
        },
      },
    ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);

      setLoadingDisplay("");
      setEnterButtonsDisplay("hidden");
  
      if (dropType == "premint.pool") {
        setEnterModalText("Fetching preminted NFTs...");
        const timer = setTimeout(() => {
          try {
            axios
              .post(
                `${getCurrentApiList
                }/v1/chain/get_table_rows`,
                {
                  table: "results",
                  scope: "waxdaomarket",
                  code: "waxdaomarket",
                  limit: 1,
                  lower_bound: uniqueID,
                  upper_bound: uniqueID,
                  json: true,
                }
              )
              .then((resultResponse) => {
                if (resultResponse.data.rows[0].outcome != "") {
                  apiWorked = "yes";
  
                  axios
                    .get(
                      `${getCurrentAtomicApiList
                      }/atomicassets/v1/assets?ids=${resultResponse.data.rows[0].outcome.join(
                        "%2C"
                      )}&page=1&limit=100&order=desc&sort=asset_id`
                    )
                    .then((validResponse) => {
  
                      setEnterModalText(
                        <span className="text-center align-center justify-center">
                          Here are your new NFTs!
                          <br />
                          {validResponse != null &&
                          validResponse.data.data.length > 0 ? (
                            validResponse.data.data.map((item, index) => (
                              <NFT key={index}>
                                <p key={index}>
                                  <span className="font-bold">
                                    {item.name != null ? item.name : ""}
                                  </span>
                                  <br />
                                  Mint{" "}
                                  {item.template_mint != null
                                    ? item.template_mint
                                    : ""}
                                </p>
  
                                {item.data.img != null && (
                                  <NFTLogo
                                    src={`https://ipfs.io/ipfs/${item.data.img.trim()}`}
                                  />
                                )}
  
                                {item.data.img == null &&
                                  item.data.video != null && (
                                    <video
                                      style={{
                                        width: "150px",
                                        maxWidth: "150px",
                                        height: "150px",
                                        maxHeight: "150px",
                                        borderRadius: "25%",
                                      }}
                                      loop
                                    >
                                      <source
                                        src={`https://ipfs.io/ipfs/${item.data.video.trim()}`}
                                        type="video/mp4"
                                      />
                                    </video>
                                  )}
  
                                {item.data.img == null &&
                                  item.data.video == null &&
                                  item.data.image != null && (
                                    <NFTLogo
                                      src={`https://ipfs.io/ipfs/${item.data.image.trim()}`}
                                    />
                                  )}
                              </NFT>
                            ))
                          ) : (
                            <span></span>
                          )}
                        </span>
                      );
                    });
                } else {
                  setEnterModalText(
                    "Could not retrieve result. Check your wallet to see what you got."
                  );
                }
                setLoadingDisplay("none");
                setEnterButtonsDisplay("");
              });
          } catch (e) {
            setEnterModalText("Could not retrieve result. Check your wallet.");
            console.log(e);
          }
        }, 4000);
        const timer2 = setTimeout(() => {
          if (apiWorked == "no") {
            setEnterModalText("Could not retrieve result. Check your wallet.");
            setEnterButtonsDisplay("");
            setLoadingDisplay("none");
          }
        }, 5000);
  
        return () => {
          clearTimeout(timer);
          clearTimeout(timer2);
        };
      } 
      else {
        setLoadingDisplay("");
        setEnterButtonsDisplay("hidden");
        setEnterModalText("Processing your transaction...");
        const timer = setTimeout(() => {
          setEnterModalText("Your purchase was successful");
          setLoadingDisplay("none");
          setEnterButtonsDisplay("");
        }, 4000);
        return () => clearTimeout(timer);
      }
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }

}; //end purchaseDrop

export const claimDrop = async (
  DropID,
  quantity,
  dropType,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession  

  var uniqueID = getRndInteger(100000000, 200000000);

  var apiWorked = "no";

    const action = [
      {
        account: "waxdaomarket",
        name: "claimdrop",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          drop_ID: DropID,
          quantity_to_mint: quantity,
          unique_id: uniqueID,
        },
      },
    ];


    try{
    const result = await session.transact({ actions: action }, defaultTxSettings);
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");

    if (dropType == "premint.pool") {
      setEnterModalText("Fetching preminted NFT...");
      const timer = setTimeout(() => {
        try {
          axios
            .post(
              `${getCurrentApiList
              }/v1/chain/get_table_rows`,
              {
                table: "results",
                scope: "waxdaomarket",
                code: "waxdaomarket",
                limit: 1,
                lower_bound: uniqueID,
                upper_bound: uniqueID,
                json: true,
              }
            )
            .then((resultResponse) => {
              if (resultResponse.data.rows[0].outcome != "") {
                apiWorked = "yes";

                axios
                  .get(
                    `${getCurrentAtomicApiList
                    }/atomicassets/v1/assets?ids=${
                      resultResponse.data.rows[0].outcome
                    }&page=1&limit=1&order=desc&sort=asset_id`
                  )
                  .then((validResponse) => {
                    setEnterModalText(
                      <span className="text-center align-center justify-center">
                        Here's your new NFT!
                        <br />
                        {validResponse != null && (
                          <span>
                            {validResponse.data.data[0].data.name}
                            <br />
                            <img
                              src={`https://ipfs.io/ipfs/${validResponse.data.data[0].data.img}`}
                              style={{
                                width: "150px",
                                maxWidth: "150px",
                                height: "auto",
                                marginLeft: "auto",
                                marginRight: "auto",
                                alignSelf: "center",
                              }}
                            />
                          </span>
                        )}
                      </span>
                    );
                  });
              } else {
                setEnterModalText(
                  "Could not retrieve result. Check your wallet to see what you got."
                );
              }
              setLoadingDisplay("none");
              setEnterButtonsDisplay("");
            });
        } catch (e) {
          setEnterModalText("Could not retrieve result. Check your wallet.");
          console.log(e);
        } //end catch timer
      }, 4000);
      const timer2 = setTimeout(() => {
        if (apiWorked == "no") {
          setEnterModalText("Could not retrieve result. Check your wallet.");
          setEnterButtonsDisplay("");
          setLoadingDisplay("none");
        }
      }, 5000);

      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    } //end if drop type is premint pool
    else {
      setLoadingDisplay("");
      setEnterButtonsDisplay("hidden");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText("Your purchase was successful");
        setLoadingDisplay("none");
        setEnterButtonsDisplay("");
      }, 4000);
      return () => clearTimeout(timer);
    }
  } catch (e) {
    console.log('ERROR: ', e);
    setEnterModalText(e.message);
    setEnterModalDisplay("");
  }
}; //end claimDrop



export const unbox = async (
  DropID,
  asset_id,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

  let uniqueID = getRndInteger(100000000, 200000000);

  let apiWorked = "no";
  
    const action = [
      {
        account: "atomicassets",
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          to: "waxdaomarket",
          from: session.actor,
          asset_ids: [asset_id],
          memo: "|unbox|" + DropID + "|" + uniqueID + "|",
        },
      },
    ];

    try {

    const result = await session.transact({ actions: action }, defaultTxSettings);

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Fetching pack results...");
    const timer = setTimeout(() => {
      try {
        //get result from API
        axios
          .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
            table: "results",
            scope: "waxdaomarket",
            code: "waxdaomarket",
            limit: 1,
            lower_bound: uniqueID,
            upper_bound: uniqueID,
            json: true,
          })
          .then((resultResponse) => {
            if (resultResponse.data.rows[0].outcome != "") {
              apiWorked = "yes";

              axios
                .get(
                  `${getCurrentAtomicApiList}/atomicassets/v1/assets?ids=${resultResponse.data.rows[0].outcome.join(
                    "%2C"
                  )}&page=1&limit=100&order=desc&sort=asset_id`
                )
                .then((validResponse) => {
                  setEnterModalText(
                    <span className="text-center align-center justify-center">
                      Here are your new NFTs!
                      <br />
                      {validResponse != null &&
                      validResponse.data.data.length > 0 ? (
                        validResponse.data.data.map((item, index) => (
                          <NFT key={index}>
                            <p>
                              <span className="font-bold">
                                {item.name != null ? item.name : ""}
                              </span>
                              <br />
                              Mint{" "}
                              {item.template_mint != null
                                ? item.template_mint
                                : ""}
                            </p>

                            {item.data.img != null && (
                              <NFTLogo
                                src={`${resizerPrefix}${item.data.img.trim()}${resizerSuffix}`}
                              />
                            )}

                            {item.data.img == null &&
                              item.data.video != null && (
                                <video
                                  style={{
                                    width: "150px",
                                    maxWidth: "150px",
                                    height: "150px",
                                    maxHeight: "150px",
                                    borderRadius: "25%",
                                  }}
                                  loop
                                >
                                  <source
                                    src={`${resizerPrefix}${item.data.video.trim()}${resizerSuffix}`}
                                    type="video/mp4"
                                  />
                                </video>
                              )}

                            {item.data.img == null &&
                              item.data.video == null &&
                              item.data.image != null && (
                                <NFTLogo
                                  src={`${resizerPrefix}${item.data.image.trim()}${resizerSuffix}`}
                                />
                              )}
                          </NFT>
                        ))
                      ) : (
                        <span></span>
                      )}
                    </span>
                  );
                });
            } else {
              setEnterModalText(
                "Could not retrieve result. Check your wallet to see what you got."
              );
            }
            setLoadingDisplay("none");
            setEnterButtonsDisplay("");
          });
      } catch (e) {
        setEnterModalText("Could not retrieve result. Check your inventory.");
        console.log(e);
      } //end catch timer
    }, 4000);
    const timer2 = setTimeout(() => {
      if (apiWorked == "no") {
        setEnterModalText("Could not retrieve result. Check your wallet.");
        setEnterButtonsDisplay("");
        setLoadingDisplay("none");
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  } catch (e) {
    console.log('ERROR: ', e);
    setEnterModalText(e.message);
    setEnterModalDisplay("");
  }
}; //end unbox

export const createDrop = async (
  dropPrice,
  dropSymbol,
  dropPrecision,
  rewardContract,
  collection,
  schema,
  templateID,
  totalAvailable,
  limitPerUser,
  cooldownSeconds,
  whitelistType,
  usernames,
  farmName,
  minimumNftsStaked,
  startTimestamp,
  endTimestamp,
  dropDescription,
  logoHash,
  receivingAccount,
  revenueOption,
  poolName,
  percentToPool,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  let theMinimum;

  if (
    minimumNftsStaked == null ||
    minimumNftsStaked == "" ||
    minimumNftsStaked == undefined
  ) {
    theMinimum = "0";
  } else {
    theMinimum = minimumNftsStaked;
  }

  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

    const action = [
      {
        account: "waxdaomarket",
        name: "createdrop",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          price: dropPrice,
          token_symbol: dropPrecision + "," + dropSymbol,
          contract: rewardContract,
          collection: collection,
          schema: schema,
          template_id: templateID,
          total_available: totalAvailable,
          limit_per_user: limitPerUser,
          cooldown: cooldownSeconds,
          whitelist_type: whitelistType,
          allowed_users: usernames.split(","),
          farmname: farmName,
          minimum_to_stake: theMinimum,
          start_time: startTimestamp,
          end_time: endTimestamp,
          drop_description: dropDescription,
          drop_logo: logoHash,
          receiver: receivingAccount,
          pool_or_farm: revenueOption,
          pool_or_farm_name: poolName,
          percent_to_pool: percentToPool,
          pool_id: [],
          drop_type: "standard",
          pack_template: "0",
        },
      },
    ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText(
          "Your drop has been created. Make sure your collection has RAM (Inventory -> RAM -> Collection RAM)"
        );
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);

      if(e.message.indexOf("must add waxdaomarket as an authorized account") > -1){
        setEnterModalText(
          <span>
            waxdaomarket is not authorized on this collection.
            <br />
            <br />
            <GameButton
              onClick={() => {
                authorizeWaxDAO(
                  collection,
                  setEnterModalText,
                  setLoadingDisplay,
                  setEnterModalDisplay,
                  wharfSession
                );
              }}
            >
              Authorize Now
            </GameButton>
          </span>
        );        
      } else {
        setEnterModalText(e.message);
        setEnterModalDisplay("");
      }
    }    
};