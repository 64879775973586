import React from 'react';
import { ModalContent } from './LeftDiv';
import { SelectLpTokenUnderlyingInfo, SelectTokenHeaderCont, SelectTokenModalInnerWrapper, SelectTokenModalWrapper, SelectTokenRow, SelectTokenRowTokenAmount, SelectTokenRowTokenName } from '../Styles2024';
import { currentUsername } from '../data/config';

const SelectTokenCreatorTokenModal = (props) => {
    const showAttributeModal = props.showAttributeModal;
    const setShowAttributeModal = props.setShowAttributeModal;
    const tokens = props.tokens;
    const selectedToken = props.selectedToken;
    const setSelectedToken = props.setSelectedToken;
    const setSelectedPrecision = props.setSelectedPrecision;

    return (
        <SelectTokenModalWrapper className={showAttributeModal}>
            <SelectTokenHeaderCont>
                <div>Select A Token</div>
                <div style={{ paddingRight: "5px", fontWeight: "300" }}>
                    <button onClick={() => setShowAttributeModal(false)}>
                        X
                    </button>
                </div>
            </SelectTokenHeaderCont>
            <SelectTokenModalInnerWrapper>
                <ModalContent>
                    {
                        (tokens && tokens.length > 0) ? (
                            tokens
                                .filter(t => t.contract === currentUsername)
                                .map((t, index) => (
                                    <SelectTokenRow key={index}>
                                        <button
                                            onClick={() => {
                                                setSelectedToken(t.currency)
                                                setSelectedPrecision(t.decimals)
                                                setShowAttributeModal(false)
                                            }}
                                        >


                                            <SelectTokenRowTokenName>
                                                {t.currency}                                              
                                            </SelectTokenRowTokenName>

                                            <SelectTokenRowTokenAmount>
                                                {t.amount}
                                            </SelectTokenRowTokenAmount>
                                        </button>
                                    </SelectTokenRow>
                                ))
                        ) : (
                            tokens && tokens.length > 0 ? (
                                tokens.map((t, index) => (
                                    <SelectTokenRow key={index}>
                                        <button
                                            onClick={() => {
                                                setSelectedToken(t.currency)
                                                setSelectedPrecision(t.decimals)
                                                setShowAttributeModal(false)
                                            }}
                                        >
   

                                            <SelectTokenRowTokenName>
                                                {t.currency}                                             
                                            </SelectTokenRowTokenName>

                                            <SelectTokenRowTokenAmount>
                                                {t.amount}
                                            </SelectTokenRowTokenAmount>

                                        </button>
                                    </SelectTokenRow>
                                ))
                            ) : (
                                <span>No tokens available</span>
                            )
                        )
                    }
                </ModalContent>
            </SelectTokenModalInnerWrapper>
        </SelectTokenModalWrapper>
    )
}

export default SelectTokenCreatorTokenModal;
