import { useEffect } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentWebsiteURL } from "../../data/config";

import {
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";

export const showManageLocksSection = (currentToolsSection) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();

  useEffect(() => {
    //   if (currentToolsSection == "Locks") {
    //
    //   }
  }, [currentToolsSection]);

  return (
    <span className={currentToolsSection != "Locks" && "hidden"}>
      <TallFiltersCont>
        <h2>Manage Token Locks</h2>
        <br />
        <TallFiltersInnerCont>
          You can manage your tokens locks on the{" "}
          <a href={`${currentWebsiteURL}/manage-locks`}>Manage Locks Section</a> of WaxDAO.
        </TallFiltersInnerCont>
      </TallFiltersCont>
    </span>
  );
};