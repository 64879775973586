import { currentUsername, defaultSpinnerDuration, defaultTxSettings, getCurrentAtomicApiList } from "../config";
import { getWalletSession } from "./wallet_functions";
import axios from "axios";

let pageSize = 100;

export const handleRemoveAsset = (asset_id, assetVector, setAssetVector) => {
    const assetList = [...assetVector];
    assetList.splice(assetList.indexOf(asset_id), 1);
    setAssetVector(assetList);
}

export const handleAddAsset = (asset_id, assetVector, setAssetVector) => {
        setAssetVector([...assetVector, asset_id])   
}

export const handleRemovingFromRemoval = (asset_id, removalVector, setRemovalVector) => {
  const assetList = [...removalVector];
  assetList.splice(assetList.indexOf(asset_id), 1);
  setRemovalVector(assetList);
}

export const handleAddingToRemoval = (asset_id, removalVector, setRemovalVector) => {
      setRemovalVector([...removalVector, asset_id])  
}

export const addToPremintPool = async (PoolID, assetVector, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession
  
      const action = [{
            account: 'atomicassets',
            name: 'transfer',
            authorization: [session.permissionLevel],
          data: {
            to: 'waxdaomarket',
            from: session.actor,
            asset_ids: assetVector,
            memo: '|premint_deposit|' + PoolID + '|',
          }
        }]

        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText("Processing your transaction...");
          const timer = setTimeout(() => {
            setEnterModalText("Your assets have been added")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }        

  } //end addToPremintPool

export const removeFromPremintPool = async (PoolID, removalVector, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

      const action = [{
            account: 'waxdaomarket',
            name: 'removeassets',
            authorization: [session.permissionLevel],
          data: {
            pool_id: PoolID,
            user: session.actor,
            assets_to_remove: removalVector,
          }
        }]
        
        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText("Processing your transaction...");
          const timer = setTimeout(() => {
            setEnterModalText("Your assets have been added")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }            
  
  } //end removeFromPremintPool


export const getNextPage = (pageCount, setPageCount, stakedAssets, setStakedAssets, totalAssets, listOfAssets, setLoadMoreDisplay ) => {

    axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?ids=${listOfAssets.slice((pageCount * 100) - 100,pageCount * 100).join("%2C")}&page=${`1`}&limit=100&order=desc&sort=transferred`)
    .then((validResponse) => {
      let list = [...stakedAssets];

      if(Array.isArray(stakedAssets[0])){
        list = stakedAssets[0];
      }

      list = list.concat(validResponse.data.data);

      setStakedAssets(list);

      if(pageCount * pageSize < totalAssets){

        setPageCount(pageCount + 1);

      } else { setLoadMoreDisplay('hidden'); }
     
    })
    .catch((error) => console.log(error));
  
}

export const getNextUnstakedPage = (unstakedPageCount, setUnstakedPageCount, unstaked, setUnstaked, setUnstakedLoadMoreDisplay, newCollection) => {
  if(!currentUsername){return;}

  let thisPage = unstakedPageCount;

  if(newCollection == ""){
    axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?owner=${currentUsername}&page=${thisPage}&limit=100&order=desc&sort=transferred`)
    .then((atomicResponse) => {
      let list = [...unstaked];
      list = list.concat(atomicResponse.data.data);
      setUnstaked(list);
      
      if(atomicResponse.data.data.length >= 100){
        setUnstakedPageCount(thisPage + 1);
      } else { setUnstakedLoadMoreDisplay('hidden'); }
      
    })
    .catch((error) => console.log(error));
  }
  else{
    axios.get(`${currentAtomicApiList[Math.floor(Math.random() * (1 - 0) ) + 0]}/atomicassets/v1/assets?collection_name=${newCollection}&owner=${currentUsername}&page=${thisPage}&limit=100&order=desc&sort=transferred`)
    .then((atomicResponse) => {
  
      let list = [...unstaked];
      list = list.concat(atomicResponse.data.data);
      setUnstaked(list);

      if(atomicResponse.data.data.length >= 100){
        setUnstakedPageCount(thisPage + 1);
      } else { setUnstakedLoadMoreDisplay('hidden'); }
    })
    .catch((error) => console.log(error));
  }  
}

export const switchCollection = (unstakedPageCount, setUnstakedPageCount, unstaked, setUnstaked, setUnstakedLoadMoreDisplay, setNewCollection, newCollection) => {
  setUnstaked([]);

  if(!currentUsername){return;}

  let thisPage = 1;
  setNewCollection(newCollection);

    axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${newCollection}&owner=${currentUsername}&page=${thisPage}&limit=100&order=desc&sort=asset_id`)
    .then((atomicResponse) => {
  
      setUnstaked(atomicResponse.data.data);


      if(atomicResponse.data.data.length >= 100){

        setUnstakedPageCount(2);
        setUnstakedLoadMoreDisplay("");
      } else { setUnstakedLoadMoreDisplay('hidden'); }
      
    })
    .catch((error) => console.log(error));
}

export const createPremintPool = async (
  displayName,
  collection_name,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  if(localStorage.getItem("wharf--session") == null){
    setEnterModalText(
        "You are not logged in. Click the wallet icon in the top menu"
        );
        return;        
  }

  const session = wharfSession

    const action = [
      {
        account: "waxdaomarket",
        name: "createpool",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          collection: collection_name,
          display_name: displayName,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText("Your pool was created")   
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }
}; //end createPremintPool