import React, { useState, useEffect } from 'react'
import { DropCardImage, DropCardTopHalf, DropPageCardBottomHalf, DropPageCardCooldown, DropPageCardEndDate, DropPageCardLimitPerUser, DropPageCardMinimumMint, DropPageCardName, DropPageCardStartDate, DropPageCollection, DropPageNFTCardWrapper } from '../data/css/DropStyles'
import { DropPageCollectionWrapper } from '../data/css/PremiintStyles';
import { WalletCardMint } from '../data/css/WalletStyles';
import { currentAtomicHubLink, currentWebsiteURL } from '../data/config';
import { theme } from '../Styles';
import { buildIpfsUrl } from '../data/functions/global_functions';
import { STANDARD_NFT_IMAGE_WIDTH } from '../data/constants';

const DropPageNFTCard = (props) => {
    const templateData = props.templateData;
    const dropData = props.dropData;

    const [dropTimer, setDropTimer] = useState("");
    const [timerWasUpdated, setTimerWasUpdated] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
          if (dropData?.start_time != null) {
            let current_time = Date.now() / 1000;
            let start_time = dropData.start_time;
            if (current_time < dropData.start_time) {
              let total_minutes_left = Math.round(
                Number(start_time - current_time) / 60
              );
              let days_left = Math.floor(total_minutes_left / 1440);
              let hours_left =  Math.floor( (total_minutes_left - (days_left * 1440) ) / 60 );
              let minutes_left = total_minutes_left - (days_left * 1440) - (hours_left * 60);
              setDropTimer(days_left + "d " + hours_left + "h " + minutes_left + "m");
            } else {
              setDropTimer("");
            }
          }
          setTimerWasUpdated((prev) => !prev);
        }, 1000);
        return () => {
          clearTimeout(timer);
        };
      }, [dropTimer, timerWasUpdated]);


  return (
    <DropPageNFTCardWrapper>
        <DropCardTopHalf>
            {templateData?.immutable_data?.img && (
                <DropCardImage src={buildIpfsUrl(templateData.immutable_data.img, STANDARD_NFT_IMAGE_WIDTH)} />
            )}

            {!templateData?.immutable_data?.img && dropData?.drop_logo && (
                <DropCardImage src={buildIpfsUrl(dropData.drop_logo, STANDARD_NFT_IMAGE_WIDTH)} />
            )}            
          
        </DropCardTopHalf>

        {dropData.total_left != null && (
            <WalletCardMint>
            {dropData.total_left} left
            </WalletCardMint>
        )}

        {dropData.blends_remaining != null && (
            <WalletCardMint>
            {dropData.blends_remaining} left
            </WalletCardMint>
        )}        

      {dropData.collection != null && (
          <DropPageCollectionWrapper>
          <a href={`${currentWebsiteURL}/collection/${dropData.collection}`}>
        <DropPageCollection>
        {dropData.collection}
        </DropPageCollection>
        </a>
        </DropPageCollectionWrapper>
      )}

      {dropData != null && dropData == "oig" && (
          <DropPageCollectionWrapper>
          <a href={`${currentWebsiteURL}/collection/shiftybearsx`}>
        <DropPageCollection>
        shiftybearsx
        </DropPageCollection>
        </a>
        </DropPageCollectionWrapper>
      )}      

        {templateData.name != null && (
            <DropPageCardName>
                {templateData.name}
            </DropPageCardName>
        )}

        <DropPageCardBottomHalf />

        {dropData.start_time != null &&(
            <DropPageCardStartDate>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Starts</div>
                <div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.textMain}}>
                    {/* {new Date(dropData.start_time * 1000).toLocaleDateString()} */}
                    {dropTimer != "" ? dropTimer : "In progress"}
                    </div>
                </div>
            </DropPageCardStartDate>
        )}

        {dropData.end_time != null &&(
            <DropPageCardEndDate>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Ends</div><div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.textMain}}>{new Date(dropData.end_time * 1000).toLocaleDateString()}</div>
                </div>
            </DropPageCardEndDate>
        )}

        {dropData.cooldown != null && dropData.drop_type != "premint.pack" && (
            <DropPageCardCooldown>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Cooldown</div> <div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.textMain}}>{dropData.cooldown} Seconds</div>
                </div>
            </DropPageCardCooldown>
        )}

        {dropData.cooldown_reset != null && (
            <DropPageCardCooldown>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Cooldown</div> <div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.textMain}}>{dropData.cooldown_reset} Seconds</div>
                </div>
            </DropPageCardCooldown>
        )}        

            {dropData.template_id != null && dropData.drop_type == "premint.pack" && (
            <DropPageCardCooldown>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Pack Template</div> <div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.secondary}}><a href={`${currentAtomicHubLink}/explorer/template/${dropData.collection}/${dropData.template_id}`} target="none">{dropData.template_id}</a></div>
                </div>
            </DropPageCardCooldown>
        )}

        {dropData.limit_per_user != null && dropData.drop_type != "premint.pack" && (
            <DropPageCardLimitPerUser>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Limit Per User</div><div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.textMain}}>{dropData.limit_per_user}</div>
                </div>
            </DropPageCardLimitPerUser>
        )}

        {dropData != null && dropData == "oig" && ( 
            <span>
            <DropPageCardStartDate>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Starts</div>
                <div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.textMain}}>
                    {/* {new Date(dropData.start_time * 1000).toLocaleDateString()} */}
                    {"In progress"}
                    </div>
                </div>
            </DropPageCardStartDate> 
            <DropPageCardCooldown>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Pack Template</div> <div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.secondary}}><a href={`${currentAtomicHubLink}/explorer/template/shiftybearsx/650240`} target="none">650240</a></div>
                </div>
            </DropPageCardCooldown>
            <DropPageCardEndDate>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Ends</div><div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.textMain}}>Jan 23</div>
                </div>
            </DropPageCardEndDate>            
            <DropPageCardLimitPerUser>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Limit Per User</div><div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.textMain}}>1</div>
                </div>
            </DropPageCardLimitPerUser>
            </span>
        )}        

        {templateData.issued_supply != null && dropData.drop_type != "premint.pack" && (
            <DropPageCardMinimumMint>
                <div className='flex justify-between'>
                <div className='pl-6 inline-block text-left' style={{width: "50%"}}>Minimum Mint</div><div className='pl-4 inline-block text-left' style={{width: "50%", color: theme.textMain}}>{Number(templateData.issued_supply) + 1}/{templateData.max_supply == 0 ? <span>&#8734;</span> : templateData.max_supply}</div>
                </div>
            </DropPageCardMinimumMint>
        )}



    </DropPageNFTCardWrapper>
  )
}

export default DropPageNFTCard