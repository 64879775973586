import { ModalErrorCont, ModalSuccessCont } from "../../Styles2024";
import { currentWebsiteURL, defaultSpinnerDuration, defaultTxSettings } from "../../data/config";
import { PROCESSING_TX_TEXT, error_svg, success_svg } from "../../data/constants";
import { getWalletSession } from "../../data/functions/wallet_functions";


export const createPool = async (paymentMethod, waxdaoAmount, farmName, farmLogo, tokenToStake, decimals, tokenContract, rewardToken, rewardDecimals, rewardContract, stakingSeconds, timestamp, stakingAmount, assetToBurn, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");
  
    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession
    
    let action;

    if(paymentMethod == "Pay 250 Wax"){
      
      action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [session.permissionLevel],
          data: {
              to: 'waxdaofarmer',
              from: session.actor,
              quantity: '250.00000000 WAX',
              memo: '|createpool|' + farmName + '|' + rewardContract + '|' + farmLogo + '|' + timestamp + '|' + stakingAmount + '|' + stakingSeconds + '|' + tokenToStake + '|'  + decimals + '|' + tokenContract + '|' + rewardToken + '|' + rewardDecimals + '|',
          }
        }]
  
    } //end if paymentMethod == WAX
  
  
    else if(paymentMethod == "Pay WAXDAO"){
      action = [{
              account: 'token.waxdao',
              name: 'transfer',
              authorization: [session.permissionLevel],
            data: {
                to: 'waxdaofarmer',
                from: session.actor,
                quantity: `${waxdaoAmount.toFixed(8)} WAXDAO`,
                memo: '|createpool|' + farmName + '|' + rewardContract + '|' + farmLogo + '|' + timestamp + '|' + stakingAmount + '|' + stakingSeconds + '|' + tokenToStake + '|'  + decimals + '|' + tokenContract + '|' + rewardToken + '|' + rewardDecimals + '|',
            }
          }]
            
      } //end if paymentMethod == WOJAK
  
      else if(paymentMethod == "Burn 1 Wojak NFT"){
  
        action = [{
            account: 'atomicassets',
            name: 'transfer',
            authorization: [session.permissionLevel],
            data: {
            asset_ids: [assetToBurn],
            from: session.actor,
            to: 'waxdaofarmer',
            memo: '|createpool|' + farmName + '|' + rewardContract + '|' + farmLogo + '|' + timestamp + '|' + stakingAmount + '|' + stakingSeconds + '|' + tokenToStake + '|'  + decimals + '|' + tokenContract + '|' + rewardToken + '|' + rewardDecimals + '|',
              }
            }]
            
        } //end if paymentMethod == burn NFT

        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText(<span>
            <ModalSuccessCont>
            {success_svg}
            </ModalSuccessCont>                 
              Your pool has been created. You can deposit rewards on the{" "}
              <a href={`${currentWebsiteURL}/edit-pool/${farmName}`}>pool management page</a>
            </span>)
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(<span>
            <ModalErrorCont>
            {error_svg}
            </ModalErrorCont>
            {e.message}        
          </span>);
          setEnterModalDisplay("");
        }
  }


export const getTokenHelp = (setEnterModalDisplay, setEnterModalText) => {
    setEnterModalDisplay(""); 
    
    setEnterModalText(<span>
        For WAX, the info is: <br/><br/>
        Contract: eosio.token<br/>
        Precision: 8<br/>
        For other tokens, search our <a href={`${currentWebsiteURL}/tokens`} target="none" className="text-secondary">Token Tracker</a> for this info.
      </span>);

}

export const poolIsExpired = (expires) => {
  let isExpired = false;

  if(expires < Date.now() / 1000){
    isExpired = true;
  }
  return isExpired
}