import { useState } from "react";
import { currentApiList, v1MarketContract, v2DAOContract } from "../../data/config";
import axios from 'axios';

export const useGetUserProfile = () => {
    
    const initialProfileState = {
        username: '',
        socials: {
          website: '',
          telegram: '',
          discord: '',
          twitter: '',
          medium: '',
          youtube: '',
          atomichub: '',
          waxdao: '',
        },
        profile: {
          avatar: '',
          cover_image: '',
          description: '',
        },
        asset_ids: []
      }
      
      const [userProfile, setUserProfile] = useState(initialProfileState);
      
    const [userProfileIsLoading, setUserProfileIsLoading] = useState(true);

    const getUserProfile = async (user) => {
        setUserProfile(prevState => ({
            ...prevState,
            username: user
        }));        
        setUserProfileIsLoading(true);
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "profiles2",
                    scope: v1MarketContract,
                    code: v1MarketContract,
                    limit: 1,
                    lower_bound: user,
                    upper_bound: user,
                    json: true,
                });
                
                if (res.data.rows) {
                    if(res.data.rows[0] != null){
                        setUserProfile(prevState => ({
                            ...prevState,
                            ...res.data.rows[0]
                        }));
                    }
                    
                    console.log(res.data.rows[0]);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }
        setUserProfileIsLoading(false);
    }

    return [userProfile, getUserProfile, userProfileIsLoading, setUserProfile]
}
