import {
  currentApiList,
  currentUsername,
  getCurrentApiList,
  getCurrentAtomicApiList,
  mainnetLightApiList,
  defaultTxSettings,
  defaultSpinnerDuration,
} from ".././config";
import axios from "axios";
import { PROCESSING_TX_TEXT, error_svg, success_svg } from "../constants";
import { ModalErrorCont, ModalSuccessCont } from "../../Styles2024";

// PREMINTED POOLS

/*
export const createPremintPool = async (
  farmContract,
  collection_name,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  setShowSearchCollections
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");
  setShowSearchCollections(false);

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  try {
    const action = [
      {
        account: "waxdaomarket",
        name: "createpool",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          collection: collection_name,
          display_name: farmContract,
        },
      },
    ];
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 300,
        broadcast: true,
      }
    );
    setLoadingDisplay("");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your pool has been created");
      setLoadingDisplay("none");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    console.log(e);
  }
}; //end createPremintPool
*/

export const claimAll = async (
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession 

    const action = [
      {
        account: "waxdaofarmer",
        name: "claimall",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your rewards have been claimed")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }        

}; //end claimAll

export const claimAllPools = async (
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession  

    const action = [
      {
        account: "waxdaofarmer",
        name: "claimallpool",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your rewards have been claimed")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }      

}; //end claimAllPools

// BLENDS

export const cancelBlend = async (
  dropID,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

    const action = [
      {
        account: "waxdaomarket",
        name: "removeblend",
        authorization: [session.permissionLevel],
        data: {
          ID: dropID,
          user: session.actor,
        },
      },
    ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText("Your blend has been cancelled")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }

}; //end cancelBlend

export const editBlend = async (
  blendID,
  title,
  description,
  coverImage,
  startTime,
  endTime,
  limitPerUser,
  cooldown,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  if(localStorage.getItem("wharf--session") == null){
      setEnterModalText(
          "You are not logged in. Click the wallet icon in the top menu"
          );
          return;        
  }

  const session = wharfSession

    const action = [
      {
        account: "waxdaomarket",
        name: "editblend",
        authorization: [session.permissionLevel],
        data: {
          blend_ID: blendID,
          blend_title: title,
          blend_description: description,
          cover_image: coverImage,
          start_time: startTime,
          end_time: endTime,
          limit_per_user: limitPerUser,
          cooldown_reset: cooldown,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText("Your blend has been edited")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }

}; //end editBlend

export const whiteListMe = async (
  product,
  asset_id,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  setWhitelistMeDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");
  setWhitelistMeDisplay("none");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession 

  let send_nft_to = "";
  let memo_to_use = "";

  if (product == "super blender") {
    send_nft_to = "waxdaomarket";
    memo_to_use = "|blend_whitelist|";
  }

  if (product == "token creator") {
    send_nft_to = "waxywojaknft";
    memo_to_use = "createatoken";
  }

    const action = [
      {
        account: "atomicassets",
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          to: send_nft_to,
          from: session.actor,
          asset_ids: [asset_id],
          memo: memo_to_use,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("You are now whitelisted for the " + product)
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    } 

}; //end whitelistMe

export const blendDeposit = async (
  depositAmount,
  item,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

  let amount_to_send = "";
  let this_tokens_precision = "";

  if (item.decimals == null) {
    amount_to_send = depositAmount + " " + item.currency;
    this_tokens_precision = "0";
  } else {
    amount_to_send =
      Number(depositAmount).toFixed(item.decimals) + " " + item.currency;
    this_tokens_precision = item.decimals;
  }

    const action = [
      {
        account: "waxdaomarket",
        name: "asserttoken",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          contract: item.contract,
          token_symbol: this_tokens_precision + "," + item.currency,
        },
      },
      {
        account: item.contract,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          to: "waxdaomarket",
          from: session.actor,
          quantity: amount_to_send,
          memo: "|token_deposit|",
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your tokens have been deposited")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }       
}; //end blendDeposit

export const blendDepositv2 = async (
  depositAmount,
  item,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  var amount_to_send = "";
  var this_tokens_precision = "";

  if (item.decimals == null) {
    amount_to_send = depositAmount + " " + item.currency;
    this_tokens_precision = "0";
  } else {
    amount_to_send =
      Number(depositAmount).toFixed(item.decimals) + " " + item.currency;
    this_tokens_precision = item.decimals;
  }

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: "waxdaomrktv2",
        name: "asserttoken",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          contract: item.contract,
          token_symbol: this_tokens_precision + "," + item.currency,
        },
      },
      {
        account: item.contract,
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          to: "waxdaomrktv2",
          from: this_user,
          quantity: amount_to_send,
          memo: "|token_deposit|",
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 300,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    //setEnterButtonsDisplay('hidden');
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your tokens have been deposited.");
      setLoadingDisplay("none");
      //setEnterButtonsDisplay('');
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
}; //end blendDepositv2

export const blendDepositTwo = async (
  depositAmount,
  item,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  var amount_to_send = "";
  var this_tokens_precision = "";
  var this_tokens_symbol = item.balance.substring(
    item.balance.indexOf(" ") + 1
  );

  if (item.balance.substring(0, item.balance.indexOf(" ")).indexOf(".") == -1) {
    amount_to_send =
      Math.floor(Number(depositAmount)) + " " + this_tokens_symbol;
    this_tokens_precision = "0";
  } else {
    amount_to_send =
      Number(depositAmount).toFixed(
        Number(
          item.balance.substring(
            item.balance.indexOf(".") + 1,
            item.balance.indexOf(" ")
          ).length
        )
      ) +
      " " +
      this_tokens_symbol;
    this_tokens_precision = item.balance.substring(
      item.balance.indexOf(".") + 1,
      item.balance.indexOf(" ")
    ).length;
  }

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: "waxdaomarket",
        name: "asserttoken",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          contract: item.contract,
          token_symbol: this_tokens_precision + "," + this_tokens_symbol,
        },
      },
      {
        account: item.contract,
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          to: "waxdaomarket",
          from: this_user,
          quantity: amount_to_send,
          memo: "|token_deposit|",
        },
      },
    ];

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 300,
        broadcast: true,
      }
    );
    setLoadingDisplay("");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your tokens have been deposited.");
      setLoadingDisplay("none");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
}; //end blendDepositTwo

export const depositBlendRam = async (
  depositAmount,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

    let pre_amount_to_send = Number(depositAmount).toFixed(8);
    let amount_to_send = pre_amount_to_send + " WAX";

    const action = [
      {
        account: "eosio.token",
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          to: "waxdaomarket",
          from: session.actor,
          quantity: amount_to_send,
          memo: "|deposit_creator_ram|" + session.actor + "|",
        },
      },
    ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your RAM deposit was successful")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }         
    
}; //end depositBlendRam

export const withdrawBlendRam = async (
  depositAmount,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession   

  let pre_amount_to_send = Number(depositAmount).toFixed(8);
  let amount_to_send = pre_amount_to_send + " WAX";

    const action = [
      {
        account: "waxdaomarket",
        name: "wthdrawblram",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          amount_to_withdraw: amount_to_send,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your withdrawal was successful")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }        
}; //end withdrawBlendRam

export const withdrawBlendToken = async (
  depositAmount,
  item,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession 

  let amount_to_send = "";
  let this_tokens_precision = "";
  let this_tokens_symbol = item.balance.substring(
    item.balance.indexOf(" ") + 1
  );

  if (item.balance.substring(0, item.balance.indexOf(" ")).indexOf(".") == -1) {
    amount_to_send =
      Math.floor(Number(depositAmount)) + " " + this_tokens_symbol;
    this_tokens_precision = "0";
  } else {
    amount_to_send =
      Number(depositAmount).toFixed(
        Number(
          item.balance.substring(
            item.balance.indexOf(".") + 1,
            item.balance.indexOf(" ")
          ).length
        )
      ) +
      " " +
      this_tokens_symbol;
    this_tokens_precision = item.balance.substring(
      item.balance.indexOf(".") + 1,
      item.balance.indexOf(" ")
    ).length;
  }

    const action = [
      {
        account: "waxdaomarket",
        name: "wthdrawtoken",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          amount_to_withdraw: amount_to_send,
          contract: item.contract,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your tokens withdrawal was successful")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }      
}; //end withdrawBlendToken

//   COLLECTIONS

export const depositCollectionRam = async (
  depositAmount,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  collection,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

  let pre_amount_to_send = Number(depositAmount).toFixed(8);
  let amount_to_send = pre_amount_to_send + " WAX";

    const action = [
      {
        account: "eosio.token",
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          to: "waxdaomarket",
          from: session.actor,
          quantity: amount_to_send,
          memo: "|deposit_collection_ram|" + collection + "|",
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your RAM deposit was successful")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }        
}; //end depositCollectionRam

export const withdrawCollectionRam = async (
  depositAmount,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  collection,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

  let pre_amount_to_send = Number(depositAmount).toFixed(8);
  let amount_to_send = pre_amount_to_send + " WAX";

    const action = [
      {
        account: "waxdaomarket",
        name: "withdrawram",
        authorization: [session.permissionLevel],
        data: {
          collection: collection,
          user: session.actor,
          amount_to_withdraw: amount_to_send,
        },
      },
    ];

        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText("Your RAM withdrawal was successful")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }          
}; //end withdrawCollectionRam

export const authorizeWaxDAO = async (
  collection,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

      if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

    const action = [
      {
        account: "atomicassets",
        name: "addcolauth",
        authorization: [session.permissionLevel],
        data: {
          collection_name: collection,
          account_to_add: "waxdaomarket",
        },
      },
    ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText("You have authorized waxdaomarket, you can now create drops")   
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }    
    

}; //end authorizeWaxDAO

export const removeWaxDAOCollectionAuth = async (
  collection,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession 

    const action = [
      {
        account: "atomicassets",
        name: "remcolauth",
        authorization: [session.permissionLevel],
        data: {
          collection_name: collection,
          account_to_remove: "waxdaomarket",
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Authorization has been removed for waxdaomarket")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }            

}; //end removeWaxDAOCollectionAuth

export const notifyWaxDAO = async (
  collection,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession  

    const action = [
      {
        account: "atomicassets",
        name: "addnotifyacc",
        authorization: [session.permissionLevel],
        data: {
          collection_name: collection,
          account_to_add: "waxdaomarket",
        },
      },
    ];
    

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText("waxdaomarket will now receive notifications for " + collection);
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }

      
}; //end notifyWaxDAO

export const addCollectionNotify = async (
  account_to_add,
  collection,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession  

    const action = [
      {
        account: "atomicassets",
        name: "addnotifyacc",
        authorization: [session.permissionLevel],
        data: {
          collection_name: collection,
          account_to_add: account_to_add,
        },
      },
    ];
    

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText(account_to_add + " will now receive notifications for " + collection);
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }

      
}; 

export const removeCollectionNotify = async (
  account_to_remove,
  collection,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession   

    const action = [
      {
        account: "atomicassets",
        name: "remnotifyacc",
        authorization: [session.permissionLevel],
        data: {
          collection_name: collection,
          account_to_remove: account_to_remove,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Notifications have been removed for " + account_to_remove)
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }        

};

//TOKENS

export const transferTokens = async (
  receiver,
  depositAmount,
  item,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  var amount_to_send = "";

  if (item.decimals == null) {
    amount_to_send = depositAmount + " " + item.currency;
  } else {
    amount_to_send =
      Number(depositAmount).toFixed(item.decimals) + " " + item.currency;
  }

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: item.contract,
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          to: receiver,
          from: this_user,
          quantity: amount_to_send,
          memo: "Sent using WaxDAO wallet",
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 300,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    //setEnterButtonsDisplay('hidden');
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your tokens have been sent");
      setLoadingDisplay("none");
      //setEnterButtonsDisplay('');
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
}; //end transferTokens

export const stakeTokens = async (
  depositAmount,
  item,
  poolItem,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  var amount_to_send = "";

  if (item.decimals == null) {
    amount_to_send = depositAmount + " " + item.currency;
  } else {
    amount_to_send =
      Number(depositAmount).toFixed(item.decimals) + " " + item.currency;
  }

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: "waxdaofarmer",
        name: "staketokens",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          poolname: poolItem.poolname,
        },
      },
      {
        account: item.contract,
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          to: "waxdaofarmer",
          from: this_user,
          quantity: amount_to_send,
          memo: "|stake_tokens|" + poolItem.poolname + "|",
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 300,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    //setEnterButtonsDisplay('hidden');
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your tokens have been staked");
      setLoadingDisplay("none");
      //setEnterButtonsDisplay('');
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
}; //end stakeTokens

export const stakeTokensNew = async (
  depositAmount,
  item,
  poolname,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

  let amount_to_send = "";

  if (item.decimals == null) {
    amount_to_send = depositAmount + " " + item.currency;
  } else {
    amount_to_send =
      Number(depositAmount).toFixed(item.decimals) + " " + item.currency;
  }

    const action = [
      {
        account: "waxdaofarmer",
        name: "staketokens",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          poolname: poolname
        },
      },
      {
        account: item.contract,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          to: "waxdaofarmer",
          from: session.actor,
          quantity: amount_to_send,
          memo: "|stake_tokens|" + poolname + "|",
        },
      },
    ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your tokens have been staked")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }           
}; //end stakeTokens


export const unstakeTokens = async (
  depositAmount,
  item,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession 

  let amount_to_send = "";
  let this_tokens_precision = "";
  let this_tokens_symbol = item.amountstaked.substring(
    item.amountstaked.indexOf(" ") + 1
  );

  if (
    item.amountstaked
      .substring(0, item.amountstaked.indexOf(" "))
      .indexOf(".") == -1
  ) {
    amount_to_send = Math.floor(Number(depositAmount));
    this_tokens_precision = "0";
  } else {
    amount_to_send = Number(depositAmount).toFixed(
      Number(
        item.amountstaked.substring(
          item.amountstaked.indexOf(".") + 1,
          item.amountstaked.indexOf(" ")
        ).length
      )
    );
    this_tokens_precision = item.amountstaked.substring(
      item.amountstaked.indexOf(".") + 1,
      item.amountstaked.indexOf(" ")
    ).length;
  }

    const action = [
      {
        account: "waxdaofarmer",
        name: "unstaketoken",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          amount: amount_to_send + " " + this_tokens_symbol,
          poolname: item.poolname,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your tokens have been unstaked")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }           
}; //end unstakeTokens

//POOLS

export const claimRewards = async (
  PoolName,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

    const action = [
      {
        account: "waxdaofarmer",
        name: "claimpoolrwd",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          pool: PoolName,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your rewards have been claimed")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }      
}; //end claimrewards

//FARMS

export const claimFarmRewards = async (
  FarmName,
  rewardSymbol,
  rewardContract,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

    const action = [
      {
        account: rewardContract,
        name: 'open',
        authorization: [session.permissionLevel],
      data: {
        owner: session.actor,
        symbol: rewardSymbol,
        ram_payer: session.actor,
      }
  },{
        account: "waxdaofarmer",
        name: "claimrewards",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          farm: FarmName,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your rewards have been claimed")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }        
}; //end claimFarmRewards

export const claimFarmRam = async (
  FarmName,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  try {
    const action = [
      {
        account: "waxdaofarmer",
        name: "claimfarmram",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          farm: FarmName,
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 300,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your Wax has been returned to your wallet");
      setLoadingDisplay("none");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
}; // end claimFarmRam

//MANAGE FARMS

//farmName, amountToAdd, contract, rewardtoken
export const depositFarmRewards = async (
  item,
  depositAmount,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  var amount_to_send = "";

  //make api call to get precision

  await axios
    .post(
      `${
        currentApiList[Math.floor(Math.random() * (2 - 0)) + 0]
      }/v1/chain/get_table_rows`,
      {
        table: "stat",
        scope: item.rewardtoken,
        code: item.contract,
        limit: 1,
        json: true,
      }
    )
    .then((validResponse) => {
      console.log(validResponse);

      if (
        validResponse.data.rows[0] != null &&
        validResponse.data.rows[0] != ""
      ) {
        const the_row_we_found = validResponse.data.rows[0];
        if (
          the_row_we_found.supply
            .substring(0, the_row_we_found.supply.indexOf(" "))
            .indexOf(".") == -1
        ) {
          amount_to_send =
            Math.floor(Number(depositAmount)) + " " + item.rewardtoken;
        } else {
          amount_to_send =
            Number(depositAmount).toFixed(
              Number(
                the_row_we_found.supply.substring(
                  the_row_we_found.supply.indexOf(".") + 1,
                  the_row_we_found.supply.indexOf(" ")
                ).length
              )
            ) +
            " " +
            item.rewardtoken;
        }
      } //end validresponse
    }) //end .then
    .catch((error) => {
      console.log(error);
      setEnterModalText(
        "Could not locate this token. Possible API error, try again."
      );
      return;
    });

  if (amount_to_send == "") {
    setEnterModalText(
      "Could not locate this token. Possible API error, try again."
    );
    return;
  }

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: item.contract,
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          to: "waxdaofarmer",
          from: this_user,
          quantity: amount_to_send,
          memo: "|farm_deposit|" + item.farmname + "|",
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 300,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your deposit was successful");
      setLoadingDisplay("none");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
}; // end depositFarmRewards

//MANAGE POOLS

export const depositPoolRewards = async (
  item,
  depositAmount,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  var amount_to_send = "";
  var this_tokens_precision = Number(
    item.rwdtoken.substring(0, item.rwdtoken.indexOf(","))
  );
  var this_tokens_symbol = item.rwdtoken.substring(
    item.rwdtoken.indexOf(",") + 1
  );

  if (this_tokens_precision == 0) {
    amount_to_send =
      Math.floor(Number(depositAmount)) + " " + this_tokens_symbol;
  } else {
    amount_to_send =
      Number(depositAmount).toFixed(this_tokens_precision) +
      " " +
      this_tokens_symbol;
  }

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: item.contract,
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          to: "waxdaofarmer",
          from: this_user,
          quantity: amount_to_send,
          memo: "|pool_deposit|" + item.poolname + "|",
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 300,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your deposit was successful");
      setLoadingDisplay("none");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
}; // end depositPoolRewards

//DROPS

export const editDropDetails = async (
  dropID,
  start_time,
  end_time,
  description,
  price,
  precision,
  tokenName,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

    const action = [
      {
        account: "waxdaomarket",
        name: "editdrop",
        authorization: [session.permissionLevel],
        data: {
          ID: dropID,
          user: session.actor,
          start_time: start_time,
          end_time: end_time,
          drop_description: description,
          price: Number(price).toFixed(Number(precision)) + " " + tokenName,
        },
      },
    ];
 
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText("Your drop has been edited")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }

}; //end editDropDetails

export const cancelDrop = async (
  dropID,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

    const action = [
      {
        account: "waxdaomarket",
        name: "removedrop",
        authorization: [session.permissionLevel],
        data: {
          ID: dropID,
          user: session.actor,
        },
      },
    ];
  
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your drop has been cancelled")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }

}; //end cancelDrop

export const cancelPack = async (
  dropID,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession  

    const action = [
      {
        account: "waxdaomarket",
        name: "cancelpack",
        authorization: [session.permissionLevel],
        data: {
          pack_id: dropID,
          user: session.actor,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText("Your pack has been cancelled. You can modify your preminted pool(s) again")   
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }

}; //end cancelDrop

//ADVERTISE

export const buyAdSpace = async (
  token,
  ipfs,
  URL,
  time,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  if(localStorage.getItem("wharf--session") == null){
      setEnterModalText(
          "You are not logged in. Click the wallet icon in the top menu"
          );
          return;        
  }

  const session = wharfSession   

  let contract;
  let price;

  if (token == "wax") {
    contract = "eosio.token";
    price = "50.00000000 WAX";
  } else {
    contract = "token.waxdao";
    price = "5000.00000000 WAXDAO";
  }

    const action = [
      {
        account: contract,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          to: "waxdaofarmer",
          from: session.actor,
          quantity: price,
          memo: "|buy_ad_space|" + ipfs + "|" + URL + "|" + time + "|",
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText(<span>
          <ModalSuccessCont>
          {success_svg}
          </ModalSuccessCont>
          {'Your ad slot has been booked!'}
        </span>)
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(<span>
        <ModalErrorCont>
        {error_svg}
        </ModalErrorCont>
        {e.message}        
      </span>);
      setEnterModalDisplay("");
      setLoadingDisplay("none");
    }        
}; //end buyAdSpace

export const getNextUnstakedPage = (
  unstakedPageCount,
  setUnstakedPageCount,
  unstaked,
  setUnstaked,
  setUnstakedLoadMoreDisplay,
  newCollection,
  setNextPageIsLoading
) => {
  const waxUsername = currentUsername;

  if (!waxUsername) {
    return;
  }

  if (newCollection.length >= 1) {
    axios
      .get(
        `${
          getCurrentAtomicApiList
        }/atomicassets/v1/assets?collection_name=${newCollection}&owner=${waxUsername}&page=${unstakedPageCount}&limit=100&order=desc&sort=transferred`
      )
      .then((validResponse) => {
        var list = [...unstaked];

        if (Array.isArray(unstaked[0])) {
          list = unstaked[0];
        }

        list = list.concat(validResponse.data.data);
        setUnstaked(list);

        if (validResponse.data.data.length == 100) {
          setUnstakedPageCount(unstakedPageCount + 1);
        } else {
          setUnstakedLoadMoreDisplay("hidden");
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setNextPageIsLoading(false))
  } else {
    axios
      .get(
        `${
          getCurrentAtomicApiList
        }/atomicassets/v1/assets?owner=${waxUsername}&page=${unstakedPageCount}&limit=100&order=desc&sort=transferred`
      )
      .then((validResponse) => {
        var list = [...unstaked];

        if (Array.isArray(unstaked[0])) {
          list = unstaked[0];
        }

        list = list.concat(validResponse.data.data);
        setUnstaked(list);

        if (validResponse.data.data.length == 100) {
          setUnstakedPageCount(unstakedPageCount + 1);
        } else {
          setUnstakedLoadMoreDisplay("hidden");
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setNextPageIsLoading(false))
  } //end else
};

export const unstakeNFT = async (
  FarmName,
  asset_id,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: "waxdaofarmer",
        name: "unstake",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          asset_id: asset_id,
          farmname: FarmName,
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 180,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your NFT has been unstaked");
      setLoadingDisplay("none");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
}; //end unstakeNFT

export const openWalletTokenBalancesTab = async (setMyTokenBalances) => {
  let unfiltered = [];
  let response;

  for (let endpoint of mainnetLightApiList) {
    try {
      response = await axios.get(`${endpoint}/api/account/wax/${currentUsername}`);
      unfiltered = response.data.balances;
      console.log("light api balances:")
      console.log(unfiltered);
      setMyTokenBalances(unfiltered);
      break;
    } catch (error) {
      console.log(`Error occurred with endpoint ${endpoint}:`);
      console.log(error.response ? error.response.data : error);
    }
  }

  if (!response) {
    console.log("All requests to endpoints failed.");
  }
};


export const openStakeableTokensSection = (
  setThisTokenIsStakeableInThesePools,
  symbol,
  contract,
  setNoPoolsFoundMessage
) => {
  setThisTokenIsStakeableInThesePools([]);
  setNoPoolsFoundMessage("");
  var foundList = [];

  axios
    .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
      table: "pools",
      scope: "waxdaofarmer",
      code: "waxdaofarmer",
      limit: 1000,
      json: true,
    })
    .then((farmVectors) => {
      if (farmVectors.data.rows.length == 0) {
        setNoPoolsFoundMessage(
          "This token is not stakeable in any WaxDAO pools"
        );
        return;
      } else {
        var matchesFound = 0;
        var startingIndex = 0;

        while (startingIndex < farmVectors.data.rows.length) {
          if (
            farmVectors.data.rows[startingIndex].stkcontract == contract &&
            farmVectors.data.rows[startingIndex].tokentostake.substring(
              farmVectors.data.rows[startingIndex].tokentostake.indexOf(",") + 1
            ) == symbol
          ) {
            foundList = [...foundList, farmVectors.data.rows[startingIndex]];
            matchesFound++;
          }
          startingIndex++;
        } //end while
      } //end else

      if (matchesFound > 0) {
        setThisTokenIsStakeableInThesePools(foundList);
        setNoPoolsFoundMessage(
          "This token is stakeable in the following pools:"
        );
      } else {
        setNoPoolsFoundMessage(
          "This token is not stakeable in any WaxDAO pools"
        );
      }
    })
    .catch((error) => console.log(error));
};

export const isStakeable = (farmList, nft) => {
  let isStakeable = false;
  let possiblyStakeable = false;
  let stakeableFarms = [];
  let possibleFarms = [];

  if (farmList?.length > 0) {
    let farmCount = 0;
    while (farmCount < farmList.length) {
      //v1 farmtype = collection
      if (
        farmList[farmCount].farmtype != null &&
        farmList[farmCount].farmtype == "collection" &&
        nft.collection?.collection_name == farmList[farmCount].collection
      ) {
        stakeableFarms.push(farmList[farmCount].farmname);
        isStakeable = true;
      } else if (
        farmList[farmCount].farmtype != null &&
        farmList[farmCount].farmtype == "schema" &&
        nft.schema?.schema_name == farmList[farmCount].schema &&
        nft.collection?.collection_name == farmList[farmCount].collection
      ) {
        stakeableFarms.push(farmList[farmCount].farmname);
        isStakeable = true;
      } else if (
        farmList[farmCount].farmtype != null &&
        farmList[farmCount].farmtype == "template" &&
        nft.collection?.collection_name == farmList[farmCount].collection && 
        farmList[farmCount].templates.indexOf(Number(nft.template?.template_id)) > -1
      ){
        stakeableFarms.push(farmList[farmCount].farmname);
        isStakeable = true;
      }

      farmCount++;
    }
    return [isStakeable, stakeableFarms, possiblyStakeable, possibleFarms];
  } else return [isStakeable, stakeableFarms, possiblyStakeable, possibleFarms];
};

export const transferTokensNew = async (
  receiver,
  depositAmount,
  item,
  memo,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

  let amount_to_send = "";

  if (item.decimals == null) {
    amount_to_send = depositAmount + " " + item.currency;
  } else {
    amount_to_send =
      Number(depositAmount).toFixed(item.decimals) + " " + item.currency;
  }

    const action = [
      {
        account: item.contract,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          to: receiver,
          from: session.actor,
          quantity: amount_to_send,
          memo: memo,
        },
      },
    ];
    
    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        if(memo == "token migration" && receiver == "token.waxdao" && item.contract == "mdcryptonfts"){
          setEnterModalText(<span>
            <ModalSuccessCont>
            {success_svg}
            </ModalSuccessCont>
            Your migration is complete
          </span>)
        }
        else if(receiver == "swap.alcor"){
          setEnterModalText(<span>
            <ModalSuccessCont>
            {success_svg}
            </ModalSuccessCont>
            Your swap is complete
          </span>)
        }
        else if(receiver == "waxywojaknft" && memo == "royalty"){
          setEnterModalText(<span>
            <ModalSuccessCont>
            {success_svg}
            </ModalSuccessCont>
            You are now whitelisted!
          </span>)
        }        
        else{
          setEnterModalText(<span>
            <ModalSuccessCont>
            {success_svg}
            </ModalSuccessCont>
            Your tokens have been sent
          </span>)
        }
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(<span>
        <ModalErrorCont>
        {error_svg}
        </ModalErrorCont>
        {e.message}        
      </span>
        );
      setEnterModalDisplay("");
    }   

};

export const getPoolsToStakeIn = (pools, token) => {
  let poolsToStakeIn = []
  let i = 0

  while(i < pools.length){
    if(pools[i].tokentostake.substring(pools[i].tokentostake.indexOf(",") + 1) == token.symbol && pools[i].stkcontract == token.contract){
      poolsToStakeIn.push(pools[i])
    }
    i++
  }
  return poolsToStakeIn
}


export const openUnstakeTokensSection = (
  setStakedTokens,
  setStakedTokensAreLoading
) => {

  axios
    .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
      table: "tokens",
      scope: "waxdaofarmer",
      code: "waxdaofarmer",
      key_type: "name",
      index_position: 2,
      limit: 100,
      lower_bound: currentUsername,
      upper_bound: currentUsername,
      json: true,
    })
    .then((res) => {
      setStakedTokens(res.data.rows);
    })
    .catch((error) => console.log(error))
    .finally(() => setStakedTokensAreLoading(false))
};


export const getAssetSymbol = (asset) => {
  let symbol = asset.substring(asset.indexOf(" ") + 1)

  return symbol;
}

export const getAssetQuantity = (asset) => {
  let quantity = asset.substring(0, asset.indexOf(" "))

  return quantity;
}

export const isValidForWhitelisting = (nft) => {

  if(nft?.collection?.collection_name == "hoodpunknfts" && nft?.schema?.schema_name == "waxywojaks"){
    return true
  }

  return false
};

export const isAWojakPack = (nft) => {

  if(nft?.collection?.collection_name == "hoodpunknfts" && nft?.schema?.schema_name == "wojakpacks"){
    return true
  }

  return false
};


export const isLoggedIn = () => {
  if(currentUsername == null || currentUsername == "" || currentUsername.length < 2){
    return false
  }

  return true
}


export const transferNFTs = async (
  asset_ids,
  receiver,
  memo,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession   

    const action = [
      {
        account: "atomicassets",
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          to: receiver,
          from: session.actor,
          asset_ids: asset_ids,
          memo: memo,
        },
      },
    ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setEnterModalText("Your NFTs were transferred to " + receiver);
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }           

}; //end transferNFTs