import React from 'react'
import { NewProposal } from './LeftDiv'

const WaxLabs2 = () => {
  return (
    <NewProposal>
        <br/><br/><br/><br/><br/>
        <h2>Important resources from the coding tutorial series:</h2>
        <p className='italic'>* This page will be updated over time *</p>
        <br/>
        <h3>VIDEO SERIES ON YOUTUBE:</h3>
        <a href="https://youtu.be/h6fGnthbpeY" target="none" className="text-red">Part 1</a><br/>
        <a href="https://youtu.be/sHSjbS345r0" target="none" className="text-red">Part 2</a><br/>
        <a href="https://youtu.be/V_RDYqCNnmc" target="none" className="text-red">Part 3</a><br/>
        <a href="https://youtu.be/2_W2jhyHnmc" target="none" className="text-red">Part 4</a><br/>
        <a href="https://youtu.be/gzM6dDkcjJs" target="none" className="text-red">Part 5</a><br/>
        <a href="https://youtu.be/GMdFrkSdQ-A" target="none" className="text-red">Part 6</a><br/>
        <a href="https://youtu.be/fITOtOZ-HGw" target="none" className="text-red">Part 7</a><br/>
        <a href="https://youtu.be/8ljvEHQ4Pm8" target="none" className="text-red">Part 8</a><br/>
        <a href="https://youtu.be/eWcRyGJJ2Lc" target="none" className="text-red">Part 9</a><br/>
        
        <br/>
        <h3>VIDEO SERIES ON 3SPEAK:</h3>
        <a href="https://3speak.tv/watch?v=mikedcrypto/ywdnldmr&utm_source=studio" target="none" className="text-red">Part 1</a><br/>
        <a href="https://3speak.tv/watch?v=mikedcrypto/rnnbxvvk&utm_source=studio" target="none" className="text-red">Part 2</a><br/>
        <a href="https://3speak.tv/watch?v=mikedcrypto/lpzuzmnv&utm_source=studio" target="none" className="text-red">Part 3</a><br/>
        <a href="https://3speak.tv/watch?v=mikedcrypto/mfdohikj&utm_source=studio" target="none" className="text-red">Part 4</a><br/>
        <a href="https://3speak.tv/watch?v=mikedcrypto/rlfcimcmvz&utm_source=studio" target="none" className="text-red">Part 5</a><br/>
        <a href="https://3speak.tv/watch?v=mikedcrypto/fonldreswy&utm_source=studio" target="none" className="text-red">Part 6</a><br/>
        <a href="https://3speak.tv/watch?v=mikedcrypto/dmapknunio&utm_source=studio" target="none" className="text-red">Part 7</a><br/>
        <a href="https://3speak.tv/watch?v=mikedcrypto/ftxscnrywe&utm_source=studio" target="none" className="text-red">Part 8</a><br/>
        <a href="https://3speak.tv/watch?v=mikedcrypto/hhxggejzsz&utm_source=studio" target="none" className="text-red">Part 9</a><br/>

        <br/>
        <h3>VIDEO SERIES ON LBRY/ODYSEE:</h3>
        <a href="https://odysee.com/@MikeDCrypto:e" target="none" className="text-red">My Channel</a><br/>
        <br/><br/>

        <h3>Caleb Curry C++ Programming Course:</h3>
        https://www.youtube.com/watch?v=_bYFu9mBnr4
        <br/><br/>

        <h3>EOSIO/Antelope Developer Documentation:</h3>
        https://developers.eos.io/
        <br/><br/>

        <h3>WAX Discord:</h3>
        https://discord.gg/u6QNHx9T63
        <br/><br/>

        <h3>Antelope Developer Telegram:</h3>
        https://t.me/antelopedevs
        <br/><br/>

        <h3>WaxDAO Telegram:</h3>
        https://t.me/hoodpunks
        <br/><br/>
        
        <h3>Sample Smart Contract From Video:</h3>
        https://github.com/mdcryptonfts/tutorial_contract/blob/main/tutorial.cpp
        <br/><br/>

        <h3>React Admin Panel From Video:</h3>
        https://github.com/mdcryptonfts/Admin_Panel
        <br/><br/>

        <h3>Install Brew On Mac:</h3>
        https://brew.sh/
        <br/><br/>

        <h3>Wax Block Explorer:</h3>
        https://wax.bloks.io/
        <br/><br/>

        <h3>Get WAX Testnet Account:</h3>
        https://faucet.waxsweden.org/get_account?YOUR_ACCOUNT_NAME
        <br/><br/>

        <h3>Get WAX Testnet Tokens:</h3>
        https://faucet.waxsweden.org/get_token?YOUR_ACCOUNT_NAME
        <br/><br/>

        <h3>Anchor Wallet:</h3>
        https://greymass.com/en/anchor/
        <br/><br/>

        <h3>NodeJS Website:</h3>
        https://nodejs.org/en/
        <br/><br/>

        <h3>EosJS Documentation:</h3>
        https://developers.eos.io/manuals/eosjs/latest/index
        <br/><br/>


        <h2 className='text-pink'>WALLET COMMANDS:</h2>

        <h3>Create A New Wallet:</h3>
        cleos wallet create -n WALLET_NAME --file FILE_NAME.txt
        <br/><br/>

        <h3>Open Wallet:</h3>
        cleos wallet open -n WALLET_NAME
        <br/><br/>

        <h3>Get List Of Wallets:</h3>
        cleos wallet list
        <br/><br/>

        <h3>Unlock Wallet:</h3>
        cleos wallet unlock -n WALLET_NAME
        then enter password
        <br/><br/>

        <h3>Create New Private/Public Keypair In CLEOS</h3>
        cleos wallet create_key -n WALLET_NAME
        <br/><br/>

        <h3>Import Wallet To CLEOS:</h3>
        cleos wallet import -n WALLET_NAME
        then enter private key
        <br/><br/>


        <h2 className='text-pink'>CONTRACT COMMANDS:</h2>

        <h3>Compile Contract:</h3>
        eosio-cpp CONTRACT_NAME.cpp -o CONTRACT_NAME.wasm --abigen
        <br/><br/>

        <h3>Deploy Contract:</h3>
        cleos -u API_NODE_LINK set contract WAX_ACCOUNT_NAME PATH_TO_CONTRACT_FOLDER WASM_FILE.wasm --abi ABI_FILE.abi -p WAXACCOUNT_NAME@active
        <br/><br/>

        <h3>Deploy Contract Example:</h3>
        cleos -u https://testnet.waxsweden.org set contract codetutorial /Users/admin/tutorial_contracts codetutorial.wasm --abi codetutorial.abi -p codetutorial@active
        <br/><br/>

        <h3>Add Eosio.Code Permission To Contract Account:</h3>
        cleos -u API_NODE_LINK set account permission WAXACCOUNT_NAME active --add-code
        <br/><br/>



        <h2 className='text-pink'>OTHER COMMANDS:</h2>

        <h3>Check if NodeJS is installed:</h3>
        node --version
        <br/><br/>

        <h3>Initialize NodeJS Project:</h3>
        npm init -y
        <br/><br/>

        <h3>Install EosJS:</h3>
        npm install eosjs axios node-fetch util
        <br/><br/>

        <h3>Downgrade Node-Fetch:</h3>
        npm i node-fetch@2.6.1
        <br/><br/><br/><br/>





    </NewProposal>
  )
}

export default WaxLabs2