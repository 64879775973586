import { currentWebsiteURL, defaultSpinnerDuration, defaultTxSettings } from "../config";
import { PROCESSING_TX_TEXT } from "../constants";
import { getWalletSession } from "./wallet_functions";

export const createLock = async (receivingAccount, token_contract, amount, precision, token_name, timestamp, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

    let amountToSend = Number(amount).toFixed(Number(precision)) + " " + token_name.toUpperCase();

      const action = [{
        account: 'waxdaolocker',
        name: 'createlock',
        authorization: [session.permissionLevel],
        data: {
            creator: session.actor,
            receiver: receivingAccount,
            amount: amountToSend,
            token_contract: token_contract,
            unlock_time: timestamp,
        }
        }]

        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText(<span>
              Your lock has been created. To deposit the funds, go to the{" "} 
              <a href={`${currentWebsiteURL}/manage-locks`}>lock management page</a>
            </span>)
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }    

} //end createLock

export const cancelLock = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

      const action = [{
            account: 'waxdaolocker',
            name: 'cancellock',
            authorization: [session.permissionLevel],
          data: {
            lock_ID: ID,
          }
        }]

        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText("Your lock has been cancelled")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }        

  } //end cancelLock  

  export const withdrawLock = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

      const action = [{
            account: 'waxdaolocker',
            name: 'withdraw',
            authorization: [session.permissionLevel],
          data: {
            lock_ID: ID,
          }
        }]
        
        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText("Your funds have been withdrawn")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }        

  } //end withdrawLock

  export const depositLockFunds = async (ID, amount, contract, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession    

      const action = [{
            account: contract,
            name: 'transfer',
            authorization: [session.permissionLevel],
          data: {
              from: session.actor,
              to: 'waxdaolocker',
              quantity: amount,
              memo: "|deposit|" + ID + "|",
          }
        }]

        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText("Your drop has been cancelled")
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }        
        

  } //end depositLockFunds

  export const createLockV2 = async (token_contract, amount, precision, token_name, months_to_lock, setEnterModalText, setLoadingDisplay, setEnterModalDisplay, wharfSession) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

    let unlock_time = Math.round(Date.now() / 1000) + (months_to_lock * 60 * 60 * 24 * 30);

    let amountToSend = Number(amount).toFixed(Number(precision)) + " " + token_name.toUpperCase();

      const action = [{
        account: 'waxdaolocker',
        name: 'createlock',
        authorization: [session.permissionLevel],
        data: {
            creator: session.actor,
            receiver: session.actor,
            amount: amountToSend,
            token_contract: token_contract,
            unlock_time: unlock_time,
        }
        },{
          account: token_contract,
          name: 'transfer',
          authorization: [session.permissionLevel],
          data: {
            from: session.actor,
            to: "waxdaolocker",
            quantity: amountToSend,
            memo: "deposit_v2"
          }
          }]

        try {
          const result = await session.transact({ actions: action }, defaultTxSettings);
          setLoadingDisplay("");
          setEnterModalText(PROCESSING_TX_TEXT);
          const timer = setTimeout(() => {
            setEnterModalText(<span>
              Your tokens have been locked. You can view details under "My Locks".
            </span>)
            setLoadingDisplay("none");
          }, defaultSpinnerDuration);
          return () => clearTimeout(timer);
        } catch (e) {
          console.log('ERROR: ', e);
          setEnterModalText(e.message);
          setEnterModalDisplay("");
        }    

} //end createLock