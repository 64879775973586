import React, { useEffect, useState } from 'react'
import { useGetBannerAds } from './CustomHooks/useGetBannerAds';
import '.././pages/markdown-styles.css'; 
import { buildIpfsUrl } from '../data/functions/global_functions';
import { HighlightsWrapper } from '../Styles2024';
import SingleHighlight from './SingleHighlight';

const fallbackBanners = [
  {ipfs: "QmXy8MXJKZACbYMFnhWuvKWRTspSWgGH7ZTfkw4DDHfQou", url:"https://neftyblocks.com/collection/metabattler/drops"},
  {ipfs: "QmXB2MjggBSNPyTD1UigFhkywy5vHaUEjTfAwDVjLtj1og", url:"https://niftyrace.com"},
  {ipfs: "QmQSvrbaKJ3i1E4m4sZxjAoXPAt9r1hoqid5be54uiSoht", url:"https://waxonedge.app"}
]

const createBannerLink = (bannerAds, bannersAreLoading, slot, param) => {
  let fallbackParam = param == 'ipfs_hash' ? 'ipfs' : 'url';

  if(bannersAreLoading){
    return 
  }
  if(!bannersAreLoading){
    if((!bannerAds) || (bannerAds && !bannerAds[0]) ){
      return fallbackBanners[slot - 1][fallbackParam]
    }
    if(bannerAds[0]){
      let ad_slot;
      if(slot == 1){
        ad_slot = 'ad_slot_a';
      } else if(slot == 2){
        ad_slot = 'ad_slot_b';
      } else {
        ad_slot = 'ad_slot_c';
      }
      if(bannerAds[0][ad_slot].owner != "waxdao"){
        return bannerAds[0][ad_slot][param];
      }
      return fallbackBanners[slot - 1][fallbackParam];
    }        
  }
}

export const buildHighlightImage = (data, setIsLoading) => {
  const possibleKeys = ["video", "img", "image", "front", "picture", "pic", "ipfs", "front_image",
      "cover", "back", "backimg", "back_image"];

  const lowerCasedData = Object.keys(data).reduce((newData, key) => {
    newData[key.toLowerCase()] = data[key];
    return newData;
  }, {});

  for (let key of possibleKeys) {
    const val = lowerCasedData[key];
    if (val && val.length >= 5) {
        if (key === 'video') {
          if(hasAutoplayOn()){
            return (
              <video autoPlay loop muted 
                onLoadedMetadata={() => setIsLoading(false)}
                onError={() => setIsLoading(false)}
                loading="lazy">
                <source src={buildIpfsUrl(val.trim(), STANDARD_NFT_IMAGE_WIDTH)} type="video/mp4" />
              </video>
            );
          } else {
            return (
              <video controls loop muted 
              onLoadedMetadata={() => setIsLoading(false)}
              onError={() => setIsLoading(false)}              
              loading="lazy">
                <source src={buildIpfsUrl(val.trim(), STANDARD_NFT_IMAGE_WIDTH)} type="video/mp4" />
              </video>
            );            
          }

        } else {
          return <img src={buildIpfsUrl(val, STANDARD_NFT_IMAGE_WIDTH)} 
          onLoad={() => setIsLoading(false)}
          onError={() => setIsLoading(false)}
          loading="lazy" />;
        }
      }
    }
};




const CurrentHighlights = () => {

const [bannerAds, getBanners, bannersAreLoading] = useGetBannerAds();
const [show, setShow] = useState(Math.floor(Math.random() * 3) + 1);
const [hide, setHide] = useState(Math.floor(Math.random() * 3) + 1);

useEffect(() => {
  let isMounted = true;

  const fetchBannerAds = async () => {
      try {
          if (isMounted) {
              await getBanners();
          }
      } catch (error) {
          console.log(e)
      }
  };

  fetchBannerAds();
  
  return () => {
      isMounted = false;
  };
}, []);

useEffect(() => {
  const intervalId = setInterval(() => {
    setShow(prevShow => {
      if (prevShow === 1 || prevShow === 2) {
        return prevShow + 1;
      } else {
        return 1;
      }
    });
    setHide(prevShow => {
      if (prevShow === 1 || prevShow === 2) {
        return prevShow + 1;
      } else {
        return 1;
      }
    });    
  }, 10000);

  return () => {
    clearInterval(intervalId);
  };
}, []);


  return (
    <HighlightsWrapper>
      <SingleHighlight bannersAreLoading={bannersAreLoading} show={show == 1} hide={hide == 1} image={createBannerLink(bannerAds, bannersAreLoading, 1, 'ipfs_hash')} url={createBannerLink(bannerAds, bannersAreLoading, 1, 'url')} />
      <SingleHighlight bannersAreLoading={bannersAreLoading} show={show == 2} hide={hide == 2} image={createBannerLink(bannerAds, bannersAreLoading, 2, 'ipfs_hash')} url={createBannerLink(bannerAds, bannersAreLoading, 2, 'url')} />
      <SingleHighlight bannersAreLoading={bannersAreLoading} show={show == 3} hide={hide == 3} image={createBannerLink(bannerAds, bannersAreLoading, 3, 'ipfs_hash')} url={createBannerLink(bannerAds, bannersAreLoading, 3, 'url')} />
    </HighlightsWrapper>
  )
}

export default CurrentHighlights