import { CloseWalletSearchBar, WalletSearchBar, WalletSearchBarCollectionList, WalletSearchBarCont, WalletSearchBarSingleCollectionButton } from "../../components/LeftDiv";
import { ExploreFiltersCont, ExploreFiltersInnerCont, FoldersContainer, FoldersRow, SingleFolderThird } from "../../Styles";

export const showNFTsSection = (
  currentAssetSection,
  currentNFTSection,
  setCurrentNFTSection,
  currentWalletSection,
  currentSidebarSubcategory,
  myCollections,
  enteredText,
  setEnteredText,
  showSearchCollections,
  setShowSearchCollections,
  switchCollection,
  unstakedPageCount,
  setUnstakedPageCount,
  unstaked,
  setUnstaked,
  setUnstakedLoadMoreDisplay,
  setNewCollection,

) => {
  return (
    <span className={currentAssetSection != "NFTs" && "hidden"}>
      <ExploreFiltersCont>
        <h2>Filters</h2>
        <FoldersContainer>
          <FoldersRow>
            <SingleFolderThird
              onClick={() => setCurrentNFTSection("Unstaked")}
              selected={currentNFTSection == "Unstaked" && true}
            >
              Unstaked
            </SingleFolderThird>
            <SingleFolderThird
              onClick={() => setCurrentNFTSection("Staked")}
              selected={currentNFTSection == "Staked" && true}
            >
              Staked
            </SingleFolderThird>
            <SingleFolderThird
              onClick={() => setCurrentNFTSection("Stakeable")}
              selected={currentNFTSection == "Stakeable" && true}
            >
              Stakeable Only
            </SingleFolderThird>
          </FoldersRow>
        </FoldersContainer>
        <br />
        <ExploreFiltersInnerCont>
          <WalletSearchBarCont
            className={
              currentWalletSection != "NFTs" ||
              currentSidebarSubcategory != "All"
                ? "hidden"
                : "flex"
            }
          >
            <WalletSearchBar
              placeholder={`All Collections ${
                myCollections?.assets == null
                  ? ""
                  : "(" + myCollections.assets + ")"
              }`}
              maxLength={12}
              onChange={(e) => {
                setEnteredText(e.target.value.toLowerCase());
              }}
              onFocus={() => {
                setShowSearchCollections(true);
              }}
            />
            <CloseWalletSearchBar
              onClick={() => {
                setShowSearchCollections(false);
              }}
              className={!showSearchCollections ? "hidden" : "inline-block"}
            >
              X
            </CloseWalletSearchBar>
            <br />
            <WalletSearchBarCollectionList
              className={showSearchCollections == false && "hidden"}
            >
              {myCollections?.collections?.length > 0
                ? myCollections.collections.map((item, index) => (
                    <span
                      key={index}
                      className={
                        enteredText.length >= 1 &&
                        item.collection?.collection_name
                          .substring(0, enteredText.length)
                          .indexOf(enteredText) == -1 &&
                        "hidden"
                      }
                    >
                      {item.collection?.collection_name != null && (
                        <WalletSearchBarSingleCollectionButton
                          key={index}
                          onClick={() => {
                            switchCollection(
                              unstakedPageCount,
                              setUnstakedPageCount,
                              unstaked,
                              setUnstaked,
                              setUnstakedLoadMoreDisplay,
                              setNewCollection,
                              item.collection.collection_name,
                              setShowSearchCollections
                            );
                          }}
                        >
                          {item.collection?.collection_name} ({item.assets})
                        </WalletSearchBarSingleCollectionButton>
                      )}
                    </span>
                  ))
                : "You don't have any collections in your wallet"}
            </WalletSearchBarCollectionList>
          </WalletSearchBarCont>
        </ExploreFiltersInnerCont>
      </ExploreFiltersCont>
    </span>
  );
};
