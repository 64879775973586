import { useState } from "react";
import { tokenServerURL } from "../../data/config";
import axios from 'axios';

export const useGetNsfwCollections = () => {
    
    const [nsfwCollections, setNsfwCollections] = useState([]);
    const [nsfwIsLoading, setNsfwIsLoading] = useState(true);

    const getNsfwCollections = async () => {
        setNsfwIsLoading(true);

        try {
            const res = await axios.post(`${tokenServerURL}/get-nsfw-collections`,{
            });
            
            if (res.data) {
                const nsfwCollections = res.data[0].collections.split(',').filter(Boolean) || [];  
                console.log("NSFW collections: " + nsfwCollections)
                setNsfwCollections(nsfwCollections)
            }
        } catch (error) {
            console.log(`Error fetching NSFW collections: ${error}`);
        }

        setNsfwIsLoading(false);
    }

    return [nsfwCollections, getNsfwCollections, nsfwIsLoading]
}
