import React, { useEffect, useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { FarmsPageCont, FarmsTitle, FarmTitle, FarmEndDate, PoolAmount, MissingInfoMsg, DaoButton, PaymentOptCont, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, CancelButton, SingleDropCont, MyDropsCont, MyDropsContainer, SingleDropLogo, FinalizeButton, SuggestionBtn } from '../../components/LeftDiv';
import { getCurrentApiList, currentUsername, getCurrentAtomicApiList, mainnetWebsiteURL } from '../../data/config';
import { cancelDrop, isLoggedIn } from '../../data/functions/wallet_functions';


const MyDrops = () => {

  const {
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    wharfSession
} = useStateContext();

const [dropList, setDropList] = useState([]);
const [myCollections, setMyCollections] = useState([]);
const [myPremintedPools, setMyPremintedPools] = useState([]);

useEffect(() => {

 if(!isLoggedIn()){
   return('');
 }
      axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
            table:"drops",
            scope:"waxdaomarket",
            code:"waxdaomarket",
            key_type: 'name',
            index_position: 2,
            limit:100,
            lower_bound: currentUsername,
            upper_bound: currentUsername,
            json:true
          })
            .then((response) => {
                setDropList([response.data.rows]);
            })
            .catch((error) => console.log(error));
  }

, []);



useEffect(() => {
 
     if(!isLoggedIn()){
       return('');
     }
  
     axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/collections?authorized_account=${currentUsername}&page=1&limit=100&order=desc&sort=created`)
                .then((response) => {
                    setMyCollections([response.data.data]);
                    console.log(response.data.data)
                })
                .catch((error) => console.log(error));
      }
    , []);


    useEffect(() => {

       if(!isLoggedIn()){
         return('');
       }
      
        axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
                  table:"premintpools",
                  scope:"waxdaomarket",
                  code:"waxdaomarket",
                  key_type: 'name',
                  index_position: 2,
                  limit:100,
                  lower_bound: currentUsername,
                  upper_bound: currentUsername,
                  json:true
                })
                  .then((response) => {
                    setMyPremintedPools([response.data.rows]);
                  })
                  .catch((error) => console.log(error));
        }
      
      , []);





  return (
  <div id="seo">
    <Helmet>
    <title>My Drops</title>
    <meta name="description" content="Manage your NFT drops on WaxDAO" />
    <link rel="canonical" href={`${mainnetWebsiteURL}/my-drops`} />
    </Helmet>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>


    <FarmsPageCont>

      <FarmsTitle>
        Manage Drops
        &nbsp;&nbsp;

        <a href="https://waxdao.io/create-drop">
        <DaoButton>
        + New Drop
        </DaoButton>
        </a>
      </FarmsTitle>

      <MyDropsContainer>



{dropList == null && (
<span>
<PaymentOptCont>
<MissingInfoMsg>
You have not created any drops yet.<br/><br/>

<a href="https://waxdao.io/create-drop">
<DaoButton>
    Create Drop Now
</DaoButton>
</a>
</MissingInfoMsg>
</PaymentOptCont>
</span>
)}

{dropList != null && dropList[0] != null && dropList[0].length == 0 && (

<span>
<PaymentOptCont>
<MissingInfoMsg>
    You have not created any drops yet.<br/><br/>

    <a href="https://waxdao.io/create-drop">
    <SuggestionBtn>
        Create Drop Now
    </SuggestionBtn>
    </a>
    </MissingInfoMsg>
</PaymentOptCont>
</span>
)}



{dropList[1] == null && dropList[0] != null && Array.isArray(dropList[0]) && dropList[0].length >= 1 ? (
   
   <span>
    <FarmsTitle>
        My Drops
    </FarmsTitle>

    <MyDropsCont>


    {dropList[0].map((item, index) => (



<span className={item.drop_type != null && item.drop_type == "premint.pack" && "hidden"}>
    <SingleDropCont>
      {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

      {item.price != null && (
        <PoolAmount>
         {item.price}
        </PoolAmount>
      )}

      {item.end_time != null && (
        <FarmEndDate>
          Ends {new Date(item.end_time * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}
      
      <a href={`https://waxdao.io/drops/${item.ID}`}>
        <DaoButton>
            View Drop
        </DaoButton>

      </a>
      <br/>

      <CancelButton onClick={() => { cancelDrop( item.ID, setEnterModalText, setLoadingDisplay, setEnterModalDisplay, wharfSession) }}>
          Cancel Drop
      </CancelButton>

    </SingleDropCont>
    </span>

    ))}
    </MyDropsCont>
    </span>
    
    ) : dropList[1] != null && Array.isArray(dropList) && 
    
    (
<span>
<MyDropsCont>

    {dropList.map((item, index) => (
      <span className={item.drop_type != null && item.drop_type == "premint.pack" && "hidden"}>
      <a href={`https://waxdao.io/drops/${item.ID}`}>
      <SingleDropCont>
      {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

      {item.price != null && (
        <PoolAmount>
         {item.price}
        </PoolAmount>
      )}

      {item.end_time != null && (
        <FarmEndDate>
          Ends {new Date(item.end_time * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

      <DaoButton>
          Cancel Drop
      </DaoButton>

     </SingleDropCont>
     </a>
     </span>
      ))}
     
     </MyDropsCont>
      </span>
    )}


{myCollections[1] == null && myCollections[0] != null && Array.isArray(myCollections[0]) ? (
   
   <span>
    <FarmsTitle>
        My Collections
    </FarmsTitle>

    <MyDropsCont>


    {myCollections[0].map((item, index) => (




    <SingleDropCont>
      {item.img != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.img}`} />
      )}
      {item.collection_name != null && (
          <span>
        <FarmTitle>
        {item.collection_name}
        </FarmTitle>

          <a href={`https://waxdao.io/collection/${item.collection_name}`}>
          <DaoButton>
              View Collection
          </DaoButton>
        </a>
        </span>
    )}

    


    </SingleDropCont>


    ))}
    </MyDropsCont>
    </span>
    
    ) : myCollections[1] != null && Array.isArray(myCollections) && 
    
    (
<span>
<MyDropsCont>

    {myCollections.map((item, index) => (

      <SingleDropCont>
      {item.img != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.img}`} />
      )}
      {item.collection_name != null && (
          <span>
        <FarmTitle>
        {item.collection_name}
        </FarmTitle>

          <a href={`https://waxdao.io/collection/${item.collection_name}`}>
          <DaoButton>
              View Collection
          </DaoButton>
        </a>
        </span>
    )}



     </SingleDropCont>


      ))}
     
     </MyDropsCont>
      </span>
    )}


{/* PRE MINTED POOLS */}


{myPremintedPools[1] == null && myPremintedPools[0] != null && Array.isArray(myPremintedPools[0]) ? (
   
   <span>
    <FarmsTitle>
        Pre-Minted Pools
        &nbsp;&nbsp;

        <a href="https://waxdao.io/create-premint">
        <DaoButton>
        + New Pool
        </DaoButton>
        </a>
    </FarmsTitle>

    <MyDropsCont>


    {myPremintedPools[0].map((item, index) => (




    <SingleDropCont>
      {item.display_name != null && (
                <FarmTitle>
                {item.display_name}
                </FarmTitle>
      )}

      {item.collection != null && (
          <span>
            <br/>
        {item.collection}

        </span>
    )}


{item.amount_of_assets != null && (
          <span>
            <br/>
        {item.amount_of_assets} Assets

        </span>
    )}

{item.ID != null && (
          <span>
            <br/><br/>
          <a href={`https://waxdao.io/premint-pool/${item.ID}`}>
          <SuggestionBtn>
              Manage Pool
          </SuggestionBtn>
        </a>
        </span>
    )}

{item.status != null && item.status == "available" && (
          <span>
            <br/>
            <br/>
          <a href={`https://waxdao.io/create-pack/${item.ID}`}>
          <FinalizeButton>
              Create Pack
          </FinalizeButton>
        </a>
        <br/>
            <br/>
          <a href={`https://waxdao.io/create-preminted-drop/${item.ID}`}>
          <FinalizeButton>
              Create Preminted Drop
          </FinalizeButton>
        </a>






        </span>
    )}






    </SingleDropCont>


    ))}
    </MyDropsCont>
    </span>
    
    ) : myPremintedPools[1] != null && Array.isArray(myPremintedPools) && 
    
    (
<span>
<MyDropsCont>

    {myPremintedPools.map((item, index) => (

      <SingleDropCont>
      {item.img != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.img}`} />
      )}
      {item.collection_name != null && (
          <span>
        <FarmTitle>
        {item.collection_name}
        </FarmTitle>

          <a href={`https://waxdao.io/collection/${item.collection_name}`}>
          <DaoButton>
              View Collection
          </DaoButton>
        </a>
        </span>
    )}



     </SingleDropCont>


      ))}
     
     </MyDropsCont>
      </span>
    )}



{/* PRE MINTED PACKS */}

{dropList[1] == null && dropList[0] != null && Array.isArray(dropList[0]) && dropList[0].length >= 1 ? (
   
   <span>
    <FarmsTitle>
        Preminted Packs
    </FarmsTitle>

    <MyDropsCont>


    {dropList[0].map((item, index) => (



<span className={item.drop_type != null && item.drop_type != "premint.pack" && "hidden"}>
    <SingleDropCont>
      {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

{item.ID != null && (
        <PoolAmount>
         Pack #{item.ID}
        </PoolAmount>
      )}
      
      <a href={`https://waxdao.io/unbox/${item.ID}`}>
        <DaoButton>
            View Page
        </DaoButton>

      </a>
  

    </SingleDropCont>
    </span>

    ))}
    </MyDropsCont>
    </span>
    
    ) : dropList[1] != null && Array.isArray(dropList) && 
    
    (
<span>
<MyDropsCont>

    {dropList.map((item, index) => (
      <span className={item.drop_type != null && item.drop_type != "premint.pack" && "hidden"}>
      <a href={`https://waxdao.io/unbox/${item.ID}`}>
      <SingleDropCont>
      {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

{item.ID != null && (
        <PoolAmount>
         Pack #{item.ID}
        </PoolAmount>
      )}


     </SingleDropCont>
     </a>
     </span>
      ))}
     
     </MyDropsCont>
      </span>
    )}

</MyDropsContainer>

      </FarmsPageCont>
      <br/><br/><br/>

    </div>
  )
}

export default MyDrops