import styled from 'styled-components'
import { theme } from "../../Styles";


export const NFTCardCheckbox = styled.button`
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    z-index: 200;
    font-size: 18px;
    font-weight: 500;
    top: 20px;
    left: 20px;
    line-height: 14px;
    border: 2px solid ${theme.secondary};
    border-radius: 3px;
    color: ${theme.textMain};
    background-color: ${props => props.selected == "yes" ? theme.secondary : "transparent"};
`

export const ShowNFTInfo = styled.button`
    position: absolute;
    display: inline-block;
    width: 35px;
    height: 35px;
    z-index: 200;
    font-size: 24px;
    font-weight: 500;
    top: 15px;
    left: 92px;
    text-align: center;
    line-height: 14px;
    //border: 2px solid ${theme.secondary};
    border-radius: 10px;
    color: ${theme.textMain};
    //background-color: ${props => props.selected == "yes" ? theme.secondary : "transparent"};
    background-color: #17161686;
`

export const ShowNFTWrapper = styled.div`
    position: absolute;
    top: 15px;
    width: 100%;
    text-align: center;
`

export const DropPageCollectionWrapper = styled.div`
    position: absolute;
    top: 195px;
    width: 100%;
    text-align: center;
`
 
export const DropPageShowNFTInfo = styled.button`
    //position: absolute;
    display: inline-block;
    width: 35px;
    height: 35px;
    z-index: 200;
    font-size: 24px;
    font-weight: 500;
    //top: 15px;
    //left: 92px;
    text-align: center;
    line-height: 14px;
    //border: 2px solid ${theme.secondary};
    border-radius: 10px;
    color: ${theme.textMain};
    //background-color: ${props => props.selected == "yes" ? theme.secondary : "transparent"};
    background-color: #17161686;
`

export const AttributeModalWrapper = styled.div`
   
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 15011;
   
    width: 600px;
    max-width: 90%;
    height: 600px;
    max-height: 70%;
    background-color: ${theme.mainDarker};
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 30px;
    box-shadow: 0px 0px 5px 3px whitesmoke;



    @media (max-width: 900px) {
        margin-top: 50px; 
    }
`

export const AttributeModalInnerWrapper = styled.div`
   
   
    width: 100%;
    max-width: 100%;
    height: 80%;
    max-height: 80%;
    background-color: ${theme.mainDarker};
    text-align: left;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    overflow-x: hidden;

    -ms-overflow-style: scrollbar;  /* IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: ${theme.secondary} black; 

    ::-webkit-scrollbar{
        height: 4px;
    }

    ::-webkit-scrollbar-thumb{

        background: ${theme.secondary};
    }

    ::-webkit-scrollbar-track{

        background: #dfdcdc40;
    }

    a{
        color: ${theme.secondary};
    }

`

export const AttrModalName = styled.div`
    font-size: 18px;
    font-weight: 500;
    width:100%;
    text-align: left;
    color: ${theme.textMain};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`
export const AttrModalRow = styled.div`
    font-size: 14px;
    font-weight: 200;
    width:100%;
    text-align: left;
    color: ${theme.textSecondary};


`

export const AttrModalKey = styled.div`
    font-size: 14px;
    font-weight: 900;
    color: ${theme.textMain};
    display: inline-block;
    margin-right: 5px;
`