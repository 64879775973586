import { useState } from "react";
import { currentApiList, currentUsername } from "../../data/config";
import axios from 'axios';
import { isLoggedIn } from "../../data/functions/wallet_functions";

export const useGetAirdropClaims = () => {
    
    const [claims, setClaims] = useState([]);
    const [loading, setLoading] = useState(true);

    const getClaims = async (AirdropId) => {
        setLoading(true);

        if(!isLoggedIn()){
            setLoading(false)
            setClaims([])
            return [claims, getClaims, loading]
        }        
        
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "claims",
                    scope: AirdropId,
                    code: "wufclaimtool",
                    limit: 1,
                    lower_bound: currentUsername,
                    upper_bound: currentUsername,            
                    json: true,
                });

                if (res.data.rows?.length > 0) {
                    setClaims(res.data.rows);
                    console.log(res.data.rows);
                    break;
                }
            } catch (error) {
                console.log(`Failed on API endpoint ${api}. Error: ${error}`);
            }
        }

        setLoading(false);
    }

    return [claims, getClaims, loading]
}
