import { useState } from "react";
import { currentApiList, v2DAOContract } from "../../data/config";
import axios from 'axios';

export const useGetV2ProposalInfo = () => {
    
    const [proposalInfo, setProposalInfo] = useState([]);
    const [infoIsLoading, setInfoIsLoading] = useState(true);

    const getProposalInfo = async (dao, proposal_id) => {
        setInfoIsLoading(true);
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "proposals",
                    scope: dao,
                    code: v2DAOContract,
                    limit: 1,
                    lower_bound: proposal_id,
                    upper_bound: proposal_id,
                    json: true,
                });
                
                if (res.data.rows) {
                    console.log(res)
                    setProposalInfo(res.data.rows[0]);
                    console.log(res.data.rows);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }
        setInfoIsLoading(false);
    }

    return [proposalInfo, getProposalInfo, infoIsLoading]
}
