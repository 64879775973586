import styled from 'styled-components'
import { currentTheme, testTheme, theme } from '../../Styles'



export const HomePageCont = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: 0px; 
    margin-right: 0px;
    margin-top: 70px;
    //background-color: ${testTheme.mainLight};
    padding-bottom: 2em;
    color: ${currentTheme.textMain};
    //background: #1F2A44;
    //background: linear-gradient(0deg, #1F2A44 0%, #151f36 65%);
    @media (max-width: 847px) {
        padding-top: 0.5em;
    }

`

export const HeroLeftSide = styled.div`
    display: inline-block;
    width: 65%;
    max-width: 65%;
    height: 100%;
    padding-right: 15px;
    //background-color: ${testTheme.mainLight};
    //background: #1F2A44;
    //background: linear-gradient(0deg, #1F2A44 0%, #151f36 65%);
    @media (max-width: 847px) {
        width: 100%;
        max-width: 100%;
        display: block;
        text-align: center;
        background: unset;
    }

`

export const HeroRightSide = styled.div`
    display: inline-block;
    width: 35%;
    max-width: 35%;
    height: 100%;
    //background-color: ${testTheme.mainLight};
    //background: #1F2A44;
    //background: linear-gradient(0deg, #1F2A44 0%, #151f36 65%);
    @media (max-width: 847px) {
        width: 100%;
        max-width: 100%;
        display: block;
        padding-top: 1.2em;
        background: unset;
    }
`

export const HeroLargeText = styled.div`
    font-size: 54px;
    margin-top: 45px;
    margin-left: 50px;
    font-weight: 500;
    font-weight: 900;
    line-height: 1.2em;

    @media (max-width: 847px) {
        margin-left: auto;
        margin-right: auto;
        font-size: 48px;
        padding-left: 1em;
        padding-right: 1em;
    }

    @media (max-width: 500px) {
        font-size: 36px;
    }

`

export const HeroMediumText = styled.div`
    line-height: 1.2em;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 50px;

    @media (max-width: 847px) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 1em;
        padding-right: 1em;
    }

    @media (max-width: 500px) {
        font-size: 18px;
    }
`

export const CallToActionCont = styled.div`
    width: 90%;
    display: flex;
    justify-content: left;
    padding-left: 15px;
    gap: 40px;
    height: 70px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 847px) {
        justify-content: space-around;
    }

`

export const CallToActionHrefHomepage = styled.a`
    display: inline-block;
    width: 200px;
    height: 60px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 900;
    background-color: ${theme.secondary};
    text-align: center;
    vertical-align: middle;


    @media (max-width: 500px) {
        font-size: 14px;
        width: 170px;
        padding-left: 1em;
        padding-right: 1em;

    }

    :hover{
        background-color: ${theme.secondaryHover};
        transition: background-color 1s;
    }
`

export const CallToActionContent = styled.span`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
    vertical-align: middle;
`

export const StatsCont = styled.div`
    width: 90%;
    display: flex;
    justify-content: left;
    padding-left: 15px;
    gap: 10px;
    height: 70px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 847px) {
        justify-content: space-around;
    }
`

export const SingleStat = styled.div`
    width: 150px;
    height: 80px;
    font-weight: 500;
    color: ${currentTheme.textMain};
`

export const SingleStatHeader = styled.div`

    font-size: 30px;
    font-weight: 900;
    color: ${currentTheme.textMain};

    @media (max-width: 500px) {
        font-size: 24px;
    }
`

export const SingleStatButton = styled.button`
    border: 1px solid ${currentTheme.textMain};
    border-radius: 15px;
    width: 120px;
    height: 30px;
    font-size: 14px;
    font-weight: 500;
    color: ${currentTheme.textMain};

    :hover{
        background-color: ${currentTheme.textMain};
        color: ${currentTheme.main};
        transition: background-color 1s;
    }
`

export const SingleStatDetail = styled.div`

    font-size: 16px;
    color: #e7e6e6;

    @media (max-width: 500px) {
        font-size: 14px;
    }
`

export const HeroAd = styled.div`
    padding-top: 25px;
    width: 90%;
    height: 420px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: rgba(0,0,0,.2);
    border-radius: 25px;
`

export const HeroAdImage = styled.img`
    width: 250px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    border-radius: 25px;
`

export const HeroAdMessage = styled.div`
    width: 80%;
    font-weight: 900;
    font-size: 14px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    color: ${currentTheme.textMain};
`

export const WojakCallToAction = styled.a`
    display: inline-block;
    width: 200px;
    height: 40px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 900;
    color: ${currentTheme.textMain};
    background-color: ${theme.secondary};
    
    :hover{
        background-color: ${theme.secondaryHover};
        transition: background-color 1s;
    }

`

export const SocialProofSingleLogo = styled.div`
    display: inline-block;
    width: 19%;
    max-width: 19%;
    margin-left: 3%; 
    margin-right: 3%;
    text-align: center;
    font-size: 24px;
    color: white;

    :hover{
        box-shadow: 0px 0px 5px 0.8px ${theme.secondaryHover};
        transition: box-shadow 0.5s;
    }

`