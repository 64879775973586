import { useState } from "react";
import axios from 'axios';
import { getCurrentApiList } from "../../data/config";

export const useGetDynamicTemplates = () => {
    const [templates, setTemplates] = useState([]);

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    let count = 1;

    const fetchTemplates = async (farmname, farmtype, nextKey = 0, accumulatedTemplates = []) => {
        const apiUrl = `${getCurrentApiList}/v1/chain/get_table_rows`;
        const table = farmtype === "template" ? "farmtmplates" : "nctemplates";
        const params = {
            table: table,
            scope: farmname,
            code: "waxdaofarmer",
            lower_bound: nextKey,
            limit: 500,
            json: true
        };

        try {
            console.log(`fetching page ${count}  of stakeable templates`);
            const res = await axios.post(apiUrl, params);
            const newTemplates = res.data.rows;
            const allTemplates = [...accumulatedTemplates, ...newTemplates];
            console.log(res)
            
            if (res.data.more && res.data.next_key) {
                count ++;
                await delay(500);
                return fetchTemplates(farmname, farmtype, res.data.next_key, allTemplates);
            } else {
                console.log("all templates fetched")
                return allTemplates;
            }
        } catch (error) {
            console.error('Error fetching templates:', error);
            return accumulatedTemplates;
        }
    };

    const getDynamicTemplates = async (farmname, farmtype) => {
        const allTemplates = await fetchTemplates(farmname, farmtype);
        setTemplates(allTemplates);
    };

    return [templates, getDynamicTemplates];
}
