import React from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { Helmet } from "react-helmet";
import {
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
} from "../../components/LeftDiv";
import {
  currentWebsiteURL,
  mainnetWebsiteURL,
} from "../../data/config";
import {
  MilestoneDescription,
  NewListingBody,
  NewListingCont,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import '../markdown-styles.css'; 

const FarmFAQV2 = () => {
  const {
    enterModalText,
    loadingDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
  } = useStateContext();

  return (
    <div id="seo">
      <Helmet>
        <title>V2 Farm FAQ - WaxDAO</title>
        <meta
          name="description"
          content="FAQ for version 2 NFT farms on WaxDAO"
        />
        <link rel="canonical" href={`${mainnetWebsiteURL}/v2/farm-faq`} />
      </Helmet>

        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <GameButton onClick={() => setEnterModalDisplay("hidden")}>
            Close Window
          </GameButton>
        </Modal>

        <NewListingCont>
          <NewListingBody>
            <TallFiltersCont>
                <h2>Farm FAQ</h2>
            </TallFiltersCont>

            <TallFiltersCont>
                <h2>How should I format the farm name?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                Farm names have the same format as WAX addresses. Typically a max of 12 characters, a-z, 1-5 and . are all valid characters. Capital letters are invalid, as well as spaces, 0, and 6-9.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
                <h2>How much does it cost to create a farm?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                    <ReactMarkdown className="my-markdown">
                There are 3 options for farm payments: 250 WAX, 25,000 WAXDAO, or [1 Wojak NFT](https://waxdao.io/drops/247)
                </ReactMarkdown>
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>  

            <TallFiltersCont>
                <h2>How many NFTs can be staked in my farm?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>                
                Version 2 farms don't have any limits. Theoretically, there can be "unlimited" NFTs in your farm. In reality it's not unlimited, but you shouldn't have any issues unless there are millions of NFTs in your farm.
                <br/><br/>
                The real limit would probably be related to the amount of tokens you'd need to deposit in order to cover everyone's rewards.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>                        

            <TallFiltersCont>
                <h2>Where can I view/edit my farm?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                You can edit it at waxdao.io/v2/edit-farm/FARM_NAME_HERE
                <br/><br/>
                You can view it at waxdao.io/v2/farm/FARM_NAME_HERE
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>  

            <TallFiltersCont>
                <h2>What is "last update" and why does my claimable balance look wrong?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                "Last update" is the last time that your balances were updated. If payouts have gone by since your last update, then the claimable{" "}
                balances that you see will not reflect the amount you can actually claim.
                <br/><br/>
                All you need to do is click the "Claim" button, and any rewards you are owed will be paid out - regardless of what numbers you see under the claimable balances section.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>              

            <TallFiltersCont>
                <h2>If I stake more NFTs, will it affect my existing rewards?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                Yes, let me give you 2 scenarios to explain this.
                <br/><br/>
                Scenario 1: A farm pays out every 60 minutes. It has been 59 minutes since your last update. You stake another NFT in that farm.
                Your 60 minute timer will reset, and you will lose the previous 59 minutes that went by.
                <br/><br/>
                Scenario 2: A farm pays out every 60 minutes. It has been 121 minutes since your last update. You stake another NFT in that farm.
                2 hours worth of rewards will be applied to your claimable balances, and your 60 minute timer will restart.
                The best time to stake more NFTs is directly after a payout.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>    

            <TallFiltersCont>
                <h2>I tried to stake NFTs but got an error (needs 12345 but has 4321)?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                This means the farm doesn't have enough rewards to cover your staking payouts. You can try contacting the farm owner
                and asking them to add more rewards to the farm.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont> 

            <TallFiltersCont>
                <h2>What should I set the farm expiration date to?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                That's totally up to you - however, you need to be able to deposit enough rewards to cover any NFTs that you want people to stake in the farm.{" "}
                For example, there are 168 hours in a week. If you set the reward per NFT to 1 WAX per hour, and you set the farm to expire in 1 week - then that means you need to deposit 168 WAX for each NFT that will be staked in the farm.{" "}
                In this example, if you deposited 200 WAX total - only 1 NFT could be staked in the farm. So keep this in mind when setting your expiration date. You can always extend the farm later, or add more rewards as time goes by etc.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>  

            <TallFiltersCont>
                <h2>I am trying to extend my farm, but it says there aren't enough rewards?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                Please see the previous question. You need enough rewards in the farm to cover all staked NFTs until the expiration date.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>                         

            <TallFiltersCont>
                <h2>Can I kick/ban people from staking in my farm?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                If you want that option, you can use the <a href={`${currentWebsiteURL}/create-farm`}>Version 1 Farm Creator</a>
                <br/><br/>
                Version 2 farms do not support kicking users; one of the main selling points about these farms is the fact that once someone stakes in the farm,
                they are <span style={{color: "white"}}><b>guaranteed</b></span> their rewards until the farm expires.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont> 

            <TallFiltersCont>
                <h2>Can I remove tokens from the reward pool?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                No, not under any circumstances. Use a version 1 farm if you want that option.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>     

            <TallFiltersCont>
                <h2>If I close a farm, can I get a new farm for free?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                We decided not to offer that option on version 2 farms. A lot of time, work and money went into developing these, and{" "}
                we feel that less than $10 is more than fair for a new farm.
                <br/><br/>
                Allowing people to close old farms and get new ones for free has just resulted in unnecessary strain on our servers and UI.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>      

            <TallFiltersCont>
                <h2>I have a version 1 farm, can I get a version 2 farm for free?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                Please see the previous question.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>  

            <TallFiltersCont>
                <h2>I tried to claim my rewards, but I got an error ("farms.waxdao needs RAM")</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                This is likely because you've never held the token you are trying to claim.{" "}
                An easy way to fix this is to just buy a very small amount of that token, or to use the "open" action on that token's contract.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>          

            <TallFiltersCont>
                <h2>How many tokens can I reward people with in 1 farm?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                You can add up to 3 reward tokens to a farm.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>    

            <TallFiltersCont>
                <h2>If I add more than 1 reward token, does each NFT have to give rewards for each token?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                No, let's say you add 3 reward tokens (WAX, WAXDAO and HONEY). It is completely fine if 1 NFT rewards only WAXDAO, while another rewards only HONEY, while another rewards all 3 tokens.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>                                                                 

            <TallFiltersCont>
                <h2>How often do the farms pay out?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                This depends on how the farm creator configured their farm. They can set the payouts to be anywhere from 1 hour to 30 days.{" "}
                You can find out how often a specific farm pays out by checking the "Farm Details" section on that farm's page.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>   

            <TallFiltersCont>
                <h2>I heard there was a way to automatically send royalties from my NFT collection to my NFT farm. How do I set that up?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                You need to deploy our <a href="https://github.com/mdcryptonfts/royalty_contract" target="none">royalty contract</a> in order to do that. There
                are instructions in the readme file.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>  


            <TallFiltersCont>
                <h2>Why do I need to be authorized on the NFT collection in order to create a farm for it?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                The "simple" answer is that these farms do have an impact on the collection. So the collection owner needs to approve any farms that use their NFTs.
                <br/><br/>
                The more technical answer, is that the non-custodial farm contract has a notification handler that reacts to NFT transfers.{" "}
                This notification handler needs to perform operations, such as looking up the owner of the NFT, finding out its staking value, updating claimable balances for the sender/receiver, etc.{" "}
                These operations cost CPU - and that CPU cost is passed on to the user who sends the NFT. Therefore, it could actually be an attack vector that could ruin the experience of collecting, if we didn't apply this restriction.{" "}
                Someone could create 100 farms and add someone else's collection to each farm - making the CPU costs so large, that transactions could actually fail if someone tried to transfer an NFT.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>   

            <TallFiltersCont>
                <h2>Can I stake the same NFT in more than 1 farm?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                No, for the same reasons as the above question (CPU costs). There are other reasons too, but this is the main one.{" "}
                So you'll need to unstake your NFT from a farm, if you want to stake it in another farm.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>    

            <TallFiltersCont>
                <h2>What happens if I transfer or sell my NFT after I stake it?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                Simple, your NFT gets unstaked automatically. Your claimable reward balances are updated, and you can go claim them any time you like.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>     

            <TallFiltersCont>
                <h2>If I don't claim my rewards by tomorrow at 4:20 PM, do I lose them?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                Nope. You can claim now, or in 420 years. Even if you sold your NFT. The rewards will be waiting for you when you're ready.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont> 

            <TallFiltersCont>
                <h2>Are my NFTs safe when I stake them in these farms?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                Welcome to non-custodial staking. Your NFTs don't ever leave your wallet in the first place.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>    

            <TallFiltersCont>
                <h2>I heard there was an airdrop!</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                No. Don't click the link, it's a scam. Block and report.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>    

            <TallFiltersCont>
                <h2>How do the different farm types work?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                • <span className="font-bold text-white">Collections:</span> For any collection you add to the farm, <span className="font-bold text-white">any NFT</span> in the collection will be stakeable<br/>
                • <span className="font-bold text-white">Schemas:</span> For any schemas you add to the farm, <span className="font-bold text-white">any NFT</span> in that schema will be stakeable<br/>
                • <span className="font-bold text-white">Template IDs:</span> Only the template IDs you specify can be staked in the farm<br/>
                • <span className="font-bold text-white">Attributes:</span> Only the attributes you specify can be staked in the farm
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>     

            <TallFiltersCont>
                <h2>I need a farm where people can stake for 3 hours if their NFT has a rarity of 17, but if the rarity is 18 and they are holding 27 WAT tokens in their account, then they need to unstake but also if they have 100 WAX worth of CPU and the NFT has a shirt color of blue, then 20% of royalties from 9 collections get backed into John's NFT because John staked before September 3rd and has more than 12 NFTs from the puppies schema and played my off-chain game for 420.69 hours out of the past 3 months.</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                Wat?<br/><br/>

                But in all seriousness, if you have some extremely complex request that's specific to your collection - then you likely need a custom contract written. It probably won't be cheap, and we may not even have time to do it. But you are welcome to reach out to us and ask for a quote.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>   

            <TallFiltersCont>
                <h2>You suck at writing FAQs. My question is not answered here. Where do I go to complain about your terrible writing skills?</h2>
                <br/>
                <TallFiltersInnerCont>
                <MilestoneDescription>
                Pop into our <a href="https://t.me/hoodpunks" target="none">Telegram</a> or <a href="https://discord.gg/xdvn5dkqvP" target="none">Discord</a> and tell us how not helpful we are.
                </MilestoneDescription>
                </TallFiltersInnerCont>
            </TallFiltersCont>                                                                                                           

          </NewListingBody>
        </NewListingCont>
      <br />
      <br />
      <br />
    </div>
  );
};

export default FarmFAQV2;
