import React from 'react'
import { FarmCard2024Avatar, FarmCard2024DetailsRow, FarmCard2024DetailsWrapper, FarmCard2024Name, FarmCard2024Separator, FarmCard2024Tags, FarmCard2024Wrapper } from '../pages/farmsv2/FarmStyles2024'
import { buildIpfsUrl } from '../data/functions/global_functions';
import { isAnOfficialFarm, isAnOfficialFarm2024 } from '../data/functions/farm_functions';
import { currentWebsiteURL } from '../data/config';

const showFarmVersion = (farm) => {
    if(farm.contract === 'waxdaofarmer') return 'V1';
    else if(farm.contract === 'farms.waxdao') return 'V2';
}

const showTotalFundsString = (total_funds) => {
    if(total_funds.indexOf(".") > -1){
        return `${total_funds.split(".")[0]} ${total_funds.split(" ")[1]}`;
    } else {
        return total_funds;
    }
}

const buildFarmUrl = (farm) => {
    if(farm.contract === 'waxdaofarmer') return `${currentWebsiteURL}/farm/${farm.farmname}`;
    else if(farm.contract === 'farms.waxdao') return `${currentWebsiteURL}/v2/farm/${farm.farmname}`;
}

const FarmCard2024 = (props) => {
    const farm = props.farm;
    const calculatedHeight = 160 + (farm?.reward_pools?.reward_pools?.length * 20);
    const height = Math.max(calculatedHeight, 180);

  return (
    <FarmCard2024Wrapper height={height} href={buildFarmUrl(farm)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g id="_19-Arrow_Right" data-name="19-Arrow Right"><path d="M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm0,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30Z"/><path d="M26.71,15.29l-7-7L18.29,9.71,23.59,15H5v2H23.59l-5.29,5.29,1.41,1.41,7-7A1,1,0,0,0,26.71,15.29Z"/></g></svg>
        <FarmCard2024Avatar src={buildIpfsUrl(farm?.avatar, 150)} />
        <FarmCard2024Name>{farm?.farmname}</FarmCard2024Name>
        <FarmCard2024Tags>
            {showFarmVersion(farm)}{', '}
            {showFarmVersion(farm) == "V2" && "OFFICIAL"}
            {showFarmVersion(farm) == "V1" && isAnOfficialFarm2024(farm) && "OFFICIAL"}
            {showFarmVersion(farm) == "V1" && !isAnOfficialFarm2024(farm) && "UNOFFICIAL"}
        </FarmCard2024Tags>
        <FarmCard2024Separator />
        <FarmCard2024DetailsWrapper>
            <FarmCard2024DetailsRow><h3>NFTS STAKED</h3><h4>{farm?.total_staked}</h4></FarmCard2024DetailsRow>
            <FarmCard2024DetailsRow><h3>REWARD POOLS</h3><h4>{farm?.reward_pools?.reward_pools?.map((pool, index) => (
                <span key={index}>{index != 0 && <br/>}{showTotalFundsString(pool.total_funds)}</span>
            ))}</h4></FarmCard2024DetailsRow>
            <FarmCard2024DetailsRow><h3>ENDS</h3><h4>{new Date(farm.expiration * 1000).toLocaleDateString()}</h4></FarmCard2024DetailsRow>
        </FarmCard2024DetailsWrapper>

    </FarmCard2024Wrapper>
  )
}

export default FarmCard2024