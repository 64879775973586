import React, { useEffect, useState } from "react";
import { currentWebsiteURL } from "../data/config";
import {
  buildDropCardImage2024,
  collectionIsNsfw,
  showNsfwOverlay,
} from "../data/functions/global_functions";
import { hasNsfwHidden } from "../pages/Settings";
import {
  DropCard2024TimeWrapper,
  DropCardCollectionNameWrapper2024,
  DropCardPriceAndSupplyWrapper,
  DropCardTopHalf2024,
  DropCardWrapper2024,
  NftCardMint2024,
  NftCardWrapper2024,
  NftcardTopHalf2024,
} from "../data/css/NftStyles2024";
import LoadingSpinnerImageFallback from "./LoadingSpinnerImageFallback";
import NumberFormat from "react-number-format";

const formatTotalLeft = (qty) => {
  let total_left = Number(qty);

  if (isNaN(total_left)) return "NaN";

  if (total_left < 1000) {
    return total_left.toString();
  } else if (total_left >= 1000 && total_left < 10000) {
    return (total_left / 1000).toFixed(2) + "k";
  } else if (total_left >= 10000 && total_left < 100000) {
    return (total_left / 1000).toFixed(1) + "k";
  } else if (total_left >= 100000 && total_left < 1000000) {
    return Math.floor(total_left / 1000) + "k";
  } else if (total_left >= 1000000 && total_left < 1000000000) {
    return (total_left / 1000000).toFixed(2) + "M";
  } else if (total_left >= 1000000000) {
    return "1B+";
  }
};

const formatTotalAvail = (qty) => {
  let total_left = Number(qty);

  if (isNaN(total_left)) return "NaN";

  if (total_left == 0) {
    return <span>&#8734;</span>;
  }

  if (total_left < 1000) {
    return total_left.toString();
  } else if (total_left >= 1000 && total_left < 10000) {
    return (total_left / 1000).toFixed(2) + "k";
  } else if (total_left >= 10000 && total_left < 100000) {
    return (total_left / 1000).toFixed(1) + "k";
  } else if (total_left >= 100000 && total_left < 1000000) {
    return Math.floor(total_left / 1000) + "k";
  } else if (total_left >= 1000000 && total_left < 1000000000) {
    return (total_left / 1000000).toFixed(2) + "M";
  } else if (total_left >= 1000000000) {
    return "1B+";
  }
};

const showFormattedPrice = (asset) => {
  let qty = Number(asset.split(" ")[0]);
  let sym = asset.split(" ")[1];
  if (qty == 0 && sym == "FREE") return "FREE";
  else if (qty < 1000) {
    return `${qty.toFixed(2)} ${sym}`;
  } else if (qty >= 1000 && qty < 10000) {
    return (qty / 1000).toFixed(2) + "k " + sym;
  } else if (qty >= 10000 && qty < 100000) {
    return (qty / 1000).toFixed(1) + "k " + sym;
  } else if (qty >= 100000 && qty < 1000000) {
    return Math.floor(qty / 1000) + "k " + sym;
  } else if (qty >= 1000000 && qty < 1000000000) {
    return (qty / 1000000).toFixed(2) + "M " + sym;
  } else if (qty >= 1000000000) {
    return "1B+ " + sym;
  }

  //return <><NumberFormat displayType='text' thousandSeparator={true} value={Number(qty).toFixed(2)} style={{backgroundColor:'transparent'}} />&nbsp; {sym}</>
};

const DropCard2024 = (props) => {
  const item = props.item;
  const nsfwCollections = props.nsfwCollections;
  const nsfwIsLoading = props.nsfwIsLoading;

  const [isLoading, setIsLoading] = useState(true);
  const [timeStatus, setTimeStatus] = useState("");

  const calculateTimeStatus = (startTimestamp, endTimestamp) => {
    const now = Date.now();
    const startMs = Number(startTimestamp) * 1000;
    const endMs = Number(endTimestamp) * 1000;
    const startDiff = startMs - now;
    const endDiff = endMs - now;

    if (startTimestamp == 0 && endTimestamp == 0) {
      return "In Progress";
    } else if (startTimestamp == 0 && endTimestamp != 0 && endDiff > 0) {
      return formatTimeDiff(endDiff, "Ends");
    } else if (startTimestamp == 0 && endTimestamp != 0 && endDiff <= 0) {
      return "Ended";
    } else if (endTimestamp == 0 && startTimestamp != 0 && startDiff > 0) {
      return formatTimeDiff(startDiff, "Starts");
    } else if (endTimestamp == 0 && startTimestamp != 0 && startDiff <= 0) {
      return "In Progress";
    } else if (startTimestamp != 0 && endTimestamp != 0 && endDiff <= 0) {
      return "Ended";
    } else if (startTimestamp != 0 && endTimestamp != 0 && startDiff > 0) {
      return formatTimeDiff(startDiff, "Starts");
    } else if (
      startTimestamp != 0 &&
      endTimestamp != 0 &&
      startDiff <= 0 &&
      endDiff > 0
    ) {
      return formatTimeDiff(endDiff, "Ends");
    } else {
      return "Unknown Status";
    }
  };

  const formatTimeDiff = (timeDiff, startsOrEnds) => {
    if (timeDiff >= 24 * 60 * 60 * 1000 * 365 * 10) {
      const decades = Math.floor(timeDiff / (24 * 60 * 60 * 1000 * 365 * 10));
      return `${startsOrEnds} in ${decades} decades`;
    }
    if (timeDiff >= 24 * 60 * 60 * 1000 * 365) {
      const years = Math.floor(timeDiff / (24 * 60 * 60 * 1000 * 365));
      return `${startsOrEnds} in ${years} years`;
    }
    if (timeDiff >= 24 * 60 * 60 * 1000 * 30) {
      const months = Math.floor(timeDiff / (24 * 60 * 60 * 1000 * 30));
      return `${startsOrEnds} in ${months} months`;
    } else if (timeDiff >= 24 * 60 * 60 * 1000) {
      const days = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
      return `${startsOrEnds} in ${days} days`;
    } else if (timeDiff >= 60 * 60 * 1000) {
      const hours = Math.floor(timeDiff / (60 * 60 * 1000));
      const minutes = Math.floor((timeDiff % (60 * 60 * 1000)) / (60 * 1000));
      return `${startsOrEnds} in ${hours} hours, ${minutes} minutes`;
    } else {
      const minutes = Math.floor(timeDiff / (60 * 1000));
      const seconds = Math.floor((timeDiff % (60 * 1000)) / 1000);
      return `${startsOrEnds} in ${minutes} minutes, ${seconds} seconds`;
    }
  };

  useEffect(() => {
    const updateStatus = () => {
      const status = calculateTimeStatus(item.start_time, item.end_time);
      setTimeStatus(status);
    };

    updateStatus();
    const interval = setInterval(updateStatus, 1000);

    return () => clearInterval(interval);
  }, [item.start_time, item.end_time]);

  return (
    <DropCardWrapper2024>
      <DropCardTopHalf2024>
        {(nsfwIsLoading || (!nsfwIsLoading && !collectionIsNsfw(item.collection_name, nsfwCollections) && isLoading)) && <LoadingSpinnerImageFallback />}

        {!nsfwIsLoading && (
          <a href={`${currentWebsiteURL}/drops/${item.id}`}>
            {collectionIsNsfw(item.collection_name, nsfwCollections) &&
            hasNsfwHidden()
              ? showNsfwOverlay()
              : buildDropCardImage2024(item, setIsLoading)}
          </a>
        )}
      </DropCardTopHalf2024>

      {item.collection_name && (
        <a href={`${currentWebsiteURL}/collection/${item.collection_name}`}>
          <DropCardCollectionNameWrapper2024>
            {item.collection_name}
          </DropCardCollectionNameWrapper2024>
        </a>
      )}

      <DropCardPriceAndSupplyWrapper>
        <h2>
          {item.price_options &&
            showFormattedPrice(item.price_options[0].price)}
        </h2>
        {item.total_left && item.total_available && (
          <NftCardMint2024 small={true}>
            {formatTotalLeft(item.total_left)}/
            {formatTotalAvail(item.total_available)}
          </NftCardMint2024>
        )}
      </DropCardPriceAndSupplyWrapper>

      <DropCard2024TimeWrapper>
        <h3>{timeStatus}</h3>
      </DropCard2024TimeWrapper>
    </DropCardWrapper2024>
  );
};

export default DropCard2024;
