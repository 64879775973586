import { useState } from "react";
import { currentApiList, currentUsername, getCurrentApiList } from "../../data/config";
import axios from 'axios';
import { Name } from "eos-common";

export const useGetBlenderBalances = () => {
    
    const [blenderBalances, setBlenderBalances] = useState([]);
    const [blenderBalancesAreLoading, setBlenderBalancesAreLoading] = useState(true);
    const nameValue = new Name(currentUsername).raw().toString();

    const getBlenderBalances = () => {
    
        axios
        .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
          table: "tokenbalance",
          scope: nameValue,
          code: "waxdaomarket",
          limit: 1000,
          json: true,
        })
        .then((res) => {
            setBlenderBalances(res.data.rows);
        })
        .catch((error) => console.log(error))
        .finally(() => setBlenderBalancesAreLoading(false))

    }

    return [blenderBalances, getBlenderBalances, blenderBalancesAreLoading]
}


