import React, {useState} from 'react'
import { AttributeModalInnerWrapper, AttributeModalWrapper } from '../data/css/PremiintStyles';
import { transferNFTs } from '../data/functions/wallet_functions';
import { MainButton } from '../Styles';

import { GameButton, ModalContent } from './LeftDiv'
import { useStateContext } from '../contexts/ContextProvider';

const TransferNFTsModal = (props) => {
  const { wharfSession } = useStateContext();

    const showTransferNFTsModal = props.showTransferNFTsModal;
    const setShowTransferNFTsModal = props.setShowTransferNFTsModal;
    const assetVector = props.assetVector;
    const setEnterModalDisplay = props.setEnterModalDisplay;
    const setEnterModalText = props.setEnterModalText;
    const setLoadingDisplay = props.setLoadingDisplay;



    const [receiver, setReceiver] = useState("")
    const [memo, setMemo] = useState("");

  return (
    <AttributeModalWrapper className={!showTransferNFTsModal && "hidden"}>
        <AttributeModalInnerWrapper>
            <ModalContent>
            <h3>Receiver</h3>
            <input value={receiver} onChange={(e) => setReceiver(e.target.value.toLowerCase())} maxLength={13} placeholder="e.g. mike.wam" />
            <br/><br/>

            <h3>Memo</h3>
            <input value={memo} onChange={(e) => setMemo(e.target.value)} maxLength={240} placeholder="optional" />
            <br/><br/>    
            <MainButton onClick={() => { transferNFTs(assetVector,
                receiver,
                memo,
                setEnterModalDisplay,
                setEnterModalText,
                setLoadingDisplay,
                wharfSession
                );

                setShowTransferNFTsModal(false);
                
                }}>
            Send {assetVector.length} NFTs
            </MainButton>        
            
        </ModalContent>
      </AttributeModalInnerWrapper>
      <br/>
      <GameButton onClick={() => setShowTransferNFTsModal(false) }>
        Close Window
      </GameButton>
      </AttributeModalWrapper>
  )
}

export default TransferNFTsModal