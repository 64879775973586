import styled from 'styled-components'
import { currentTheme, theme } from '../../Styles'

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>

export const SinglePoolDetail = styled.div`
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
    width: 50%;
    color: ${theme.textSecondary};

    @media (max-width: 500px) {
        font-size: 12px;
    }
`

export const PoolLineBreak = styled.span`
    @media (min-width: 621px) {
        display: none;
    }
`

export const SinglePoolDetailBold = styled.div`
    display: inline-block;
    width: 50%;
    color: ${theme.textMain};
    font-weight: 900;
`

export const PoolCardAPR = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 10px;
    font-weight: 200;
    top: 20px;
    right: 20px;
    padding:3px 10px 3px 10px;
    border-radius: 10px;
    color: ${theme.textMain};
    background-color: #171616a9;
`

export const PoolCardName = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 270px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${theme.textSecondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const PoolCardEndDate = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 300px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${theme.textSecondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const PoolCardTokenToStake = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 330px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${theme.textSecondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const PoolCardPoolSize = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 360px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${theme.secondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const ClaimTokensButton = styled.button`
    border: 1px solid ${currentTheme.textMain};
    border-radius: 15px;
    width: 100%;
    height: auto;
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    color: ${currentTheme.textMain};
    padding-top: 5px;
    padding-bottom: 5px;

    :hover{
        background-color: ${currentTheme.textMain};
        color: ${currentTheme.main};
        transition: background-color 1s;
    }
`


export const OpenRowButton = styled.button`
    display: inline-block;
    width: 200px;
    height: 40px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 900;
    color: ${currentTheme.textMain};
    background-color: ${currentTheme.secondary};
    margin-top: 10px;
    
    :hover{
        background-color: ${currentTheme.secondaryHover};
        transition: background-color 1s;
    }

`

export const PoolH2 = styled.h2`
    font-size: 24px;
    font-weight: 900;
    color: ${currentTheme.secondary};

`

export const PoolH3 = styled.h3`
    font-size: 20px;
    font-weight: 900;
    color: ${currentTheme.textMain};

`