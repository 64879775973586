import { useEffect, useState } from "react";
import { useGetStakedOnlyFarms } from "../../components/CustomHooks/useGetStakedOnlyFarms";
import { useGetStakedOnlyPools } from "../../components/CustomHooks/useGetStakedOnlyPools";
import LoadingDiv from "../../components/LoadingDiv";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentWebsiteURL } from "../../data/config";
import {
    ClaimInfo,
  TokenCard,
} from "../../data/css/WalletStyles";
import { getPrecisionFromAsset, roundWalletBalance } from "../../data/functions/pool_functions";
import {
  claimAll,
  claimAllPools,
  claimFarmRewards,
  claimRewards,
  getAssetSymbol,
} from "../../data/functions/wallet_functions";
import {
  FoldersContainer,
  FoldersRow,
  SingleFolderTab,
  SmallButton,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import { initials } from '@dicebear/collection';
import { createAvatar } from '@dicebear/core';

const MyAvatar = ({ letters }) => {
  const avatar = createAvatar(initials, {
    seed: letters,
  });

  const svg = avatar.toString();

  return (
      <img src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`} alt={`Avatar-${letters}`} 
        style={{borderRadius:"5px", opacity:"90%"}}
      />
  );
};

export const showClaimRewardsSection = (
  currentMainSection,
  currentToolsSection,
  setLoadingDisplay
) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
    wharfSession
  } = useStateContext();

  const [currentClaimSection, setCurrentClaimSection] = useState("NFT Farms");
  const [myFarms, getStakedOnlyFarms, farmsAreLoading, farmsWereLoaded] =
    useGetStakedOnlyFarms();
    const [myPools, getStakedOnlyPools, poolsAreLoading, poolsWereLoaded] = useGetStakedOnlyPools();

  useEffect(() => {
    if (
      currentClaimSection == "NFT Farms" &&
      currentToolsSection == "Claim Rewards" &&
      !farmsWereLoaded
    ) {
      getStakedOnlyFarms();
    }

    else if (
        currentClaimSection == "Token Pools" &&
        currentToolsSection == "Claim Rewards" &&
        !poolsWereLoaded
      ) {
        getStakedOnlyPools();
      }


  }, [currentClaimSection, currentToolsSection]);

    return (
      <span className={currentToolsSection != "Claim Rewards" && "hidden"}>
        <TallFiltersCont>
          <TallFiltersInnerCont>
            <FoldersContainer>
              <FoldersRow>
                <SingleFolderTab
                  onClick={() => setCurrentClaimSection("NFT Farms")}
                  selected={currentClaimSection == "NFT Farms" && true}
                >
                  NFT Farms
                </SingleFolderTab>
                <SingleFolderTab
                  onClick={() => setCurrentClaimSection("Token Pools")}
                  selected={currentClaimSection == "Token Pools" && true}
                >
                  Token Pools
                </SingleFolderTab>
              </FoldersRow>
            </FoldersContainer>
          </TallFiltersInnerCont>
        </TallFiltersCont>

       {!farmsAreLoading && (
           <span className={currentClaimSection != "NFT Farms" && "hidden"}>
               {myFarms?.length > 0 && (
                   <TallFiltersCont>
                       <div className="w-100 pl-4">
                       <SmallButton
                        onClick={() => {
                            claimAll(
                            setEnterModalText,
                            setLoadingDisplay,
                            setEnterModalDisplay,
                            wharfSession
                            );
                        }}                       
                       >
                           Claim All
                       </SmallButton>
                       </div>
                   </TallFiltersCont>
               )}

               {myFarms?.length > 0 ? myFarms.map((item, index) => (
                   <span key={index}>
                       <TokenCard>
                       <a href={`${currentWebsiteURL}/farm/${item.farmname}`}>
                       {MyAvatar({ letters: item.farmname })}    
              </a>
              <ClaimInfo>
              {item.farmname}
              <SmallButton
                onClick={() => {
                    claimFarmRewards(
                        item.farmname,
                        getPrecisionFromAsset(item.claimable) + "," + getAssetSymbol(item.claimable),
                        item.contract,
                        setEnterModalText,
                        setLoadingDisplay,
                        setEnterModalDisplay,
                        wharfSession
                    );
                    }}
              
              >Claim {roundWalletBalance(item.claimable)}</SmallButton>
              </ClaimInfo>
                       </TokenCard>
                       
                   </span>
               )) : (
                 <div className="w-100 text-center pt-4">
                   No farms to show
                   </div>
               )}
           </span>
       )}
       {farmsAreLoading && currentClaimSection == "NFT Farms" && <LoadingDiv />}

       {!poolsAreLoading && (
           <span className={currentClaimSection != "Token Pools" && "hidden"}>

               {myPools?.length > 0 && (
                   <TallFiltersCont>
                       <div className="w-100 pl-4">
                       <SmallButton
                        onClick={() => {
                            claimAllPools(
                            setEnterModalText,
                            setLoadingDisplay,
                            setEnterModalDisplay,
                            wharfSession
                            );
                        }}                       
                       >
                           Claim All
                       </SmallButton>
                       </div>
                   </TallFiltersCont>
               )}

               {myPools?.length > 0 ? myPools.map((item, index) => (
                   <span key={index}>
                       <TokenCard>
                           <a href={`${currentWebsiteURL}/pool/${item.poolname}`}>
                       {MyAvatar({letters: item.poolname})}
              </a>
              <ClaimInfo>
              {item.poolname}<SmallButton
                onClick={() => {
                    claimRewards(
                        item.poolname,
                        setEnterModalText,
                        setLoadingDisplay,
                        setEnterModalDisplay,
                        wharfSession
                    );
                    }}
              
              >Claim {roundWalletBalance(item.claimable)}</SmallButton>
              </ClaimInfo>
                       </TokenCard>
                       
                   </span>
               )) : (
                <div className="w-100 text-center pt-4">
                No pools to show
                </div>
               )}
           </span>
       )}

       {poolsAreLoading && currentClaimSection == "Token Pools" &&  <LoadingDiv />}


      </span>
    );
};
