import { defaultSpinnerDuration, defaultTxSettings } from "../config";

export const handleRemoveAsset = (asset_id, assetVector, setAssetVector) => {
  const assetList = [...assetVector];
  assetList.splice(assetList.indexOf(asset_id), 1);
  setAssetVector(assetList);
};

export const handleAddAsset = (asset_id, assetVector, setAssetVector) => {
  setAssetVector([...assetVector, asset_id]);
};


export const createPack = async (
  assetVector,
  collection,
  templateID,
  totalAvailable,
  startTimestamp,
  dropDescription,
  logoHash,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

    if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession

    const action = [
      {
        account: "waxdaomarket",
        name: "createdrop",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          price: 0,
          token_symbol: "0,FREE",
          contract: "free",
          collection: collection,
          schema: "na",
          template_id: Number(templateID),
          total_available: Number(totalAvailable),
          limit_per_user: 0,
          cooldown: 0,
          whitelist_type: "none",
          allowed_users: [],
          farmname: "na",
          minimum_to_stake: 0,
          start_time: Number(startTimestamp),
          end_time: Number(4102488000),
          drop_description: dropDescription,
          drop_logo: logoHash,
          receiver: "na",
          pool_or_farm: "no",
          pool_or_farm_name: "na",
          percent_to_pool: 0,
          pool_id: assetVector,
          drop_type: "premint.pack",
          pack_template: Number(templateID),
        },
      },
    ];

    try {
      const result = await session.transact({ actions: action }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText("Your unbox link has been created")
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(e.message);
      setEnterModalDisplay("");
    }

}; //end createPack

export const filterPoolsByAvailable = (
  poolsList,
  PoolID,
  setPoolData,
  setTotalAvailable,
  setPoolStatus,
  setCollection,
  setAssetVector
) => {
  let filteredPools = [];
  let i = 0;
  let x = 0;
  let poolIDWasFound = false;
  let poolIDIsAvailable = false;
  let collection = "";

  while (i < poolsList.length) {
    if (poolsList[i].ID == PoolID) {
      poolIDWasFound = true;
      if (poolsList[i].status == "available") {
        poolIDIsAvailable = true;
      }
      setPoolData(poolsList[i]);
      setTotalAvailable(poolsList[i].assets.length);
      setPoolStatus(poolsList[i].status);
      collection = poolsList[i].collection;
      setCollection(collection);
      setAssetVector([Number(poolsList[i].ID)]);

      break;
    }
    i++;
  }

  if (poolIDWasFound && poolIDIsAvailable) {
    while (x < poolsList.length) {
      if (
        poolsList[x].status == "available" &&
        poolsList[x].collection == collection
      ) {
        filteredPools.push(poolsList[x]);
      }
      x++;
    }
  }

  return [filteredPools, poolIDWasFound, poolIDIsAvailable];
};

export const handleCalendarChange = (e, setStartTime) => {
  let date = new Date(e);
  const value = date.getTime() / 1000;

  setStartTime(value);
  return;
};
