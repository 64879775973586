import { useState } from "react";
import { currentApiList, v2FarmContract } from "../../data/config";
import axios from 'axios';

export const useGetV2Stakeables = () => {
    
    const [stakeables, setStakeables] = useState([]);
    const [stakeablesAreLoading, setStakeablesAreLoading] = useState(true);
    const [hasDataFetched, setHasDataFetched] = useState(false); // New state to check if data is already fetched

    const getStakeables = async (farm_type, farm_name) => {
        if(hasDataFetched) return; // If data is already fetched, don't make API calls

        setStakeablesAreLoading(true);
        let table;
        if(farm_type == 0){table = "valuesbycol"}
        else if(farm_type == 1){table = "valuesbysch"}
        else if(farm_type == 2){table = "valuesbytemp"}
        else if(farm_type == 3){table = "valuesbyatt"}

        let lower_bound = 0;
        let more = true;

        for (const api of currentApiList) {
            while(more) { // Loop until there's no more data
                try {
                    const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                        table: table,
                        scope: farm_name,
                        code: v2FarmContract,
                        limit: 500,
                        lower_bound: lower_bound,
                        json: true,
                    });
                    
                    if (res.data.rows) {
                        setStakeables(prevState => [...prevState, ...res.data.rows]); // Append new data to the stakeables array
                        more = res.data.more; // Update the "more" flag

                        // If more data exists, update the lower bound
                        if(res.data.more) {
                            lower_bound = res.data.next_key;
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }

        setHasDataFetched(true); // Set data fetched to true after API calls are finished
        setStakeablesAreLoading(false);
    }

    return [stakeables, getStakeables, stakeablesAreLoading]
}
