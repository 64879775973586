import React from 'react'
import { NotFoundCont } from './LeftDiv'

const Error = (props) => {
  return (
    <NotFoundCont>
      <br/>
        {props.error != null && props.error}
    </NotFoundCont>
  )
}

export default Error