import waxdao_ad from "./waxdao_ad.jpg";

export const APP_NAME = "WaxDAO";

export const defaultTxSettings = {
  broadcast: true,
  expireSeconds: 300
}

export const bulkyTxSettings = {
  broadcast: true,
  expireSeconds: 3600
}

export const defaultSpinnerDuration = 2500;

export const mainnetWebsiteURL = "https://waxdao.io";
const stagingWebsiteURL = "https://staging.waxdao.io"

export const testnetWebsiteURL = "https://test.waxdao.io";

export const localWebsiteURL = "http://localhost:3000";

export const adminServerURL = "http://localhost:5001";

export const getBlogPostEndpoint = "get-blog";

export const getAllBlogsEndpoint = "get-all-blogs";

export const removeBlogEndpoint = "remove-blog";

export const editBlogEndpoint = "edit-blog";

export const blogImagePath = "https://mikedcrypto.com/img/waxdao";

export const getStatsEndpoint = "get-stats";

export const tokenApi = "https://tokens.waxdaobp.io"

export const tokenServerURL = "https://api.waxdao.io";
export const waxdaoApi = 'https://waxdao-api.waxdaobp.io'
export const getAllTokensEndpoint = "get-all-tokens";
export const getTokenEndpoint = "get-token";
export const addTokenEndpoint = "add-token";
export const loginEndpoint = "login";
export const addUserEndpoint = "add-user";

//Backed NFTs API
export const backedTokensApi = 'https://backed.waxdaobp.io'

export const testnetChain = {
  chainId: "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "testnet.wax.pink.gg",
      port: "443",
    },
  ],
};

export const mainnetChain = {
  chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "api.waxdaobp.io",
      port: "443",
    },
  ],
};

export const apiList = [
  "https://waxtest.api.eosnation.io",
  "https://waxtest.api.eosnation.io",
  "https://waxtest.api.eosnation.io",
];

export const atomicApiList = [
  "https://test.wax.api.atomicassets.io",
  "https://test.wax.api.atomicassets.io",
];

export const hyperionApiList = [
  "https://wax.blokcrafters.io",
  "https://api.waxsweden.org",
  "https://wax.eosrio.io",
];

export const mainnetApiList = [
  "https://api.waxdaobp.io",
  "https://api.waxsweden.org",
  "https://wax.api.eosnation.io",
  //"https://waxapi.ledgerwise.io",
  "https://api.hivebp.io",
  "https://api2.hivebp.io",
  "https://api3.hivebp.io",
  "https://wax.eosusa.io",
  "https://wax.eosdac.io"
];

export const mainnetLightApiList = [
  "https://light-api.waxdaobp.io",
  "https://wax.light-api.net",
  "https://lightapi-wax.tacocrypto.io",
  "https://light-api-wax-main.wecan.dev",
];

export const mainnetAtomicApiList = [
  "https://aa.waxdaobp.io",
  //"https://atomicassets.ledgerwise.io",
  "https://wax.api.atomicassets.io",
  "https://atomic.hivebp.io",
  "https://wax.eosusa.io"
  // "https://api.wax-aa.bountyblok.io",
  // "https://aa-wax-public1.neftyblocks.com",
  // "https://api.wax.tgg.gg",
  // "https://wax-aa.eosdac.io",
  // "https://atomic.wax.eosrio.io"
];

export const mainnetAtomicHubLink = "https://wax.atomichub.io";

export const testnetAtomicHubLink = "https://wax-test.atomichub.io";

// THE CURRENT CONFIG RIGHT NOW

export const currentWebsiteURL = mainnetWebsiteURL;
const currentConfig = "mainnet";
export const currentUsername = localStorage.getItem('waxAccount');
export const currentWalletProvider = localStorage.getItem("walletProvider");

export const userSelectedAnAtomicApi = localStorage.getItem('userSelectedAnAtomicApi');
export const currentSelectedAtomicApi = localStorage.getItem('currentSelectedAtomicApi');

export const userSelectedAnApi = localStorage.getItem('userSelectedAnApi');
export const currentSelectedApi = localStorage.getItem('currentSelectedApi');

export const userSelectedALightApi = localStorage.getItem('userSelectedALightApi');
export const currentSelectedLightApi = localStorage.getItem('currentSelectedLightApi');



//Contract Names
export const v1MarketContract = "waxdaomarket";
export const v2DAOContract = "dao.waxdao";
export const v1FarmContract = "waxdaofarmer";
export const v2FarmContract = "farms.waxdao"
export const nameServiceContract = "names.waxdao";


let thisChain;
let thisApiList;
let thisAtomicApiList;
let thisAtomicHubLink;
let thisNetworkNameForAtomicHub;

if (currentConfig == "mainnet") {
  thisChain = mainnetChain;
  thisApiList = mainnetApiList;
  thisAtomicApiList = mainnetAtomicApiList;
  thisAtomicHubLink = mainnetAtomicHubLink;
  thisNetworkNameForAtomicHub = "wax-mainnet";
} else if (currentConfig == "testnet") {
  thisChain = testnetChain;
  thisApiList = apiList;
  thisAtomicApiList = atomicApiList;
  thisAtomicHubLink = testnetAtomicHubLink;
  thisNetworkNameForAtomicHub = "wax-testnet";
}

export const currentNetworkNameForAtomicHub = thisNetworkNameForAtomicHub;

export const floorPriceApiProvider = "https://aa.waxdaobp.io";

export const adminMode = false;

export const myChain = thisChain;

export const currentApiList = thisApiList;

export const currentAtomicApiList = thisAtomicApiList;

export const currentHyperionApiList = hyperionApiList;

export const currentAtomicHubLink = thisAtomicHubLink;

export const currentAdBanner = waxdao_ad;

export const currentIpfsEndpoint = "https://ipfs.waxdaobp.io/ipfs";

const isAtomicApiSelected = userSelectedAnAtomicApi && userSelectedAnAtomicApi !== 'false';
export const getCurrentAtomicApiList = isAtomicApiSelected ? currentSelectedAtomicApi : currentAtomicApiList[Math.floor(Math.random() * 3)];

const isApiSelected = userSelectedAnApi && userSelectedAnApi !== 'false';
export const getCurrentApiList = isApiSelected ? currentSelectedApi : currentApiList[Math.floor(Math.random() * 3)];

const isLightApiSelected = userSelectedALightApi && userSelectedALightApi !== 'false';
export const getCurrentLightApiList = isLightApiSelected ? currentSelectedLightApi : mainnetLightApiList[Math.floor(Math.random() * 3)];


export const getApiListByIndex = (index) => {
  return currentApiList[index];
};

//END OF THIS CURRENT CONFIG


export const notificationProps = {
  uniqueId: 1,
  message: "Hi"
}

export const waxExplorerLink = "https://waxblock.io";
