import { useEffect, useState } from "react";
import { useGetBlendRamBalance } from "../../components/CustomHooks/useGetBlendRamBalance";
import { useGetCollectionRamBalance } from "../../components/CustomHooks/useGetCollectionRamBalance";
import { useGetCollectionsByAuthorizedAccount } from "../../components/CustomHooks/useGetCollectionsByAuthorizedAccount";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentUsername } from "../../data/config";
import {
  PoolLineBreak,
  SinglePoolDetailBold,
} from "../../data/css/PoolPageStyles";
import {
  SendAllButton,
  TokenCard,
  TokenDetailsButton,
  TokenDetailsContainer,
} from "../../data/css/WalletStyles";
import {
  depositBlendRam,
  depositCollectionRam,
  getAssetQuantity,
  withdrawBlendRam,
  withdrawCollectionRam,
} from "../../data/functions/wallet_functions";
import {
  FoldersContainer,
  FoldersRow,
  MainButton,
  SingleFolderFourth,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import LoadingDiv from "../../components/LoadingDiv";
import { buildIpfsUrl } from "../../data/functions/global_functions";

export const showRamSection = (currentAssetSection, setLoadingDisplay) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
    wharfSession,
  } = useStateContext();

  const [currentRamSection, setCurrentRamSection] = useState("Blend RAM");

  return (
    <span className={currentAssetSection != "RAM" && "hidden"}>
      <TallFiltersCont>
        <TallFiltersInnerCont>
          <FoldersContainer>
            <FoldersRow>
              <SingleFolderFourth
                onClick={() => {
                  setCurrentRamSection("Blend RAM");
                }}
                selected={currentRamSection == "Blend RAM" && true}
              >
                Blend RAM
              </SingleFolderFourth>
              <SingleFolderFourth
                onClick={() => {
                  setCurrentRamSection("Collection RAM");
                }}
                selected={currentRamSection == "Collection RAM" && true}
              >
                Collection RAM
              </SingleFolderFourth>
            </FoldersRow>
          </FoldersContainer>
        </TallFiltersInnerCont>
      </TallFiltersCont>

      {showBlendRamSection(
        currentRamSection,
        enterModalDisplay,
        setEnterModalDisplay,
        enterModalText,
        setEnterModalText,
        setLoadingDisplay,
        wharfSession
      )}

      {showCollectionRamSection(currentRamSection, setLoadingDisplay)}
    </span>
  );
};

export const showBlendRamSection = (
  currentRamSection,
  enterModalDisplay,
  setEnterModalDisplay,
  enterModalText,
  setEnterModalText,
  setLoadingDisplay,
  wharfSession
) => {
  const [blendRamBalance, getBlendRamBalance, blendRamBalanceIsLoading] =
    useGetBlendRamBalance(currentUsername);
  const [blendRamAmount, setBlendRamAmount] = useState(0);

  useEffect(() => {
    if (currentRamSection == "Blend RAM") {
      getBlendRamBalance(currentUsername);
    }
  }, [currentRamSection]);

  if (!blendRamBalanceIsLoading && currentRamSection == "Blend RAM") {
    return (
      <span>
        <TallFiltersCont>
          <h2>{currentUsername}</h2>
          <br />
          <TallFiltersInnerCont>
            <span style={{ fontSize: "12px" }}>
              Blender Balance{" "}
              <PoolLineBreak>
                <br />
              </PoolLineBreak>
              <SinglePoolDetailBold>
                {blendRamBalance[0] != null
                  ? blendRamBalance[0].balance
                  : "0 WAX"}
              </SinglePoolDetailBold>
            </span>
            <br />
            <br />
            <h3>Amount</h3>
            <input
              value={blendRamAmount}
              onChange={(e) => setBlendRamAmount(e.target.value)}
            />
            <br />
            <SendAllButton
              onClick={() =>
                blendRamBalance[0] != null &&
                setBlendRamAmount(getAssetQuantity(blendRamBalance[0].balance))
              }
            >
              Withdraw all
            </SendAllButton>
            <br />
            <br />
            <MainButton
              onClick={() =>
                depositBlendRam(
                  blendRamAmount,
                  setEnterModalDisplay,
                  setEnterModalText,
                  setLoadingDisplay,
                  wharfSession
                )
              }
            >
              Deposit {blendRamAmount} WAX
            </MainButton>
            &nbsp;&nbsp;&nbsp;
            <MainButton
              onClick={() =>
                withdrawBlendRam(
                  blendRamAmount,
                  setEnterModalDisplay,
                  setEnterModalText,
                  setLoadingDisplay,
                  wharfSession
                )
              }
            >
              Withdraw {blendRamAmount} WAX
            </MainButton>
          </TallFiltersInnerCont>
        </TallFiltersCont>
      </span>
    );
  } else if (blendRamBalanceIsLoading) {
    return <LoadingDiv />;
  }
};

export const showCollectionRamSection = (
  currentRamSection,
  setLoadingDisplay
) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
    wharfSession,
  } = useStateContext();

  const [selectedCollection, setSelectedCollection] = useState("");
  const [collectionRamAmount, setCollectionRamAmount] = useState(0);

  const [myCollections, getMyCollections, collectionsWereLoaded] =
    useGetCollectionsByAuthorizedAccount(currentUsername);
  const [
    collectionRamBalance,
    getCollectionRamBalance,
    collectionRamBalanceIsLoading,
  ] = useGetCollectionRamBalance(selectedCollection);

  useEffect(() => {
    if (!collectionsWereLoaded && currentRamSection == "Collection RAM") {
      getMyCollections(currentUsername);
    }
  }, [currentRamSection]);

  useEffect(() => {
    if (selectedCollection != "") {
      getCollectionRamBalance(selectedCollection);
    }
  }, [selectedCollection]);

  return (
    <span className={currentRamSection != "Collection RAM" && "hidden"}>
      {!collectionsWereLoaded ? (
        <LoadingDiv />
      ) : (
        myCollections?.map((item, index) => (
          <TokenCard
            selected={selectedCollection == item.collection_name ? true : false}
            key={index}
          >
            <img
              src={item.img ? buildIpfsUrl(item.img, 200) : 'https://logos.waxdao.io/default-logo.png'}
              onError={(e) =>
                (e.target.onerror = null)(
                  (e.target.src = "https://logos.waxdao.io/default-logo.png")
                )
              }
            />
            {item.collection_name}
            <TokenDetailsButton
              onClick={() => {
                setSelectedCollection(
                  selectedCollection != item.collection_name
                    ? item.collection_name
                    : ""
                );
                setCollectionRamAmount(0);
              }}
            >
              Details
            </TokenDetailsButton>
            <br />
            <TokenDetailsContainer
              className={selectedCollection != item.collection_name && "hidden"}
            >
              <span style={{ fontSize: "12px" }}>
                Balance{" "}
                <PoolLineBreak>
                  <br />
                </PoolLineBreak>
                <SinglePoolDetailBold>
                  {collectionRamBalanceIsLoading && "Loading..."}
                  {!collectionRamBalanceIsLoading &&
                    collectionRamBalance[0] != null &&
                    collectionRamBalance[0].balance}
                  {!collectionRamBalanceIsLoading &&
                    collectionRamBalance[0] == null &&
                    "0 WAX"}
                </SinglePoolDetailBold>
              </span>
              <br />
              <br />
              <h3>Amount</h3>
              <input
                placeholder="0"
                value={collectionRamAmount}
                onChange={(e) => setCollectionRamAmount(e.target.value)}
              />
              <br />
              <SendAllButton
                onClick={() =>
                  !collectionRamBalanceIsLoading &&
                  collectionRamBalance[0] != null &&
                  setCollectionRamAmount(
                    getAssetQuantity(collectionRamBalance[0].balance)
                  )
                }
              >
                Withdraw all
              </SendAllButton>
              <br />
              <br />
              <MainButton
                onClick={() =>
                  depositCollectionRam(
                    collectionRamAmount,
                    setEnterModalDisplay,
                    setEnterModalText,
                    setLoadingDisplay,
                    selectedCollection,
                    wharfSession
                  )
                }
              >
                Deposit
              </MainButton>
              &nbsp;&nbsp;&nbsp;
              <MainButton
                onClick={() =>
                  withdrawCollectionRam(
                    collectionRamAmount,
                    setEnterModalDisplay,
                    setEnterModalText,
                    setLoadingDisplay,
                    selectedCollection,
                    wharfSession
                  )
                }
              >
                Withdraw
              </MainButton>
            </TokenDetailsContainer>
          </TokenCard>
        ))
      )}
    </span>
  );
};
