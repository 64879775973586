import { useState } from "react";
import { backedTokensApi, waxdaoApi } from "../../data/config";
import axios from 'axios';

export const useGetUserItem = () => {
    
    const [itemData, setItemData] = useState({});
    const [itemIsLoading, setItemIsLoading] = useState(true);

    const getItem = async (user, item) => {

        setItemIsLoading(true);

        let itemText;

        switch (item) {
          case "NFT Farms":
            itemText = "nft_farms";
            break;
          case "Token Farms":
            itemText = "token_farms";
            break;
          case "Blends":
            itemText = "blends";
            break;
          case "Drops":
            itemText = "drops";
            break;
          case "Unbox":
            itemText = "packs";
            break;
          case "DAOs":
            itemText = "daos";
            break;                                    
          default:
            itemText = "";
            break;
        }

        

        const requestData = {
            user: user,
            item: itemText
          };
          
          try {
            const res = await axios
            .post(`${waxdaoApi}/get-user-items`, requestData, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            })    

            if (res.data && res.data.rows) {
                setItemData(res.data.rows);
                console.log(res.data);
            }
        } catch (error) {
            console.log(`Failed to fetch item data: ` + error);
        } finally {
          setItemIsLoading(false);
        }           

    }  

    return [itemData, getItem, itemIsLoading]
}
