import { useState } from "react";
import { currentApiList, getCurrentApiList } from "../../data/config";
import axios from 'axios';

export const useGetAllPools = () => {
    
    const [pools, setPools] = useState([]);
    const [poolsAreLoading, setPoolsAreLoading] = useState(true);

    const getAllPools = () => {
        
        axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
            table: "pools",
            scope: "waxdaofarmer",
            code: "waxdaofarmer",
            limit: 1000,
            json: true,
            }).then((res) => {
                setPools(res.data.rows);
                console.log(res.data.rows);
            })
            .catch((error) => console.log(error))
            .finally(() => setPoolsAreLoading(false))

    }

    return [pools, getAllPools, poolsAreLoading]
}