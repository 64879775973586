import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { BenefitsCont, SingleBenefitTitle, SingleBenefitDescription, LastCallToActionCont, LastCallToActionTitle, HomepageFAQCont, HomepageFAQTitle, HomepageFAQDescription, HomepageQuestion, HomepageQuestionPlusSign, HomepageFAQAnswer, FAQHref, ModalOverlay, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton } from '../components/LeftDiv';
import cover_without_symbol from '../data/cover_without_symbol.png';
import { currentUsername, currentWebsiteURL, getCurrentApiList, mainnetWebsiteURL } from '../data/config';
import { AlreadyHaveText, WhitelistCallToAction } from '../data/css/TokenCreatorStyles';
import { burnTokens, createNewToken, deployStep1, issueTokens, transferTokens } from '../data/functions/TokenCreatorFunctions';
import DeployModal from '../components/DeployModal';
import { CallToActionContent } from '../data/css/HomePageStyles';
import { isLoggedIn } from '../data/functions/wallet_functions';
import { WOJAK_SECONDARY_LINK } from '../data/constants';
import { CreateLockButton, FolderTab2024, FoldersContainer2024, HeroLeft2024, HeroLeftBottom2024, HeroRight2024, HeroWrapper2024, HomepageNotification, HomepageWrapper2024, Modal2024, ModalContent2024, ModalOverlay2024, ProductOfWeekButtonWrapper, ProductOfWeekWrapper, theme2024 } from '../Styles2024';
import { Creator2024Button, CreatorLeftMiddle2024, FiltersCont2024, SpotlightButton, TokenCreatorLeftTop2024 } from './token_creator/TokenCreatorStyles';
import { ChooseExchangeCont, ChooseTokenButton, FoldersRowCentered, LockAmountInput } from './locker/LockStyles';
import { useGetTokenBalsFromLightApi } from '../components/CustomHooks/useGetTokenBalsFromLightApi';
import SelectTokenCreatorTokenModal from '../components/SelectTokenCreatorTokenModal';
import { useGetDynamicRatio } from '../components/CustomHooks/useGetDynamicRatio';

export const getWaxdaoPrice = (waxAmount, ratio) => {
  return Math.ceil(Number((Number(waxAmount) / Number(ratio.wax_per_waxdao)) * (1 - ratio?.current_discount))).toFixed(0);
}

const TokenCreator = () => {

  const { setCurrentNavbarLocation, wharfSession
  } = useStateContext();

 const [faqIsExpanded, setFaqIsExpanded] = useState(false);
 const [currentExpandedFAQAnswer, setCurrentExpandedFAQAnswer] = useState("");
 const [selectedAction, setSelectedAction] = useState("create");
 const [thisUsersTokens, setThisUsersTokens] = useState([]);
 const [selectedToken, setSelectedToken] = useState("");
 const [tokenToIssue, setTokenToIssue] = useState("");
 const [amountToIssue, setAmountToIssue] = useState(0);
 const [enterModalText, setEnterModalText] = useState("");
 const [enterModalDisplay, setEnterModalDisplay] = useState("hidden");
 const [loadingDisplay, setLoadingDisplay] = useState("none");
 const [amountToCreate, setAmountToCreate] = useState(0);
 const [symbolToCreate, setSymbolToCreate] = useState("");
 const [precisionToCreate, setPrecisionToCreate] = useState(8);
 const [amountToBurn, setAmountToBurn] = useState(0);
 const [selectedTokenToBurn, setSelectedTokenToBurn] = useState([]);
 const [precisionToBurn, setPrecisionToBurn] = useState(0);
 const [amountToTransfer, setAmountToTransfer] = useState(0);
 const [receiver, setReceiver] = useState("");
 const [precisionToTransfer, setPrecisionToTransfer] = useState(0);
 const [deployModalDisplay, setDeployModalDisplay] = useState("hidden");
 const [deployModalText, setDeployModalText] = useState("Deploying your contract...");
 const [refresh, setRefresh] = useState(false);

 const [tokenBalances, getTokenBalances, balancesAreLoading] = useGetTokenBalsFromLightApi();
 const [showSelectTokenModal, setShowSelectTokenModal] = useState(false);
 const [showSelectTokenToBurnModal, setShowSelectTokenToBurnModal] = useState(false);
 const [ratio, getDynamicRatio, ratioIsLoading] = useGetDynamicRatio();

 
 useEffect(() => {
  let isMounted = true;
  if (isMounted && !ratio?.next_push) {
      getDynamicRatio();
  }

  const interval = setInterval(() => {
      if (isMounted && ratio && new Date().getTime() > ratio.next_push * 1000) {
          getDynamicRatio();
      }
  }, 5000); 

  return () => {
      clearInterval(interval);
      isMounted = false;
  };
}, [ratio, getDynamicRatio]);


 useEffect(() => {
  let isMounted = true;

  if(isMounted){
    if (isLoggedIn()) {
      getTokenBalances();
    }
  }

  return () => {
    isMounted = false;
  }   

}, [refresh]);  


  useEffect(() => {

    setCurrentNavbarLocation("Tokens");

    if(!isLoggedIn()){
      return;
    }

    axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
        table:"accounts",
        scope:currentUsername,
        code:currentUsername,
        limit:100,
        json:true
      })
    .then((response) => {

      const tokensFound = response.data.rows;
    
      let i = 0;

      if(tokensFound.length > 0){
          while(i < tokensFound.length){
            var quantity = Number(response.data.rows[i].balance.substring(0, response.data.rows[i].balance.indexOf(" ")));
            var symbol = response.data.rows[i].balance.substring(response.data.rows[i].balance.indexOf(" ") + 1);
            var precision;
            if(response.data.rows[i].balance.substring(0, response.data.rows[i].balance.indexOf(" ")).indexOf(".") == -1){
                precision = 0;
            } else {
                precision = response.data.rows[i].balance.substring(response.data.rows[i].balance.indexOf(".") + 1, response.data.rows[i].balance.indexOf(" ")).length;
            }
            tokensFound[i] = {"amount": quantity, "symbol": symbol, "precision": precision};  
            i++;
          }
          console.log(tokensFound)
          setThisUsersTokens(tokensFound);
      } else {
          return;
      }

    })
    .catch((error) => console.log(error.response.data));

}, [])



const getStatTable = (amountToIssue, tokenToIssue, setEnterModalText, setLoadingDisplay, setEnterModalDisplay) => {
    if(!isLoggedIn()){ return; }

    setEnterModalDisplay("");
    setEnterModalText("Scanning contract...");
    setLoadingDisplay("");

    axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
        table:"stat",
        scope:tokenToIssue,
        code:currentUsername,
        limit:100,
        json:true
      })
    .then((response) => {
      const tokensFound = response.data.rows;
      
      var i = 0;

      if(tokensFound[i] != null){
            var quantity = Number(response.data.rows[i].max_supply.substring(0, response.data.rows[i].max_supply.indexOf(" ")));
            var symbol = response.data.rows[i].max_supply.substring(response.data.rows[i].max_supply.indexOf(" ") + 1);
            var precision;
            if(response.data.rows[i].max_supply.substring(0, response.data.rows[i].max_supply.indexOf(" ")).indexOf(".") == -1){
                precision = 0;
            } else {
                precision = response.data.rows[i].max_supply.substring(response.data.rows[i].max_supply.indexOf(".") + 1, response.data.rows[i].max_supply.indexOf(" ")).length;
            }
      
            setLoadingDisplay("none");

            issueTokens(amountToIssue, symbol, precision, setEnterModalText, setLoadingDisplay, setEnterModalDisplay, wharfSession);

            console.log(tokensFound)
      } else {
        setEnterModalText("Could not locate that token");
        setLoadingDisplay("none");
        console.log("Could not locate that token");
        return;
      }

    })
    .catch((error) => {
      console.log(error.response.data)
      if(error.response.data && error.response.data.error?.details){
        setEnterModalText(JSON.stringify(error.response.data.error.details));
      }
      else{
        setEnterModalText(JSON.stringify(error.response.data));
      }
      setLoadingDisplay("none")
    });
}



  return (
  <div id="seo">
    <Helmet>
    <title>Token Creator - WaxDAO</title>
    <meta name="description" content="WaxDAO builds tools for creators on Wax blockchain" />
    <link rel="canonical" href={`${mainnetWebsiteURL}/token-creator`} />
    </Helmet>

    <ModalOverlay2024 className={enterModalDisplay}/>
    <Modal2024 className={enterModalDisplay}>
    <div style={{width: "100%", textAlign: "right", paddingRight: "5px", fontSize: "22px", fontWeight: "400", color: theme2024.textMain}}><button
            onClick={() => {
                setEnterModalDisplay("hidden");
              }}            
            >X</button></div>      
      <ModalContent2024>
        {enterModalText}
      </ModalContent2024>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      </Modal2024>

            {showSelectTokenModal && (<span>
              <ModalOverlay2024 />
              <SelectTokenCreatorTokenModal 
              showAttributeModal={showSelectTokenModal}
              setShowAttributeModal={setShowSelectTokenModal}
              tokens = {tokenBalances}
              selectedToken = {selectedToken}
              setSelectedToken = {setSelectedToken} 
              setSelectedPrecision = {setPrecisionToTransfer}
            />
            </span>)}   

            {showSelectTokenToBurnModal && (<span>
              <ModalOverlay2024 />
              <SelectTokenCreatorTokenModal 
              showAttributeModal={showSelectTokenToBurnModal}
              setShowAttributeModal={setShowSelectTokenToBurnModal}
              tokens = {tokenBalances}
              selectedToken = {selectedTokenToBurn}
              setSelectedToken = {setSelectedTokenToBurn} 
              setSelectedPrecision = {setPrecisionToBurn}
            />
            </span>)}                   

<DeployModal className={deployModalDisplay} setDeployModalDisplay={setDeployModalDisplay} deployModalText={deployModalText} loadingDisplay={loadingDisplay} deployModalDisplay={deployModalDisplay} />
      {ratio && (
      <HomepageNotification>
        Pay with {Number(getWaxdaoPrice(250, ratio)).toLocaleString('en')} WAXDAO tokens, 250 WAX or 1 Wojak NFT
      </HomepageNotification>
      )}
    <HomepageWrapper2024>

    <HeroWrapper2024>
          <HeroLeft2024>
            <TokenCreatorLeftTop2024>Create Tokens</TokenCreatorLeftTop2024>
            <CreatorLeftMiddle2024>
              With No Coding
            </CreatorLeftMiddle2024>
            <HeroLeftBottom2024 buttons={false}>
            Launch your own WAX token in 5 minutes or less, using the WaxDAO token creator.
            </HeroLeftBottom2024>
            <HeroLeftBottom2024 buttons={true}>

                <a href={`https://youtu.be/qwdumwxJNxg`} target="none">
              <Creator2024Button primary={true}>WATCH TUTORIAL</Creator2024Button>
              </a>

              <Creator2024Button
                onClick={() => { 
                  deployStep1(setDeployModalText, setLoadingDisplay, setDeployModalDisplay, wharfSession); 
                  }}              
              >DEPLOY CONTRACT</Creator2024Button>

            </HeroLeftBottom2024>
          </HeroLeft2024>

          <HeroRight2024>
            <ProductOfWeekWrapper>
              <img src={cover_without_symbol} />
              <h2>Save Money</h2>
              <h3>Pay With WAXDAO</h3>
              <p>
                By paying with WAXDAO tokens, you can save on whitelisting fees.
              </p>

              <ProductOfWeekButtonWrapper>
              <a href={`${currentWebsiteURL}/swap`}>
                <SpotlightButton>Buy WAXDAO</SpotlightButton>
                </a>

                <SpotlightButton primary={true}
                  onClick={() => { transferTokens(getWaxdaoPrice(250, ratio), "WAXDAO", 8, "waxywojaknft", "createatoken", setEnterModalText, setLoadingDisplay, setEnterModalDisplay, wharfSession) }  }
                >Pay Now</SpotlightButton>

              </ProductOfWeekButtonWrapper>
            </ProductOfWeekWrapper>
          </HeroRight2024>
        </HeroWrapper2024>

        <BenefitsCont>


<AlreadyHaveText>
  <SingleBenefitTitle>
    Already have your token contract deployed?
  </SingleBenefitTitle>
  <SingleBenefitDescription>
    Choose an option below so you can create, issue, transfer or burn tokens.
  </SingleBenefitDescription>
</AlreadyHaveText>
</BenefitsCont>

<FiltersCont2024>
<h2>Manage Tokens</h2>
<FoldersContainer2024>
<FoldersRowCentered>
  <FolderTab2024
    selected={selectedAction == "create"}
    disabled={selectedAction == "create"}
    onClick={() => {
      setSelectedAction("create")
    }}   
  >
    Create
  </FolderTab2024>
  <FolderTab2024
    selected={selectedAction == "issue"}
    disabled={selectedAction == "issue"}
    onClick={() => {
      setSelectedAction("issue")
    }}   
  >
    Issue
  </FolderTab2024>
  <FolderTab2024
    selected={selectedAction == "transfer"}
    disabled={selectedAction == "transfer"}
    onClick={() => {
      setSelectedAction("transfer")
    }}   
  >
    Transfer
  </FolderTab2024>
  <FolderTab2024
    selected={selectedAction == "burn"}
    disabled={selectedAction == "burn"}
    onClick={() => {
      setSelectedAction("burn")
    }}   
  >
    Burn
  </FolderTab2024>      
</FoldersRowCentered>

<span className={ selectedAction != "create" && "hidden"}>
  <LockAmountInput>
  <br/>
<h3>Max Supply</h3>
<input value={amountToCreate} onChange={(e) => { setAmountToCreate(e.target.value); }} />
</LockAmountInput>


<LockAmountInput>
  <br/>
<h3>Symbol</h3>
<input value={symbolToCreate} placeholder="e.g. WAX" onChange={(e) => { setSymbolToCreate(e.target.value.toUpperCase()); }}  />

</LockAmountInput>

<LockAmountInput>
  <br/>
<h3>Precision</h3>
<input value={precisionToCreate} onChange={(e) => { setPrecisionToCreate(e.target.value); }}  />
<br/><br/>
</LockAmountInput>


    <CreateLockButton
        disabled={amountToCreate == 0 || amountToCreate == null || symbolToCreate == null || symbolToCreate.length == 0 || precisionToCreate == null || precisionToCreate.length == 0}
        onClick={() => {
          createNewToken(amountToCreate, symbolToCreate, precisionToCreate, setEnterModalText, setLoadingDisplay, setEnterModalDisplay, wharfSession)
        }}
    >
    {(amountToCreate == 0 || amountToCreate == null || symbolToCreate == null || symbolToCreate.length == 0 || precisionToCreate == null || precisionToCreate.length == 0) ?<span style={{color:theme2024.textSecondary}}>Enter supply details</span> : "CREATE TOKEN"}
    </CreateLockButton>
</span>

<span className={ selectedAction != "issue" && "hidden"}>
<LockAmountInput>
  <br/>
<h3>Amount</h3>
<input value={amountToIssue} onChange={(e) => { setAmountToIssue(e.target.value); }} />
</LockAmountInput>

<LockAmountInput>
  <br/>
<h3>Symbol</h3>
<input value={tokenToIssue} onChange={(e) => { setTokenToIssue(e.target.value); }} />
<br/><br/>
</LockAmountInput>


<CreateLockButton
        disabled={amountToIssue == 0 || amountToIssue == null || tokenToIssue == null || tokenToIssue.length == 0}
        onClick={() => {
          getStatTable(amountToIssue, tokenToIssue, setEnterModalText, setLoadingDisplay, setEnterModalDisplay)
        }}
    >
    {(amountToIssue == 0 || amountToIssue == null || tokenToIssue == null || tokenToIssue.length == 0) ?<span style={{color:theme2024.textSecondary}}>Enter details</span> : `ISSUE ${amountToIssue} ${tokenToIssue}`}
    </CreateLockButton>
</span>


<span className={ selectedAction != "transfer" && "hidden"}>

<LockAmountInput>
  <br/>
<h3>Amount</h3>
<input value={amountToTransfer} onChange={(e) => { setAmountToTransfer(e.target.value); }} />
<br/><br/>
</LockAmountInput>

        {isLoggedIn() && !balancesAreLoading &&
        (tokenBalances.some(balance => balance.contract === currentUsername) ? (
            <div>
                <ChooseExchangeCont>
                Which token do you want to transfer?
                </ChooseExchangeCont>

                <ChooseTokenButton
                    onClick={() => setShowSelectTokenModal(true)}
                >
                    {!selectedToken ? "Choose" : selectedToken}
                </ChooseTokenButton>
               

            </div>
            )
            :
                <ChooseTokenButton>
                    No balances to transfer
                </ChooseTokenButton>
        )
        }


    <LockAmountInput>
  <br/>
<h3>Receiver</h3>
<input value={receiver} onChange={(e) => { setReceiver(e.target.value.toLowerCase()); }} maxLength={12} />
<br/><br/>
</LockAmountInput>

<CreateLockButton
        disabled={amountToTransfer == 0 || amountToTransfer == null || receiver == null || receiver.length == 0 || selectedToken == null || selectedToken.length == 0}
        onClick={() => {
          transferTokens(amountToTransfer, selectedToken, precisionToTransfer, receiver, "Tokens sent using WaxDAO", setEnterModalText, setLoadingDisplay, setEnterModalDisplay, wharfSession)
        }}
    >
    {(amountToTransfer == 0 || amountToTransfer == null || receiver == null || receiver.length == 0 || selectedToken == null || selectedToken.length == 0) ?<span style={{color:theme2024.textSecondary}}>Enter transfer details</span> : `TRANSFER ${amountToTransfer} ${selectedToken}`}
    </CreateLockButton>

</span>

<span className={ selectedAction != "burn" && "hidden"}>

<LockAmountInput>
  <br/>
<h3>Amount</h3>
<input value={amountToBurn} onChange={(e) => { setAmountToBurn(e.target.value); }} />
<br/><br/>
</LockAmountInput>

        {isLoggedIn() && !balancesAreLoading &&
        (tokenBalances.some(balance => balance.contract === currentUsername) ? (
            <div className='mb-10'>
                <ChooseExchangeCont>
                Which token do you want to burn?
                </ChooseExchangeCont>

                <ChooseTokenButton
                    onClick={() => setShowSelectTokenToBurnModal(true)}
                >
                    {(!selectedTokenToBurn || selectedTokenToBurn.length == 0) ? "Choose" : selectedTokenToBurn}
                </ChooseTokenButton>
               

            </div>
            )
            :
                <ChooseTokenButton>
                    No tokens to burn
                </ChooseTokenButton>
        )
        }


<CreateLockButton
        disabled={amountToBurn == 0 || amountToBurn == null || selectedTokenToBurn == null || selectedTokenToBurn.length == 0}
        onClick={() => {
          burnTokens(amountToBurn, selectedTokenToBurn, precisionToBurn, setEnterModalText, setLoadingDisplay, setEnterModalDisplay, wharfSession);
        }}
    >
    {(amountToBurn == 0 || amountToBurn == null || selectedTokenToBurn == null || selectedTokenToBurn.length == 0) ?<span style={{color:theme2024.textSecondary}}>Enter burn details</span> : `BURN ${amountToBurn} ${selectedTokenToBurn}`}
    </CreateLockButton>
</span>

</FoldersContainer2024>

</FiltersCont2024>



<HomepageFAQCont>
<HomepageFAQTitle>
FAQ
</HomepageFAQTitle>
<HomepageFAQDescription>
Need some help getting started?
</HomepageFAQDescription>

<HomepageQuestion>
How do I get whitelisted?
</HomepageQuestion>
<HomepageQuestionPlusSign onClick={() => {
if(faqIsExpanded == false){setFaqIsExpanded(true); setCurrentExpandedFAQAnswer("Answer 1");}
else if(faqIsExpanded == true && currentExpandedFAQAnswer != "Answer 1"){setCurrentExpandedFAQAnswer("Answer 1");}
else{setFaqIsExpanded(false);}
}}>
+
</HomepageQuestionPlusSign>
<HomepageFAQAnswer className={(currentExpandedFAQAnswer != "Answer 1" || faqIsExpanded == false) && "hidden"}>
There are 3 options for whitelisting:
<ul><br/>
<li>• Pay <FAQHref href="https://www.coingecko.com/en/coins/wax" target="none">250 WAX</FAQHref></li>
<li>• Pay <FAQHref href="https://wax.alcor.exchange/swap?input=WAX-eosio.token&output=WAXDAO-token.waxdao" target="none">{ratio && Number(getWaxdaoPrice(250, ratio)).toLocaleString('en')} WAXDAO</FAQHref></li> 
<li>• Burn a <FAQHref href={WOJAK_SECONDARY_LINK} target="none">Wojak NFT</FAQHref></li>
</ul>
<br/>
For more detailed instructions, please watch the tutorial video above.
</HomepageFAQAnswer>



<HomepageQuestion>
How much RAM/CPU/NET do I need?
</HomepageQuestion>
<HomepageQuestionPlusSign onClick={() => {
if(faqIsExpanded == false){setFaqIsExpanded(true); setCurrentExpandedFAQAnswer("Answer 2");}
else if(faqIsExpanded == true && currentExpandedFAQAnswer != "Answer 2"){setCurrentExpandedFAQAnswer("Answer 2");}
else{setFaqIsExpanded(false);}
}}>
+
</HomepageQuestionPlusSign>
<HomepageFAQAnswer className={(currentExpandedFAQAnswer != "Answer 2" || faqIsExpanded == false) && "hidden"}>
You need 500k bytes of RAM. It is also ideal to have at least 2-3ms of CPU, and roughly 40-50 WAX worth of NET.
</HomepageFAQAnswer>


<HomepageQuestion>
Do I need to pay for each token I create?
</HomepageQuestion>
<HomepageQuestionPlusSign onClick={() => {
if(faqIsExpanded == false){setFaqIsExpanded(true); setCurrentExpandedFAQAnswer("Answer 3");}
else if(faqIsExpanded == true && currentExpandedFAQAnswer != "Answer 3"){setCurrentExpandedFAQAnswer("Answer 3");}
else{setFaqIsExpanded(false);}
}}>
+
</HomepageQuestionPlusSign>
<HomepageFAQAnswer className={(currentExpandedFAQAnswer != "Answer 3" || faqIsExpanded == false) && "hidden"}>
You only need to pay once for each WAX account that you want to add the token contract to. You can use that contract to create as many tokens as you like.
</HomepageFAQAnswer>


<HomepageQuestion>
Anchor won't let me deploy the contract.
</HomepageQuestion>
<HomepageQuestionPlusSign onClick={() => {
if(faqIsExpanded == false){setFaqIsExpanded(true); setCurrentExpandedFAQAnswer("Answer 4");}
else if(faqIsExpanded == true && currentExpandedFAQAnswer != "Answer 4"){setCurrentExpandedFAQAnswer("Answer 4");}
else{setFaqIsExpanded(false);}
}}>
+
</HomepageQuestionPlusSign>
<HomepageFAQAnswer className={(currentExpandedFAQAnswer != "Answer 4" || faqIsExpanded == false) && "hidden"}>
You need to enable the "Allow Dangerous Transactions" setting in Anchor wallet. They block any contract deployments by default, for your own protection.
</HomepageFAQAnswer>


<HomepageQuestion>
Can I use My Cloud Wallet?
</HomepageQuestion>
<HomepageQuestionPlusSign onClick={() => {
if(faqIsExpanded == false){setFaqIsExpanded(true); setCurrentExpandedFAQAnswer("Answer 5");}
else if(faqIsExpanded == true && currentExpandedFAQAnswer != "Answer 5"){setCurrentExpandedFAQAnswer("Answer 5");}
else{setFaqIsExpanded(false);}
}}>
+
</HomepageQuestionPlusSign>
<HomepageFAQAnswer className={(currentExpandedFAQAnswer != "Answer 5" || faqIsExpanded == false) && "hidden"}>
You can "technically" use any wallet that you own the private keys to. However, many users have reported issues when trying to deploy contracts using Cloud Wallet.{" "}
It is highly recommended to use Anchor Wallet.
</HomepageFAQAnswer>

<HomepageQuestion>
Why isn't the transaction working?!
</HomepageQuestion>
<HomepageQuestionPlusSign onClick={() => {
if(faqIsExpanded == false){setFaqIsExpanded(true); setCurrentExpandedFAQAnswer("Answer 6");}
else if(faqIsExpanded == true && currentExpandedFAQAnswer != "Answer 6"){setCurrentExpandedFAQAnswer("Answer 6");}
else{setFaqIsExpanded(false);}
}}>
+
</HomepageQuestionPlusSign>
<HomepageFAQAnswer className={(currentExpandedFAQAnswer != "Answer 6" || faqIsExpanded == false) && "hidden"}>
Usually the culprit is one of the following:
<ul><br/>
<li>• Insufficient RAM, CPU or NET</li>
<li>• You aren't using the latest version of Anchor wallet</li>
<li>• You don't have the contract deployed to your account</li> 
<li>• You are using a mobile device (contract must be deployed from a desktop computer)</li>
<li>• You are not whitelisted to use the token creator</li>
<li>• You don't like to follow directions and have ignored the entire tutorial</li>
</ul><br/>
If you need help, please join our <FAQHref href="https://t.me/hoodpunks" target="none">Telegram</FAQHref>
</HomepageFAQAnswer>

</HomepageFAQCont>





<LastCallToActionCont>
<LastCallToActionTitle>
Get whitelisted right now!
</LastCallToActionTitle>
<br/><br/>
<WhitelistCallToAction>
  <CallToActionContent onClick={() => { transferTokens("250", "WAX", 8, "waxywojaknft", "createatoken", setEnterModalText, setLoadingDisplay, setEnterModalDisplay, wharfSession) }  }>
  Pay 250 WAX
  </CallToActionContent>
</WhitelistCallToAction>
&nbsp;&nbsp;&nbsp;
<WhitelistCallToAction>
  <CallToActionContent onClick={() => { transferTokens(ratio && Number(getWaxdaoPrice(250, ratio)), "WAXDAO", 8, "waxywojaknft", "createatoken", setEnterModalText, setLoadingDisplay, setEnterModalDisplay, wharfSession) }  }>
  Pay {ratio && Number(getWaxdaoPrice(250, ratio)).toLocaleString('en')} WAXDAO
  </CallToActionContent>
</WhitelistCallToAction>


</LastCallToActionCont>

    </HomepageWrapper2024>


    </div>
  )
}

export default TokenCreator