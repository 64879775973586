import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { Helmet } from "react-helmet";
import {
  currentWebsiteURL,
  currentUsername,
  mainnetWebsiteURL,
} from "../../data/config";

import {
  CloseWindow,
  Modal,
  ModalContent,
  Spinner,
  SpinnerBlue,
  SpinnerGreen,
  SpinnerRed,
} from "../../components/LeftDiv";
import {
  finalizeV2Proposal,
  formatEndTime,
  showChoices,
  showCurrentResults,
  showProposalOutcomeText,
  showTransferWarning,
} from "./proposal_v2_functions";
import {
  FarmDescriptionContainer,
  MilestoneDescription,
  NewListingBody,
  NewListingCont,
  TallFiltersCont,
} from "../../Styles";
import {
  ProposalDetailCard,
  ProposalPageResultsWrapper,
  SubmitPropV2Button,
} from "./DAOStylesV2";
import { useGetV2ProposalInfo } from "../../components/CustomHooks/useGetV2ProposalInfo";
import LoadingPage from "../../components/LoadingPage";
import Error from "../../components/Error";
import { useGetV2DAOInfo } from "../../components/CustomHooks/useGetV2DAOInfo";
import { useGetProofAssetsForVoting } from "../../components/CustomHooks/useGetProofAssetsForVoting";
import { isLoggedIn } from "../../data/functions/wallet_functions";
import { voteInV2DAO } from "./dao_v2_functions";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import {
  CreateLockButton,
  Modal2024,
  ModalContent2024,
  ModalOverlay2024,
  PageBody2024,
  PageWrapper2024,
  theme2024,
} from "../../Styles2024";
import { DarkBgTextBox } from "../utilities/PowerupStyles";

export const formatVotes = (daoData, votes) => {
  if (daoData?.gov_token_symbol != "0,NULL") {
    let precision = daoData?.gov_token_symbol.split(",")[0];
    if (precision == 0) {
      return Number(votes).toLocaleString();
    } else {
      return (Number(votes) / Math.pow(10, Number(precision))).toLocaleString();
    }
  }
  return Number(votes).toLocaleString();
};

const ProposalPageV2 = ({ location }) => {
  const { currentColor, wharfSession } = useStateContext();

  const [enterModalDisplay, setEnterModalDisplay] = useState("hidden");
  const [enterModalText, setEnterModalText] = useState("");
  const [loadingDisplay, setLoadingDisplay] = useState("none");
  const [enterButtonsDisplay, setEnterButtonsDisplay] = useState("");

  const [selectedChoice, setSelectedChoice] = useState(null);

  //custom hooks
  const [proposalInfo, getProposalInfo, infoIsLoading] = useGetV2ProposalInfo();
  const [daoData, getDaoData, daoDataIsLoading] = useGetV2DAOInfo();
  const [proofAssets, getProofAssetsForVoting] = useGetProofAssetsForVoting();

  const { DAOName, ProposalID } = useParams();

  useEffect(() => {
    getProposalInfo(DAOName, ProposalID);
  }, []);

  useEffect(() => {
    if (!infoIsLoading && proposalInfo != null) {
      getDaoData(DAOName);
    }
  }, [infoIsLoading, proposalInfo]);

  useEffect(() => {
    if (
      !infoIsLoading &&
      !daoDataIsLoading &&
      proposalInfo != null &&
      daoData != null
    ) {
      if (isLoggedIn() && daoData.dao_type == 5) {
        getProofAssetsForVoting(currentUsername, daoData.gov_schemas);
      }
    }
  }, [infoIsLoading, daoDataIsLoading]);

  if (!infoIsLoading && proposalInfo && !daoDataIsLoading && daoData) {
    return (
      <div id="seo">
        <Helmet>
          <title>Proposal {ProposalID} - WaxDAO</title>
          <meta
            name="description"
            content={`Vote on proposal ${ProposalID} on WaxDao`}
          />
          <link
            rel="canonical"
            href={`${mainnetWebsiteURL}/v2/${DAOName}/proposals/${ProposalID}`}
          />
        </Helmet>

        <ModalOverlay2024 className={enterModalDisplay} />
        <Modal2024 className={enterModalDisplay}>
          <div
            style={{
              width: "100%",
              textAlign: "right",
              paddingRight: "5px",
              fontSize: "22px",
              fontWeight: "400",
              color: theme2024.textMain,
            }}
          >
            <button
              onClick={() => {
                setEnterModalDisplay("hidden");
              }}
            >
              X
            </button>
          </div>
          <ModalContent2024>{enterModalText}</ModalContent2024>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>
        </Modal2024>

        <span>
          <PageWrapper2024>
            <PageBody2024>
              <ProposalDetailCard>
                <h2>
                  {proposalInfo?.title}{" "}
                  {DAOName != null && (
                    <a href={`${currentWebsiteURL}/v2/daos/${DAOName}`}>
                      in {DAOName}
                    </a>
                  )}
                </h2>

                <div
                  className="flex flex-wrap gap-2 mt-2"
                  style={{ justifyContent: "right", marginBottom: "10px" }}
                >
                  {proposalInfo.outcome != null && (
                    <button>
                      {showProposalOutcomeText(
                        proposalInfo.end_time,
                        proposalInfo.outcome
                      )}
                    </button>
                  )}

                  {proposalInfo.proposal_id && (
                    <button>ID #{proposalInfo.proposal_id}</button>
                  )}

                  <button>
                    {proposalInfo?.end_time != null &&
                      formatEndTime(proposalInfo.end_time)}
                  </button>
                </div>

                <h3>
                  TOTAL VOTES:{" "}
                  <span style={{ color: theme2024.primary }}>
                    {formatVotes(daoData, proposalInfo?.total_votes)}
                  </span>
                </h3>
              </ProposalDetailCard>

              {showTransferWarning(proposalInfo)}

              <DarkBgTextBox>
                <ReactMarkdown>
                  {proposalInfo?.description != null &&
                    proposalInfo.description}
                </ReactMarkdown>
              </DarkBgTextBox>

              <ProposalPageResultsWrapper>
                <h2>Current Results</h2>
                {showCurrentResults(
                  daoData,
                  proposalInfo.choices,
                  proposalInfo.total_votes
                )}

                <br />
                <br />

                {proposalInfo?.end_time &&
                  proposalInfo?.end_time > Math.round(Date.now() / 1000) && (
                    <span>
                      <h2>Vote</h2>

                      {showChoices(
                        proposalInfo.choices,
                        selectedChoice,
                        setSelectedChoice
                      )}
                    </span>
                  )}

                {proposalInfo?.end_time &&
                  proposalInfo.end_time > Math.round(Date.now() / 1000) && (
                    <>
                      <CreateLockButton
                        disabled={selectedChoice === null}
                        selected={selectedChoice != null}
                        onClick={() => {
                          if (selectedChoice !== null) {
                            voteInV2DAO(
                              DAOName,
                              ProposalID,
                              selectedChoice,
                              proofAssets,
                              setEnterModalText,
                              setLoadingDisplay,
                              setEnterButtonsDisplay,
                              setEnterModalDisplay,
                              wharfSession
                            );
                          }
                        }}
                      >
                        Submit Vote
                      </CreateLockButton>
                      {daoData?.dao_type == 5 && (
                        <span>
                          <br />
                          <br />
                          You have {proofAssets?.length} NFTs to vote with
                          <br />
                          <br />
                        </span>
                      )}
                    </>
                  )}

                {proposalInfo?.end_time &&
                  proposalInfo?.end_time < Math.round(Date.now() / 1000) &&
                  proposalInfo?.outcome == 0 && (
                    <CreateLockButton
                      disabled={proposalInfo?.outcome !== 0}
                      onClick={() => {
                        finalizeV2Proposal(
                          DAOName,
                          ProposalID,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterButtonsDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        );
                      }}
                    >
                      {proposalInfo.outcome !== 0
                        ? "Finalized"
                        : "Finalize Proposal"}
                    </CreateLockButton>
                  )}
              </ProposalPageResultsWrapper>
            </PageBody2024>
          </PageWrapper2024>
        </span>
      </div>
    );
  } else if (infoIsLoading || daoDataIsLoading) {
    return <LoadingPage />;
  } else if (!infoIsLoading && proposalInfo == null) {
    return <Error error={"Proposal could not be found"} />;
  }
};

export default ProposalPageV2;
